import { Button, Skeleton, Stack } from '@mui/material';
import { ContactPanelHeader } from '../../../containers/ContactPanel/ContactPanelHeader';
import { getSquare } from '../../../theme/style.helpers';
import { SendIcon } from '../../../icons/common/SendIcon';
import range from 'lodash/range';

export const ContactPanelSkeleton = () => (
    <Stack>
        <ContactPanelHeader />
        <Stack
            sx={{
                mt: 6,
                px: 4,
                pb: 8,
                borderBottom: '1px solid',
                borderColor: 'divider',
            }}
            alignItems="center"
            width={1}
        >
            <Skeleton variant="circular" sx={getSquare(64)} />
            <Skeleton sx={{ mt: 3 }} width={120} />
            <Button
                size="small"
                color="info"
                sx={{ mt: 5 }}
                startIcon={<SendIcon />}
                fullWidth
                disabled
            >
                Message
            </Button>
        </Stack>
        <Stack px={4} py={2} gap={4}>
            {range(5).map((i) => (
                <Skeleton width={220} height={25} key={i} />
            ))}
        </Stack>
    </Stack>
);

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const Enter = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6667 3.64587C11.9083 3.64587 12.1042 3.84175 12.1042 4.08337V4.80211C12.1042 5.27626 12.1042 5.6587 12.0789 5.9684C12.0529 6.28727 11.9978 6.56735 11.8658 6.82648C11.6561 7.23808 11.3214 7.57273 10.9098 7.78245C10.6507 7.91448 10.3706 7.96953 10.0517 7.99558C9.74203 8.02088 9.35959 8.02088 8.88543 8.02087H3.38959L4.97607 9.60735C5.14692 9.7782 5.14692 10.0552 4.97607 10.2261C4.80521 10.3969 4.5282 10.3969 4.35735 10.2261L2.02401 7.89273C1.85316 7.72188 1.85316 7.44487 2.02401 7.27401L4.35735 4.94068C4.5282 4.76983 4.80521 4.76983 4.97607 4.94068C5.14692 5.11154 5.14692 5.38855 4.97607 5.5594L3.38959 7.14587H8.86671C9.36397 7.14587 9.71061 7.14553 9.98048 7.12349C10.2452 7.10185 10.3974 7.06153 10.5126 7.00282C10.7595 6.87699 10.9603 6.6762 11.0862 6.42924C11.1449 6.31402 11.1852 6.16191 11.2068 5.89714C11.2289 5.62728 11.2292 5.28064 11.2292 4.78337V4.08337C11.2292 3.84175 11.4251 3.64587 11.6667 3.64587Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

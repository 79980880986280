import { Stack, SxProps, Typography } from '@mui/material';
import { colors } from '../../theme/palette';
import { IconOuterButton } from '../../components/IconOuterButton/IconOuterButton';
import { ArrowDownwardFilled } from '../../icons/common/CArrowDownwardFilled';
import { ArrowUpwardFilled } from '../../icons/common/CArrowUpwardFilled';
import { Enter } from '../../icons/common/CEnter';

const container: SxProps = {
    p: 3,
    flexDirection: 'row',
    bgcolor: colors.lightGrey[50],
    alignItems: 'center',
    borderTopColor: 'divider',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
};
export const KeyboardTip = () => (
    <Stack direction="row" sx={container}>
        <IconOuterButton sx={{ mr: 0.5 }}>
            <ArrowDownwardFilled />
        </IconOuterButton>
        <IconOuterButton sx={{ mr: 2 }}>
            <ArrowUpwardFilled />
        </IconOuterButton>

        <Typography variant="body4">Navigate</Typography>
        <IconOuterButton sx={{ ml: 4, mr: 2 }}>
            <Enter />
        </IconOuterButton>
        <Typography variant="body4">Select</Typography>
    </Stack>
);

import React, { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ButtonBase, TextField } from '@mui/material';

type Props = Readonly<{
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}>;

export function EditableField({ value, onChange }: Props) {
    const [isWritable, setIsWritable] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState(isEmpty(value) ? '' : value);

    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    if (isWritable) {
        return (
            <TextField
                size="small"
                autoFocus
                variant="outlined"
                name="name"
                placeholder="Enter a name..."
                value={inputValue}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (['Enter', 'Escape'].includes(e.key)) {
                        e.preventDefault();
                        e.target?.blur();
                    }
                }}
                onChange={({ target }) => {
                    setInputValue(target.value);
                }}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    if (
                        e.currentTarget.value !== value ||
                        (!value && e.currentTarget.value) ||
                        (value && !e.currentTarget.value)
                    ) {
                        onChange(e);
                    }

                    setIsWritable(false);
                }}
            />
        );
    }

    return (
        <ButtonBase
            onClick={() => setIsWritable(true)}
            sx={({ typography }) => ({
                ...typography.body2,
                fontWeight: 500,
                py: 1,
                '&:hover': {
                    color: 'info.main',
                },
            })}
        >
            {value?.length ? value : `Enter a name`}
        </ButtonBase>
    );
}

import materialShadows, { Shadows } from '@mui/material/styles/shadows';

const initial: Shadows = [...materialShadows];
initial[1] = '0 1px 1px 0 rgba(31, 41, 54, 0.16)';
initial[2] = '0 2px 4px 0 rgba(31, 41, 54, 0.16)';
initial[3] = '0 1px 2px 0 #1F293614';
initial[4] = '0px 0px 0px 3px rgba(63, 93, 244, 0.12)';
initial[5] = '0 6px 32px 0 #1F293614';
initial[6] = '0px 0px 0px 3px rgba(140, 22, 150, 0.12)';

export const shadows = initial;

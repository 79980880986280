import { ChangeEvent, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, InputLabel, TextField } from '@mui/material';
import { addInviteeEmail, INVITEE_EMAIL_REGEXP } from '../../../utils/invite';
import { useInviteTeamMembers } from '../../../queries/user';
import { rolesOptions } from '../../../main/settings/Members/utils';
import { RoleTypes } from '../../../api/types';
import { useInboxes } from '../../../queries/inboxes';
import { UUID } from '../../../types/uuid';
import { InviteIcon } from './InviteIcon';
import { EnhancedSelect } from '../../EnhancedSelect/EnhancedSelect';
import FormHelperText from '@mui/material/FormHelperText';
import { GenericDialog } from '../GenericDialog/GenericDialog';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';
import { formatPhoneNumber } from '../../../utils/phoneNumber';

export interface InviteUserModalProps {
    open: boolean;
    onClose: () => void;
}

type Inputs = {
    email: string;
    role: RoleTypes;
    inboxIds: UUID[];
};

export const InviteUserModal = ({ open, onClose }: InviteUserModalProps) => {
    const { data: inboxes = [] } = useInboxes();
    const inboxesOptions = useMemo(
        () =>
            inboxes.map((inbox) => ({
                id: inbox.id,
                label: inbox.name || inbox.phone,
                description: formatPhoneNumber(inbox.phone),
            })),
        [inboxes],
    );

    const { mutate: invite, isSuccess } = useInviteTeamMembers();
    const {
        handleSubmit,
        formState: { errors, isValid },
        control,
        setError,
        clearErrors,
        watch,
        reset,
    } = useForm<Inputs>({
        defaultValues: {
            inboxIds: [],
        },
    });

    const selectedRole = watch('role');
    const selectedInboxIds = watch('inboxIds');

    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);

    const onSubmit = ({ email, role, inboxIds }: Inputs) => {
        const rawEmails = email.split(',');
        const isInputInvalid = rawEmails.some(
            (e) => !e.match(INVITEE_EMAIL_REGEXP),
        );

        if (isInputInvalid) {
            setError('email', { type: 'custom', message: 'Invalid email' });
            return;
        }

        const parsedEmails = rawEmails.reduce<string[]>(
            (emails, email) => addInviteeEmail(emails, email),
            [],
        );
        invite({ emails: parsedEmails, role, inboxIds });
        reset();
    };

    return (
        <GenericDialog
            open={open}
            onClose={onClose}
            IconComponent={InviteIcon}
            title="Invite a member"
            description="Once you invite a teammate, he (or she) receives an invitation link. Separate users by comma."
            onSubmit={handleSubmit(onSubmit)}
            confirmLabel="Send invite"
            confirmButtonProps={{
                disabled: !isValid,
            }}
            dismissButtonProps={{
                startIcon: <ArrowLeftIcon />,
            }}
            dismissLabel="Back"
        >
            <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                    return (
                        <TextField
                            error={!!errors.email}
                            fullWidth
                            value={value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                clearErrors('email');
                                onChange(String(e.target?.value));
                            }}
                            placeholder="Enter your teammate’s email"
                            label="Send invite to"
                            helperText={
                                !!errors.email &&
                                (errors.email.message ??
                                    'This field is required')
                            }
                        />
                    );
                }}
            />
            <Controller
                name="role"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                    return (
                        <FormControl error={!!errors.role} fullWidth>
                            <InputLabel htmlFor="role">Role</InputLabel>
                            <EnhancedSelect
                                id="role"
                                placeholder="Choose a role"
                                options={rolesOptions}
                                value={value}
                                onChange={(val) => onChange(val as RoleTypes)}
                                menuMaxWidth={372}
                                variant="outlined"
                            />
                            {!!errors.role && (
                                <FormHelperText>
                                    This field is required
                                </FormHelperText>
                            )}
                        </FormControl>
                    );
                }}
            />
            {![RoleTypes.Admin, RoleTypes.WorkspaceOwner].includes(
                selectedRole,
            ) && (
                <Controller
                    name="inboxIds"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <FormControl error={!!errors.inboxIds} fullWidth>
                                <InputLabel htmlFor="inboxIds">
                                    Phone
                                </InputLabel>
                                <EnhancedSelect
                                    id="inboxIds"
                                    searchable
                                    multiple
                                    hasCheckbox
                                    placeholder="Choose a phone"
                                    options={inboxesOptions}
                                    value={value}
                                    onChange={(value, checked) => {
                                        const inboxIds = selectedInboxIds ?? [];
                                        if (checked) {
                                            inboxIds.push(value as UUID);
                                        } else {
                                            inboxIds.splice(
                                                inboxIds.indexOf(value as UUID),
                                                1,
                                            );
                                        }
                                        onChange(inboxIds);
                                    }}
                                    variant="outlined"
                                />
                                {!!errors.inboxIds && (
                                    <FormHelperText>
                                        This field is required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        );
                    }}
                />
            )}
        </GenericDialog>
    );
};

import { useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CohortContactsListItem } from './CohortContactsListItem';
import styles from './ContactsList.module.scss';
import { Contact } from '../../api/types';
import ContactPanel from '../../containers/ContactPanel';
import searchIcon from '../../assets/icons/icons-16/search.svg';
import Input from '../../elements/Inputs/Input';
import Button from '../../elements/Buttons';
import { useMeQueryData } from '../../queries/user';
import { CampaignContact } from '../../api/campaign.types';

const filterContacts = (
    contacts: (Contact | CampaignContact | Partial<Contact>)[] = [],
    searchTerm: string,
) => {
    if (searchTerm) {
        const stLower = searchTerm.toLowerCase();
        return contacts.filter(
            ({ name, phone, email }) =>
                name?.toLowerCase().includes(stLower) ||
                phone?.toLowerCase().includes(stLower) ||
                email?.toLowerCase().includes(stLower),
        );
    } else {
        return contacts;
    }
};

interface CohortContactsListProps {
    contacts?: (Contact | CampaignContact | Partial<Contact>)[];
    selectedContact: Contact | CampaignContact | Partial<Contact> | null;
    renderEmpty?: boolean;
    onContactClick: (contact: Contact | CampaignContact | null) => void;
}

export const CohortContactsList = ({
    contacts = [],
    selectedContact,
    renderEmpty = false,
    onContactClick,
}: CohortContactsListProps) => {
    const currentUser = useMeQueryData();
    const [searchTerm, setSearchTerm] = useState<string>('');

    const filteredContacts = useMemo(
        () => filterContacts(contacts, searchTerm),
        [contacts, searchTerm],
    );

    if (renderEmpty) {
        return null;
    }

    if (selectedContact) {
        return (
            <div className={styles['contacts-panel__container']}>
                <ContactPanel
                    contactId={selectedContact.id}
                    contactPhone={selectedContact.phone}
                />
                <Button
                    type="text"
                    className={styles['button']}
                    onClick={() => onContactClick(null)}
                >
                    Back
                </Button>
            </div>
        );
    }

    const itemData = {
        countryCode: currentUser?.activeTeam.countryCode,
        contacts: filteredContacts,
        onClick: onContactClick,
    };

    return (
        <div className={styles['contacts-list']}>
            <Input
                className={styles['contacts-list__search-input']}
                placeholder="Search"
                prefix={<img src={searchIcon} alt="search" />}
                value={searchTerm}
                onChange={setSearchTerm}
                fullWidth
            />
            <div className={styles['contacts-list__contacts']}>
                <AutoSizer className={styles['contacts-list__autosizer']}>
                    {({ height }: { height: number }) => (
                        // @ts-ignore
                        <List
                            innerElementType="ul"
                            height={height}
                            className={styles['contacts-list__outer']}
                            itemCount={filteredContacts.length}
                            itemData={itemData}
                            itemSize={48}
                        >
                            {CohortContactsListItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

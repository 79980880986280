import {
    Box,
    Dialog,
    InputAdornment,
    MenuList,
    TextField,
} from '@mui/material';
import { KeyboardEvent, SetStateAction, useEffect, useState } from 'react';
import { SearchFilledIcon } from '../../icons/common/SearchFilledIcon';
import { ContactsList } from './ContactsList/ContactsList';
import { useDebounce } from '@uidotdev/usehooks';
import { KeyboardTip } from './KeyboardTip';
import { InboxesList } from './InboxesList/InboxesList';
import { ConversationsList } from './ConversationsList/ConversationsList';
import { WorkflowsList } from './WorkflowsList/WorkflowsList';
import { useCloseOnLocationChange } from './use-close-on-location-change';
import { container, dialogPaperProps, menuList } from './styles';
import { useTrack } from '../../contexts/analytics';
import { NavigationsList } from './NavigationsList/NavigationsList';
import { useHotkeys } from './use-hotkeys';

type Props = {
    setOpen: (value: SetStateAction<boolean>) => void;
    open: boolean;
};

export const SearchDialog = (props: Props) => {
    useCloseOnLocationChange(props);
    useHotkeys(props.setOpen);
    const track = useTrack();
    const [query, setQuery] = useState<string>('');
    const debounced = useDebounce(query, 330);
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);

    useEffect(() => {
        if (props.open) {
            track('global_search_queried', { queryLength: debounced });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    useEffect(() => {
        const links = document.querySelectorAll(
            '#search-dialog-holder .MuiMenuItem-root',
        );
        links.forEach((link, index) => {
            if (index === focusedIndex) {
                link.classList.add('Mui-selected');
                link.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                link.classList.remove('Mui-selected');
            }
        });
    }, [focusedIndex]);

    useEffect(() => {
        if (debounced) {
            setFocusedIndex(-1);
        }
    }, [debounced]);

    useEffect(() => {
        if (!props.open) {
            setQuery('');
            setFocusedIndex(-1);
        }
    }, [props.open]);

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.setOpen(false);
            return;
        }
        const links = document.querySelectorAll(
            '#search-dialog-holder .MuiMenuItem-root',
        );
        if (e.key === 'Enter') {
            links[focusedIndex]?.click();
            track('global_search_navigation', { action: 'enter' });
            return;
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setFocusedIndex((prev) => {
                if (prev === -1) {
                    return 0;
                }
                return prev + 1 < links.length ? prev + 1 : -1;
            });
            track('global_search_navigation', { action: 'down' });
            return;
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setFocusedIndex((prev) => {
                if (prev === -1) {
                    return links.length - 1;
                }
                return prev - 1;
            });
            track('global_search_navigation', { action: 'up' });
        }
    };

    return (
        <Dialog
            PaperProps={dialogPaperProps}
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <Box sx={container}>
                <Box sx={{ px: 4, pb: 0, width: 1 }}>
                    <TextField
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={onKeyDown}
                        autoFocus
                        fullWidth
                        placeholder="Search"
                        aria-label="Search"
                        InputProps={{
                            autoComplete: 'off',
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchFilledIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <MenuList
                    sx={menuList}
                    role="listbox"
                    id="search-dialog-holder"
                >
                    <ContactsList query={debounced} />
                    <ConversationsList query={debounced} />
                    <InboxesList query={query} />
                    <NavigationsList query={query} />
                    <WorkflowsList query={query} />
                </MenuList>
                <KeyboardTip />
            </Box>
        </Dialog>
    );
};

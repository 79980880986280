import { SxProps, Theme } from '@mui/material';
import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';
import { colors } from '../../theme/palette';

export const aside: SxProps = {
    width: ['100vw', '100vw', 248],
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: ['row', 'row', 'column'],
    borderRight: '1px solid',
    borderColor: 'divider',
    overflowWrap: 'anywhere',
    position: 'relative',
};

export const topRow: SxProps = {
    width: 1,
    borderBottom: '1px solid',
    borderColor: 'divider',
    height: HEADER_HEIGHT,
    bgcolor: colors.lightGrey[50],
};

export const section: SxProps<Theme> = (theme) => ({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    backgroundColor: 'custom.gray.superLight',
    borderTop: '1px solid',
    borderTopColor: 'custom.gray.divider',

    scrollbarWidth: 'none' /* Firefox */,
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,

    '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
    },

    [theme.breakpoints.down('md')]: {
        height: '1vh',
    },
});

export const content: SxProps<Theme> = (theme) => ({
    width: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 61,
        left: 0,
        display: 'none',
        zIndex: 10,
        minHeight: 'calc(100vh - 60px)',

        '&.isMenuOpen': {
            display: 'flex',
        },
    },
});

import { UUID } from '../types/uuid';
import client from './http';
import { Discussion, MessageComment } from './types';

export interface CreateDiscussionDto {
    messageId: number;
}

export interface CreateCommentDto {
    discussionId: number;
    text: string;
    mentionedUserIds?: UUID[];
    fileIds?: string[];
}

export function getDiscussion(discussionId: number): Promise<Discussion> {
    return client()
        .get(`/message-comments/discussion/${discussionId}`)
        .then((d) => d.data);
}

export function createDiscussion(
    dto: CreateDiscussionDto,
): Promise<Discussion> {
    return client()
        .post('/message-comments/discussion', dto)
        .then((d) => d.data);
}

export function createComment(dto: CreateCommentDto): Promise<MessageComment> {
    return client()
        .post('/message-comments', dto)
        .then((d) => d.data);
}

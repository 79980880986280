import { Message, MessageStatus } from '../../../../api/types';
import { useNow } from '../../../../components/ConversationRow/use-now';
import dayjs from 'dayjs';

export const useIsPlannedMessage = (message: Message) => {
    const now = useNow({
        interval: 30_000,
        disabled: message.status !== MessageStatus.Scheduled,
    });
    return (
        message.status === MessageStatus.Scheduled &&
        dayjs(now).diff(message.timestamp, 'seconds') < -60
    );
};

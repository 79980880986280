import { ParagraphElement } from '../../slate';
import { DefaultElement, RenderElementProps } from 'slate-react';
import { Typography } from '@mui/material';
import { memo } from 'react';

export type ParagraphProps = RenderElementProps & {
    element: ParagraphElement;
};

export const Paragraph = memo(function Paragraph(props: ParagraphProps) {
    return (
        <DefaultElement {...props}>
            <Typography variant="body3" color="primary.dark">
                {props.children}
            </Typography>
        </DefaultElement>
    );
});

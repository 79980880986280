import { Theme, useMediaQuery } from '@mui/material';

export const HideUpMd = ({
    children,
}: {
    children: React.ReactNode;
}): null | React.ReactNode => {
    const isUpMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
    return isUpMd ? null : children;
};

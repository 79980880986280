import { Box, ButtonBase, Chip } from '@mui/material';
import { Attachment } from '../../../../api/types';
import { getSquare } from '../../../../theme/style.helpers';
import { useState } from 'react';
import { AttachmentBackdrop } from './AttachmentBackdrop';
import { MimeTypeIcon } from '../../../../icons/common/files/MimeTypeIcon';
import { useTrack } from '../../../../contexts/analytics';

type Props = { attachment: Attachment };

export const AttachmentItem = ({ attachment }: Props) => {
    const track = useTrack();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        track('attachment_previewed');
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (attachment.type && attachment.type.includes('image/')) {
        return (
            <>
                <ButtonBase
                    onClick={handleClickOpen}
                    aria-label="Attachment preview"
                    sx={{
                        ...getSquare(32),
                        borderRadius: 1.5,
                        overflow: 'hidden',
                        backgroundColor: 'primary.main',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            ...getSquare(32),
                            maxWidth: 1,
                            maxHeight: 1,
                            objectFit: 'cover',
                            '&:hover': {
                                opacity: 0.85,
                                mixBlendMode: 'soft-light',
                            },
                        }}
                        src={attachment.url}
                        alt={attachment.name}
                    />
                </ButtonBase>
                <AttachmentBackdrop
                    attachment={attachment}
                    open={open}
                    onClick={handleClose}
                />
            </>
        );
    }

    return (
        <>
            <Chip
                variant="outlined"
                icon={<MimeTypeIcon type={attachment.type} />}
                onClick={handleClickOpen}
                label={attachment.name}
                sx={{ maxWidth: 220 }}
            />
            <AttachmentBackdrop
                attachment={attachment}
                open={open}
                onClick={handleClose}
            />
        </>
    );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ActionTypes {
    SET_QUICK_SEARCH_TERM = 'SET_QUICK_SEARCH_TERM',
    TOGGLE_FILTERS = 'TOGGLE_FILTERS',
    SET_COHORT = 'SET_COHORT',
    SET_COHORT_ID = 'SET_COHORT_ID',
    SET_FILTERS = 'SET_FILTERS',
    SET_SELECTED_CONTACTS = 'SET_SELECTED_CONTACTS',
    TOGGLE_MODE = 'TOGGLE_MODE',
    SET_CAMPAIGN_MODE = 'SET_CAMPAIGN_MODE',
    SET_LIMIT_REACHED = 'SET_LIMIT_REACHED',
}

export type Payload = any;

export interface Action {
    type: ActionTypes;
    payload?: Payload;
}

type Dispatch = (action: Action) => void;

const getActionCreatorFor =
    (dispatch: Dispatch, type: ActionTypes) => (payload?: Payload) =>
        dispatch({ type, payload });

// Actual actions
const createActions = (dispatch: Dispatch) => ({
    setQuickSearchTerm: getActionCreatorFor(
        dispatch,
        ActionTypes.SET_QUICK_SEARCH_TERM,
    ),
    setLimitReached: getActionCreatorFor(
        dispatch,
        ActionTypes.SET_LIMIT_REACHED,
    ),
    toggleFilters: getActionCreatorFor(dispatch, ActionTypes.TOGGLE_FILTERS),
    setCohort: getActionCreatorFor(dispatch, ActionTypes.SET_COHORT),
    setCohortId: getActionCreatorFor(dispatch, ActionTypes.SET_COHORT_ID),
    setFilters: getActionCreatorFor(dispatch, ActionTypes.SET_FILTERS),
    setSelectedContacts: getActionCreatorFor(
        dispatch,
        ActionTypes.SET_SELECTED_CONTACTS,
    ),
    toggleMode: getActionCreatorFor(dispatch, ActionTypes.TOGGLE_MODE),
    setCampaignMode: getActionCreatorFor(
        dispatch,
        ActionTypes.SET_CAMPAIGN_MODE,
    ),
});

export default createActions;

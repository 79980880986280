import { Contact } from '../api/types';
import isUndefined from 'lodash/isUndefined';

/** @deprecated */
export type ContactLibrary = {
    list: Contact[];
    mapByPhone: Record<string, number>;
};

/** @deprecated */
export const getEmptyLibrary = (): ContactLibrary => ({
    list: [],
    mapByPhone: {},
});

export const getEmpty = (
    phone: string,
): Omit<Contact, 'id' | 'created' | 'updated'> => ({
    name: '',
    data: {},
    email: '',
    avatarURL: '',
    phone,
    public: false,
});

export const findIndexById = (id: string, library: ContactLibrary) => {
    const idx = library.list.findIndex((contact) => contact.id === id);

    if (idx === -1) {
        return null;
    }
    return idx;
};

export const getById = (id: string | undefined, library: ContactLibrary) => {
    if (id) {
        const idx = findIndexById(id, library);
        if (idx !== null) {
            return library.list[idx];
        }
    }

    return null;
};

export const findIndexByPhone = (phone: string, library: ContactLibrary) => {
    const idx = library.mapByPhone[phone];

    if (typeof idx === 'undefined') {
        return null;
    }
    return idx;
};

export const updateContact = (contact: Contact, library: ContactLibrary) => {
    const { id, phone } = contact;

    if (phone) {
        const idxByPhone = findIndexByPhone(phone, library);
        if (idxByPhone !== null) {
            library.list[idxByPhone] = contact;
            return library;
        }
    }

    const idxById = findIndexById(id, library);
    if (idxById !== null) {
        library.list[idxById] = contact;

        if (contact.phone) {
            library.mapByPhone[contact.phone] = idxById;
        }
        return library;
    }

    return library;
};

export const getByPhoneOrEmpty = (
    phone: string | undefined,
    library: ContactLibrary,
) => {
    if (!phone) {
        return getEmpty('');
    }
    const index = library.mapByPhone[phone];
    if (!isUndefined(index)) {
        return library.list[index];
    }

    return getEmpty(phone);
};

export const getByPhone = (
    phone: string | undefined,
    library: ContactLibrary,
) => {
    if (phone) {
        const idx = findIndexByPhone(phone, library);
        if (idx !== null) {
            return library.list[idx];
        }
    }

    return null;
};

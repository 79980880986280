import cc from 'classcat';
import { useNavigate, useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { validate as uuidValidate } from 'uuid';
import { InboxConversationsList } from '../../components/ConversationsList/InboxConversationsList';
import InboxSetupFinish from '../../components/InboxSetupFinish';
import {
    InboxCampaignWorkflow,
    InboxConversationWorkflow,
} from '../../components/InboxWorkflow';
import Loading from '../../components/Loading/Loading';
import { ChatEditContactContainer } from '../../containers/ChatEditContactContainer/ChatEditContactContainer';
import * as styles from './styles';
import { useInboxes } from '../../queries/inboxes';
import ConversationDiscussion from '../../components/ConversationDiscussion';
import { NewConversation } from '../../components/NewConversation/NewConversation';
import { useTrack } from '../../contexts/analytics';
import { isMobile } from '../../utils/mobile';
import { usePropertiesQuery } from '../../queries/properties';
import { Campaign } from '../campaign/v3/Campaign';
import { CampaignMessage } from '../campaign/v3/CampaignMessage/CampaignMessage';
import { ModernInbox } from '../../pages/NewInbox/ModernInbox';
import { Entity } from '../../pages/NewInbox/query.helpers';
import { UUID } from '../../types/uuid';
import { useConversation } from '../../queries/conversations';
import { Box } from '@mui/material';
import { useEnabledFeature } from '../../queries/user';
import { Feature } from '../../api/types';
import { useInboxSearchParams } from './useInboxSearchParams';

type View = 'conversation' | 'campaign' | 'campaigns' | 'finish' | null;

const detectView = (entityId: string): View => {
    if (entityId === 'finish') {
        return 'finish';
    }

    if (entityId === 'campaigns') {
        return 'campaigns';
    }

    if (uuidValidate(entityId)) {
        return 'conversation';
    }

    const campaignId = Number.parseInt(entityId, 10);
    if (
        entityId?.length !== 36 && //definitely not a UUID
        Number.isFinite(campaignId) &&
        !Number.isNaN(campaignId) &&
        campaignId > 0
    ) {
        return 'campaign';
    }

    return null;
};

export interface InboxProps {
    inboxId: string;
    actionId: string;
    entity: Entity;
    entityId?: UUID | number;
    subEntityId?: number;
    campaignId?: number;
    campaignMessageId?: string;
}

export function InboxPage() {
    usePropertiesQuery();
    const navigate = useNavigate();
    const {
        inboxId,
        actionId,
        entity: paramsEntity,
        entityId: paramsEntityId,
        subEntityId,
        campaignId,
        campaignMessageId,
    } = useParams<{
        inboxId: string;
        actionId: string;
        entity: string;
        entityId?: string;
        subEntityId?: string;
        campaignId?: string;
        campaignMessageId?: string;
    }>();
    const query = useInboxSearchParams();
    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);
    const track = useTrack();
    const { data: inboxes = [], isLoading } = useInboxes();
    const [activeDiscussion, setActiveDiscussion] = useState<number | null>(
        null,
    );
    const [isCreationFlow, setCreationFlow] = useState(false);
    const [view, setView] = useState<View>(detectView(actionId!));
    const [contactForDetails, setContactForDetails] = useState<string | null>(
        null,
    );

    const inbox = useMemo(
        () => inboxes.find(({ id }) => id === inboxId),
        [inboxes, inboxId],
    );

    const { data: conversation } = useConversation(
        paramsEntity === 'conversations' ? paramsEntityId : undefined,
    );
    const hasContactInfo = useMemo(() => {
        return (
            paramsEntity === 'conversations' &&
            inbox &&
            conversation?.members.length === 1
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, inbox, conversation?.members.length]);

    const [isContactInfoShown, setContactInfoShown] = useState(
        hasContactInfo && !isMobile(),
    );

    const toggleContactInfo = useCallback(() => {
        if (!isContactInfoShown) {
            setActiveDiscussion(null);
        }
        setContactInfoShown((x) => (hasContactInfo ? !x : false));
    }, [hasContactInfo, isContactInfoShown]);

    useEffect(() => {
        if (inbox?.status === 'pending') {
            navigate(`/inbox/${inbox.id}/verify/customer`);
        }
    }, [inbox?.id, inbox?.status, navigate]);

    useEffect(() => {
        if (activeDiscussion) {
            setContactInfoShown(false);
        }
    }, [activeDiscussion]);

    useEffect(() => {
        setView(detectView(actionId!));
    }, [actionId]);

    useEffect(() => {
        setContactInfoShown(hasContactInfo && !isMobile());
        setActiveDiscussion(null);
    }, [inbox, hasContactInfo]);

    const onCreateClick = useCallback(() => {
        track('clicked_create_conversation', {
            inbox_id: inbox?.id,
        });

        setCreationFlow(true);
        setView(null);
        navigate(`/inbox/${inbox?.id}`);
    }, [track, inbox?.id, navigate]);

    const onFinishConversation = useCallback(
        (entityId: string | number, view: View) => {
            setCreationFlow(false);
            setView(view);
            if (view === 'conversation') {
                navigate(`/inbox/${inbox?.id}/${entityId}`);
            }
            if (view === 'campaign') {
                navigate(`/inbox/${inbox?.id}/campaigns/${entityId}`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inbox],
    );

    const onConversationDiscussionClose = useCallback(() => {
        setActiveDiscussion(null);
    }, [setActiveDiscussion]);

    if ([null, 'campaign', 'campaigns'].includes(view) && isNewInboxEnabled) {
        const entity = [
            Entity.Campaigns,
            Entity.Conversations,
            Entity.New,
        ].includes(paramsEntity as Entity)
            ? paramsEntity
            : Entity.Conversations;
        const entityId =
            entity === Entity.Campaigns && paramsEntityId
                ? +paramsEntityId
                : paramsEntityId;
        return (
            <ModernInbox
                inboxId={inboxId!}
                entity={entity as Entity}
                entityId={entityId}
                subEntityId={+subEntityId!}
                query={query}
                hasContactInfo={hasContactInfo}
            />
        );
    }

    if (isLoading || !inbox) {
        return <Loading />;
    }

    return (
        <Box
            sx={styles.inbox}
            className={cc({
                contactInfoShown: isContactInfoShown,
                discussionShown: activeDiscussion,
            })}
        >
            {inbox && (
                <Box
                    sx={styles.conversationsList}
                    className={cc({
                        mobileHidden:
                            isCreationFlow ||
                            contactForDetails ||
                            (view &&
                                ['conversation', 'campaign'].includes(view)),
                    })}
                >
                    <InboxConversationsList
                        key={inbox?.id}
                        inbox={inbox}
                        campaignId={+campaignId!}
                        onCreateClick={onCreateClick}
                    />
                </Box>
            )}
            {isCreationFlow && inbox && !view && (
                <NewConversation
                    inboxId={inbox?.id}
                    onFinish={onFinishConversation}
                />
            )}
            {view === 'finish' && <InboxSetupFinish />}
            {view === 'conversation' && inbox && (
                <InboxConversationWorkflow
                    inboxId={inbox?.id}
                    isContactInfoShown={isContactInfoShown}
                    conversationId={actionId ?? ''}
                    setContactForDetails={setContactForDetails}
                    toggleContactInfo={toggleContactInfo}
                    setActiveDiscussion={setActiveDiscussion}
                />
            )}
            {view === 'campaign' && (
                <InboxCampaignWorkflow
                    campaignId={Number.parseInt(actionId ?? '')}
                    toggleContactInfo={toggleContactInfo}
                />
            )}
            {view === 'campaigns' && !campaignMessageId && (
                <Campaign campaignId={campaignId ? +campaignId : undefined} />
            )}
            {view === 'campaigns' && !!campaignMessageId && !!campaignId && (
                <CampaignMessage
                    campaignId={+campaignId}
                    campaignMessageId={+campaignMessageId}
                />
            )}
            {isContactInfoShown && contactForDetails && (
                <ChatEditContactContainer
                    contactPhone={contactForDetails}
                    isContactInfoShown={isContactInfoShown}
                    onBack={toggleContactInfo}
                />
            )}
            {activeDiscussion !== null && (
                <ConversationDiscussion
                    discussionId={activeDiscussion}
                    onClose={onConversationDiscussionClose}
                />
            )}
        </Box>
    );
}

import { useEffect, useRef, useState } from 'react';
import cc from 'classcat';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import Input from '../../../../elements/Inputs/Input';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

export const EmailValue = ({ userProperty, onChange }: ValueProps<string>) => {
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(inputRef, () => setEditEnabled(false));

    useEffect(() => {
        if (isEditEnabled) {
            inputRef.current?.focus();
        }
    }, [isEditEnabled]);

    if (isEditEnabled) {
        return (
            <Input
                type="email"
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                value={userProperty.value}
                blurOnEnter={true}
                onBlur={(value) => {
                    setEditEnabled(false);
                    onChange?.({ ...userProperty, value });
                }}
                ref={inputRef}
                fullWidth
            />
        );
    }

    return (
        <div
            className={cc([styles['value'], styles['limited-value']])}
            title={userProperty.value}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                userProperty.value
            ) : (
                <span className={styles['value__empty']}>+ Set a value</span>
            )}
        </div>
    );
};

import { SxProps } from '@mui/material';
import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';

export const description: SxProps = {
    padding: '24px 0 32px 0',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 1px 0 0 #e2e5ea',
};

export const header: SxProps = {
    display: 'flex',
    height: HEADER_HEIGHT,
    px: 4,
    py: 4.5,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'divider',
};
export const root: SxProps = {
    height: 1,
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
};

import { useCallback, useMemo } from 'react';
import Loading from '../../../components/Loading/Loading';
import { useInboxesQueryData } from '../../../queries/inboxes';
import { useGetMicrosoftTeamsPhones } from '../../../queries/integrations';
import { StepShell } from '../../Onboarding/StepShell';
import { useTrack } from '../../../contexts/analytics';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Stack, Typography, Link } from '@mui/material';
import { useSetupMicrosoftTeamsInboxContext } from './setup-teams-phone-context';
import { TeamsPhone } from '../../../api/types';
import { PhonesList } from '../CarrierPhoneNumber/PhonesList';
import { MSTeams } from '../../../icons/carriers/MSTeams';
import { TeamsPhoneItem } from './TeamsPhoneItem';
import { useResetMicrosoftGraphCredentials } from '../../../queries/user';
import { useNavigate } from 'react-router';

export const MSTeamsPhoneSelectionStep = () => {
    const { selectedPhones, setSelectedPhones } =
        useSetupMicrosoftTeamsInboxContext();
    const inboxes = useInboxesQueryData();
    const { data = { phones: [] }, isPending } = useGetMicrosoftTeamsPhones();
    const track = useTrack();
    const { mutateAsync: resetMicrosoftGraphCredentials } =
        useResetMicrosoftGraphCredentials();
    const navigate = useNavigate();

    const availablePhones = useMemo(
        () =>
            (data.phones ?? []).filter((phone) => {
                return !(inboxes ?? []).find(
                    (inbox) => inbox.phone === phone.phone,
                );
            }),
        [inboxes, data],
    );

    const setUniqueSelectedPhones = useCallback(
        (phone: TeamsPhone) => {
            setSelectedPhones((phones) => {
                if (phones.some((p) => p.phone === phone.phone)) {
                    return phones.filter((p) => p.phone !== phone.phone);
                } else {
                    return [...phones, phone];
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedPhones],
    );

    const ctaText = useMemo(() => {
        if (selectedPhones.length === 0) {
            return 'Select Phone Numbers To Enable';
        }

        if (selectedPhones.length === 1) {
            return 'Enable SMS on 1 Phone';
        }

        if (selectedPhones.length > 1) {
            return `Enable SMS on ${selectedPhones.length} Phones`;
        }
    }, [selectedPhones.length]);

    return (
        <StepShell
            title="Activate SMS"
            icon={<MSTeams />}
            subtitle="Enable SMS on any of your Teams phones by selecting them below"
            goBackward="/setup-inbox/ms-teams/auth"
        >
            <Stack spacing={6} width={320}>
                {isPending ? (
                    <Loading />
                ) : (
                    <>
                        <PhonesList
                            phones={availablePhones}
                            selected={selectedPhones}
                            handleSelect={setUniqueSelectedPhones}
                            phoneToString={(phone) => phone.phone}
                            itemHeight={56}
                            ItemComponent={TeamsPhoneItem}
                        />
                        <Button
                            component={RouterLink}
                            to="/setup-inbox/ms-teams/info"
                            onClick={() => {
                                track('clicked_add_teams_phones', {
                                    phones: selectedPhones,
                                });
                            }}
                            disabled={selectedPhones!.length === 0}
                            fullWidth
                        >
                            {ctaText}{' '}
                        </Button>

                        <Stack textAlign="center">
                            <Typography variant="body4" color="primary.dark">
                                Not the right phone numbers appearing?
                            </Typography>
                            <Link
                                onClick={async () => {
                                    await resetMicrosoftGraphCredentials();
                                    navigate('/setup-inbox/existing-number');
                                }}
                                variant="body4"
                                color="info.main"
                            >
                                Retry with another account -&gt;
                            </Link>
                        </Stack>
                    </>
                )}
            </Stack>
        </StepShell>
    );
};

import React from 'react';
import { CardBrand } from '../../../../api/billing';
import { UnknownCardIcon } from '../../../../icons/common/UnknownCardIcon';
import { VisaIcon } from '../../../../icons/common/VisaIcon';
import { MastercardIcon } from '../../../../icons/common/MastercardIcon';
import { UnionPayIcon } from '../../../../icons/common/UnionPayIcon';
import { JcbIcon } from '../../../../icons/common/JcbIcon';
import { DiscoverIcon } from '../../../../icons/common/DiscoverIcon';
import { DinersIcon } from '../../../../icons/common/DinersIcon';
import { AmericanExpressIcon } from '../../../../icons/common/AmericanExpressIcon';

export const cardIconsMap: Record<CardBrand, React.FC> = {
    [CardBrand.Amex]: AmericanExpressIcon,
    [CardBrand.Diners]: DinersIcon,
    [CardBrand.Discover]: DiscoverIcon,
    [CardBrand.Jcb]: JcbIcon,
    [CardBrand.Mastercard]: MastercardIcon,
    [CardBrand.Unionpay]: UnionPayIcon,
    [CardBrand.Visa]: VisaIcon,
    [CardBrand.Unknown]: UnknownCardIcon,
};

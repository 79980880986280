import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { Redirect } from '../utils/redirect';
import styles from './index.module.scss';
import { askNotificationPermission } from '../notifications';
import { useMeQueryData } from '../queries/user';
import { get } from '../utils/local-storage';
import { useSocket } from '../websocket';
import { useNotifications } from './use-notifications';
import { useTeamsSubPage } from '../hooks/useTeamsSubPage';
import SideBar from '../containers/SideBar';
import useClarity from '../hooks/useClarity';

export function Main() {
    useNotifications();
    const socket = useSocket();
    const navigate = useNavigate();
    const subPage = useTeamsSubPage();
    const profile = useMeQueryData();

    const auth = get('auth');
    useClarity();

    useEffect(() => {
        if (subPage) {
            navigate(subPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subPage]);

    useEffect(() => {
        if (profile) {
            askNotificationPermission();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.id]);

    useEffect(() => {
        socket.init();
        return () => socket.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    return profile ? (
        <div className={styles['main']}>
            <SideBar />
            <Outlet />
        </div>
    ) : (
        <Redirect to="/login" />
    );
}

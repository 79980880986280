import { Components } from '@mui/material/styles';
import { pixelize } from '../pages/NewInbox/inbox.styles';
import { Theme } from '@mui/material';

export const MuiListItemText: Components<Theme>['MuiListItemText'] = {
    defaultProps: {
        primaryTypographyProps: {
            variant: 'body3',
        },
    },
    styleOverrides: {
        primary: {
            fontSize: 14,
            lineHeight: pixelize(20),
        },
        multiline: ({ theme }) => ({
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }),
        secondary: ({ theme }) => ({
            fontSize: 12,
            lineHeight: pixelize(16),
            letterSpacing: pixelize(0.2),
            color: 'text.secondary',
            marginTop: theme.spacing(1),
        }),
    },
};

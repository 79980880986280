import { Conversation } from '../../api/types';
import { queryClient } from '../../queries/queryClient';
import {
    CONVERSATION_MESSAGES_KEY,
    UNREADS_COUNT_KEY,
} from '../../queries/messages';
import { PagedData } from '../../types/PagedData';
import {
    CONVERSATION_KEY,
    CONVERSATIONS_LIST_KEY,
} from '../../queries/conversations';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';

export enum Action {
    Subscribe = 'subscribe',
    Unsubscribe = 'unsubscribe',
    Typing = 'typing',
}

export const CONVERSATION_UPDATED_EVENT = 'conversation:updated';
export type ConversationUpdateHandler = (c: Conversation) => void;

let conversations: Conversation[] = [];

const handleUpdates = () => {
    uniqBy(conversations.reverse(), 'id').map((conversation) => {
        queryClient.invalidateQueries({
            queryKey: [CONVERSATION_MESSAGES_KEY, conversation.id],
        });

        queryClient.setQueryData<Conversation>(
            [CONVERSATION_KEY, conversation.id],
            (prev) => (prev ? { ...prev, ...conversation } : prev),
        );

        queryClient.setQueriesData<PagedData<Conversation>>(
            {
                queryKey: [CONVERSATIONS_LIST_KEY, conversation.inboxId],
                exact: false,
            },
            (prev) => {
                if (!prev) {
                    return prev;
                }

                return {
                    ...prev,
                    pages: prev.pages.map((conversations) =>
                        conversations.map((item) => {
                            return item.id === conversation.id
                                ? { ...item, ...conversation }
                                : item;
                        }),
                    ),
                };
            },
        );
    });
    queryClient.invalidateQueries({
        queryKey: [UNREADS_COUNT_KEY],
    });
    conversations = [];
};
const throttledUpdates = throttle(handleUpdates, 1_000);

export const handleConversationUpdated: ConversationUpdateHandler = (
    conversation: Conversation,
) => {
    conversations.push(conversation);
    throttledUpdates();
};

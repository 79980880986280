import { SxProps } from '@mui/material';

export const radio: SxProps = { padding: 0, marginRight: 2 };

export const radioLabel: SxProps = { display: 'block', width: 1 };

export const toolbar: SxProps = {
    '& .MuiDateTimePickerToolbar-timeDigitsContainer': {
        alignItems: 'center',
    },
};

export const button: SxProps = { width: 104 };

export const checkbox: SxProps = {
    padding: 0,
    marginRight: 4,
};

export const checkboxWrapper: SxProps = {
    margin: 0,
    width: 1,

    '& .MuiFormControlLabel-label': {
        display: 'flex',
        flex: 1,
    },
};

export const infoIcon: SxProps = { width: 16, height: 16 };

export const phoneIcon: SxProps = { width: 20 };

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const PlusIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99992 2.70825C10.3451 2.70825 10.6249 2.98807 10.6249 3.33325V9.37492H16.6666C17.0118 9.37492 17.2916 9.65474 17.2916 9.99992C17.2916 10.3451 17.0118 10.6249 16.6666 10.6249H10.6249V16.6666C10.6249 17.0118 10.3451 17.2916 9.99992 17.2916C9.65474 17.2916 9.37492 17.0118 9.37492 16.6666V10.6249H3.33325C2.98807 10.6249 2.70825 10.3451 2.70825 9.99992C2.70825 9.65474 2.98807 9.37492 3.33325 9.37492H9.37492V3.33325C9.37492 2.98807 9.65474 2.70825 9.99992 2.70825Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { ValueProps } from '../../types';
import { LimitedTextValue } from '../LimitedTextValue';
import { useMeQueryData } from '../../../../queries/user';
import { parsePhoneNumber } from '../../../../utils/phoneNumber';

export const PhoneValue = (props: ValueProps<string>) => {
    const user = useMeQueryData();

    return (
        <LimitedTextValue
            {...props}
            validate={(nextUserProps) => {
                if (!nextUserProps?.value) {
                    return null;
                }

                const parsed = parsePhoneNumber(
                    nextUserProps?.value ?? '',
                    user?.activeTeam.countryCode,
                );

                return parsed ? null : 'Invalid phone number';
            }}
        />
    );
};

import { WithFeature } from '../../../containers/Feature/WithFeature';
import { ClerkPermission, Feature } from '../../../api/types';
import {
    Box,
    Chip,
    Collapse,
    Divider,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useMeQueryData } from '../../../queries/user';
import { usePermission } from '../../../containers/WithPermission/WithPermission';
import { useUpdateTeam } from '../../../queries/team';
import { useTrack } from '../../../contexts/analytics';
import { formControl, switchHolder } from './styles';

export const TeamWhitelistDomains = () => {
    const track = useTrack();
    const team = useMeQueryData()?.activeTeam;
    const canModify = usePermission(ClerkPermission.ModifyTeam);
    const { mutateAsync: updateAsync, isPending } = useUpdateTeam();

    const onChange = () =>
        updateAsync({
            whitelistDisabled: !team?.whitelistDisabled,
        }).then(() =>
            track(
                team?.whitelistDisabled
                    ? 'team_domain_whitelist_disabled'
                    : 'team_domain_whitelist_enabled',
            ),
        );

    return (
        <WithFeature feature={Feature.TeamDomainWhitelist}>
            <Collapse in={!isEmpty(team?.whitelistDomains)} sx={{ m: 0 }}>
                <Stack sx={{ py: 6 }}>
                    <Typography variant="body2">Whitelist domains</Typography>
                    <Stack my={4} py={2}>
                        <Stack
                            direction={['column', 'column', 'row']}
                            gap={4}
                            justifyContent="space-between"
                        >
                            <Stack>
                                <Typography variant="body3">
                                    Domains access
                                </Typography>
                                <Typography
                                    variant="body4"
                                    color="text.secondary"
                                >
                                    Allow users with whitelisted domains to join
                                    the team without an invite
                                </Typography>
                            </Stack>
                            <Box sx={switchHolder}>
                                <FormControlLabel
                                    value={!team?.whitelistDisabled}
                                    onChange={onChange}
                                    control={
                                        <Switch
                                            checked={!team?.whitelistDisabled}
                                            size="small"
                                            color="info"
                                            inputProps={{
                                                'aria-label':
                                                    'Allow whitelist domain switch',
                                            }}
                                        />
                                    }
                                    label={
                                        team?.whitelistDisabled
                                            ? 'Turn on'
                                            : 'Turn off'
                                    }
                                    labelPlacement="start"
                                    disabled={!canModify || isPending}
                                    sx={formControl}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack direction="row" gap={4} alignItems="center">
                        <Typography variant="body4" color="text.secondary">
                            Domains associated with your user email:
                        </Typography>
                        <Stack direction="row" gap={2}>
                            {team?.whitelistDomains.map((domain) => (
                                <Chip
                                    label={domain}
                                    key={domain}
                                    size="small"
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>

                <Divider />
            </Collapse>
        </WithFeature>
    );
};

import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography,
} from '@mui/material';
import { colors } from '../../../theme/palette';
import { section } from '../styles';
import { NavLink } from 'react-router-dom';
import { createElement } from 'react';
import { brandNavItem, items } from './navigation-items';
import { usePermission } from '../../WithPermission/WithPermission';
import { ClerkPermission, Feature } from '../../../api/types';
import { useEnabledFeature } from '../../../queries/user';
import { useTrack } from '../../../contexts/analytics';

type Props = { query: string };

export const NavigationsList = ({ query = '' }: Props) => {
    const track = useTrack();
    const brandOptEnabled = useEnabledFeature(Feature.BrandOptInIntegration);
    const accessBrand = usePermission(
        brandOptEnabled
            ? ClerkPermission.BrandAccess
            : ClerkPermission.ModifyTeam,
    );
    const isCallingStatisticsEnabled = useEnabledFeature(
        Feature.CallingStatistics,
    );
    const filtered = (accessBrand ? items.concat(brandNavItem) : items)
        .filter(
            ({ title, aliases }) =>
                title.toLowerCase().includes(query.toLowerCase()) ||
                aliases
                    .filter(
                        (alias) =>
                            isCallingStatisticsEnabled ||
                            alias !== 'Calling Statistics',
                    )
                    .some((alias) =>
                        alias.toLowerCase().includes(query.toLowerCase()),
                    ),
        )
        .slice(0, 3);

    if (!filtered.length) {
        return null;
    }

    return (
        <Box sx={section}>
            <Typography
                variant="body4"
                sx={{ color: colors.text.secondary, px: 3 }}
            >
                Workspace
            </Typography>
            {filtered?.map((page) => (
                <MenuItem
                    onClick={() => {
                        track('global_search_navigation', {
                            action: 'visit',
                            type: 'workspace',
                            queryLength: query.length,
                        });
                    }}
                    key={page.title}
                    component={NavLink}
                    to={page.to}
                >
                    <ListItemIcon>{createElement(page.icon)}</ListItemIcon>
                    <ListItemText>{page.title}</ListItemText>
                </MenuItem>
            ))}
        </Box>
    );
};

import { Contact } from '../../../api/types';
import throttle from 'lodash/throttle';
import { client } from '../../../api/http';

type PhonePromise = {
    promise: Promise<Contact | null>;
    resolve: (c: Contact | null) => void;
    reject: (reason: string) => void;
};

export class PhoneLookupAggregator {
    static THROTTLE_TIME = 200;

    private toFetch: Set<string>;

    private callbacks: Record<string, PhonePromise> = {};

    constructor() {
        this.toFetch = new Set([]);
    }

    private run = throttle(
        async () => {
            if (this.toFetch.size === 0) {
                return [];
            }
            const phones = [...this.toFetch].sort((a, b) => a.localeCompare(b));
            this.toFetch.clear();
            try {
                const contacts = await client
                    .post<Contact[]>('/v2/contacts/by-phones', { phones })
                    .then(({ data }) => data);

                phones.forEach((phone) => {
                    const contact = contacts.find((c) => c.phone === phone);
                    this.callbacks[phone]?.resolve(contact ?? null);
                    delete this.callbacks[phone];
                });
            } catch (e) {
                phones.forEach((phone) => {
                    this.callbacks[phone]?.reject(e as string);
                    delete this.callbacks[phone];
                });
            }
        },
        PhoneLookupAggregator.THROTTLE_TIME,
        { leading: false },
    );
    getContact(phone: string) {
        this.toFetch.add(phone);
        if (!this.callbacks[phone]) {
            this.callbacks[phone] = {} as PhonePromise;
            this.callbacks[phone].promise = new Promise<Contact | null>(
                (resolve, reject) => {
                    this.callbacks[phone].resolve = resolve;
                    this.callbacks[phone].reject = reject;
                },
            );
        }
        this.run();
        return this.callbacks[phone].promise;
    }
}

export const aggregator = new PhoneLookupAggregator();

import { SxProps } from '@mui/material';
import { HEADER_HEIGHT, pixelize } from '../inbox.styles';

export const container: SxProps = {
    display: 'grid',
    width: 1,
    gridTemplateRows: '1fr auto',
    height: [
        `calc(100vh - ${pixelize(HEADER_HEIGHT)} - 61px)`,
        `calc(100vh - ${pixelize(HEADER_HEIGHT)} - 61px)`,
        `calc(100vh - ${pixelize(HEADER_HEIGHT)})`,
    ],
};
export const historyHolder: SxProps = {
    display: 'flex',
    overflow: 'hidden',
    height: 1,
    width: 1,
};

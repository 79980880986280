import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuList,
    TextField,
    Typography,
} from '@mui/material';
import { FilterIcon } from './CFilterIcon';
import { useMenu } from './use-menu';
import { useCohorts } from '../../queries/cohorts';
import { useEffect, useState } from 'react';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useGoToQueryParam } from '../../pages/NewInbox/query.helpers';
import { useTrack } from '../../contexts/analytics';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { SearchIcon } from '../../icons/common/SearchIcon';

export type SpecialConversationFilter = 'me' | 'unread';
type Props = {
    disabled?: boolean;
    defaultSelectedCohorts?: number[];
    defaultFilter?: SpecialConversationFilter | '';
};

const getVerifiedFilter = (
    defaultFilter: 'me' | 'unread' | '' | undefined,
): SpecialConversationFilter | '' =>
    defaultFilter && ['me', 'unread'].includes(defaultFilter)
        ? defaultFilter
        : '';

export const InboxFilters = ({
    disabled = false,
    defaultSelectedCohorts = [],
    defaultFilter = '',
}: Props) => {
    const track = useTrack();
    const menu = useMenu();
    const cohorts = useCohorts();
    const goToQueryParam = useGoToQueryParam();

    const [selected, setSelected] = useState<number[]>(defaultSelectedCohorts);
    const [filter, setFilter] = useState<string>(
        getVerifiedFilter(defaultFilter),
    );
    const [term, setTerm] = useState<string>('');
    const filtered =
        (term
            ? cohorts.data?.filter((cohort) => cohort.name.includes(term))
            : cohorts.data) || [];

    useEffect(() => {
        const params = [
            [QueryParam.CohortIds, selected.join(',')],
            [QueryParam.SpecialFilter, filter],
        ];
        const unset = params.filter(([_, value]) => !value).map(([key]) => key);
        const query = fromPairs(params.filter(([_, value]) => value));
        goToQueryParam(query, unset);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, selected]);

    useEffect(() => {
        if (term.length) {
            track('cohort_filter_search_used');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term.length > 0]);

    useEffect(() => {
        if (menu.open) {
            setFilter(getVerifiedFilter(defaultFilter));
            setSelected(defaultSelectedCohorts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu.open]);

    return (
        <>
            <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    menu.handleOpen(e)
                }
                color="white-contained"
                disabled={disabled}
                aria-label="Filters"
                data-testid="filter-icon-button"
                size="large"
            >
                <FilterIcon />
            </IconButton>
            <Menu
                anchorEl={menu.anchorEl}
                onClose={() => menu.handleClose()}
                open={menu.open}
                slotProps={{
                    paper: {
                        style: {
                            maxWidth: 270,
                        },
                    },
                }}
            >
                <Typography
                    sx={{ px: 2.5, py: 2 }}
                    variant="body2"
                    id="filter-special-label"
                >
                    Filter by:
                </Typography>
                <Divider />
                <MenuList>
                    <FormGroup
                        sx={{ width: 1 }}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            track('inbox_filter_clicked', {
                                filter: event.target?.value,
                            });
                            setSelected([]);
                            setFilter((prev) =>
                                prev === event.target?.value
                                    ? ''
                                    : event.target?.value,
                            );
                        }}
                        data-testid="filter-special-items"
                        aria-labelledby="filter-special-label"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            data-testid="filter-me-option"
                            sx={{ px: 0, width: 1, mx: 0 }}
                            size="small"
                            label="Assigned to me"
                            value="me"
                            control={
                                <Checkbox
                                    checked={filter === 'me'}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    size="small"
                                />
                            }
                            componentsProps={{
                                typography: { variant: 'body3' },
                            }}
                        />
                        <FormControlLabel
                            sx={{ px: 0, width: 1, mx: 0 }}
                            size="small"
                            label="Unread messages"
                            value="unread"
                            control={
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon />}
                                    checked={filter === 'unread'}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                    size="small"
                                />
                            }
                            componentsProps={{
                                typography: { variant: 'body3' },
                            }}
                            data-testid="filter-unread-option"
                        />
                    </FormGroup>
                </MenuList>
                <Divider />
                <Box sx={{ px: 2.5, pt: 2 }}>
                    <Typography
                        variant="body3"
                        display="block"
                        pb={2}
                        color="text.secondary"
                        id="filter-cohorts-label"
                    >
                        Saved cohorts
                    </Typography>
                    <TextField
                        aria-label="Search"
                        size="small"
                        placeholder="Search"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </Box>
                <MenuList
                    sx={{ maxHeight: 400, overflow: 'auto' }}
                    data-testid="inbox-filter-cohort-list"
                >
                    {filtered.map((cohort) => (
                        <FormControlLabel
                            key={cohort.id}
                            onChange={() => {
                                track('inbox_filter_cohort_clicked', {
                                    cohortId: cohort.id,
                                });
                                setFilter('');
                                setSelected((prev) =>
                                    prev.includes(cohort.id)
                                        ? prev.filter((id) => id !== cohort.id)
                                        : prev.concat(cohort.id),
                                );
                            }}
                            sx={{ width: 1, px: 0, mx: 0 }}
                            componentsProps={{
                                typography: { variant: 'body3' },
                            }}
                            data-testid="filter-cohort-option"
                            size="small"
                            control={
                                <Checkbox
                                    checked={selected.includes(cohort.id)}
                                    size="small"
                                />
                            }
                            label={cohort.name}
                        />
                    ))}
                    {term.length > 1 && isEmpty(filtered) && (
                        <Box
                            sx={{
                                px: 2.5,
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                            }}
                        >
                            <ListItemIcon>
                                <CloseIcon color="error" />
                            </ListItemIcon>
                            <ListItemText>
                                Nothing found for &quot;{term}&quot;
                            </ListItemText>
                        </Box>
                    )}
                </MenuList>
            </Menu>
        </>
    );
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const FileTypePDF = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32" fill="none">
            <g id="Group">
                <g id="Vrstva_x0020_1_15_">
                    <path
                        id="Vector"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.19984 0H14.8985L23.9659 9.45128V27.8341C23.9659 30.1369 22.1029 32 19.808 32H4.19984C1.897 32 0.0339355 30.1369 0.0339355 27.8341V4.1659C0.0338951 1.86306 1.89696 0 4.19984 0Z"
                        fill="#E5252A"
                    />
                    <g id="Group_2">
                        <path
                            id="Vector_2"
                            opacity="0.302"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.8904 0V9.37931H23.9658L14.8904 0Z"
                            fill="white"
                        />
                        <path
                            id="Vector_3"
                            d="M4.66357 23.8761V18.031H7.15034C7.76604 18.031 8.25381 18.1989 8.62162 18.5428C8.98942 18.8786 9.17335 19.3344 9.17335 19.9021C9.17335 20.4698 8.98942 20.9256 8.62162 21.2614C8.25381 21.6052 7.76604 21.7731 7.15034 21.7731H6.15883V23.8761H4.66357ZM6.15883 20.5018H6.98243C7.2063 20.5018 7.38222 20.4538 7.50218 20.3419C7.6221 20.2379 7.6861 20.094 7.6861 19.9021C7.6861 19.7102 7.62214 19.5663 7.50218 19.4623C7.38226 19.3504 7.20634 19.3024 6.98243 19.3024H6.15883V20.5018ZM9.78901 23.8761V18.031H11.86C12.2678 18.031 12.6516 18.087 13.0114 18.2069C13.3712 18.3268 13.6991 18.4948 13.9869 18.7267C14.2748 18.9505 14.5066 19.2544 14.6746 19.6382C14.8345 20.022 14.9224 20.4618 14.9224 20.9575C14.9224 21.4453 14.8345 21.8851 14.6746 22.2689C14.5066 22.6527 14.2748 22.9565 13.9869 23.1804C13.699 23.4123 13.3712 23.5802 13.0114 23.7002C12.6516 23.8201 12.2678 23.8761 11.86 23.8761H9.78901ZM11.2523 22.6047H11.6841C11.9159 22.6047 12.1318 22.5808 12.3317 22.5248C12.5236 22.4688 12.7075 22.3808 12.8835 22.2609C13.0514 22.141 13.1873 21.973 13.2833 21.7492C13.3792 21.5253 13.4272 21.2614 13.4272 20.9575C13.4272 20.6457 13.3792 20.3818 13.2833 20.158C13.1873 19.9341 13.0514 19.7662 12.8835 19.6462C12.7075 19.5263 12.5237 19.4383 12.3317 19.3824C12.1318 19.3264 11.9159 19.3024 11.6841 19.3024H11.2523V22.6047ZM15.6741 23.8761V18.031H19.832V19.3024H17.1693V20.2379H19.2962V21.5013H17.1693V23.8761H15.6741Z"
                            fill="white"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </SvgIcon>
);

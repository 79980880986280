import { Box, IconButton, Menu } from '@mui/material';
import { Conversation } from '../../api/types';

import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { useRef, useState } from 'react';
import styles from './ConversationRow.module.scss';
import { memo } from 'react';
import { ConversationActions } from './ConversationActions';

type Props = {
    conversation: Conversation;
};
export const ContextMenu = memo(function ContextMenu({ conversation }: Props) {
    const anchor = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    return (
        <>
            <Box
                sx={{ opacity: open ? 1 : undefined }}
                className={styles.menuButton}
            >
                <IconButton
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        setOpen(true);
                    }}
                    ref={anchor}
                    sx={{ padding: 0, '&:hover': { color: 'blue.main' } }}
                    aria-label="Context menu"
                    size="small"
                >
                    <BiDotsHorizontalRounded />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchor.current}
                open={open}
                onClose={() => setOpen(false)}
            >
                <ConversationActions conversation={conversation} />
            </Menu>
        </>
    );
});

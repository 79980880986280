import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { CardBrand, StripePaymentMethod } from '../../../api/billing';
import { DotDivider } from '../../../components/common/DotDivider';
import { cardIconsMap } from './utils/cardIconsMap';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';
import { useConfirmDelete } from './hooks/use-confirm-delete';
import {
    useDeletePaymentMethod,
    useUpdateDefaultPaymentMethod,
} from '../../../queries/billing';
import { useMemo, useState } from 'react';
import { CustomStar } from '../../../icons/common/CCustomStar';

const CardIcon = ({ cardBrand }: { cardBrand: CardBrand }) => {
    const Icon = cardIconsMap[cardBrand];
    return <Icon />;
};

export const PaymentMethod = ({
    paymentMethod,
    isLast,
    isMoreThanOne,
    isDefault,
}: {
    paymentMethod: StripePaymentMethod;
    isLast: boolean;
    isMoreThanOne: boolean;
    isDefault: boolean;
}) => {
    const [confirm, Dialog] = useConfirmDelete();
    const [isHovered, setIsHovered] = useState(false);
    const { mutateAsync: deletePaymentMethod, isPending: isDeletePending } =
        useDeletePaymentMethod();

    const {
        mutateAsync: updateDefaultPaymentMethod,
        isPending: isUpdateDefaultPending,
    } = useUpdateDefaultPaymentMethod();

    const cardDetails = useMemo(
        () =>
            `${paymentMethod?.card?.display_brand.split('_').join(' ')} **** ${paymentMethod?.card?.last4}`,
        [paymentMethod.card],
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'capitalize',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '34px',
                            height: '24px',
                            mr: 4,
                        }}
                    >
                        <CardIcon cardBrand={paymentMethod.card.brand} />
                    </Box>{' '}
                    <Typography variant="body3">{cardDetails}</Typography>
                    <DotDivider />
                    <Typography variant="body3" color="text.secondary">
                        Expires {paymentMethod.card.exp_month}/
                        {paymentMethod.card.exp_year.toString().slice(-2)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    {isHovered && !isDefault && !isUpdateDefaultPending && (
                        <Tooltip
                            PopperProps={{ sx: { textAlign: 'center' } }}
                            placement="top"
                            title="Set as default"
                        >
                            <IconButton
                                size="medium"
                                color="primary"
                                onClick={() => {
                                    updateDefaultPaymentMethod(
                                        paymentMethod.id,
                                    );
                                }}
                            >
                                <CustomStar
                                    sx={{
                                        color: 'custom.text.secondary',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isUpdateDefaultPending && (
                        <CircularProgress size={18} color="error" />
                    )}
                    {isDefault && (
                        <Tooltip
                            PopperProps={{ sx: { textAlign: 'center' } }}
                            placement="top"
                            title={
                                isMoreThanOne
                                    ? 'To delete your current default card, please select another card as the default first'
                                    : 'You should add another card if you want to delete a default card'
                            }
                        >
                            <Chip
                                label="Default card"
                                size="small"
                                sx={{ px: 2, cursor: 'pointer' }}
                            />
                        </Tooltip>
                    )}
                    {!isDefault && (
                        <IconButton
                            disabled={isDeletePending}
                            sx={{ color: 'error.main' }}
                            onClick={async () => {
                                if (await confirm()) {
                                    deletePaymentMethod(paymentMethod.id);
                                }
                            }}
                        >
                            {isDeletePending ? (
                                <CircularProgress size={18} color="error" />
                            ) : (
                                <DeleteIcon />
                            )}
                        </IconButton>
                    )}

                    <Dialog
                        isLoading={isDeletePending}
                        cardDetails={cardDetails}
                    />
                </Box>
            </Box>
            {!isLast && <Divider />}
        </>
    );
};

import client from './http';
import {
    AttachWorkflowDto,
    CreateWorkflowDto,
    RenameWorkflowDto,
} from './types';
import { Workflow } from '../components/workflows/types';

export function createWorkflow(payload: CreateWorkflowDto): Promise<Workflow> {
    return client()
        .post('/workflows', payload)
        .then((res) => res.data);
}

export function updateWorkflow(
    id: number,
    payload: CreateWorkflowDto,
): Promise<Workflow> {
    return client()
        .put(`/workflows/${id}`, payload)
        .then((res) => res.data);
}

export function removeWorkflow(ids: number[]): Promise<void> {
    return client()
        .post('/workflows/remove', { ids })
        .then((res) => res.data);
}

export function attachWorkflow(dto: AttachWorkflowDto): Promise<Workflow> {
    return client()
        .post('/workflows/attach', dto)
        .then((res) => res.data);
}

export function detachWorkflow(dto: AttachWorkflowDto): Promise<Workflow> {
    return client()
        .post('/workflows/detach', dto)
        .then((res) => res.data);
}

export function renameWorkflow(dto: RenameWorkflowDto): Promise<Workflow> {
    return client()
        .post('/workflows/rename', dto)
        .then((res) => res.data);
}

import { Box, Typography } from '@mui/material';
import { indicatorContainer } from '../../ConversationsList/styles';
import { UUID } from '../../../types/uuid';
import {
    ConversationsFilter,
    ConversationsFilterType,
} from '../../../api/types';

type Props = {
    inboxId: UUID;
    filter?: ConversationsFilter;
};

export const NoConversations = ({ filter }: Props) => {
    if (!filter || filter.type === ConversationsFilterType.Active) {
        return null;
    }

    return (
        <Box sx={indicatorContainer}>
            <Typography variant="h3" mt={6}>
                Nothing found
            </Typography>
            <Typography variant="body3" mt={2}>
                Clear the filters or create a new conversation.
            </Typography>
        </Box>
    );
};

import { UUID } from '../types/uuid';
import client from './http';
import { Message, Profile } from './types';

export interface MessageToSend {
    from: string;
    to: string[];
    body: string;
    inboxId: string;
    user: Profile;
    conversationId: string;
    /** @deprecated use attachments */
    fileIds?: string[];
    attachments?: UUID[];
    messageTemplateId?: number;
}

export function getMessages(conversationId: string, timestamp?: number) {
    return client()
        .get<Message[]>(`/messages/conversation/${conversationId}`, {
            params: { timestamp },
        })
        .then(({ data }) => {
            return data;
        });
}

export function deleteMessageUnfurl({
    messageId,
    unfurlId,
}: {
    messageId: number;
    unfurlId: string;
}) {
    return client()
        .delete<void>(`/messages/${messageId}/unfurl/${unfurlId}`)
        .then(({ data }) => data);
}

export function deleteMessage({ messageId }: { messageId: number }) {
    return client()
        .delete(`/messages/${messageId}`)
        .then((r) => r.data);
}

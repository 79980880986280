import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const InfoIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5ZM1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5515 4.33333C8.77535 4.32765 8.99264 4.40913 9.15757 4.56059C9.1828 4.58257 9.2065 4.60627 9.22842 4.6315C9.4922 4.93442 9.46046 5.3938 9.15754 5.65757C8.81246 5.96624 8.29053 5.96624 7.94543 5.65757C7.91842 5.63429 7.89319 5.60906 7.86991 5.58205C7.60869 5.27911 7.64251 4.82179 7.94543 4.56059C8.11069 4.40968 8.32777 4.32827 8.5515 4.33333ZM8.38788 10.306C8.50564 10.3759 8.64249 10.4066 8.77879 10.3939C8.8846 10.3994 8.99064 10.3902 9.09392 10.3666C9.17559 10.3464 9.25561 10.3201 9.33332 10.2878L9.23634 10.6696C8.94947 10.7787 8.72017 10.8626 8.54845 10.9212C8.35262 10.9836 8.14785 11.0133 7.94238 11.009C7.65059 11.0269 7.36215 10.9386 7.13027 10.7606C6.93767 10.6096 6.82577 10.378 6.82725 10.1333C6.82723 10.0319 6.83431 9.93066 6.84848 9.83027C6.86585 9.713 6.8891 9.5967 6.91816 9.48178L7.27876 8.24542C7.3121 8.12725 7.33936 8.01514 7.36059 7.90907C7.38241 7.80954 7.39358 7.70795 7.39392 7.60605C7.40702 7.48691 7.3709 7.36759 7.29392 7.27574C7.18059 7.20037 7.04439 7.16714 6.90908 7.1818C6.81262 7.18253 6.71673 7.19683 6.62423 7.22423C6.52728 7.25454 6.44241 7.2818 6.37272 7.30605L6.47574 6.90605C6.7121 6.81311 6.93736 6.73331 7.1515 6.66665C7.34743 6.60345 7.55171 6.56975 7.75757 6.56665C8.04483 6.54759 8.32921 6.63376 8.55757 6.80907C8.74322 6.96613 8.84699 7.19936 8.83939 7.4424C8.83939 7.46353 8.83666 7.49057 8.83332 7.5237C8.82803 7.5761 8.82121 7.64372 8.82121 7.72725C8.80973 7.85365 8.78535 7.97855 8.74848 8.09998L8.3909 9.33029C8.35874 9.44199 8.33246 9.55527 8.31212 9.66969C8.29111 9.76943 8.27895 9.87084 8.27577 9.97272C8.26059 10.0951 8.30184 10.2177 8.38788 10.306Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

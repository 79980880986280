import AuthLayout from '../../layouts/AuthLayout';
import LoginForm from '../../components/Forms/LoginForm';
import { Redirect } from '../../utils/redirect';
import { useMeQueryData } from '../../queries/user';

const LoginPage = () => {
    const me = useMeQueryData();
    return me?.id && me?.acceptedTermsOfService ? (
        <Redirect to="/" />
    ) : (
        <AuthLayout>
            <LoginForm nextPage="/" />
        </AuthLayout>
    );
};

export default LoginPage;

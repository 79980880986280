import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { colors } from './palette';
import { pixelize } from '../pages/NewInbox/inbox.styles';

export const MuiTab: Components<Theme>['MuiTab'] = {
    styleOverrides: {
        root: (props) => ({
            fontSize: 12,
            lineHeight: pixelize(20),
            letterSpacing: 0.2,
            minWidth: 'auto',
            textTransform: 'none',
            padding: props.theme.spacing(2),
            minHeight: 32,
            '&.MuiTab-color-info': {
                color: colors.text.primary,
            },
            '&.MuiTab-color-info.Mui-selected': {
                color: props.theme.palette.info.main,
            },
        }),
    },
};

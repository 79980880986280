import { Box, Divider, IconButton, Link, Typography } from '@mui/material';
import { ClerkPermission, Unfurl as UnfurlType } from '../../api/types';
import { CloseIcon } from '../../icons/common/CCloseIcon';

import * as styles from './styles';
import { useState } from 'react';
import { useDeleteMessageUnfurl } from '../../queries/messages';
import { WithPermission } from '../../containers/WithPermission/WithPermission';

interface UnfurlProps {
    unfurl: UnfurlType;
    unfurlId: string;
    messageId: number;
    conversationId: string;
}

export const Unfurl = ({
    unfurl,
    unfurlId,
    conversationId,
    messageId,
}: UnfurlProps) => {
    const [brockenIcon, setBrockenIcon] = useState(false);
    const [brockenImage, setBrockenImage] = useState(false);

    const { mutateAsync: deleteMessageUnfurl } = useDeleteMessageUnfurl();

    return (
        <Box sx={styles.container}>
            <WithPermission
                requiredPermissions={[ClerkPermission.DeleteMessage]}
            >
                <IconButton
                    size="small"
                    onClick={() =>
                        deleteMessageUnfurl({
                            messageId,
                            conversationId,
                            unfurlId,
                        })
                    }
                    aria-label="Delete"
                >
                    <CloseIcon />
                </IconButton>
            </WithPermission>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: 1,
                }}
            >
                <Box sx={styles.header}>
                    {!brockenIcon && (
                        <img
                            src={unfurl.icon}
                            alt={unfurl.siteName}
                            onError={() => setBrockenIcon(true)}
                        />
                    )}

                    <Typography variant="body4" fontWeight={500}>
                        {unfurl.siteName}
                    </Typography>
                </Box>
                <Link
                    href={unfurl.url}
                    target="_blank"
                    rel="noreferrer"
                    variant="body4"
                    underline="hover"
                    sx={{ textAlign: 'left' }}
                >
                    <Typography variant="body4" color="info.main">
                        {unfurl.title}
                    </Typography>
                </Link>
                {unfurl.description && (
                    <Box sx={styles.description}>
                        {unfurl.image && !brockenImage && (
                            <img
                                src={unfurl.image}
                                onError={() => setBrockenImage(true)}
                            />
                        )}
                        <Typography
                            variant="body4"
                            color={({ palette: { text } }) => text.secondary}
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            {unfurl.description}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Unfurl;

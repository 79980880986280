import { Box, Skeleton, Typography } from '@mui/material';
import random from 'lodash/random';
import { DotDivider } from '../../../../components/common/DotDivider';
import range from 'lodash/range';
import { base } from './conversation-item.styles';

export const ConversationItemSkeleton = () => (
    <Box sx={base}>
        <Box display="flex" gap={2} width={1}>
            <Skeleton variant="circular" width={20} height={20} />
            <div>
                <Box display="flex" alignItems="center">
                    <Typography fontWeight={500} variant="body4">
                        <Skeleton width={random(80, 120)} />
                    </Typography>
                    <DotDivider />
                    <Skeleton width={60} />
                </Box>
                <Box mt={1}>
                    {range(random(1, 2)).map((i) => (
                        <Skeleton width={random(80, 320)} key={i} />
                    ))}
                </Box>
            </div>
        </Box>
    </Box>
);

import React, { useState } from 'react';
import { Box, Button, Divider, Drawer, IconButton } from '@mui/material';
import * as styles from './styles';
import { MenuIcon } from './MenuIcon';
import { FullArrowLeftIcon } from './FullArrowLeftIcon';
import { Navigation } from './Navigation/Navigation';
import { ContentHeader } from './ContentHeader';
import { useNavigate } from 'react-router';

type Props = {
    children: React.ReactNode;
    title: string;
    category?: string;
    description?: React.ReactNode;
    action?: React.ReactNode | null;
};

export function SettingsLayout({
    children,
    title,
    description,
    action,
    category = 'Settings',
}: Props) {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <Box sx={styles.layout}>
            <Box sx={styles.layoutHeader}>
                <IconButton
                    onClick={() => setMenuOpen(true)}
                    sx={styles.layoutHeaderMenuButton}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <Drawer
                variant="permanent"
                open={isMenuOpen}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={(theme) => ({
                    ...styles.sidebar,
                    [theme.breakpoints.down('md')]: {
                        display: isMenuOpen ? 'block' : 'none',
                    },
                })}
            >
                <Box sx={styles.header}>
                    <Button
                        sx={styles.homeButton}
                        variant="text"
                        startIcon={<FullArrowLeftIcon />}
                        onClick={() => navigate('/')}
                    >
                        Home
                    </Button>
                </Box>
                <Divider />
                <Navigation setMenuOpen={() => setMenuOpen(false)} />
            </Drawer>
            <Box sx={styles.container}>
                <ContentHeader
                    title={title}
                    description={description}
                    category={category}
                    action={action}
                />
                <Box sx={styles.layoutContainer}>{children}</Box>
            </Box>
        </Box>
    );
}

import { useLayoutEffect, useRef, forwardRef } from 'react';
import cc from 'classcat';
//assets
import { FiCheck, FiMinus } from 'react-icons/fi';
//styles
import styles from './CheckBox.module.scss';
import { Typography } from '@mui/material';

export type CheckBoxProps = {
    size?: 'normal' | 'large';
    checked?: boolean;
    indeterminate?: boolean;
    label?: string | JSX.Element;
    disabled?: boolean;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onChange?: (checked: boolean) => void;
};

// @deprecated - use CheckBox from @mui
const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(function CheckBox(
    props: CheckBoxProps,
    ref,
) {
    const checkBoxClass = cc([
        styles.checkbox__wrapper,
        props.className,
        {
            [styles.checkbox__wrapper_checked]:
                props.checked || props.indeterminate,
            [styles.checkbox__wrapper_disabled]: props.disabled,
            [styles['checkbox__wrapper_size-large']]: props.size === 'large',
        },
    ]);

    const newRef = useRef<HTMLInputElement>(null);

    const input = ref || newRef;

    useLayoutEffect(() => {
        if (!!input?.current && !!props.indeterminate) {
            input.current.indeterminate = props.indeterminate;
        }
    }, [input, props.indeterminate]);

    return (
        <label className={checkBoxClass}>
            <span className={styles.checkbox}>
                <input
                    ref={input}
                    type="checkbox"
                    className={styles.checkbox__input}
                    checked={props.checked}
                    disabled={props.disabled}
                    onClick={props.onClick}
                    onChange={() => props.onChange?.(!props.checked)}
                />
                <span className={styles.checkbox__inner}>
                    {props.indeterminate ? (
                        <FiMinus className={styles['checkbox__icon']} />
                    ) : (
                        <FiCheck className={styles['checkbox__icon']} />
                    )}
                </span>
            </span>
            {props.label && (
                <Typography variant="body3" ml={2.5}>
                    {props.label}
                </Typography>
            )}
        </label>
    );
});

export default CheckBox;

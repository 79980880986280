import { Inbox } from '../../api/types';
import { queryClient } from '../../queries/queryClient';
import { INBOX_LIST_KEY } from '../../queries/inboxes';
import { UUID } from '../../types/uuid';

export const INBOX_DELETED_EVENT = 'inbox:deleted';
export type InboxDeletedHandler = (inboxId: UUID) => void;

export const inboxDeletedHandler: InboxDeletedHandler = (inboxId) => {
    queryClient.setQueryData<Inbox[]>([INBOX_LIST_KEY], (prev) => {
        if (!prev) {
            return [];
        }
        return prev.filter(({ id }) => id !== inboxId);
    });
};

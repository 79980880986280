export const getInitials = (name: string): string =>
    name
        ?.replace(/[0-9,'"|_\-+]|\(|\)/gi, '')
        .trim()
        .split(' ')
        .filter(Boolean)
        .slice(0, 2)
        .map(([char]) => char)
        .join('')
        .toUpperCase() ?? '';

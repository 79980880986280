import emojis from 'emojibase-data/en/compact.json';
import shortcodes from 'emojibase-data/en/shortcodes/emojibase.json';
import uniq from 'lodash/uniq';
import { CustomEditor } from '../../../slate';
import { BaseRange, Transforms } from 'slate';

export const emojiShortcodes = Object.keys(shortcodes)
    .filter((key) => !key.includes('-'))
    .reduce<Record<string, string>>((acc, key) => {
        const item = shortcodes[key];
        const unicode = emojis.find((emoji) => emoji.hexcode === key)?.unicode;

        if (typeof item === 'string') {
            return {
                ...acc,
                [item]: unicode,
            };
        } else if (Array.isArray(item)) {
            return {
                ...acc,
                ...item.reduce(
                    (acc2, it) => ({
                        ...acc2,
                        [it]: unicode,
                    }),
                    acc,
                ),
            };
        }

        return acc;
    }, {});

export function findEmoji(text: string) {
    if (text.length < 2) {
        return [];
    }

    return uniq(
        Object.keys(emojiShortcodes).filter((shortcode) =>
            shortcode.startsWith(text),
        ),
    );
}

export function insertEmoji(
    editor: CustomEditor,
    targetRange: BaseRange,
    emoji: string,
) {
    Transforms.setSelection(editor, {
        focus: targetRange.anchor,
    });

    Transforms.insertText(editor, emoji);

    Transforms.move(editor);
}

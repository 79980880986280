import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const RadioSelectedIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <circle cx="10" cy="10" r="9.5" fill="#fff" stroke="currentColor" />
            <circle cx="10" cy="10" r="4.2" fill="currentColor" />
        </svg>
    </SvgIcon>
);

import { useEffect, useMemo } from 'react';
import { useUpdateTeam } from '../queries/team';
import { useBrandQuery } from '../queries/settings';
import { useListIntegrations } from '../queries/integrations';
import { BrandRegistrationStatus, InboxStatus } from '../api/types';
import { useInboxes } from '../queries/inboxes';
import { useOnboardingData } from './useOnboardingData';

export const useUpdateOnboardingData = (): string | undefined => {
    const { score, onboarding, total, isOnboardingBlockedForOldTeams } =
        useOnboardingData();

    const isLoading = useMemo(() => !onboarding, [onboarding]);
    const isInitLoading = useMemo(
        () =>
            !!onboarding &&
            Object.values(onboarding).every((item) => item) &&
            !total,
        [total, onboarding],
    );
    const { data: brand, isSuccess: brandFetched } = useBrandQuery(
        (!onboarding?.brandRegistration || isInitLoading) && !isLoading,
    );
    const { data: integrations = [], isSuccess: integrationsFetched } =
        useListIntegrations(
            (!onboarding?.integration || isInitLoading) && !isLoading,
        );
    const { data: inboxes = [], isSuccess: inboxesFetched } = useInboxes(
        (!onboarding?.inboxActivation || isInitLoading) && !isLoading,
    );
    const { mutate: updateTeam } = useUpdateTeam();

    const isBrandVerified = useMemo(
        () =>
            brand?.status === BrandRegistrationStatus.VERIFIED ||
            brand?.status === BrandRegistrationStatus.VETTED_VERIFIED,
        [brand?.status],
    );

    const isInboxActive = useMemo(
        () => inboxes.some((inbox) => inbox.status === InboxStatus.ACTIVE),
        [inboxes],
    );

    const isIntegrationAdded = useMemo(
        () => integrations.length > 0,
        [integrations.length],
    );

    // // Update onboarding data
    useEffect(() => {
        if (
            !isLoading &&
            !isInitLoading &&
            onboarding &&
            (score !== 100 || isOnboardingBlockedForOldTeams)
        ) {
            const {
                inboxActivation,
                brandRegistration,
                integration,
                teamsLicense,
            } = onboarding;

            const needUpdateInbox = isInboxActive && !inboxActivation;
            const needUpdateBrand = isBrandVerified && !brandRegistration;
            const needUpdateIntegration = isIntegrationAdded && !integration;

            if (needUpdateInbox || needUpdateBrand || needUpdateIntegration) {
                updateTeam({
                    onboarding: {
                        inboxActivation: needUpdateInbox
                            ? isInboxActive
                            : inboxActivation,
                        teamsLicense,
                        brandRegistration: needUpdateBrand
                            ? isBrandVerified
                            : brandRegistration,
                        integration: needUpdateIntegration
                            ? isIntegrationAdded
                            : integration,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isLoading,
        isInitLoading,
        isBrandVerified,
        isInboxActive,
        isIntegrationAdded,
    ]);

    // Init onboarding data save
    useEffect(() => {
        if (
            !isLoading &&
            isInitLoading &&
            brandFetched &&
            integrationsFetched &&
            inboxesFetched
        ) {
            updateTeam({
                onboarding: {
                    inboxActivation: isInboxActive,
                    teamsLicense: false,
                    brandRegistration: isBrandVerified,
                    integration: isIntegrationAdded,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isLoading,
        isInitLoading,
        brandFetched,
        integrationsFetched,
        inboxesFetched,
    ]);

    return inboxes.find((inbox) => inbox.status !== InboxStatus.ACTIVE)?.id;
};

import { Box, ButtonBase, SxProps, Tooltip } from '@mui/material';
import { AdapterLink } from '../ConversationsList/AdapterLink';
import { getViewSelector } from '../ConversationsList/styles';
import { Entity } from '../../pages/NewInbox/query.helpers';
import { DotDivider } from '../common/DotDivider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getSquare } from '../../theme/style.helpers';
import { useTrack } from '../../contexts/analytics';
import { UUID } from '../../types/uuid';
import { useIsVerified } from '../../queries/settings';

type Props = { entity: Entity; inboxId: UUID; sx?: SxProps };
export const EntitySwitcher = ({ entity, inboxId, sx }: Props) => {
    const track = useTrack();
    const isVerified = useIsVerified();

    return (
        <Box
            display="flex"
            alignItems="center"
            mt={-2}
            mx={4}
            width={1}
            sx={sx}
        >
            <ButtonBase
                data-testid="sidebar-conversation-filter"
                LinkComponent={AdapterLink}
                sx={getViewSelector(entity === Entity.Conversations)}
                href={`/inbox/${inboxId}/${Entity.Conversations}`}
                onClick={() => {
                    track('inbox_conversations_clicked');
                }}
            >
                Conversations
            </ButtonBase>
            <DotDivider />
            <ButtonBase
                data-testid="sidebar-campaign-filter"
                LinkComponent={AdapterLink}
                sx={getViewSelector(entity === Entity.Campaigns)}
                href={`/inbox/${inboxId}/${Entity.Campaigns}`}
                onClick={() => {
                    track('inbox_campaigns_clicked');
                }}
            >
                Campaigns{' '}
                {!isVerified && (
                    <Tooltip title="Brand registration required">
                        <InfoOutlinedIcon sx={{ ...getSquare(12), ml: 1 }} />
                    </Tooltip>
                )}
            </ButtonBase>
        </Box>
    );
};

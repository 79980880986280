import { PlanPeriod, PlanType } from '../containers/PaymentFlow/plans';
import client from './http';

export const setupShopifySubscription = (
    tier: PlanType,
    billingCycle: PlanPeriod,
) =>
    client().post<{ confirmation_url: string }>(
        '/shopify/billing/createRecurringCharge',
        { tier, billingCycle },
    );

import { Components, svgIconClasses, Theme } from '@mui/material';

export const MuiInputAdornment: Components<Theme>['MuiInputAdornment'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            color: theme.palette.custom.text.secondary,

            [`& .${svgIconClasses.root}`]: {
                width: 20,
                height: 20,
            },
        }),
    },
};

import { Stack, SxProps } from '@mui/material';
import { HEADER_HEIGHT, pixelize } from '../inbox.styles';
import range from 'lodash/range';
import { ChatMessageSkeleton } from './Message/ChatMessageSkeleton';
import { memo } from 'react';

const INPUT_HEIGHT = `130`;
const stack: SxProps = {
    flex: 1,
    width: 1,
    gap: 2,
    maxHeight: `calc(100vh - ${pixelize(HEADER_HEIGHT)} - ${INPUT_HEIGHT}px)`,
    overflow: 'hidden',
};

export const HistorySkeleton = memo(function HistorySkeleton() {
    return (
        <Stack sx={stack}>
            {range(4).map((i) => (
                <ChatMessageSkeleton key={i} />
            ))}
        </Stack>
    );
});

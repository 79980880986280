import cc from 'classcat';
import Icon from '../../icons/Icon';
import styles from './MenuItem.module.scss';

interface MenuItemProps {
    isSelected?: boolean;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
}

/** @deprecated use mui MenuItem*/
export const MenuItem = ({
    isSelected,
    className,
    disabled,
    children,
    onClick,
}: MenuItemProps) => (
    <li
        className={cc([
            styles['root'],
            className,
            {
                [styles['root_selected']]: isSelected,
                [styles['root_disabled']]: disabled,
            },
        ])}
        onClick={onClick}
    >
        <div className={styles['root__children']}>{children}</div>
        {isSelected && <Icon name="check" className={styles['root__icon']} />}
    </li>
);

import { ModalLayoutProps } from '../../types/LayoutProps';
import styles from './ModalLayout.module.scss';
import { useRef } from 'react';
import { useModalContext } from '../../containers/modal/reducer';
import useOnClickOutside from '../../hooks/useOnClickOutside';

function ModalLayout(props: ModalLayoutProps) {
    const innerRef = useRef<HTMLDivElement>(null);
    const { removeModal } = useModalContext();
    useOnClickOutside(innerRef, () => {
        if (!props.noClickOutside) {
            removeModal();
        }
    });
    return (
        <div className={styles['clerk-modal']}>
            <div ref={innerRef}>{props.children}</div>
        </div>
    );
}

export default ModalLayout;

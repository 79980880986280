import cc from 'classcat';
import styles from './MessageCommentItem.module.scss';
import { PublicFile, MessageComment } from '../../api/types';
import { dateFormatRelative } from '../../helpers/formatting';
import AttachmentItem from '../AttachmentItem';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

export interface Props {
    comment: MessageComment;
    senderName: string;
    senderAvatar?: string;
    className?: string;
    attachments?: PublicFile[];
}

export const MessageCommentItem = (props: Props) => (
    <div className={cc([styles['root'], props.className])}>
        <div className={styles['root__avatar']}>
            <UserAvatar
                size={24}
                imageUrl={props.senderAvatar}
                username={props.senderName}
            />
        </div>

        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    mb: 1,
                }}
            >
                <span className={styles['root__name']}>{props.senderName}</span>
                <span className={styles['root__delimiter']} />
                <span className={styles['root__date']}>
                    {dateFormatRelative(props.comment.created)}
                </span>
            </Box>

            {/* Message text */}
            {props.comment.text && (
                <div className={styles['root__text']}>{props.comment.text}</div>
            )}
            {!isEmpty(props.attachments) && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    {props.attachments?.map((attachment) => (
                        <AttachmentItem
                            key={attachment.id}
                            attachment={attachment}
                        />
                    ))}
                </Box>
            )}
        </div>
    </div>
);

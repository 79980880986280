import { useState } from 'react';
import { Box, Link } from '@mui/material';

type CollapsibleTextSectionProps = {
    visibleComponent: JSX.Element;
    hiddenComponent: JSX.Element;
    expandedByDefault?: boolean;
};

export const CollapsibleTextSection = (props: CollapsibleTextSectionProps) => {
    const [expanded, setExpanded] = useState(props.expandedByDefault ?? false);
    return (
        <div>
            {props.visibleComponent}
            {expanded ? props.hiddenComponent : null}
            <Box mt={2}>
                <Link
                    sx={{ cursor: 'pointer' }}
                    color="info.main"
                    variant="body4"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? 'Show Less' : 'Show More'}
                </Link>
            </Box>
        </div>
    );
};

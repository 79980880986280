import {
    ComponentType,
    createContext,
    ReactNode,
    useContext,
    useReducer,
} from 'react';
import { createPortal } from 'react-dom';
import Toast from './index';
import { NoEntryIcon } from '../../icons/common/CNoEntryIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export type ToastModal = {
    id: string;
    icon?: ComponentType<Exclude<SvgIconProps, 'component'>>;
    title?: string;
    severity?: 'error' | 'warning' | 'info' | 'success';
    description: ReactNode;
    duration?: number;
};

export type ToastModalState = ToastModal[];

const initialState: Array<ToastModal> = [];

export enum ToastActions {
    ADD,
    REMOVE,
    REMOVE_ALL,
}

export type ToastDispatch =
    | {
          type: ToastActions.ADD;
          payload: {
              id?: string;
              title?: string;
              severity?: 'error' | 'warning' | 'info' | 'success';
              icon?: ComponentType<Exclude<SvgIconProps, 'component'>>;
              description: ReactNode;
              duration?: number;
          };
      }
    | {
          type: ToastActions.REMOVE;
          payload: {
              id: string;
          };
      }
    | {
          type: ToastActions.REMOVE_ALL;
          payload?: undefined;
      };

export const toastReducer = (
    state: Array<ToastModal>,
    { type, payload }: ToastDispatch,
) => {
    switch (type) {
        case ToastActions.ADD: {
            if (state.find((t) => t.id === payload.id)) {
                return state;
            }

            const severity = payload.severity ?? 'error';

            return [
                ...state,
                {
                    ...payload,
                    icon: payload.icon
                        ? payload.icon
                        : severity === 'error' && NoEntryIcon,
                    severity,
                    id: payload.id ?? (+new Date()).toString(),
                },
            ];
        }
        case ToastActions.REMOVE:
            return state.filter((t) => t.id !== payload.id);
        case ToastActions.REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

type ToastContextType = {
    state: ToastModal[];
    dispatch: (action: ToastDispatch) => void;
};

const ToastContext = createContext<ToastContextType>({
    state: initialState,
    dispatch: (_action) => null,
});
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(toastReducer, initialState);
    return (
        <ToastContext.Provider value={{ state, dispatch }}>
            {children}

            {createPortal(<Toast toast={state} />, document.body)}
        </ToastContext.Provider>
    );
};

export const useToastContext = () => {
    return useContext(ToastContext);
};

import { Outlet } from 'react-router';
import { SetupMicrosoftTeamsInboxProvider } from './setup-teams-phone-context';
import { TeamsPhone } from '../../../api/types';

export type MSTeamsPhoneNumbersProps = {
    selectedPhones?: TeamsPhone[];
    setSelectedPhones?: (phone: TeamsPhone) => void;
    setActivationDate?: (date: Date) => void;
    activationDate?: Date;
    goForward: () => void;
    goBack?: () => void;
};

export const MSTeamsPhoneNumbers = () => {
    return (
        <SetupMicrosoftTeamsInboxProvider>
            <Outlet />
        </SetupMicrosoftTeamsInboxProvider>
    );
};

import { useEffect, forwardRef, useRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const AdapterLink = forwardRef<
    HTMLAnchorElement,
    NavLinkProps & { href?: string }
>(function AdapterLink(props, _ref) {
    const component = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        _ref = component;
    }, [component.current]);

    return <NavLink ref={component} {...props} to={props.href} />;
});

import { useCampaign } from '../../../queries/campaigns';
import { useEffect } from 'react';
import { CampaignType } from '../../../api/campaign.types';
import { Box } from '@mui/material';
import { CampaignHeader } from './CampaignHeader';
import { useTrack } from '../../../contexts/analytics';
import { CampaignView } from '../../../pages/NewInbox/CampaignView';
import { container } from './campaign.styles';
import { useNavigate } from 'react-router';

type Props = {
    campaignId?: number;
};

export const Campaign = ({ campaignId }: Props) => {
    const track = useTrack();
    const { data } = useCampaign(campaignId);
    const navigate = useNavigate();

    useEffect(() => {
        if (
            data?.type &&
            ![CampaignType.INSTANT, CampaignType.PLANNED].includes(data.type)
        ) {
            track('campaign_redirected_to_old');
            navigate(`/campaigns/${data.id}`);
        }
    }, [data?.id, data?.type, navigate, track]);

    if (!campaignId) {
        return null;
    }

    return (
        <Box sx={container}>
            <CampaignHeader campaignId={campaignId} />
            <CampaignView campaignId={campaignId} />
        </Box>
    );
};

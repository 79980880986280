import * as styles from '../styles';
import { ValueProps } from '../../types';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export const LimitedTextValue = ({
    userProperty,
    onChange,
    formatDisplay,
    validate,
    readonly = false,
}: ValueProps<string>) => {
    const [value, setValue] = useState(userProperty.value);
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setError(null);
        setValue(userProperty.value);
    }, [userProperty]);

    if (isEditEnabled) {
        const handleLooseFocus = () => {
            const error = validate?.({ ...userProperty, value }) ?? null;
            if (error !== null) {
                setError(error);
                return;
            }
            onChange?.({ ...userProperty, value });
            setEditEnabled(false);
        };

        return (
            <TextField
                onBlur={handleLooseFocus}
                onKeyDown={(e) => {
                    switch (e.key) {
                        case 'Enter':
                            handleLooseFocus();
                            break;
                        case 'Escape':
                            setError(null);
                            setValue(userProperty.value);
                            setEditEnabled(false);
                            break;
                        default:
                            break;
                    }
                }}
                error={!!error}
                helperText={error}
                autoFocus
                size="small"
                variant="outlined"
                value={value}
                ref={inputRef}
                fullWidth
                onChange={(e) => {
                    setValue(e.target.value);
                    setError(null);
                }}
            />
        );
    }

    return (
        <Box
            sx={readonly ? styles.readOnly : styles.limitedValue}
            title={userProperty.value}
            onClick={() => !readonly && setEditEnabled(true)}
        >
            {userProperty.value ? (
                formatDisplay ? (
                    formatDisplay(userProperty.value)
                ) : (
                    <Typography variant="body3" color="primary.dark" noWrap>
                        {userProperty.value}
                    </Typography>
                )
            ) : (
                <Typography
                    variant="body3"
                    color="primary.dark"
                    sx={styles.emptyValue}
                >
                    + Set a value
                </Typography>
            )}
        </Box>
    );
};

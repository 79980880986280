import { useLatestConversationClassificationLabels } from './hooks/use-conversation-classifications';
import { Chip } from '@mui/material';

export const ClassificationLabels = ({
    conversationId,
}: {
    conversationId: string;
}) => {
    const classifications =
        useLatestConversationClassificationLabels(conversationId);

    return classifications.map((label, i) => (
        <Chip key={i} label={label} variant="outlined" size="small" />
    ));
};

import { Conversation } from '../../api/types';
import { useIsAssistantReply } from '../Inbox/Conversation/use-is-assistant-reply';
import { useUserTyping } from './use-conversation-typing';
import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { getMessageStyle } from './styles';
import { AssistantReplyStatus } from './Assistant/AssistantReplyStatus';
import { getTextFromJSON } from '../MessageInput/serializers/json';
import { useMessageStoppedBy } from './stop-phrase/use-message-stopped-by';

type Props = {
    conversation: Conversation;
};

export const ConversationStatus = ({ conversation }: Props) => {
    const isAssistantReply = useIsAssistantReply(conversation);
    const unread = conversation.unreadMessageCount > 0;
    const userTyping = useUserTyping(conversation.id);
    const phrase = useMessageStoppedBy(conversation.lastMessageBody);
    const encoded =
        window.localStorage.getItem(`${conversation.id}_draft`) ?? '';
    const draft = useMemo(() => getTextFromJSON(encoded), [encoded]);

    return (
        <Box
            typography="body4"
            component="span"
            sx={getMessageStyle(
                !!unread,
                isAssistantReply && !phrase && !userTyping,
            )}
        >
            {userTyping && (
                <Typography component="span" variant="body4" color="info.main">
                    {userTyping} is typing ...
                </Typography>
            )}
            {isAssistantReply && !phrase && (
                <AssistantReplyStatus conversation={conversation} />
            )}
            {(!isAssistantReply || !!phrase) &&
                !userTyping &&
                (draft ? (
                    <span>
                        <b>Draft:</b> {draft}
                    </span>
                ) : (
                    conversation.lastMessageBody
                ))}
        </Box>
    );
};

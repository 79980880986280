import { SxProps } from '@mui/material';

export const verticalDivider: SxProps = {
    height: 20,
    alignSelf: 'center',
};

export const header: SxProps = {
    color: 'secondary.main',
};

export const noWrap: SxProps = { whiteSpace: 'nowrap' };

import { useCallback } from 'react';
import styles from './CohortsGroup.module.scss';
import { useModalContext } from '../modal/reducer';
import Icon from '../../icons/Icon';
import Loading from '../../components/Loading/Loading';
import CollapsiblePropertiesSection from '../../components/CollapsiblePropertiesSection';
import {
    useGetCohortsForContact,
    useRemoveContactsFromCohortsMutation,
} from '../../queries/cohorts';
import { Contact } from '../../api/types';
import ContactsMoveDialog from '../../components/ContactsMoveDialog';

interface CohortsGroupProps {
    contact: Partial<Contact>;
}

export const CohortsGroup = (props: CohortsGroupProps) => {
    const { dispatch } = useModalContext();
    const { mutate: removeFromCohort } = useRemoveContactsFromCohortsMutation();
    const { isPending, data: contactCohorts = [] } = useGetCohortsForContact(
        props.contact.id,
    );

    const handleRemoveFromCohort = (cohortId: number) => {
        removeFromCohort({
            contactIds: [props.contact.id!],
            cohortIds: [cohortId],
        });
    };

    const resetModal = useCallback(() => {
        dispatch({ type: 'PUSH', payload: {} });
    }, [dispatch]);

    return (
        <CollapsiblePropertiesSection title="Cohorts">
            {isPending && <Loading className={styles['root__loader']} />}
            {!isPending && contactCohorts.length === 0 && (
                <span className={styles['root__no-cohorts']}>
                    This contact is not in a cohort
                </span>
            )}
            {!isPending &&
                contactCohorts.map((cohort, i) => (
                    <div key={cohort.id} className={styles['root__item']}>
                        <span className={styles['root__counter']}>{i + 1}</span>
                        <span className={styles['root__name']}>
                            {cohort.name}
                        </span>
                        <button
                            className={styles['root__delete-btn']}
                            onClick={() => handleRemoveFromCohort(cohort.id)}
                        >
                            <Icon size="20px" name="delete" />
                        </button>
                    </div>
                ))}
            <button
                className={styles['root__button']}
                onClick={() => {
                    const contactId = props.contact.id;
                    if (contactId) {
                        dispatch({
                            type: 'PUSH',
                            payload: {
                                content: () => (
                                    <ContactsMoveDialog
                                        selected={new Set([contactId])}
                                        onClose={resetModal}
                                        onMove={() => resetModal()}
                                    />
                                ),
                            },
                        });
                    }
                }}
            >
                <Icon
                    name="plus"
                    size="20px"
                    className={styles['root__plus-icon']}
                />
                <span>Add to cohort</span>
            </button>
        </CollapsiblePropertiesSection>
    );
};

import {
    Box,
    Chip,
    IconButton,
    ListItem,
    Stack,
    Typography,
} from '@mui/material';
import { EditIcon } from '../../../../../../icons/common/CEditIcon';
import { DeleteIcon } from '../../../../../../icons/common/CDeleteIcon';
import { useMessageTemplateRemoveQuery } from '../../../../../../queries/message-templates';
import { useMemo } from 'react';
import { MessageTemplate } from '../../../../../../api/types';

export function MessageTemplateItem({
    template,
    onSelect,
    onEdit,
}: {
    template: MessageTemplate;
    onSelect: (template: MessageTemplate) => void;
    onEdit: (template: MessageTemplate) => void;
}) {
    const { mutate: remove } = useMessageTemplateRemoveQuery();

    const chip = useMemo(() => {
        if (template.externalId) {
            return (
                <Chip label="WhatsApp Template" size="small" color="success" />
            );
        }

        if (!template.userId) {
            return <Chip label="Team" size="small" color="info" />;
        }

        return <Chip label="Personal" size="small" />;
    }, [template]);

    return (
        <ListItem
            sx={{
                py: 2,
                px: 1,

                '& .controls': {
                    display: 'none',
                },

                '& .chip': {
                    display: 'flex',
                },

                '&:hover': {
                    borderRadius: 1,
                    backgroundColor: 'custom.action.hover',

                    '& .chip': {
                        display: 'none',
                    },

                    '& .controls': {
                        display: 'flex',
                    },
                },
            }}
            key={template.id}
            onClick={() => onSelect(template)}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={4}
                flex={1}
                overflow="hidden"
            >
                <Stack
                    spacing={1}
                    flex={1}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                >
                    <Typography variant="body3" color="primary.dark" noWrap>
                        {template.name}
                    </Typography>
                    <Typography
                        variant="body4"
                        color="custom.gray.super"
                        noWrap
                    >
                        {template.body}
                    </Typography>
                </Stack>
                <Box className="chip">{chip}</Box>
                {!template.externalId && (
                    <Stack
                        className="controls"
                        spacing={0.5}
                        direction="row"
                        alignItems="center"
                    >
                        <IconButton
                            size="small"
                            color="default"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit(template);
                            }}
                            disabled={!!template.externalId}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            color="error"
                            onClick={(e) => {
                                e.stopPropagation();
                                remove(template.id);
                            }}
                            disabled={!!template.externalId}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </ListItem>
    );
}

import { Box, CircularProgress, SxProps, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useCreateKeyword } from './keywords.query';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';

declare module 'yup' {
    interface StringSchema {
        isList(message: string): this;
    }
}

Yup.addMethod(Yup.string, 'isList', function (message) {
    return this.test('isList', message, function (value) {
        if (!value) {
            return true;
        }

        return value
            .split(',')
            .map((str) => str.trim())
            .filter(Boolean)
            .every((str) => str.length >= 2 && str.length <= 160);
    });
});

const keywordResolver = yupResolver(
    Yup.object({
        phrase: Yup.string()
            .required('At least one keyword is required')
            .isList('The phrase should be between 2 and 160 characters'),
    }),
);

export const KeywordForm = ({ sx = {} }: { sx: SxProps }) => {
    const create = useCreateKeyword();

    const form = useForm({
        mode: 'onSubmit',
        resolver: keywordResolver,
    });

    useEffect(() => {
        if (create.isSuccess) {
            form.reset();
        }
    }, [create.isSuccess, form]);

    return (
        <Box
            component="form"
            onSubmit={form.handleSubmit(create.mutate)}
            sx={{ width: 1, display: 'flex', gap: 6, ...sx }}
        >
            <Controller
                name="phrase"
                control={form.control}
                required
                defaultValue=""
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <TextField
                        aria-label="Stop phrases"
                        onChange={onChange}
                        disabled={create.isPending}
                        value={value}
                        helperText={error ? error?.message : null}
                        error={!!error}
                        fullWidth
                        color="blue"
                    />
                )}
            />
            <div>
                <Button
                    disabled={create.isPending}
                    sx={{ minWidth: 140 }}
                    type="submit"
                >
                    {create.isPending ? <CircularProgress size={20} /> : 'Add'}
                </Button>
            </div>
        </Box>
    );
};

import { useContext, useMemo, useReducer, createContext } from 'react';
import createActions, { Action } from './actions';
import { getInitialState, reducer, State } from './reducer';
import { FinishHandler } from '../types';
import { UUID } from '../../../types/uuid';

// CONTEXT
interface NewConversationContext {
    state: State;
    dispatch: (action: Action) => void;
    actions: ReturnType<typeof createActions>;
}

const Context = createContext<NewConversationContext>({
    state: getInitialState(),
    dispatch: () => null,
    actions: createActions((action: Action) =>
        // eslint-disable-next-line no-console
        console.log(`Mock implementation for ${action.type}`),
    ),
});

// PROVIDER & HOOK
interface ProviderProps {
    inboxId: UUID;
    isDebug?: boolean;
    onFinish: FinishHandler;
    children: React.ReactNode;
}

export const NewConversationProvider = (props: ProviderProps) => {
    const [state, dispatch] = useReducer(
        reducer,
        getInitialState(props.inboxId, props.onFinish),
    );
    const actions = useMemo(() => createActions(dispatch), [dispatch]);

    if (props.isDebug) {
        // eslint-disable-next-line no-console
        console.log('[NewConversationProviders]:: state : ', state);
    }

    return (
        <Context.Provider value={{ state, dispatch, actions }}>
            {props.children}
        </Context.Provider>
    );
};

export const useNewConversationContext = () => useContext(Context);

import { UUID } from '../../../types/uuid';
import { History } from './History';
import { Box } from '@mui/material';
import { TypingIndicator } from '../../../components/TypingIndicator/TypingIndicator';
import {
    MessageForm,
    MessageFormData,
} from '../../../components/MessageForm/MessageForm';
import {
    useMessageCreate,
    useUpdateMessage,
} from '../../../components/MessageFormContainers/message.query';
import { handleError } from '../../../components/MessageFormContainers/utils';
import { useToastContext } from '../../../containers/toast/reducer';
import { useMemo, useState } from 'react';
import { Feature, Message } from '../../../api/types';
import { Nullable } from '../../../helpers/types';
import { container, historyHolder } from './conversation-view.styles';
import { useConversation } from '../../../queries/conversations';
import { useByPhones } from '../../../components/Contacts/use-contact-by-phone';
import { WithFeature } from '../../../containers/Feature/WithFeature';
import { ClassificationLabels } from '../../../components/ClassificationLabels/ClassificationLabels';
import { ClassifyButton } from '../../../components/ClassifyButton/ClassifyButton';

type Props = {
    conversationId: UUID;
};

const messageToFormData = (
    message: Nullable<Message>,
): MessageFormData | null => {
    if (!message) {
        return null;
    }
    return {
        message: message.body,
        sendAt: message.timestamp as string,
        attachments: message.attached,
    };
};

export const ConversationView = ({ conversationId }: Props) => {
    const [message, setMessage] = useState<Message | null>(null);
    const creation = useMessageCreate();
    const mutation = useUpdateMessage();
    const { data } = useConversation(conversationId);
    const memberQueries = useByPhones(data?.members ?? []);
    const isSingleContact = useMemo(() => {
        return (
            data?.members.length === 1 &&
            memberQueries.filter(({ data }) => data).length ===
                data?.members.length
        );
    }, [memberQueries, data?.members]);

    const { dispatch: toastDispatch } = useToastContext();

    const onSend = (formData: MessageFormData) => {
        if (message) {
            return mutation
                .mutateAsync({
                    id: message.id,
                    message: {
                        timestamp: formData.sendAt
                            ? new Date(formData.sendAt)
                            : undefined,
                        body: formData.message,
                        conversationId: message.conversationId,
                    },
                })
                .then(() => {
                    setMessage(null);
                })
                .catch(handleError(toastDispatch));
        }
        return creation
            .mutateAsync({
                conversationId,
                body: formData.message,
                timestamp: formData.sendAt
                    ? new Date(formData.sendAt)
                    : undefined,
                attachments: formData.attachments?.map(({ id }) => id),
                messageTemplateId: formData.messageTemplateId,
            })
            .then(() => {
                setMessage(null);
            })
            .catch(handleError(toastDispatch));
    };

    return (
        <Box sx={container}>
            <Box sx={historyHolder}>
                <History
                    conversationId={conversationId}
                    onEdit={(m) => setMessage(m)}
                />
            </Box>
            <Box sx={{ pt: 2 }}>
                <WithFeature feature={Feature.Classifications}>
                    <Box
                        pb={2}
                        mx={6}
                        display="flex"
                        gap={2}
                        position="relative"
                        flexWrap="wrap"
                    >
                        <ClassificationLabels conversationId={conversationId} />
                        <ClassifyButton conversationId={conversationId} />
                    </Box>
                </WithFeature>
                <TypingIndicator conversationId={conversationId} />
                <MessageForm
                    signatureAvailable
                    templatesAvailable={isSingleContact}
                    initial={messageToFormData(message)}
                    onSend={onSend}
                    conversationId={conversationId}
                    onCancel={() => setMessage(null)}
                />
            </Box>
        </Box>
    );
};

import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Conversation, Message, ScheduledMessage } from '../../api/types';
import { dateFormatFullDay } from '../../helpers/formatting';
import { useContactsQueryData } from '../../queries/contacts';
import { useGetMessages } from '../../queries/messages';
import {
    useScheduledMessageRemoveQuery,
    useScheduledMessagesQuery,
} from '../../queries/scheduled-messages';
import { useMeQueryData } from '../../queries/user';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import Loading from '../Loading/Loading';
import ScheduledMessageRow from '../ScheduledMessageRow';
import styles from './Messages.module.scss';
import {
    byDate,
    isScrollAtTheBottom,
    scrollToBottom,
    transformMessages,
    transformScheduledMessage,
} from './utils';
import isEmpty from 'lodash/isEmpty';
import { EmptyChat } from './EmptyChat';
import MessageRow from '../ChatComponents/Message/MessageRow';

interface Props {
    conversation: Conversation;
    setSmForEdit: (message: ScheduledMessage) => void;
    setMessageForEdit: (message: Message) => void;
    setActiveDiscussion?: (discussionId: number | null) => void;
}

export const Messages = ({
    conversation,
    setSmForEdit,
    setMessageForEdit,
    setActiveDiscussion,
}: Props) => {
    const [scrollMode, setScrollMode] = useState<'auto' | 'manual'>('auto');

    const currentUser = useMeQueryData();
    const contacts = useContactsQueryData();
    const {
        messages,
        fetchNextPage,
        hasNextPage,
        isFetched,
        isFetchingNextPage,
        isPending,
    } = useGetMessages(conversation.id);

    const scrollRef = useRef<HTMLDivElement>(null);

    const scheduledMessages = useScheduledMessagesQuery(conversation.id);
    const removeScheduledMessageMutation = useScheduledMessageRemoveQuery(
        conversation.id,
    );

    useEffect(() => {
        const scrollHandler = () => {
            setScrollMode(isScrollAtTheBottom(scrollRef) ? 'auto' : 'manual');
        };

        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', scrollHandler);

            return () =>
                // eslint-disable-next-line react-hooks/exhaustive-deps
                scrollRef.current?.removeEventListener('scroll', scrollHandler);
        }
    }, []);

    useLayoutEffect(() => {
        if (isFetched) {
            scrollToBottom(scrollRef);
        }
    }, [conversation.id, isFetched]);

    useLayoutEffect(() => {
        if (isFetched && scrollMode === 'auto') {
            scrollToBottom(scrollRef);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, isFetched]);

    const loadMore = useCallback(() => {
        if (!isFetchingNextPage) {
            fetchNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingNextPage]);

    const events = useMemo(
        () => (messages ? transformMessages(messages, contacts) : []),
        [messages, contacts],
    );

    if (isPending) {
        return (
            <div className={styles['clerk-chat-window__messages']}>
                <div
                    className={styles['clerk-chat-window__wrapper']}
                    ref={scrollRef}
                >
                    <Loading />
                </div>
            </div>
        );
    }

    if (
        !isPending &&
        messages?.length === 0 &&
        scheduledMessages.length === 0
    ) {
        return <EmptyChat />;
    }

    return (
        <div className={styles['clerk-chat-window__messages']}>
            <div
                className={styles['clerk-chat-window__wrapper']}
                ref={scrollRef}
            >
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasNextPage}
                    useWindow={false}
                    isReverse
                >
                    <ul className={styles['clerk-chat-window__messages-list']}>
                        {events.map((event) => (
                            <li
                                key={
                                    event.message?.id ??
                                    event.date?.toISOString()
                                }
                                className={
                                    styles['clerk-chat-window__message-item']
                                }
                            >
                                {event.date && (
                                    <span
                                        className={
                                            styles['clerk-message__date-tag']
                                        }
                                    >
                                        {dateFormatFullDay(event.date)}
                                    </span>
                                )}

                                {event.message && (
                                    <MessageRow
                                        message={event.message}
                                        conversationId={conversation.id}
                                        senderName={
                                            isEmpty(event.author?.name)
                                                ? formatPhoneNumber(
                                                      event.author?.phone,
                                                      currentUser?.activeTeam
                                                          .countryCode,
                                                  )
                                                : event.author?.name
                                        }
                                        onEditClick={setMessageForEdit}
                                        senderAvatar={event.avatar}
                                        isGroupStart={event.isGroupStart}
                                        isGroupEnd={event.isGroupEnd}
                                        setActiveDiscussion={
                                            setActiveDiscussion
                                        }
                                    />
                                )}
                            </li>
                        ))}

                        {scheduledMessages
                            .sort(byDate)
                            .map((scheduledMessage) => (
                                <li key={scheduledMessage.id}>
                                    <ScheduledMessageRow
                                        conversationId={conversation.id}
                                        message={transformScheduledMessage(
                                            contacts,
                                            conversation,
                                            scheduledMessage,
                                        )}
                                        onDeleteClick={() =>
                                            removeScheduledMessageMutation.mutate(
                                                scheduledMessage.id,
                                            )
                                        }
                                        onEditClick={() =>
                                            setSmForEdit(scheduledMessage)
                                        }
                                    />
                                </li>
                            ))}
                    </ul>
                </InfiniteScroll>
            </div>
        </div>
    );
};

import { Box, SxProps, Theme } from '@mui/material';
import dayjs from 'dayjs';
import { colors } from '../../../theme/palette';
import { checkIsTooLate } from '../../../components/ConversationRow/Assistant/check-is-until-review';

const dateDivider: SxProps<Theme> = {
    px: 4,
    py: 1,
    color: 'custom.text.secondary',
    textAlign: 'center',
    borderRadius: 1,
    borderColor: colors.lightGrey[400],
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: (theme) => theme.shadows[3],
};
type Props = {
    date: string;
};

export const DateDivider = ({ date }: Props) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 6 }}>
        <Box typography="body4" sx={dateDivider}>
            {checkIsTooLate(date)
                ? 'Waiting for review'
                : dayjs(date).format('MMMM DD, YYYY ')}
        </Box>
    </Box>
);

import { useMemo, useState } from 'react';
import styles from './PropertyTypesList.module.scss';
import { typeIconMap } from '../typeIconMap';
import Droplist, {
    DroplistItems,
    DroplistItem,
    DroplistSearch,
} from '../../../elements/Droplist';
import { ContactPropertyType } from '../../../api/types';

interface PropertyTypesListProps {
    onClick: (type: ContactPropertyType) => void;
}

export const PropertyTypesList = (props: PropertyTypesListProps) => {
    const [term, setTerm] = useState('');

    const filteredItems = useMemo(
        () =>
            typeIconMap.filter((item) =>
                item.name.toLowerCase().includes(term.toLowerCase()),
            ),
        [term],
    );

    const handleClick = (type: ContactPropertyType) => {
        setTerm('');
        props.onClick(type);
    };

    return (
        <Droplist>
            <DroplistSearch value={term} onChange={setTerm} />
            <DroplistItems>
                {filteredItems.map((item) => (
                    <DroplistItem
                        className={styles['item']}
                        key={item.name}
                        onClick={() => handleClick(item.type)}
                    >
                        {item.icon}
                        <span>{item.name}</span>
                    </DroplistItem>
                ))}
            </DroplistItems>
        </Droplist>
    );
};

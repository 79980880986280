import { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const MuiList: Components<Theme>['MuiList'] = {
    styleOverrides: {
        root: ({ theme: { spacing } }) => ({
            padding: spacing(1),
        }),
    },
};

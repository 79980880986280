import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const MuteIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.05806 2.05806C2.30214 1.81398 2.69786 1.81398 2.94194 2.05806L17.9419 17.0581C18.186 17.3021 18.186 17.6979 17.9419 17.9419C17.6979 18.186 17.3021 18.186 17.0581 17.9419L13.9078 14.7917H13.125V15C13.125 16.7259 11.7259 18.125 10 18.125C8.27411 18.125 6.875 16.7259 6.875 15V14.7917H4.65642C4.14496 14.7917 3.74264 14.7917 3.45511 14.7811C3.31081 14.7757 3.17652 14.7672 3.06303 14.7512C3.00613 14.7431 2.94107 14.7314 2.87571 14.7125C2.8177 14.6957 2.71675 14.6616 2.61721 14.5881C2.53621 14.5283 2.40938 14.4277 2.31796 14.2647C2.22654 14.1017 2.20685 13.941 2.19808 13.8408C2.18616 13.7044 2.21717 13.5901 2.23345 13.5361C2.25341 13.4699 2.2796 13.4063 2.30478 13.3508C2.35534 13.2392 2.42586 13.1079 2.50796 12.9635C2.67205 12.6749 2.91709 12.2739 3.23253 11.7578L3.23959 11.7462C3.84102 10.7621 4.375 9.29449 4.375 7.16667C4.375 6.60622 4.46946 6.05555 4.64951 5.53339L2.05806 2.94194C1.81398 2.69786 1.81398 2.30214 2.05806 2.05806ZM5.6748 6.55869C5.64181 6.75883 5.625 6.96202 5.625 7.16667C5.625 9.51587 5.03198 11.2104 4.30617 12.3981C4.00021 12.8987 3.77395 13.2691 3.6208 13.5355C3.87431 13.5416 4.21761 13.5417 4.67171 13.5417H12.6578L5.6748 6.55869ZM8.125 14.7917V15C8.125 16.0355 8.96447 16.875 10 16.875C11.0355 16.875 11.875 16.0355 11.875 15V14.7917H8.125ZM7.5564 2.40018C8.31247 2.05727 9.14686 1.875 10 1.875C11.4785 1.875 12.9043 2.42276 13.962 3.40993C15.0211 4.39845 15.625 5.74877 15.625 7.16667C15.625 8.56056 15.8542 9.66907 16.1791 10.5422C16.2995 10.8657 16.1348 11.2256 15.8113 11.3459C15.4878 11.4663 15.128 11.3016 15.0076 10.9781C14.6278 9.95741 14.375 8.70048 14.375 7.16667C14.375 6.10921 13.9253 5.08556 13.1091 4.32374C12.2914 3.56057 11.1737 3.125 10 3.125C9.32437 3.125 8.66607 3.26946 8.07271 3.53857C7.75836 3.68114 7.38794 3.54189 7.24537 3.22753C7.10279 2.91317 7.24205 2.54276 7.5564 2.40018Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

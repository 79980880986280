import cc from 'classcat';
import styles from './Chip.module.scss';
import CloseIcon from '../../assets/icons/different/close_gray.svg?react';

interface ChipProps {
    icon?: JSX.Element;
    label: string;
    className?: string;
    labelClassName?: string;
    deleteClassName?: string;
    onDelete?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

/** @deprecated use mui/material instead */
export const Chip = ({
    icon,
    label,
    className,
    labelClassName,
    deleteClassName,
    onDelete,
}: ChipProps) => {
    return (
        <div className={cc([styles['chip'], className])}>
            {icon}
            {label && (
                <span
                    className={cc([styles['chip__label'], labelClassName])}
                    title={label}
                >
                    {label}
                </span>
            )}
            {onDelete && (
                <CloseIcon
                    className={cc([styles['chip__delete'], deleteClassName])}
                    onClick={onDelete}
                />
            )}
        </div>
    );
};

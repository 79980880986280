import { useMutation } from '@tanstack/react-query';
import client from '../../api/http';
import { Attachment } from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { forceDownload } from '../../utils/downloads';

export const useUploadAttachment = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (file: File) => {
            const formData = new FormData();
            formData.append('file', file);

            return client()
                .post<Attachment>('/attachments', formData)
                .then(({ data }) => data);
        },
        onSuccess: (attachment) =>
            track('attachment_added', {
                type: attachment.type,
                size: attachment.size,
            }),
    });
};
export const useDeleteAttachment = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (id: string) =>
            client()
                .delete<null>(`/attachments/${id}`)
                .then(({ data }) => data),
        onSuccess: () => track('attachment_deleted'),
    });
};
export const useDownloadAttachment = (attachment: Attachment) => {
    const track = useTrack();

    return useMutation({
        mutationFn: () =>
            client()
                .get(`/attachments/${attachment.id}`, { responseType: 'blob' })
                .then(({ data: blob }) => {
                    const blobUrl = window.URL.createObjectURL(blob);
                    forceDownload(blobUrl, attachment.name ?? 'file');
                }),
        onSuccess: () =>
            track('attachment_downloading', {
                attachmentType: attachment.type,
            }),
    });
};

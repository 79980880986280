import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const Zoom = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
            <path
                d="M2.5 14.5C2.5 10.2996 2.5 8.19941 3.31745 6.59508C4.0365 5.18385 5.18385 4.0365 6.59508 3.31745C8.19941 2.5 10.2996 2.5 14.5 2.5H25.5C29.7004 2.5 31.8006 2.5 33.4049 3.31745C34.8161 4.0365 35.9635 5.18385 36.6825 6.59508C37.5 8.19941 37.5 10.2996 37.5 14.5V25.5C37.5 29.7004 37.5 31.8006 36.6825 33.4049C35.9635 34.8161 34.8161 35.9635 33.4049 36.6825C31.8006 37.5 29.7004 37.5 25.5 37.5H14.5C10.2996 37.5 8.19941 37.5 6.59508 36.6825C5.18385 35.9635 4.0365 34.8161 3.31745 33.4049C2.5 31.8006 2.5 29.7004 2.5 25.5V14.5Z"
                fill="url(#paint0_linear_22303_16475)"
            />
            <path
                d="M10.3333 12.5C9.45889 12.5 8.75 13.2995 8.75 14.2858V22.9464C8.75 25.4612 10.5576 27.5 12.7875 27.5L22.1666 27.4108C23.0411 27.4108 23.75 26.6112 23.75 25.625V16.875C23.75 14.3601 21.6049 12.5 19.375 12.5H10.3333Z"
                fill="white"
            />
            <path
                d="M25.8903 15.9095C25.3245 16.469 25 17.2764 25 18.125V21.7491C25 22.5978 25.3245 23.4053 25.8903 23.9646L29.411 27.0656C30.1274 27.7739 31.25 27.2001 31.25 26.1256V13.919C31.25 12.8444 30.1274 12.2707 29.411 12.979L25.8903 15.9095Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_22303_16475"
                    x1="37.5"
                    y1="2.5"
                    x2="2.5"
                    y2="37.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4583E6" />
                    <stop offset="1" stopColor="#1A44BD" />
                </linearGradient>
            </defs>
        </svg>
    </SvgIcon>
);

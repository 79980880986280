import { useMeQueryData } from '../../queries/user';
import { PropsWithChildren } from 'react';

export const SubscribersOnly = ({ children }: PropsWithChildren) => {
    const tier = useMeQueryData()?.activeTeam.tier;

    if (tier === 'free') {
        return null;
    }
    return children;
};

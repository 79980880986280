import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            minHeight: 32,
            '&.MuiTabs-color-info .MuiTabs-indicator': {
                backgroundColor: theme.palette.info.main,
            },
        }),
        indicator: {
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
        },
    },
};

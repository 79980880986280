import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const SearchFilledIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8108 21.5508C20.7123 21.5513 20.6146 21.5321 20.5236 21.4943C20.4326 21.4565 20.3501 21.4009 20.2808 21.3308L15.5808 16.6408C13.9437 18.0051 11.8436 18.6856 9.71751 18.5409C7.59139 18.3961 5.60295 17.4372 4.1659 15.8636C2.72884 14.29 1.95384 12.2229 2.00213 10.0924C2.05042 7.96188 2.91828 5.93203 4.42516 4.42516C5.93203 2.91828 7.96188 2.05042 10.0924 2.00213C12.2229 1.95384 14.29 2.72884 15.8636 4.1659C17.4372 5.60295 18.3961 7.59139 18.5409 9.71752C18.6856 11.8436 18.0051 13.9437 16.6408 15.5808L21.3408 20.2708C21.4813 20.4114 21.5601 20.6021 21.5601 20.8008C21.5601 20.9996 21.4813 21.1902 21.3408 21.3308C21.2716 21.4009 21.189 21.4565 21.098 21.4943C21.007 21.5321 20.9093 21.5513 20.8108 21.5508ZM10.3108 3.55081C8.97578 3.55081 7.67074 3.94669 6.56071 4.68839C5.45068 5.43009 4.58551 6.48429 4.07462 7.7177C3.56373 8.9511 3.43006 10.3083 3.69051 11.6177C3.95096 12.927 4.59383 14.1298 5.53784 15.0738C6.48184 16.0178 7.68458 16.6607 8.99395 16.9211C10.3033 17.1816 11.6605 17.0479 12.8939 16.537C14.1273 16.0261 15.1815 15.1609 15.9232 14.0509C16.6649 12.9409 17.0608 11.6358 17.0608 10.3008C17.0582 8.51141 16.3462 6.79606 15.0809 5.53076C13.8156 4.26546 12.1002 3.55345 10.3108 3.55081Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

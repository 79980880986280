import {
    CircularProgress,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import { BlockIcon } from '../../../icons/common/BlockIcon';
import { useBlock } from './queries';
import { emptyFunction } from '../../../helpers/function.helpers';

type Props = { phone: string[]; onSuccess?: () => void };

export const BlockPhoneListItem = ({
    phone,
    onSuccess = emptyFunction,
}: Props) => {
    const block = useBlock();

    return (
        <MenuItem
            disabled={block.isPending}
            onClick={() => block.mutateAsync(phone).then(onSuccess)}
        >
            <ListItemIcon>
                {block.isPending ? (
                    <CircularProgress size={18} disableShrink />
                ) : (
                    <BlockIcon />
                )}
            </ListItemIcon>
            <ListItemText>
                Block{phone.length > 1 && ` ${phone.length} members`}
            </ListItemText>
        </MenuItem>
    );
};

import { useEffect, useState } from 'react';
import cc from 'classcat';
import styles from './Scheduled.module.scss';
import CohortContactsList from '../../components/CohortContactsList';
import { useConversation } from '../../queries/conversations';
import { Contact } from '../../api/types';
import { useContactsQueryData } from '../../queries/contacts';
import { useNavigate, useParams } from 'react-router';
import { ScheduledConversationsList } from '../../components/ConversationsList/ScheduledConversationsList';
import Chat from '../../components/Chat';
import { getByPhoneOrEmpty } from '../../queries/contact-library.helpers';

export function Scheduled() {
    const [selectedContact, setSelectedContact] = useState<
        Partial<Contact> | Contact | null
    >(null);
    const navigate = useNavigate();
    const { conversationId } = useParams<{ conversationId: string }>();

    const { data: conversation } = useConversation(conversationId);
    const contacts = useContactsQueryData();

    const memberContacts: Partial<Contact>[] | undefined =
        conversation?.members.map((member) =>
            getByPhoneOrEmpty(member, contacts),
        );

    useEffect(() => {
        if (conversation && conversation.status === 'deleted') {
            navigate(`/inbox/`);
        } else {
            setSelectedContact(
                memberContacts && memberContacts.length > 0
                    ? memberContacts[0]
                    : null,
            );
        }
    }, [conversation, conversationId, memberContacts, navigate]);

    return (
        <div className={styles['scheduled']}>
            <div
                className={cc([
                    {
                        [styles['scheduled_mobile-hidden']]: !!conversation,
                    },
                ])}
            >
                <ScheduledConversationsList />
            </div>

            <section
                className={cc([
                    styles['chat'],
                    {
                        [styles['chat_empty']]: !conversation,
                        [styles['scheduled_mobile-hidden']]: !conversation,
                    },
                ])}
            >
                {conversationId && conversation && (
                    <Chat
                        conversation={conversation}
                        onBackClick={() => navigate('/scheduled')}
                    />
                )}
                {!conversationId && (
                    <p className={styles['scheduled__chat-placeholder']}>
                        Choose chat to start conversation
                    </p>
                )}
            </section>

            <CohortContactsList
                contacts={memberContacts}
                selectedContact={selectedContact}
                onContactClick={setSelectedContact}
            />
        </div>
    );
}

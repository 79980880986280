import cc from 'classcat';
import { useMemo, useState } from 'react';
import styles from './ExtraFiltersPopup.module.scss';
import {
    CohortMeta,
    ConversationsFilter,
    ConversationsFilterType,
} from '../../api/types';
import Radio from '../../elements/Radio';
import Search from '../../elements/Inputs/Search';
import CheckBox from '../../elements/CheckBox/CheckBox';
import { Typography } from '@mui/material';

interface Props {
    filter: ConversationsFilter;
    cohorts: CohortMeta[];
    onChange: (filter: ConversationsFilter) => void;
}

const toggleCohort = (list: CohortMeta[], cohort: CohortMeta): CohortMeta[] =>
    list.find(({ id }) => id === cohort.id)
        ? list.filter(({ id }) => id !== cohort.id)
        : list.concat(cohort);

export const ExtraFiltersPopup = (props: Props) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredCohorts = useMemo(
        () =>
            searchTerm
                ? props.cohorts.filter(({ name }) =>
                      name.toLowerCase().includes(searchTerm.toLowerCase()),
                  )
                : props.cohorts,
        [props.cohorts, searchTerm],
    );

    const isUnread = props.filter.type === ConversationsFilterType.Unread;
    const isAssignedToMe =
        props.filter.type === ConversationsFilterType.AssignedToMe;

    const getCohortClickHandler = (cohort: CohortMeta) => () => {
        const newList = toggleCohort(props.filter.cohortsList, cohort);

        if (newList.length === 0) {
            props.onChange({
                type: ConversationsFilterType.Active,
                cohortsList: [],
            });
        } else {
            props.onChange({
                type: ConversationsFilterType.ByCohorts,
                cohortsList: newList,
            });
        }
    };

    return (
        <div className={styles['root']}>
            <div
                className={cc([
                    styles['root__block'],
                    styles['root__block_divider'],
                ])}
            >
                <h5 className={styles['root__title']}>Filter by:</h5>
            </div>

            <ul
                className={cc([
                    styles['root__block'],
                    styles['root__block_divider'],
                    styles['root__list'],
                ])}
            >
                <li
                    className={styles['root__item']}
                    onClick={() =>
                        props.onChange({
                            type: ConversationsFilterType.AssignedToMe,
                            cohortsList: [],
                        })
                    }
                >
                    <Radio checked={isAssignedToMe} />
                    <Typography variant="body3" noWrap>
                        Assigned to me
                    </Typography>
                </li>
                <li
                    className={styles['root__item']}
                    onClick={() =>
                        props.onChange({
                            type: ConversationsFilterType.Unread,
                            cohortsList: [],
                        })
                    }
                >
                    <Radio checked={isUnread} />
                    <Typography variant="body3" noWrap>
                        Unread messages
                    </Typography>
                </li>
            </ul>

            <div
                className={cc([
                    styles['root__block'],
                    styles['root__cohorts-block'],
                ])}
            >
                <Typography
                    variant="body4"
                    sx={{ fontWeight: 500, color: 'custom.gray.super' }}
                >
                    Saved cohorts
                </Typography>

                {props.cohorts.length === 0 && (
                    <p className={styles['root__info-text']}>
                        You currently do not have any saved cohorts. Please
                        navigate to the <em>contacts page</em> to create a new
                        one.
                    </p>
                )}

                {props.cohorts.length > 0 && (
                    <>
                        <div className={styles['root__search']}>
                            <Search
                                value={searchTerm}
                                className={styles['root__search-input']}
                                onSearch={(term) => setSearchTerm(term)}
                                fullWidth
                            />
                        </div>
                        <ul
                            className={cc([
                                styles['root__list'],
                                styles['root__cohorts-list'],
                            ])}
                        >
                            {filteredCohorts.map((cohort) => (
                                <li
                                    key={cohort.id}
                                    className={styles['root__item']}
                                    title={cohort.name}
                                    onClick={getCohortClickHandler(cohort)}
                                >
                                    <CheckBox
                                        checked={
                                            !!props.filter.cohortsList.find(
                                                ({ id }) => id === cohort.id,
                                            )
                                        }
                                    />
                                    <Typography
                                        component="span"
                                        variant="body3"
                                        className={styles['root__item-label']}
                                    >
                                        {cohort.name}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};

import styles from './ExportLogs.module.scss';
import DatePicker from 'react-datepicker';
import AdvancedSelect, {
    OptionBase,
    OptionId,
} from '../../../elements/AdvancedSelect';
import { FormEvent, forwardRef, useMemo, useState } from 'react';
import { useInboxes, useInboxExport } from '../../../queries/inboxes';
import uniqBy from 'lodash/uniqBy';
import Input from '../../../elements/Inputs/Input';
import { InputProps } from '../../../types/ElementsProps';
import FormControl from '../../../elements/FormControl';
import Icon from '../../../icons/Icon';
import { AnalyticsActions, useAnalytics } from '../../../contexts/analytics';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

const DatePickerCustomInput = forwardRef<HTMLInputElement, InputProps>(
    function DatePickerCustomInput(props, ref) {
        return (
            <Input
                onClick={props.onClick}
                value={props.value}
                customRef={ref}
                prefix={<Icon name="calendar" />}
                fullWidth
                readOnly
                uncontrolled
            />
        );
    },
);

export const ExportLogs = () => {
    const [selectedInboxes, setSelectedInboxes] = useState<OptionBase[]>([]);
    const [message, setMessage] = useState<string>('');
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        null,
        null,
    ]);

    const { dispatch } = useAnalytics();

    // Data
    const { data: inboxes = [] } = useInboxes();
    const options = useMemo(
        () =>
            inboxes?.map((i) => ({ id: i.id, label: i.name || i.phone })) || [],
        [inboxes],
    );

    // Mutations
    const { mutateAsync: exportData, isPending: exportDataLoading } =
        useInboxExport();

    const onChange = (i: OptionId) => {
        const inbox = options.find((inbox) => inbox.id === i);
        const existing = selectedInboxes.find((o) => o.id === i);

        if (!existing && inbox) {
            setSelectedInboxes((is) => uniqBy([...is, inbox], (o) => o.id));
        } else {
            setSelectedInboxes((is) =>
                uniqBy(
                    is.filter((b) => b.id !== i),
                    (o) => o.id,
                ),
            );
        }
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: 'clicked_export_logs',
            },
        });

        if (dateRange[0] && dateRange[1]) {
            exportData({
                startDate: dayjs(dateRange[0]).startOf('day').toDate(),
                endDate: dayjs(dateRange[1]).endOf('day').toDate(),
                inboxIds: selectedInboxes.map((i) => i.id),
            })
                .then((blob) => {
                    const file = window.URL.createObjectURL(blob);
                    window.location.assign(file);
                })
                .catch(() =>
                    setMessage(
                        'There was an error, please contact Clerk Support',
                    ),
                );
        }
    };

    const btnDisabled =
        exportDataLoading ||
        !dateRange[0] ||
        !dateRange[1] ||
        selectedInboxes.length < 1;

    return (
        <SettingsLayout
            title="Logs"
            category="Data"
            description="Export any data from any phone"
        >
            <section className={styles['root']}>
                <header className={styles['root__header']}>
                    Select one or several phone and download (export) all of the
                    conversations. Clerk will generate a .csv file.
                </header>

                <form onSubmit={onSubmit}>
                    <FormControl>
                        <AdvancedSelect
                            placeholder="Select phones"
                            options={options}
                            value={selectedInboxes.map((i) => i.id)}
                            onChange={onChange}
                            multiselect
                        />
                    </FormControl>

                    <FormControl>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedInboxes(
                                    inboxes.map((i) => ({
                                        id: i.id,
                                        label: i.name || i.phone,
                                    })),
                                );
                            }}
                        >
                            Select All Phone Numbers
                        </Button>
                    </FormControl>

                    <FormControl className={styles['root__last-control']}>
                        <DatePicker
                            dateFormat="MMM d, yyyy"
                            customInput={<DatePickerCustomInput />}
                            startDate={dateRange[0]}
                            endDate={dateRange[1]}
                            onChange={(dates) => setDateRange(dates)}
                            selectsRange
                        />
                    </FormControl>
                    <Button
                        disabled={btnDisabled}
                        type="submit"
                        startIcon={
                            <Icon
                                className={styles['root__download-icon']}
                                name="file-download"
                            />
                        }
                    >
                        <span>Download .csv</span>
                    </Button>
                    <p className={styles['root__error']}>
                        {message.length > 0 && <p>{message}</p>}
                    </p>
                </form>
            </section>
        </SettingsLayout>
    );
};

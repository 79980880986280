export enum Step {
    Email = 'email',
    Code = 'code',
    Team = 'team',
}

export const getStepContent = (step: Step) => {
    if (step === Step.Email) {
        return {
            icon: '👋',
            header: 'Welcome to Clerk Chat',
            subheader: 'Drop your email to login or register',
        };
    }
    if (step === Step.Code) {
        return {
            icon: '📬',
            header: 'Welcome to Clerk Chat',
            subheader: 'Drop your email to login or register',
        };
    }
    return {
        icon: '🧳',
        header: 'Join Your Team',
        subheader: 'These are available teams associated with your email',
    };
};

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Hidden,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { DialogAttentionIcon } from '../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../elements/Dialog/DialogTitleTwoLine';
import { useCancelTeamSubscription } from '../../../queries/billing';
import { StripeMainSubscription } from '../../../api/billing';
import { CloseIcon } from '../../../icons/common/CCloseIcon';
import dayjs from 'dayjs';
import { useState } from 'react';

type Props = {
    open: boolean;
    onClose: () => void;
    subscription?: StripeMainSubscription;
};

export enum CancellationReason {
    CustomerService = 'customer_service',
    LowQuality = 'low_quality',
    MissingFeatures = 'missing_features',
    Other = 'other',
    SwitchedService = 'switched_service',
    TooComplex = 'too_complex',
    TooExpensive = 'too_expensive',
    Unused = 'unused',
}

const CancellationReasonLabels: Partial<Record<CancellationReason, string>> = {
    [CancellationReason.Unused]: 'Product/service no longer needed',
    [CancellationReason.TooExpensive]: 'Pricing is too high',
    [CancellationReason.SwitchedService]: 'Found a better alternative product',
    [CancellationReason.MissingFeatures]: 'Limited features',
    [CancellationReason.CustomerService]: 'Poor customer support',
    [CancellationReason.Other]: 'Other',
};

export const CancelSubscriptionModal = ({
    open,
    onClose,
    subscription,
}: Props) => {
    const { mutateAsync: deleteSubscription, isPending } =
        useCancelTeamSubscription();

    const [reason, setReason] = useState<CancellationReason | null>(null);
    const [comment, setComment] = useState<string | null>(null);

    const onSubmit = async () => {
        if (!subscription?.id || !reason) {
            return;
        }
        await deleteSubscription({ feedback: reason, comment: comment ?? '' });
        onClose();
    };

    if (!subscription) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
            }}
            maxWidth="xs"
            disablePortal
        >
            <DialogAttentionIcon color="error" />
            <DialogTitleTwoLine
                title="Cancel subscription"
                subtitle={
                    <>
                        <Typography color="text.secondary" variant="body3">
                            Are you sure want to cancel your subscription? It
                            will continue until
                        </Typography>{' '}
                        <Typography
                            color="text.secondary"
                            variant="body3"
                            fontWeight={500}
                        >
                            {dayjs(subscription.currentPeriodEnd * 1000).format(
                                'MMMM DD, YYYY',
                            )}
                        </Typography>{' '}
                        <Typography color="text.secondary" variant="body3">
                            and there will be no further charges.
                        </Typography>
                    </>
                }
            />
            <DialogContentText
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    px: 6,
                    pb: 6,
                    pt: 2,
                }}
            >
                <Typography
                    variant="body3"
                    color="text.primary"
                    fontWeight={500}
                >
                    Please provide a reason for your cancellation:
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="cancellation reason"
                        name="cancellationReason"
                        value={reason}
                        sx={{ gap: 3 }}
                        onChange={({ target }) =>
                            setReason(target.value as CancellationReason)
                        }
                    >
                        {Object.entries(CancellationReasonLabels).map(
                            ([value, label]) => (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    sx={{ height: 20 }}
                                    control={
                                        <Radio
                                            size="small"
                                            sx={{
                                                color: 'custom.gray.light400',
                                                '&.Mui-checked': {
                                                    color: 'blue.main',
                                                },
                                            }}
                                        />
                                    }
                                    label={label}
                                />
                            ),
                        )}
                    </RadioGroup>
                </FormControl>
                {reason === CancellationReason.Other && (
                    <TextField
                        placeholder="We value your feedback. Please share what specifically led to your decision to cancel the subscription."
                        multiline
                        rows={1}
                        inputProps={{ maxLength: 300 }}
                        value={comment}
                        onChange={({ target }) => setComment(target.value)}
                    />
                )}
            </DialogContentText>

            <DialogActions>
                <Button
                    sx={{ minWidth: 96 }}
                    onClick={() => {
                        onClose();
                    }}
                    size="large"
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    size="large"
                    fullWidth
                    color="error"
                    variant="outlined"
                    disabled={
                        isPending ||
                        !reason ||
                        (reason === CancellationReason.Other && !comment)
                    }
                    onClick={onSubmit}
                    startIcon={
                        isPending ? (
                            <CircularProgress size={18} color="error" />
                        ) : (
                            <CloseIcon />
                        )
                    }
                >
                    <Hidden smDown>Yes, I want to break up</Hidden>
                    <Hidden smUp>Break up</Hidden>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

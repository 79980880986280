import IconBase, { IconBaseProps } from '../IconBase';

/** @deprecated */
export const UnmuteIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M9.00171 17H5.60611C4.34769 17 3.71847 17 3.58609 16.9023C3.43735 16.7925 3.40101 16.7277 3.38491 16.5436C3.37057 16.3797 3.75622 15.7486 4.52752 14.4866C5.32386 13.1835 6.00006 11.2862 6.00006 8.6C6.00006 7.11479 6.6322 5.69041 7.75742 4.6402C8.88264 3.59 10.4088 3 12.0001 3C13.5914 3 15.1175 3.59 16.2427 4.6402C17.3679 5.69041 18.0001 7.11479 18.0001 8.6C18.0001 11.2862 18.6763 13.1835 19.4726 14.4866C20.2439 15.7486 20.6296 16.3797 20.6152 16.5436C20.5991 16.7277 20.5628 16.7925 20.414 16.9023C20.2817 17 19.6524 17 18.394 17H15.0001M9.00171 17L9.00006 18C9.00006 19.6569 10.3432 21 12.0001 21C13.6569 21 15.0001 19.6569 15.0001 18V17M9.00171 17H15.0001"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </IconBase>
);

import { Contact, Conversation, Feature } from '../../api/types';
import { SummarizeAction } from './SummarizeAction/SumarizeAction';
import { Stack, Divider } from '@mui/material';
import * as styles from './styles';
import { ContactInfoButton } from './ContactInfoButton';
import { ConversationMenu } from './ConversationMenu/ConversationMenu';
import { AssignToTeam } from './AssignTo/AssignToTeam';
import { AssignToCohort } from './AssignTo/AssignToCohort';
import { WithFeature } from '../../containers/Feature/WithFeature';

interface ConversationToolbarProps {
    conversation: Conversation;
    contacts: Contact[];
    // Menu
    onMuteClick?: () => void;
}

export const ConversationToolbar = (props: ConversationToolbarProps) => {
    return (
        <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={0.5}>
                <AssignToTeam conversation={props.conversation} />
                {!!props.contacts[0]?.id && (
                    <AssignToCohort
                        contactId={props.contacts[0].id}
                        disabled={
                            !props.contacts[0].id || props.contacts.length > 1
                        }
                    />
                )}
                <WithFeature feature={Feature.AiWorkflow}>
                    <SummarizeAction conversation={props.conversation} />
                </WithFeature>
            </Stack>

            <Divider orientation="vertical" flexItem sx={styles.divider} />

            <Stack direction="row" spacing={0.5}>
                <ConversationMenu
                    conversation={props.conversation}
                    onMuteClick={props.onMuteClick}
                />

                {props.conversation.members.length === 1 && (
                    <ContactInfoButton />
                )}
            </Stack>
        </Stack>
    );
};

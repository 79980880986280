import { CircularProgress, ListItemIcon } from '@mui/material';

type Props = {
    loading: boolean;
    icon?: Element;
    children: React.ReactNode;
};
export const ListItemIconLoader = ({
    loading = false,
    icon,
    children,
}: Props) => {
    if (loading) {
        return <CircularProgress size={16} sx={{ mr: 3 }} />;
    }
    return <ListItemIcon>{icon || children}</ListItemIcon>;
};

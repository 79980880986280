import cc from 'classcat';
import styles from './DiscussionBlock.module.scss';
import { Discussion } from '../../../../api/types';
import { dateFormatRelative } from '../../../../helpers/formatting';
import { pluralize } from '../../../../utils/pluralize';

export interface DiscussionBlockProps {
    discussion: Discussion;
    isInbound?: boolean;
    onClick: () => void;
}

export const DiscussionBlock = (props: DiscussionBlockProps) => {
    return (
        <div
            className={cc([
                styles['root'],
                { [styles['root_inbound']]: props.isInbound },
            ])}
        >
            <div className={styles['root__bracket']}>
                <div
                    className={cc([
                        styles['root__bracket-arc'],
                        {
                            [styles['root__bracket-arc_inbound']]:
                                props.isInbound,
                            [styles['root__bracket-arc_outbound']]:
                                !props.isInbound,
                        },
                    ])}
                />
            </div>
            <div className={styles['root__replies']} onClick={props.onClick}>
                {pluralize(props.discussion.replies, ' comment', ' comments')}
            </div>
            {props.discussion.lastReplyDate && (
                <>
                    <div className={styles['root__delimeter']} />
                    <div className={styles['root__last-reply-date']}>
                        Last reply{' '}
                        {dateFormatRelative(props.discussion.lastReplyDate)}
                    </div>
                </>
            )}
        </div>
    );
};

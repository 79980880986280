import {
    Components,
    inputBaseClasses,
    outlinedInputClasses,
    Theme,
} from '@mui/material';
import { UnfoldMoreOutlined } from '../icons/common/UnfoldMoreOutlined';
import { CloseFilledIcon } from '../icons/common/CloseFilledIcon';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
    defaultProps: {
        popupIcon: <UnfoldMoreOutlined />,
        clearIcon: <CloseFilledIcon />,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: [theme.spacing(0.5), theme.spacing(2)],
            },
            [`.${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        }),
        popupIndicatorOpen: { transform: 'initial' },
        inputRoot: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        popper: ({ theme }) => ({ paddingTop: theme.spacing(2) }),
        listbox: ({ theme }) => ({
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }),
        paper: ({ theme }) => ({
            boxShadow: theme.shadows[5],
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.divider,
        }),
        option: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
        },
        noOptions: ({ theme }) => ({
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
        }),
    },
};

import { svgIconClasses, SxProps } from '@mui/material';
import { colors } from '../../theme/palette';

const palette = {
    primary: {
        bgcolor: colors.blue[50],
        borderColor: '#788DF852',
        fill: colors.blue[500],
    },
    error: {
        fill: 'error.main',
        bgcolor: colors.red[50],
        borderColor: colors.red[200],
    },
};

export type Colors = keyof typeof palette;

export const attentionIconHolder: (c: Colors) => SxProps = (
    color = 'error',
) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 6,
    borderRadius: 100,
    borderWidth: 1,
    borderStyle: 'solid',
    bgcolor: palette[color].bgcolor,
    borderColor: palette[color].borderColor,
    p: 3,
    [`.${svgIconClasses.root}`]: {
        color: palette[color].fill,
    },
});

import {
    QueryObserverOptions,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import {
    createProperty,
    deleteProperty,
    deletePropertyOptions,
    listProperties,
    PropertyOptionRemoveDTO,
    PropertyOptionUpdateDTO,
    updateProperty,
    updatePropertyOption,
} from '../api/contacts';
import { ContactPropertyType } from '../api/types';
import { UserPropertyModel } from '../components/UserInfoProperty/types';
import { ALL_CONTACTS_KEY } from './contacts';
import { queryClient } from './queryClient';
import { UUID } from '../types/uuid';
import {
    ContactLibrary,
    getById,
    getEmptyLibrary,
    updateContact,
} from './contact-library.helpers';

export const PROPERTIES_KEY = 'properties_list';

const PROPERTY_UPDATE_KEY = 'property_update';
const PROPERTY_OPTION_UPDATE_KEY = 'property_option_update';

export function usePropertiesQuery(options?: {
    select: QueryObserverOptions<UserPropertyModel[]>['select'];
}) {
    return useQuery({
        queryKey: [PROPERTIES_KEY],
        queryFn: () =>
            listProperties().then((attributes) =>
                attributes.sort((a, b) => (a.created! > b.created! ? 1 : -1)),
            ),
        select: options?.select,
    });
}

export const useProperty = (propertyId: UUID) => {
    const { data = [] } = usePropertiesQuery();
    return data.find(({ id }) => id === propertyId);
};

export function usePropertiesQueryData() {
    return (
        queryClient.getQueryData<UserPropertyModel<unknown>[]>([
            PROPERTIES_KEY,
        ]) || []
    );
}

export function useCreateProperty() {
    return useMutation({
        mutationKey: ['property_create'],
        mutationFn: createProperty,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [PROPERTIES_KEY] });
        },
    });
}

export function useUpdateProperty() {
    return useMutation({
        mutationKey: [PROPERTY_UPDATE_KEY],
        mutationFn: updateProperty,
        onMutate: async (data) => {
            await queryClient.cancelQueries({ queryKey: [PROPERTIES_KEY] });
            await queryClient.cancelQueries({
                queryKey: [PROPERTY_UPDATE_KEY],
            });

            const previousProperties =
                queryClient.getQueryData<UserPropertyModel[]>([
                    PROPERTIES_KEY,
                ]) || [];

            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                (properties = []) => {
                    const idx = properties.findIndex(
                        ({ id }) => id === data.id,
                    );
                    const updatedProperties = properties.slice();
                    updatedProperties[idx] = {
                        ...updatedProperties[idx],
                        ...data.params,
                    };

                    return updatedProperties;
                },
            );

            return { previousProperties };
        },
        onError: (_error, _data, context) => {
            const { previousProperties = [] } = context || {};

            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                () => previousProperties,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [PROPERTIES_KEY] });
        },
    });
}

export function useDeleteProperty() {
    return useMutation({
        mutationKey: ['property_delete'],
        mutationFn: deleteProperty,
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [PROPERTIES_KEY] });

            const previousProperties =
                queryClient.getQueryData<UserPropertyModel[]>([
                    PROPERTIES_KEY,
                ]) || [];

            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                (properties = []) => properties.filter((p) => p.id !== id),
            );

            return { previousProperties };
        },
        onError: (_error, _data, context) => {
            const { previousProperties = [] } = context || {};

            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                () => previousProperties,
            );
        },
    });
}

export function useUpdatePropertyOption(
    propertyId: string,
    contactId?: string,
) {
    return useMutation<
        unknown,
        unknown,
        PropertyOptionUpdateDTO,
        { previousProperties: UserPropertyModel[] }
    >({
        mutationKey: [PROPERTY_OPTION_UPDATE_KEY],
        mutationFn: (dto) => updatePropertyOption(propertyId, dto),

        onMutate: async (dto) => {
            if (contactId) {
                await queryClient.cancelQueries({
                    queryKey: [ALL_CONTACTS_KEY],
                });
            }
            await queryClient.cancelQueries({ queryKey: [PROPERTIES_KEY] });
            await queryClient.cancelQueries({
                queryKey: [PROPERTY_OPTION_UPDATE_KEY],
            });

            // Update the property

            const previousProperties =
                queryClient.getQueryData<UserPropertyModel[]>([
                    PROPERTIES_KEY,
                ]) || [];

            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                (properties = []) => {
                    const updatedProperties = properties.slice();
                    const propertyIdx = updatedProperties.findIndex(
                        ({ id }) => id === propertyId,
                    );

                    const oldOptions = updatedProperties[propertyIdx]
                        .options as string[];
                    const newOptions = oldOptions.slice();

                    const optionIdx = newOptions.findIndex(
                        (o) => o === dto.oldOption,
                    );
                    newOptions[optionIdx] = dto.newOption;
                    updatedProperties[propertyIdx].options = newOptions;

                    return updatedProperties;
                },
            );

            // Update the current contact (otherwise on outside click the old data with old option value may be sent to the server)

            const property = previousProperties.find(
                ({ id }) => id === propertyId,
            );
            const propertyType = property?.type;

            queryClient.setQueryData<ContactLibrary>(
                [ALL_CONTACTS_KEY],
                (library = getEmptyLibrary()) => {
                    const contact = getById(contactId, library);
                    if (contact && property) {
                        const value: string[] = contact.data[propertyId] || [];

                        contact.data[propertyId] =
                            propertyType === ContactPropertyType.MultiSelect
                                ? value.reduce<string[]>(
                                      (result, item) =>
                                          result.concat(
                                              item === dto.oldOption
                                                  ? dto.newOption
                                                  : item,
                                          ),
                                      [],
                                  )
                                : dto.newOption;

                        updateContact(contact, library);
                    }
                    return library;
                },
            );

            return { previousProperties };
        },
        onError: (_error, dto, context) => {
            const { previousProperties = [] } = context || {};

            // Revert the property
            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                () => previousProperties,
            );

            // Revert the current contact
            const property = previousProperties.find(
                ({ id }) => id === propertyId,
            );
            const propertyType = property?.type;

            queryClient.setQueryData<ContactLibrary>(
                [ALL_CONTACTS_KEY],
                (contacts = getEmptyLibrary()) => {
                    const contact = getById(contactId, contacts);
                    if (contact && property) {
                        const value: string[] = contact.data[propertyId] || [];

                        contact.data[propertyId] =
                            propertyType === ContactPropertyType.MultiSelect
                                ? value.reduce<string[]>(
                                      (result, item) =>
                                          result.concat(
                                              item === dto.newOption
                                                  ? dto.oldOption
                                                  : item,
                                          ),
                                      [],
                                  )
                                : dto.oldOption;

                        updateContact(contact, contacts);
                    }
                    return contacts;
                },
            );
        },
    });
}

export function useDeletePropertyOption(
    propertyId: string,
    contactId?: string,
) {
    return useMutation<
        unknown,
        unknown,
        PropertyOptionRemoveDTO,
        { previousProperties: UserPropertyModel[] }
    >({
        mutationKey: ['property_option_delete'],
        mutationFn: (dto) => deletePropertyOptions(propertyId, dto),
        onMutate: async (dto) => {
            await queryClient.cancelQueries({ queryKey: [PROPERTIES_KEY] });
            await queryClient.cancelQueries({
                queryKey: [PROPERTY_OPTION_UPDATE_KEY],
            });

            // Update the property

            const previousProperties =
                queryClient.getQueryData<UserPropertyModel[]>([
                    PROPERTIES_KEY,
                ]) || [];

            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                (properties = []) => {
                    const updatedProperties = properties.slice();
                    const propertyIdx = updatedProperties.findIndex(
                        ({ id }) => id === propertyId,
                    );

                    const oldOptions = updatedProperties[propertyIdx]
                        .options as string[];
                    const newOptions = oldOptions.slice();

                    const optionIdx = newOptions.findIndex(
                        (o) => o === dto.option,
                    );
                    newOptions.splice(optionIdx, 1);
                    updatedProperties[propertyIdx].options = newOptions;

                    return updatedProperties;
                },
            );

            // Update the current contact (otherwise on outside click the old data with old option value may be sent to the server)

            const property = previousProperties.find(
                ({ id }) => id === propertyId,
            );
            const propertyType = property?.type;

            queryClient.setQueryData<ContactLibrary>(
                [ALL_CONTACTS_KEY],
                (contacts = getEmptyLibrary()) => {
                    const contact = getById(contactId, contacts);
                    if (contact && property) {
                        const value: string[] = contact.data[propertyId] || [];

                        if (propertyType === ContactPropertyType.MultiSelect) {
                            contact.data[propertyId] = value.filter(
                                (o) => o !== dto.option,
                            );
                        } else {
                            delete contact.data[propertyId];
                        }

                        updateContact(contact, contacts);
                    }
                    return contacts;
                },
            );

            return { previousProperties };
        },
        onError: (_error, dto, context) => {
            const { previousProperties = [] } = context || {};

            // Revert the property
            queryClient.setQueryData<UserPropertyModel[]>(
                [PROPERTIES_KEY],
                () => previousProperties,
            );

            // Revert the current contact

            const property = previousProperties.find(
                ({ id }) => id === propertyId,
            );
            const propertyType = property?.type;

            queryClient.setQueryData<ContactLibrary>(
                [ALL_CONTACTS_KEY],
                (library = getEmptyLibrary()) => {
                    const contact = getById(contactId, library);
                    if (contact && property) {
                        const value: string[] = contact.data[propertyId] || [];

                        if (propertyType === ContactPropertyType.MultiSelect) {
                            contact.data[propertyId] = value.concat(dto.option);
                        } else {
                            contact.data[propertyId] = dto.option;
                        }

                        updateContact(contact, library);
                    }
                    return library;
                },
            );
        },
    });
}

import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const CloseIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.34674 4.34661C4.54201 4.15135 4.85859 4.15135 5.05385 4.34661L8.00013 7.29289L10.9464 4.34661C11.1417 4.15135 11.4583 4.15135 11.6535 4.34661C11.8488 4.54188 11.8488 4.85846 11.6535 5.05372L8.70724 8L11.6535 10.9463C11.8488 11.1415 11.8488 11.4581 11.6535 11.6534C11.4583 11.8486 11.1417 11.8486 10.9464 11.6534L8.00013 8.70711L5.05385 11.6534C4.85859 11.8486 4.54201 11.8486 4.34674 11.6534C4.15148 11.4581 4.15148 11.1415 4.34674 10.9463L7.29302 8L4.34674 5.05372C4.15148 4.85846 4.15148 4.54188 4.34674 4.34661Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

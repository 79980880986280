import { Box, Skeleton, Stack } from '@mui/material';
import { container, info, messages } from './chat-message.styles';
import { DotDivider } from '../../../../components/common/DotDivider';
import range from 'lodash/range';
import { MessageItemSkeleton } from './MessageItemSkeleton';
import random from 'lodash/random';

export const ChatMessageSkeleton = () => {
    const inbound = !!random(0, 1);

    return (
        <Box sx={container(inbound)}>
            <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{
                    order: inbound ? 0 : 1,
                }}
            />
            <Box sx={messages(inbound)}>
                <Box typography="body5" sx={info(inbound)}>
                    <Skeleton height={12} width={120} />
                    <DotDivider />
                    <Skeleton height={12} width={40} />
                </Box>
                <Stack
                    gap={1}
                    width={1}
                    direction="column"
                    alignItems={inbound ? 'flex-start' : 'flex-end'}
                >
                    {range(random(1, 3)).map((i) => (
                        <MessageItemSkeleton key={i} inbound={inbound} />
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

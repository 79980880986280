import { useCallback, useMemo, useState } from 'react';
import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { EnhancedSelect } from '../../../components/EnhancedSelect/EnhancedSelect';
import { countriesList, countryFlags } from '../../../utils/countries';
import {
    CountryCode,
    getPhoneCode,
    isValidPhoneNumber,
    parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { CountryIcon } from '../../../icons/common/CCountryIcon';
import { InboxProvider } from '../../../api/types';
import { StepShell } from '../../Onboarding/StepShell';
import { parsePhoneNumber } from '../../../utils/phoneNumber';
import { useSetupInboxContext } from '../setup-inbox.context';
import { availableCountries } from '../utils';
import { useCreateInboxes } from '../hooks/useCreateInboxes';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { Zoom } from '../../../icons/carriers/Zoom';
import { titleMap } from '../CarrierPhoneNumber/constants';

type SupportedCarriers =
    | InboxProvider.ZOOM
    | InboxProvider.WEBEX
    | InboxProvider.OTHER;

export function PhoneConnect() {
    const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState<CountryCode>();
    const { carrier, setNewInboxes } = useSetupInboxContext();
    const [createInboxes, { error, isLoading, resetError }] =
        useCreateInboxes();

    const updatePhoneAndProvider = useCallback(async () => {
        const formattedPhone = parsePhoneNumber(phoneNumber, country!);

        const inboxes = [
            {
                phone: formattedPhone,
                provider: InboxProvider.OTHER,
            },
        ];
        setNewInboxes(inboxes);
        await createInboxes(inboxes);
    }, [phoneNumber, country, setNewInboxes, createInboxes]);

    const hasPhoneError = !!phoneNumber && !isPhoneNumberValid;

    const icon = useMemo(() => {
        switch (carrier) {
            case InboxProvider.ZOOM:
                return <Zoom />;
            default:
                return '📲';
        }
    }, [carrier]);

    return (
        <StepShell
            title={carrier ? titleMap[carrier as SupportedCarriers] : ''}
            subtitle="To proceed please enter your phone number below."
            icon={icon}
            goBackward="/setup-inbox/existing-number"
        >
            <Stack spacing={6} width={320}>
                <EnhancedSelect
                    variant="outlined"
                    searchable
                    options={countriesList.map((country) => ({
                        id: country.code,
                        label: country.name,
                        icon: countryFlags[
                            country.code as keyof typeof countryFlags
                        ],
                        secondaryLabel: `+${getPhoneCode(country.code)}`,
                        disabled: !availableCountries.includes(country.code),
                    }))}
                    placeholder="Country"
                    value={country ?? ''}
                    onChange={(countryCode) => {
                        setCountry(countryCode as CountryCode);
                    }}
                    startAdornment={
                        !country && (
                            <InputAdornment position="start">
                                <CountryIcon />
                            </InputAdornment>
                        )
                    }
                />
                <TextField
                    disabled={!country}
                    value={phoneNumber}
                    placeholder="Type in your phone"
                    onChange={(event) => {
                        setPhoneNumber(event.target.value);
                    }}
                    onBlur={(event) => {
                        if (!event.currentTarget.value) {
                            return;
                        }
                        const formattedPhone =
                            parsePhoneNumberFromString(
                                String(event.currentTarget.value),
                                country!,
                            )?.number ?? '';

                        setPhoneNumber(formattedPhone);
                        setPhoneNumberValid(isValidPhoneNumber(formattedPhone));

                        resetError();
                    }}
                    error={hasPhoneError || !!error}
                    helperText={
                        (hasPhoneError &&
                            'Use international format for phone number e.g. +13855267648') ||
                        error
                    }
                />
                <Button
                    onClick={() => {
                        updatePhoneAndProvider();
                    }}
                    disabled={
                        !(isPhoneNumberValid && carrier) ||
                        !phoneNumber ||
                        isLoading
                    }
                    fullWidth
                >
                    {isLoading ? <ButtonDotLoader /> : 'Finish'}
                </Button>
            </Stack>
        </StepShell>
    );
}

import cc from 'classcat';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './ScheduledMessageRow.module.scss';
import { loadingSpinner, loadingSpinnerProgress } from './styles';
import { ScheduledMessage, ScheduledMessageStatus } from '../../api/types';
import { useScheduledMessageUpdateQuery } from '../../queries/scheduled-messages';
import { getFullName } from '../../utils/user';
import AttachmentItem from '../AttachmentItem';
import { CopyButton } from '../CopyButton';
import Avatar from '../Avatar';
import Icon from '../../icons/Icon';
import { Box, LinearProgress } from '@mui/material';
import { TimestampChangeDialog } from '../Modals/TimestampChangeDialog/TimestampChangeDialog';
import { getNearestAlignedCurrentDate } from '../Modals/TimestampChangeDialog/utils';

export interface ScheduledMessageRowProps {
    conversationId: string;
    message: ScheduledMessage;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
}

/** @deprecated use Message with timestamp value instead */
export const ScheduledMessageRow = (props: ScheduledMessageRowProps) => {
    const [showSchedule, setShowSchedule] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const mutation = useScheduledMessageUpdateQuery(props.conversationId);

    const userName = useMemo(
        () => getFullName(props.message.user),
        [props.message.user],
    );

    return (
        <>
            <div className={cc([styles['root']])}>
                {/* Main message content */}

                <div className={styles['root__container']}>
                    {/* Header */}

                    <div className={cc([styles['root__header']])}>
                        {userName && (
                            <>
                                <span className={styles['root__name']}>
                                    {userName}
                                </span>
                                <span className={styles['root__devider']} />
                            </>
                        )}
                        {props.message.status ===
                        ScheduledMessageStatus.ERROR ? (
                            <span
                                className={cc([
                                    styles['root__date'],
                                    styles['root__date_error'],
                                ])}
                            >
                                <Icon
                                    name="mess-schedule"
                                    className={cc([
                                        styles['root__date-icon'],
                                        styles['root__date-icon_error'],
                                    ])}
                                    size="12px"
                                />
                                Error sending on{' '}
                                {dayjs(props.message.sendAt).format('MMM D')} at{' '}
                                {dayjs(props.message.sendAt).format('hh:mm A')}
                            </span>
                        ) : (
                            <span className={styles['root__date']}>
                                <Icon
                                    name="mess-schedule"
                                    className={styles['root__date-icon']}
                                    size="12px"
                                />
                                Scheduled for{' '}
                                {dayjs(props.message.sendAt).format('MMM D')} at{' '}
                                {dayjs(props.message.sendAt).format('hh:mm A')}
                            </span>
                        )}
                    </div>

                    {/* Message text */}

                    {props.message.body && (
                        <div className={cc([styles['root__text-container']])}>
                            <div className={cc([styles['root__text']])}>
                                {props.message.body}
                            </div>

                            <div className={cc([styles['root__menu']])}>
                                <CopyButton
                                    className={cc([styles['root__menu-btn']])}
                                    text={props.message.body}
                                />
                                <button
                                    className={cc([
                                        styles['root__menu-btn'],
                                        styles['root__button'],
                                    ])}
                                    onClick={() => setShowSchedule(true)}
                                >
                                    <Icon name="mess-schedule" />
                                </button>
                                <button
                                    className={cc([
                                        styles['root__menu-btn'],
                                        styles['root__button'],
                                    ])}
                                    onClick={props.onEditClick}
                                >
                                    <Icon name="edit" />
                                </button>
                                <button
                                    className={cc([
                                        styles['root__menu-btn'],
                                        styles['root__button'],
                                    ])}
                                    onClick={props.onDeleteClick}
                                >
                                    <Icon name="delete" />
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Attachements */}
                    {props.message.attachments &&
                        props.message.attachments.length > 0 && (
                            <div className={cc([styles['root__attachments']])}>
                                {props.message.attachments.map((attachment) => (
                                    <AttachmentItem
                                        key={attachment.id}
                                        attachment={attachment}
                                    />
                                ))}
                            </div>
                        )}
                </div>

                {/* Avatar */}
                <div className={cc([styles['root__avatar']])}>
                    <Avatar
                        imageUrl={props.message.user?.avatar?.url}
                        size="large"
                    />
                </div>
            </div>

            {showSchedule && (
                <TimestampChangeDialog
                    disabled={isLoading}
                    timestamp={dayjs(new Date(props.message.sendAt))}
                    onChange={async (date) => {
                        setIsLoading(true);
                        await mutation.mutateAsync({
                            id: props.message.id,
                            body: props.message.body,
                            sendAt: date.toISOString(),
                            files: props.message.attachments.map((a) => a.id),
                        });
                        setIsLoading(false);
                        setShowSchedule(false);
                    }}
                    minDate={getNearestAlignedCurrentDate()?.toDate()}
                    onClose={() => setShowSchedule(false)}
                    title="Schedule a message"
                    open
                >
                    {isLoading && (
                        <Box sx={loadingSpinner}>
                            <LinearProgress sx={loadingSpinnerProgress} />
                        </Box>
                    )}
                </TimestampChangeDialog>
            )}
        </>
    );
};

export default ScheduledMessageRow;

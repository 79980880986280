import { InfiniteData, useMutation } from '@tanstack/react-query';
import { client } from '../../../api/http';
import { Message } from '../../../api/types';
import { CampaignOrMessageCreateParams } from '../../../api/campaign.types';
import { queryClient } from '../../../queries/queryClient';
import { CONVERSATIONS_LIST_KEY } from '../../../queries/conversations';
import { useTrack } from '../../../contexts/analytics';
import { CONVERSATION_MESSAGES_KEY } from '../../../queries/messages';

export const useMessageCreateFromInbox = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (message: CampaignOrMessageCreateParams) =>
            client
                .post<Message>('/messages/from-inbox', message)
                .then(({ data }) => data),
        onSuccess: (message) => {
            track('message_created', {
                bodyLength: message.body.length,
                attachmentsCount: message.attached.length,
                status: message.status,
                origin: 'new_chat',
            });
            queryClient.setQueryData<InfiniteData<Message[]>>(
                [CONVERSATION_MESSAGES_KEY, message.conversationId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: [
                            (prev.pages[0] || []).concat(message),
                            ...prev.pages.slice(1),
                        ],
                    };
                },
            );

            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
        },
    });
};

import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import omit from 'lodash/omit';
import { CSSSelectorObjectOrCssVariables } from '@mui/system';

const truncatingStyles: CSSSelectorObjectOrCssVariables = {
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const TruncatedTypography: React.FC<
    TypographyProps & { lines: number }
> = ({ children, ...props }) => {
    return (
        <Typography
            {...omit({ ...props }, ['lines', 'sx'])}
            sx={{
                ...props.sx,
                ...truncatingStyles,
                WebkitLineClamp: props.lines,
            }}
        >
            {children}
        </Typography>
    );
};

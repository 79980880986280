import { Conversation } from '../../../api/types';
import { Avatar, Box, Skeleton } from '@mui/material';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';
import avatarPlaceholder from '../../../icons/common/avatarPlaceholder.svg';
import {
    useConversationAvatar,
    useName,
} from '../../../components/Inbox/Conversation/use-conversation-props';
import { getInitials } from '../../../main/campaign/v3/string.helpers';

type Props = {
    conversation: Pick<Conversation, 'members'>;
};

export const ConversationAvatar = ({ conversation }: Props) => {
    const name = useName(conversation.members);
    const avatar = useConversationAvatar(conversation.members);
    const initials = getInitials(name.data);

    if (name.isLoading) {
        return <Skeleton width={20} height={20} variant="circular" />;
    }

    return (
        <Avatar
            sx={{
                background: getAvatarGradient(name.data),
                width: 20,
                height: 20,
                fontSize: 10,
            }}
            alt={name.data}
        >
            {(avatar || !initials) && (
                <Box
                    src={avatar || avatarPlaceholder}
                    sx={{ width: 20, height: 20 }}
                    component="img"
                    alt="avatar"
                />
            )}
            {!avatar && initials}
        </Avatar>
    );
};

import { Box, SxProps } from '@mui/material';

export const SettingsRow = ({
    sx,
    children,
}: {
    sx?: SxProps;
    children: React.ReactNode;
}) => (
    <Box
        sx={{
            py: 4,
            gap: 6,
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: 1,
            borderBottom: 1,
            borderColor: 'custom.gray.divider',
            ...sx,
        }}
    >
        {children}
    </Box>
);

import styles from './PhoneVerification.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from '../../elements/FormInput';
import Callout from '../../components/Callout';
import Spacer from '../../components/Spacer';
import {
    useBrandCreateQuery,
    useBrandQuery,
    useBrandUpdateQuery,
} from '../../queries/settings';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PhoneVerificationProps } from './PhoneVerification';
import { AnalyticsActions, useAnalytics } from '../../contexts/analytics';
import { useInbox } from '../../queries/inboxes';
import { Button, Typography } from '@mui/material';
import Loading from '../../components/Loading/Loading';

type CustomerVerificationStepFormData = {
    fullName: string;
    companyName: string;
};

export const CustomerVerificationStep = () => {
    const navigate = useNavigate();
    const { inboxId } = useParams<PhoneVerificationProps>();
    const { control, handleSubmit, clearErrors } =
        useForm<CustomerVerificationStepFormData>();
    const { data: brand, isLoading: brandLoading } = useBrandQuery();
    const { mutateAsync: createBrand } = useBrandCreateQuery();
    const { mutateAsync: updateBrand } = useBrandUpdateQuery();
    const { isLoading: inboxLoading, data: inbox } = useInbox(inboxId!);

    const analytics = useAnalytics();

    const [isLoading, setLoading] = useState(false);

    if (inboxLoading) {
        return <Loading mt={6} />;
    }

    if (!inbox) {
        return (
            <Typography mt={6}>
                Oops! We didn&apos;t find that phone number! Please reload and
                try again
            </Typography>
        );
    }

    const onSubmit: SubmitHandler<CustomerVerificationStepFormData> = async (
        data: CustomerVerificationStepFormData,
    ) => {
        setLoading(true);
        const payload = {
            companyName: data.companyName,
            contactFirstName: data.fullName.split(' ')[0],
            contactLastName: data.fullName.split(' ')[1],
        };
        if (brand?.id) {
            await updateBrand({
                id: brand.id,
                brand: payload,
            });
        } else {
            await createBrand(payload);
        }
        setLoading(false);
        clearErrors();

        analytics.dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: 'verification_customer_info',
            },
        });

        if (inbox.verified) {
            navigate(`/inbox/${inboxId}/verify/address`);
        } else {
            navigate(`/inbox/${inboxId}/verify/call`);
        }
    };

    return brandLoading ? (
        <Loading />
    ) : (
        <form
            className={styles['root__form']}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Callout style="warning">
                <span>📞</span>
                <div>
                    Complete the verification to activate your number for
                    sending and receiving text messages.
                </div>
            </Callout>
            <Controller
                name="fullName"
                control={control}
                rules={{
                    required: true,
                    minLength: 3,
                    pattern: /^([A-Za-z-' ]+)[\s]([A-Za-z-' ]+)$/,
                }}
                defaultValue={[brand?.contactFirstName, brand?.contactLastName]
                    .filter((x) => !!x)
                    .join(' ')}
                render={({ field, fieldState }) => (
                    <FormInput
                        label="Your full name"
                        required
                        error={fieldState.isDirty && fieldState.invalid}
                        iconName="user-signature"
                        aria-label="name"
                        hint={
                            fieldState.isDirty && fieldState.invalid
                                ? 'Please enter your full name'
                                : undefined
                        }
                        {...field}
                    />
                )}
            />
            <Controller
                name="companyName"
                control={control}
                rules={{ required: true, minLength: 3 }}
                defaultValue={brand?.companyName}
                render={({ field, fieldState }) => (
                    <FormInput
                        label="Legal Company Name"
                        required
                        iconName="company"
                        aria-label="company"
                        error={fieldState.isDirty && fieldState.invalid}
                        hint={
                            fieldState.isDirty && fieldState.invalid
                                ? 'Company must be 3 characters long'
                                : undefined
                        }
                        {...field}
                    />
                )}
            />
            <Spacer height={0} width="auto" />
            <Button isLoading={isLoading} type="submit">
                Next
            </Button>
        </form>
    );
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const Members = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="CMembers">
                <path
                    id="Icon (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 4.75C6.75736 4.75 5.75 5.75736 5.75 7C5.75 8.24264 6.75736 9.25 8 9.25C9.24264 9.25 10.25 8.24264 10.25 7C10.25 5.75736 9.24264 4.75 8 4.75ZM4.25 7C4.25 4.92893 5.92893 3.25 8 3.25C10.0711 3.25 11.75 4.92893 11.75 7C11.75 9.07107 10.0711 10.75 8 10.75C5.92893 10.75 4.25 9.07107 4.25 7ZM16 7.75C15.3096 7.75 14.75 8.30964 14.75 9C14.75 9.69036 15.3096 10.25 16 10.25C16.6904 10.25 17.25 9.69036 17.25 9C17.25 8.30964 16.6904 7.75 16 7.75ZM13.25 9C13.25 7.48122 14.4812 6.25 16 6.25C17.5188 6.25 18.75 7.48122 18.75 9C18.75 10.5188 17.5188 11.75 16 11.75C14.4812 11.75 13.25 10.5188 13.25 9ZM8 13.75C5.65279 13.75 3.75 15.6528 3.75 18V19.25H12.25V18C12.25 17.2933 12.0775 16.6269 11.7723 16.0405C11.7495 16.0055 11.7299 15.969 11.7134 15.9313C10.987 14.63 9.59625 13.75 8 13.75ZM12.5414 14.4728C11.4893 13.1202 9.8463 12.25 8 12.25C4.82436 12.25 2.25 14.8244 2.25 18V20C2.25 20.4142 2.58579 20.75 3 20.75H21C21.4142 20.75 21.75 20.4142 21.75 20V19C21.75 15.6222 19.1573 13.25 16 13.25C14.6967 13.25 13.4997 13.7087 12.5414 14.4728ZM13.3085 15.7866C13.5929 16.4679 13.75 17.2156 13.75 18V19.25H20.25V19C20.25 16.4868 18.3655 14.75 16 14.75C14.9846 14.75 14.0464 15.1342 13.3085 15.7866Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </SvgIcon>
);

import { Box, Button, Link, Stack, Typography } from '@mui/material';

export function CampaignsScheduleDemo() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 1,
                width: 1,
            }}
        >
            <Stack spacing={4} alignItems="center" width={0.3}>
                <Typography
                    variant="body2"
                    color="primary.dark"
                    textAlign="center"
                >
                    Schedule a chat with our team to walk you through setting up
                    marketing campaigns and drip sequences.
                </Typography>
                <Button
                    variant="contained"
                    color="info"
                    component={Link}
                    href="https://calendar.app.google/UqeNmGJ22hTuk62J6"
                    target="_blank"
                >
                    Schedule a Demo
                </Button>
            </Stack>
        </Box>
    );
}

import { useMemo } from 'react';
import { ArrowCircleLeft } from 'phosphor-react';
import styles from './WorkflowHeader.module.scss';
import { Contact, Conversation } from '../../api/types';
import { useContactsQueryData } from '../../queries/contacts';
import ConversationTitle from '../ConversationTitle';
import ConversationToolbar from '../ConversationToolbar';
import { IconButton, Box } from '@mui/material';
import { memo } from 'react';
import { getByPhoneOrEmpty } from '../../queries/contact-library.helpers';

interface WorkflowHeaderProps {
    conversation?: Conversation;
    cohortName?: string;
    noToolbar?: boolean;
    onBackClick?: () => void;
    onToggleContactInfo?: () => void;
}

export const WorkflowHeader = memo(function WorkflowHeader({
    conversation,
    cohortName,
    noToolbar,
    onBackClick,
    onToggleContactInfo,
}: WorkflowHeaderProps) {
    const contacts = useContactsQueryData();

    const memberContacts = useMemo(() => {
        const members = conversation?.members || [];
        return members.map((m) => getByPhoneOrEmpty(m, contacts) as Contact);
    }, [conversation, contacts]);

    return (
        <header
            className={styles['workflow-header']}
            data-testid="workflow-header"
        >
            <IconButton
                sx={{ display: ['block', 'block', 'none'] }}
                onClick={onBackClick}
            >
                <ArrowCircleLeft size={16} weight="bold" />
            </IconButton>

            <ConversationTitle
                members={memberContacts}
                customName={cohortName}
                onToggleContactInfo={onToggleContactInfo}
            />

            <Box display="flex" gap={4} alignItems="center">
                {!noToolbar && conversation && (
                    <ConversationToolbar
                        conversation={conversation}
                        contacts={memberContacts}
                    />
                )}
            </Box>
        </header>
    );
});

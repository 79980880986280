import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const InviteIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24" cy="24" r="23.5" fill="#ECEFFE" stroke="#B2BEFB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9786 24.1719L14.7832 19.2758C14.2928 18.9519 14 18.4097 14 17.8256C14 16.8185 14.8257 16 15.8405 16H32.1595C33.1743 16 34 16.8185 34 17.8246C34 18.4097 33.7072 18.9519 33.2168 19.2758L25.0214 24.1719C24.7111 24.377 24.3555 24.4795 24 24.4795C23.6444 24.4795 23.2889 24.377 22.9786 24.1719ZM24 25.7429C24.5959 25.7429 25.1739 25.5479 25.6715 25.1792L33.8666 20.1052C33.9121 20.0714 33.9565 20.0362 34 20V29.9532C34 31.0739 33.1854 32 32.1589 32H15.8411C14.833 32 14 31.0943 14 29.9532V20C14.0434 20.0362 14.0877 20.0713 14.1332 20.105L22.3286 25.1792C22.8262 25.5479 23.4042 25.7429 24 25.7429Z"
                fill="#3F5DF4"
            />
        </svg>
    </SvgIcon>
);

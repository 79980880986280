import { useEffect, useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import styles from './SendMessageStep.module.scss';
import Input from '../../../../elements/Inputs/Input';
import Button from '../../../../elements/Buttons';
import { WorkflowAction, WorkflowRecipientType } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import closeIcon from '../../../../assets/icons/icons-24/gray/close.svg';
import AdvancedSelect from '../../../../elements/AdvancedSelect';
import { workflowRecipientOptions } from './utils';
import { useCohortsQuery } from '../../../../queries/cohorts';
import { renderSelectOptionWithDescription } from '../../../../elements/AdvancedSelect/renderrers';
import Icon from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';

interface SendMessageStepProps {
    step: WorkflowAction;
}

export const SendMessageStep = ({ step }: SendMessageStepProps) => {
    const { dispatch } = useWorkflowContext();
    const [actionStep, setStep] = useState(cloneDeep(step));

    const recipientType =
        actionStep.smsConfiguration?.recipientType ||
        WorkflowRecipientType.Sender;

    useEffect(() => setStep(cloneDeep(step as WorkflowAction)), [step]);

    const cohorts = useCohortsQuery();

    const cohortOptions = useMemo(
        () => cohorts.map((c) => ({ label: c.name, id: c.id })),
        [cohorts],
    );
    const onSaveHandler = () => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
            payload: actionStep,
        });
    };

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.action]} />
                    <span>Send message</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <img src={closeIcon} />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Item name
                    </label>
                    <Input
                        placeholder="Enter name"
                        value={actionStep?.name || ''}
                        onChange={(name) => setStep({ ...actionStep, name })}
                        uncontrolled
                        fullWidth
                    />
                </section>

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Send a message to
                    </label>

                    <AdvancedSelect
                        renderOption={renderSelectOptionWithDescription}
                        options={workflowRecipientOptions}
                        value={recipientType}
                        onChange={(o) => {
                            const smsConfiguration = {
                                ...actionStep.smsConfiguration,
                                recipientType: o as WorkflowRecipientType,
                            };

                            setStep({
                                ...actionStep,
                                smsConfiguration,
                            } as WorkflowAction);
                        }}
                    />
                </section>

                {recipientType === WorkflowRecipientType.Phone && (
                    <section className={styles['aside__box']}>
                        <label className={styles['aside__box-label']}>
                            Phone number
                        </label>
                        <Input
                            placeholder="Enter phone number"
                            value={
                                step.smsConfiguration?.recipients
                                    ? step.smsConfiguration.recipients[0]
                                    : undefined
                            }
                            fullWidth={true}
                            onChange={(phone) =>
                                setStep({
                                    ...actionStep,
                                    smsConfiguration: {
                                        message:
                                            actionStep.smsConfiguration
                                                ?.message || '',
                                        recipients: [phone],
                                        recipientType:
                                            WorkflowRecipientType.Phone,
                                    },
                                })
                            }
                        />
                    </section>
                )}

                {recipientType === WorkflowRecipientType.Cohort && (
                    <section className={styles['aside__box']}>
                        <label className={styles['aside__box-label']}>
                            Cohort
                        </label>
                        <AdvancedSelect
                            options={cohortOptions}
                            value={actionStep.smsConfiguration?.cohortId}
                            onChange={(id) => {
                                setStep({
                                    ...actionStep,
                                    smsConfiguration: {
                                        cohortId: id as number,
                                        recipientType:
                                            WorkflowRecipientType.Cohort,
                                        message:
                                            actionStep.smsConfiguration
                                                ?.message || '',
                                    },
                                });
                            }}
                            searchable={true}
                        />
                    </section>
                )}

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Message
                    </label>

                    <textarea
                        className={styles['aside__message']}
                        rows={3}
                        placeholder="Text goes here"
                        value={actionStep.smsConfiguration?.message || ''}
                        onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                            const smsConfiguration = {
                                ...actionStep.smsConfiguration,
                                message: e.currentTarget.value,
                            };

                            setStep({ ...actionStep, smsConfiguration });
                        }}
                    />
                </section>

                {/* <section className={styles['aside__box']}>
                    <CheckBox
                        className={styles['aside__contact-checkbox']}
                        checked={isAddContactCard}
                        label="Add a contact card to the message"
                        onChange={setAddContactCard}
                    />
                    <Search
                        value={searchTerm}
                        onSearch={setSearchTerm}
                        disabled={!isAddContactCard}
                        fullWidth
                    />
                </section> */}

                {/* <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        File upload
                    </label>

                    <AttachmentDropzone
                        onDone={(file: File) => {
                            console.log(file.name);
                        }}
                        fileTypesList={[
                            'image/jpeg',
                            'image/png',
                            'application/pdf',
                        ]}
                    />
                    <div className={styles['aside__dropzone-details']}>
                        <p className={styles['aside__dropzone-text']}>
                            Allowed formats: <b>.png, .jpg, .pdf</b>
                        </p>
                        <p className={styles['aside__dropzone-text']}>
                            File size: <b>up to 5 Mb</b>
                        </p>
                    </div>
                </section> */}
            </div>
            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};

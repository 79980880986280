import { Message, SenderType } from '../../../../api/types';
import { SxProps } from '@mui/material';
import { colors } from '../../../../theme/palette';

export const container = ({
    inbound,
    senderType,
}: Pick<Message, 'inbound' | 'senderType'>): SxProps => ({
    maxWidth: [320, 600],
    width: 'fit-content',
    borderRadius: 1,
    px: 2.5,
    whiteSpace: 'pre-wrap',
    py: 3,
    bgcolor:
        ([SenderType.CAMPAIGN, SenderType.CAMPAIGN_MESSAGE].includes(
            senderType,
        ) &&
            colors.orange[50]) ||
        (senderType === SenderType.WORKFLOW && colors.green[50]) ||
        (inbound && colors.blue[50]) ||
        colors.lightGrey[100],
});

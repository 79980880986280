import React, { useCallback, useEffect, useMemo, useState } from 'react';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import { useMeQueryData } from '../../../queries/user';
import { useContactsSearchQuery } from '../../../queries/contacts';
import {
    CountryCode,
    isValidPhoneNumber,
    parsePhoneNumber,
} from 'libphonenumber-js';
import {
    Autocomplete,
    Avatar,
    Box,
    Chip,
    InputAdornment,
    Popper,
    PopperProps,
    TextField,
    Typography,
} from '@mui/material';
import { Contact } from '../../../api/types';
import { Enter } from '../../../icons/common/CEnter';
import { createContactFromPhoneNumber } from '../utils/createContactFromPhoneNumber';
import { optionListItem, popper, textField } from './styles';
import { Legend } from './Legend';
import { useCohortsQueryData } from '../../../queries/cohorts';
import { AddCohort } from '../../../icons/common/CAddCohort';
import { CohortMetaDto } from '../../../api/cohorts';
import { getSquare } from '../../../theme/style.helpers';
import { NewConversationOption } from '../types';
import { isCohort } from '../utils/isCohort';
import { pluralize } from '../../../utils/pluralize';
import { findAndFormatPhonesInText } from '../../../utils/phoneNumber';

interface Props {
    isLimitReached: boolean;
    selectedContacts: Contact[];
    setSelectedContacts: (contacts: Contact[]) => void;
    selectedCohorts: CohortMetaDto[];
    setSelectedCohorts: (cohorts: CohortMetaDto[]) => void;
}

export const SearchPanel = ({
    isLimitReached,
    selectedContacts,
    setSelectedContacts,
    selectedCohorts,
    setSelectedCohorts,
}: Props) => {
    let inputRef: HTMLInputElement | null = null;
    const [quickSearchTerm, setQuickSearchTerm] = useState('');

    useEffect(() => {
        inputRef?.focus();
    }, [inputRef]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchInput = useCallback(
        debounce((searchTerm: string) => {
            return setQuickSearchTerm(searchTerm);
        }, 300),
        [],
    );

    const profile = useMeQueryData();
    const cohorts = useCohortsQueryData();

    const foundCohorts = useMemo(
        () =>
            cohorts.filter((c) =>
                c.name.toLowerCase().includes(quickSearchTerm.toLowerCase()),
            ),
        [cohorts, quickSearchTerm],
    );

    const { data: foundContacts = [], isFetching: isSearchFetching } =
        useContactsSearchQuery(
            quickSearchTerm,
            [],
            undefined,
            !!quickSearchTerm.length,
            true,
        );

    const handleChange = (selectedOptions: NewConversationOption[]) => {
        setQuickSearchTerm('');
        const [contacts, cohorts] = selectedOptions.reduce(
            ([contacts, cohorts]: [Contact[], CohortMetaDto[]], option) => {
                if (isCohort(option)) {
                    return [contacts, [...cohorts, option]];
                }
                return [[...contacts, option], cohorts];
            },
            [[], []],
        );
        setSelectedContacts(contacts);
        setSelectedCohorts(cohorts);
    };

    const options = useMemo((): NewConversationOption[] => {
        if (!quickSearchTerm || isLimitReached) {
            return [...foundContacts, ...foundCohorts];
        }

        const isValidPhone = isValidPhoneNumber(
            quickSearchTerm,
            profile?.activeTeam.countryCode as CountryCode,
        );
        const isExistingContact = foundContacts.some(
            (contact) => contact.phone === quickSearchTerm,
        );
        if (isValidPhone && !isExistingContact) {
            const phoneNumber = parsePhoneNumber(
                quickSearchTerm,
                profile?.activeTeam.countryCode as CountryCode,
            ).number;
            return [
                ...foundContacts,
                createContactFromPhoneNumber(phoneNumber),
            ];
        }
        return [...foundContacts, ...foundCohorts];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [foundContacts, quickSearchTerm]);

    const isSomeFilteredOptionsExist = useMemo(() => {
        const selectedContactsIds = selectedContacts.map((c) => c.id);
        const selectedCohortsIds = selectedCohorts.map((c) => c.id);
        return options.some((option) => {
            return isCohort(option)
                ? !selectedCohortsIds.includes(option.id)
                : !selectedContactsIds.includes(option.id);
        });
    }, [options, selectedContacts, selectedCohorts]);

    const handlePasteEvent = useCallback((e: React.ClipboardEvent) => {
        const value = e.clipboardData?.getData('text');
        e.preventDefault();
        const phoneObjects = findAndFormatPhonesInText(value).map(
            createContactFromPhoneNumber,
        );
        setSelectedContacts([...selectedContacts, ...phoneObjects]);
        setQuickSearchTerm('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div data-testid="recipients-search-panel">
            <Autocomplete
                multiple
                value={[...selectedContacts, ...selectedCohorts]}
                options={options}
                loading={isSearchFetching}
                clearOnBlur={false}
                autoHighlight
                fullWidth
                disableClearable
                disableCloseOnSelect
                disabled={isLimitReached}
                filterSelectedOptions
                noOptionsText="No contacts found"
                isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                }
                groupBy={(option) =>
                    isCohort(option) ? 'Cohorts' : 'Contacts'
                }
                filterOptions={(x) => x}
                sx={{
                    '.MuiAutocomplete-tag': { m: 1 },
                }}
                getOptionLabel={(option: NewConversationOption) =>
                    isCohort(option)
                        ? option.name
                        : (option.name ?? option.phone ?? '')
                }
                renderTags={(
                    value: readonly NewConversationOption[],
                    getTagProps,
                ) =>
                    value.length > 10 ? (
                        <Chip
                            variant="filled"
                            size="small"
                            color="success"
                            label={`${value.length} Contacts Selected`}
                            sx={{ m: 1 }}
                            onDelete={() => setSelectedContacts([])}
                        />
                    ) : (
                        value.map(
                            (option: NewConversationOption, index: number) => (
                                <Chip
                                    variant="filled"
                                    size="small"
                                    color="success"
                                    label={option.name}
                                    sx={{ m: 1 }}
                                    key={getTagProps({ index }).key}
                                    {...omit(getTagProps({ index }), [
                                        'key',
                                        'disabled',
                                    ])}
                                />
                            ),
                        )
                    )
                }
                onChange={(_, newValue) => handleChange(newValue)}
                onInputChange={(e) => {
                    e.type === 'change' && handleSearchInput(e.target.value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={(input: HTMLInputElement) => {
                            inputRef = input;
                        }}
                        variant="filled"
                        sx={textField}
                        placeholder="Enter a phone, name or paste a list of phone numbers"
                        InputProps={{
                            ...params.InputProps,
                            onPaste: handlePasteEvent,
                            startAdornment: (
                                <>
                                    <InputAdornment
                                        position="end"
                                        sx={{ mr: 1 }}
                                    >
                                        <Typography
                                            variant="body3"
                                            color="text.primary"
                                        >
                                            To:
                                        </Typography>
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                            endAdornment: null,
                        }}
                    />
                )}
                PopperComponent={(props: PopperProps) => {
                    return (
                        isSomeFilteredOptionsExist &&
                        !!quickSearchTerm.length && (
                            <Popper
                                {...props}
                                style={{ width: 428 }}
                                sx={popper}
                                placement="bottom-start"
                                popperOptions={{ placement: 'bottom-start' }}
                                data-testid="search-panel-popper"
                            >
                                {props.children}
                                <Legend />
                            </Popper>
                        )
                    );
                }}
                renderOption={(props, option: NewConversationOption) => (
                    <li
                        {...props}
                        key={option.id}
                        style={{ borderRadius: '4px', padding: '8px 12px' }}
                        data-testid="popper-result-list-item"
                    >
                        <Box sx={optionListItem}>
                            {isCohort(option) ? (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <AddCohort
                                            sx={{
                                                ...getSquare(20),
                                                mr: 2,
                                                color: 'custom.gray.super',
                                            }}
                                        />
                                        <Typography
                                            variant="body4"
                                            data-testid="popper-cohort-name"
                                        >
                                            {option.name}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="body4"
                                            data-testid="popper-cohort-users-count"
                                        >
                                            {pluralize(
                                                option.contactsCount,
                                                'user',
                                                'users',
                                            )}
                                        </Typography>
                                        <Enter />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                mr: 2,
                                            }}
                                            alt={option.name ?? ''}
                                            src={option.avatarURL}
                                        />
                                        <Typography
                                            variant="body4"
                                            data-testid="popper-contact-name"
                                        >
                                            {option.name ?? option.phone ?? ''}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="body4"
                                            data-testid="popper-contact-phone"
                                        >
                                            {option.name ? option.phone : ''}
                                        </Typography>
                                        <Enter />
                                    </Box>
                                </>
                            )}
                        </Box>
                    </li>
                )}
            />
        </div>
    );
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const PrivateUser = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.33342 2.5C6.1368 2.5 5.16675 3.47005 5.16675 4.66667C5.16675 5.86328 6.1368 6.83333 7.33342 6.83333C8.53003 6.83333 9.50008 5.86328 9.50008 4.66667C9.50008 3.47005 8.53003 2.5 7.33342 2.5ZM4.16675 4.66667C4.16675 2.91777 5.58451 1.5 7.33342 1.5C9.08232 1.5 10.5001 2.91777 10.5001 4.66667C10.5001 6.41557 9.08232 7.83333 7.33342 7.83333C5.58451 7.83333 4.16675 6.41557 4.16675 4.66667ZM7.33342 9.83333C5.03223 9.83333 3.16675 11.6988 3.16675 14C3.16675 14.2761 2.94289 14.5 2.66675 14.5C2.39061 14.5 2.16675 14.2761 2.16675 14C2.16675 11.1465 4.47994 8.83333 7.33342 8.83333C8.43048 8.83333 9.44905 9.17582 10.2861 9.7597C10.5126 9.91768 10.5682 10.2294 10.4102 10.4558C10.2522 10.6823 9.94052 10.7379 9.71403 10.5799C9.03933 10.1093 8.21935 9.83333 7.33342 9.83333ZM13.6955 10.9885C13.8859 11.1885 13.8782 11.505 13.6782 11.6954L10.8782 14.3621C10.6851 14.546 10.3817 14.546 10.1886 14.3621L8.98859 13.2192C8.78862 13.0288 8.7809 12.7123 8.97135 12.5123C9.16179 12.3124 9.47828 12.3046 9.67824 12.4951L10.5334 13.3095L12.9886 10.9713C13.1886 10.7808 13.505 10.7885 13.6955 10.9885Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

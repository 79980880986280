import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const ArrowDownwardFilled = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8094 8.44064C10.6385 8.26979 10.3615 8.26979 10.1906 8.44064L7.4375 11.1938L7.4375 1.75C7.4375 1.50838 7.24162 1.3125 7 1.3125C6.75838 1.3125 6.5625 1.50838 6.5625 1.75L6.5625 11.1938L3.80936 8.44064C3.63851 8.26979 3.36149 8.26979 3.19064 8.44064C3.01979 8.6115 3.01979 8.88851 3.19064 9.05936L6.69064 12.5594C6.8615 12.7302 7.1385 12.7302 7.30936 12.5594L10.8094 9.05936C10.9802 8.8885 10.9802 8.6115 10.8094 8.44064Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { ListItemText } from '@mui/material';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { TeamsPhone } from '../../../api/types';

export function TeamsPhoneItem({ phone }: { phone: TeamsPhone }) {
    return (
        <ListItemText
            sx={{
                margin: 0,
            }}
            primary={formatPhoneNumber(phone.phone)}
            primaryTypographyProps={{
                variant: 'body3',
                color: 'primary.dark',
                fontWeight: 500,
                marginBottom: 1,
            }}
            secondary={`${phone.user?.displayName || 'Unnasigned'} • ${phone.type === 'IVR' ? 'Resource Account' : 'User'}`}
            secondaryTypographyProps={{
                variant: 'body4',
                color: 'custom.gray.super',
            }}
        />
    );
}

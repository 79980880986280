import { useMemo } from 'react';
import { useListTeams } from '../queries/team';
import { useEnabledFeature, useMeQueryData } from '../queries/user';
import { Feature, OnboardingProgress } from '../api/types';
import isNull from 'lodash/isNull';

export const MINIMUM_REGISTRATION_DATE_FOR_ONBOARDING = +new Date('2024-06-04');

type OnboardingData = {
    score: number;
    finished: number | null;
    total: number | null;
    onboarding: OnboardingProgress | null;
    isOnboardingBlockedForOldTeams: boolean;
};

const defaultOnboarding: OnboardingProgress = {
    inboxActivation: true,
    teamsLicense: true,
    brandRegistration: true,
    integration: true,
};

export const useOnboardingData = (): OnboardingData => {
    const me = useMeQueryData();
    const { data: teams = [] } = useListTeams();
    const activeTeam = teams.find((team) => team.id === me?.activeTeam?.id);
    const feature = useEnabledFeature(Feature.ManageBilling);

    const onboardingValue = useMemo(() => {
        if (!activeTeam || isNull(activeTeam.onboarding || !feature)) {
            return {
                score: 0,
                finished: null,
                total: null,
                onboarding: !activeTeam ? null : defaultOnboarding,
                isOnboardingBlockedForOldTeams: false,
            };
        }
        const teamCreatedTime = me?.activeTeam.created
            ? +new Date(me.activeTeam.created)
            : +new Date();
        const isOnboardingBlockedForOldTeams =
            MINIMUM_REGISTRATION_DATE_FOR_ONBOARDING > teamCreatedTime;
        const values = Object.values(
            activeTeam?.onboarding ?? defaultOnboarding,
        );

        const finished = values.filter((value) => !!value).length;
        const total = values.length;
        const score = Math.round((finished / total) * 100);
        return {
            score,
            finished,
            total,
            onboarding: activeTeam.onboarding ?? defaultOnboarding,
            isOnboardingBlockedForOldTeams,
        };
    }, [activeTeam, me?.activeTeam.created, feature]);

    return onboardingValue;
};

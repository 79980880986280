import range from 'lodash/range';
import { ChatMessageSkeleton } from './Message/ChatMessageSkeleton';

export const FetchingSkeleton = () => (
    <>
        {range(2).map((i) => (
            <ChatMessageSkeleton key={i} />
        ))}
    </>
);

import cc from 'classcat';
import styles from './Table.module.scss';

interface TableCellProps {
    className?: string;
    width?: string;
    grow?: number;
    shrink?: number;
    children: React.ReactNode;
}

/**@deprecated*/
export const TableCell = ({
    children,
    className,
    width = 'auto',
    grow = 0,
    shrink = 0,
}: TableCellProps) => (
    <div
        className={cc([styles['table-cell'], className])}
        style={{
            width,
            flexGrow: grow,
            flexShrink: shrink,
        }}
    >
        {children}
    </div>
);

import { Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export function Header(
    props: PropsWithChildren<{ title: string; description: string }>,
) {
    return (
        <Stack
            alignItems="ceter"
            direction={{ xs: 'column', md: 'row' }}
            spacing={6}
            justifyContent="space-between"
            useFlexGap
        >
            <div>
                <Typography variant="h1" fontWeight={600} color="primary.dark">
                    {props.title}
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="custom.gray.super"
                    noWrap
                >
                    {props.description}
                </Typography>
            </div>

            {props.children}
        </Stack>
    );
}

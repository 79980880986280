import { Box, Button, Typography } from '@mui/material';
import {
    StripeMainSubscription,
    StripePaymentMethod,
} from '../../../api/billing';
import { PaymentMethod } from './PaymentMethod';
import { Add } from '../../../components/ConversationsList/CAdd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { AddCardForm } from './AddCardForm';
import { useMemo, useState } from 'react';
import { createIntent } from '../../../api/payment-flow';
import { planList } from '../../../containers/PaymentFlow/plans';
import { useAnalytics } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import * as Sentry from '@sentry/react';

const stripePromise = loadStripe(
    String(import.meta.env.VITE_STRIPE_API_KEY),
).catch((e) => Sentry.captureException(e));

export const PaymentMethods = ({
    paymentMethods,
    subscription,
    defaultPaymentMethodId,
}: {
    paymentMethods?: StripePaymentMethod[];
    subscription?: StripeMainSubscription;
    defaultPaymentMethodId?: string;
}) => {
    const me = useMeQueryData();
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const plan = useMemo(
        () => planList.find((plan) => plan.priceId === subscription?.planId),
        [subscription?.planId],
    );
    const analytics = useAnalytics();

    const dispatchUserSecretErrorEvent = () => {
        analytics.dispatch({
            payload: {
                eventPayload: {
                    userId: me?.id,
                    email: me?.email,
                    teamId: me?.activeTeam?.id,
                },
                eventName: 'user_secret_create_error',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                width: 1,
            }}
        >
            <Typography variant="body2" fontWeight={500}>
                Payment methods
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2.5,
                    width: 1,
                }}
            >
                {paymentMethods?.length ? (
                    paymentMethods.map((paymentMethod, index) => (
                        <PaymentMethod
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            isLast={index === paymentMethods.length - 1}
                            isMoreThanOne={paymentMethods.length > 1}
                            isDefault={
                                paymentMethod.id === defaultPaymentMethodId
                            }
                        />
                    ))
                ) : (
                    <Typography variant="body3">
                        Your saved credit cards will appear here.
                    </Typography>
                )}
            </Box>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<Add />}
                sx={{ alignSelf: 'baseline', mt: 2 }}
                onClick={() => {
                    if (plan) {
                        createIntent(plan.type, plan.priceId)
                            .then((data) => {
                                setClientSecret(data.secret);
                            })
                            .catch(() => {
                                dispatchUserSecretErrorEvent();
                            });
                    }
                }}
                disabled={!!paymentMethods?.length && paymentMethods.length > 9}
            >
                Add new card
            </Button>
            {clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <AddCardForm onClose={() => setClientSecret(null)} />
                </Elements>
            )}
        </Box>
    );
};

import { SxProps } from '@mui/material';

export const container = (inbound: boolean = false): SxProps => ({
    pt: 6,
    display: 'flex',
    width: 1,
    gap: 2,
    alignItems: 'flex-end',
    justifyContent: inbound ? 'flex-start' : 'flex-end',
});
export const messages = (inbound: boolean = false): SxProps => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    justifyContent: inbound ? 'flex-start' : 'flex-end',
});
export const info = (inbound: boolean = false): SxProps => ({
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: inbound ? 'flex-start' : 'flex-end',
});

export const unfurlWrapper: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
};

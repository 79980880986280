import { useEffect, useRef, useState, RefObject } from 'react';
// components
import Input from '../../../../elements/Inputs/Input';
import Avatar from '../../../../components/Avatar';
// styles
import styles from './PersonValue.module.scss';
// types
import { Profile } from '../../../../api/types';
import { userName } from '../../../../helpers/formatting';
import isString from 'lodash/isString';

interface PersonSuggestionProps {
    options?: Array<Profile>;
    wrapperRef?: RefObject<HTMLDivElement>;
    onChange?: (id: string | null) => void;
}

const getFilteredOptions = (options: Array<Profile>, filterTerm: string) =>
    options.filter(({ firstname, email }) =>
        isString(firstname)
            ? firstname.toLowerCase().startsWith(filterTerm.toLowerCase())
            : email.toLowerCase().startsWith(filterTerm.toLowerCase()),
    );

export const PersonSuggestion = ({
    options = [],
    wrapperRef,
    onChange,
}: PersonSuggestionProps) => {
    const [filterTerm, setFilterTerm] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const filteredOptions = filterTerm
        ? getFilteredOptions(options, filterTerm)
        : options;

    return (
        <div className={styles['select']} ref={wrapperRef}>
            <Input
                className={styles['select__input']}
                value={filterTerm}
                onChange={setFilterTerm}
                ref={inputRef}
            />

            <div className={styles['select__options-container']}>
                {filteredOptions.length > 0 && (
                    <ul className={styles['select__options-list']}>
                        <li
                            className={styles['select__item']}
                            onClick={() => onChange?.(null)}
                        >
                            Remove
                        </li>
                        {filteredOptions.map((user) => (
                            <li
                                key={user.id}
                                className={styles['select__item']}
                                onClick={() => onChange?.(user.id)}
                            >
                                <Avatar
                                    size="small"
                                    username={userName(user)}
                                    imageUrl={user?.avatar?.url}
                                />
                                <span>{userName(user)}</span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

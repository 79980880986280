import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const FileTypeXLS = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="32"
            viewBox="0 0 24 32"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.19257 0H14.902L23.9648 9.45986V27.8307C23.9648 30.1314 22.1079 32 19.8072 32H4.19257C1.90351 32 0.0349121 30.1314 0.0349121 27.8307V4.15765C0.0348717 1.8686 1.90351 0 4.19257 0Z"
                fill="#00733B"
            />
            <path
                opacity="0.302"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8904 0V9.3781H23.9648L14.8904 0Z"
                fill="white"
            />
            <path
                d="M9.98523 23.2992H8.43196L7.36921 21.5124L6.30641 23.2992H4.74146L6.58673 20.1927L4.96339 17.4948H6.5283L7.36917 18.8963L8.19834 17.4948H9.7633L8.1516 20.2043L9.98523 23.2992ZM10.5341 23.2992V17.4948H12.0173V22.0379H14.54V23.2992H10.5341V23.2992ZM16.9925 23.3693C16.4086 23.3693 15.8947 23.1824 15.4509 22.8204C15.0188 22.47 14.7736 22.0262 14.7385 21.5007L16.0115 21.127C16.0466 21.4073 16.1634 21.6525 16.3619 21.8394C16.5721 22.0263 16.8057 22.1197 17.0626 22.1197C17.2728 22.1197 17.448 22.073 17.5882 21.9795C17.7166 21.8861 17.7867 21.7577 17.7867 21.6058C17.7867 21.4774 17.7283 21.3723 17.6232 21.2788C17.5181 21.1971 17.3779 21.127 17.2144 21.0686C17.0509 21.0102 16.8524 20.9518 16.6538 20.9051C16.4436 20.8467 16.2334 20.7767 16.0349 20.6832C15.8247 20.6015 15.6378 20.4963 15.4743 20.3679C15.2991 20.2511 15.1706 20.0759 15.0655 19.854C14.9604 19.6438 14.902 19.3868 14.902 19.0949C14.902 18.6161 15.1005 18.219 15.5093 17.9036C15.918 17.5766 16.4086 17.4248 16.9808 17.4248C17.5531 17.4248 18.0552 17.5649 18.4757 17.8336C18.8961 18.1138 19.1647 18.4759 19.2699 18.9197L17.9385 19.4803C17.8801 19.235 17.7633 19.0365 17.5998 18.8847C17.4363 18.7445 17.2261 18.6628 16.9808 18.6628C16.7939 18.6628 16.6538 18.7095 16.537 18.7796C16.4319 18.8497 16.3852 18.9548 16.3852 19.0832C16.3852 19.2 16.4553 19.3051 16.5954 19.3869C16.7355 19.457 16.9224 19.5153 17.1326 19.5504C17.3428 19.5854 17.5764 19.6438 17.8217 19.7256C18.0786 19.819 18.3005 19.9125 18.5224 20.0409C18.7326 20.1577 18.9078 20.3562 19.0479 20.6132C19.1997 20.8818 19.2698 21.1971 19.2698 21.5592C19.2698 22.0964 19.0596 22.5402 18.6392 22.8672C18.2187 23.1942 17.6699 23.3693 16.9925 23.3693Z"
                fill="white"
            />
        </svg>
    </SvgIcon>
);

import IconBase, { IconBaseProps } from '../IconBase';

/** @deprecated */
export const WebsiteIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M11.75 21.5C9.16414 21.5 6.68419 20.4728 4.85571 18.6443C3.02723 16.8158 2 14.3359 2 11.75C2 9.16414 3.02723 6.68419 4.85571 4.85571C6.68419 3.02723 9.16414 2 11.75 2C14.3359 2 16.8158 3.02723 18.6443 4.85571C20.4728 6.68419 21.5 9.16414 21.5 11.75C21.5 14.3359 20.4728 16.8158 18.6443 18.6443C16.8158 20.4728 14.3359 21.5 11.75 21.5ZM16.47 12.5C16.2068 15.1777 15.1637 17.7193 13.47 19.81C15.1949 19.4455 16.7584 18.5399 17.9329 17.2251C19.1074 15.9103 19.8316 14.2549 20 12.5H16.47ZM3.53 12.5C3.69483 14.2468 4.41028 15.8961 5.57315 17.2099C6.73601 18.5237 8.28617 19.4342 10 19.81C8.30632 17.7193 7.26325 15.1777 7 12.5H3.53ZM8.53 12.5C8.83088 15.1472 9.95343 17.6336 11.74 19.61C13.5447 17.6401 14.6851 15.153 15 12.5H8.53ZM16.48 11H20C19.8384 9.28435 19.1456 7.66185 18.0181 6.35867C16.8906 5.05549 15.3846 4.13657 13.71 3.73C15.3538 5.81376 16.3204 8.35072 16.48 11ZM8.48 11H15C14.7922 8.2908 13.6423 5.73989 11.75 3.79C9.865 5.7428 8.72236 8.29339 8.52 11H8.48ZM3.48 11H7C7.16819 8.34741 8.14542 5.81012 9.8 3.73C8.12842 4.13882 6.626 5.05891 5.5021 6.36206C4.3782 7.66521 3.68882 9.28649 3.53 11H3.48Z"
            fill="currentColor"
        />
    </IconBase>
);

import range from 'lodash/range';
import { ItemSkeleton } from './Item/ItemSkeleton';

export const FetchingSkeleton = () => (
    <>
        {range(3).map((key) => (
            <ItemSkeleton key={key} />
        ))}
    </>
);

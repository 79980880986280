import { Conversation, Message } from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { createNotification } from '../../notifications';
import { getByPhoneOrEmpty } from '../../queries/contact-library.helpers';
import { useContactsQueryData } from '../../queries/contacts';
import { useNavigate } from 'react-router';

type VisibilityChangeHandler = () => void;

const closeNotification = (
    notification: Notification,
    handleVisibilityChange: VisibilityChangeHandler,
) => {
    notification?.close();
    document.removeEventListener('visibilitychange', handleVisibilityChange);
};

export const useShowNotification = () => {
    const contacts = useContactsQueryData();
    const navigate = useNavigate();
    const track = useTrack();

    return (message: Message, conversation: Conversation) => {
        const contact = getByPhoneOrEmpty(message.sender, contacts);
        const title = contact?.name || contact?.phone || '';

        const notification = createNotification(
            title,
            message.body,
            conversation.id,
        );

        if (notification) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    closeNotification(notification, handleVisibilityChange);
                }
            };

            document.addEventListener(
                'visibilitychange',
                handleVisibilityChange,
            );

            // Activate browser window and tab if user clicks notification
            notification?.addEventListener('click', () => {
                navigate(
                    `/inbox/${conversation.inboxId}/conversations/${conversation.id}`,
                );
                track('browser_notification_clicked', {
                    target: 'conversation',
                });
                window.focus();
                closeNotification(notification, handleVisibilityChange);
            });
        }
    };
};

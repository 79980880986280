import { Skeleton, Stack } from '@mui/material';
import range from 'lodash/range';
import random from 'lodash/random';
import map from 'lodash/map';

const list = map(range(3), (i) => [i, random(90, 120)]);

export const ListSkeleton = () => (
    <Stack>
        {list.map(([key, width]) => (
            <Stack direction="row" px={3} py={2} width={1} key={key}>
                <Stack>
                    <Skeleton
                        width={20}
                        height={20}
                        variant="circular"
                        sx={{ mr: 2, width: 20, height: 20 }}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" width={1}>
                    <Skeleton width={width} />
                    <Skeleton width={105} />
                </Stack>
            </Stack>
        ))}
    </Stack>
);

import { Box } from '@mui/material';
import { AttentionFilled } from '../../icons/common/CAttentionFilled';
import { attentionIconHolder, Colors } from './styles';
import { getSquare } from '../../theme/style.helpers';

type Props = {
    color?: Colors;
};
export const DialogAttentionIcon = ({ color = 'error' }: Props) => (
    <Box
        role="presentation"
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Box sx={attentionIconHolder(color)}>
            <AttentionFilled sx={getSquare(22)} />
        </Box>
    </Box>
);

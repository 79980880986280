import { useEffect, useState } from 'react';
import cc from 'classcat';
import { ValueProps } from '../../types';
import styles from '../values.module.scss';
import CheckBox from '../../../../elements/CheckBox/CheckBox';

export const BooleanValue = ({
    userProperty,
    onChange,
    readonly,
}: ValueProps<boolean>) => {
    const [value, setValue] = useState(!!userProperty.value);

    useEffect(() => {
        setValue(!!userProperty.value);
    }, [userProperty]);

    if (readonly) {
        return <CheckBox checked={value} size="normal" disabled />;
    }

    return (
        <CheckBox
            className={cc([styles['flex-start'], styles['boolean-value']])}
            checked={value}
            onChange={(newValue) => {
                setValue(newValue);
                onChange?.({ ...userProperty, value: newValue });
            }}
            size="normal"
        />
    );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as storage from '../utils/local-storage';
import { io, Socket } from 'socket.io-client';
import { EVENT_HANDLERS_MAP } from './mapping';
import { ClerkSocket, Pair, SocketMethods } from './types';

const stickyEvents: Record<string, Pair> = {};
let socket: ClerkSocket | undefined;

const applyHandlers = (socket: ClerkSocket): ClerkSocket => {
    Object.entries(EVENT_HANDLERS_MAP).map(([event, handler]) => {
        // @ts-ignore
        return socket.on(event, handler);
    });
    return socket;
};

const initSocket = (
    authToken: string,
    msUserId?: string,
    msTenetId?: string,
): Socket => {
    const socket: ClerkSocket = io(
        new URL(import.meta.env.VITE_WEBSOCKET_SERVER_URL).toString(),
        {
            path: '/ws/events',
            auth: { token: authToken, msUserId, msTenetId },
            transports: ['websocket'],
        },
    );

    socket.on('connect', () => {
        Object.values(stickyEvents).map(({ ev, args }) => {
            socket.emit(ev, ...args);
        });
    });

    return applyHandlers(socket);
};

export const useSocket = (): SocketMethods => {
    return {
        init: () => {
            if (socket?.connected) {
                return;
            }
            const authToken = storage.get('auth');
            const msUserId = storage.get('MS_TEAMS_USER_ID');
            const msTenetId = storage.get('MS_TENET_ID');
            socket = initSocket(authToken, msUserId, msTenetId);
        },
        getSocket() {
            return socket;
        },
        stick: (key, ev, ...args) => {
            stickyEvents[key] = { ev, args };
            socket?.emit(ev, ...args);
        },
        unstick: (key, ev, ...args) => {
            delete stickyEvents[key];
            socket?.emit(ev, ...args);
        },
        /** @deprecated redundant wrapper try to use getSocket().disconnect() */
        disconnect: () => {
            socket?.disconnect();
        },
    };
};

import { SxProps, Theme } from '@mui/material';
import { shadows } from '../../theme/shadows';

export const stepForm: SxProps = {
    width: 300,
};

export const teamStep: SxProps = {
    width: 400,
};

export const orDivider: SxProps<Theme> = (theme) => ({
    position: 'relative',
    width: 1,
    margin: theme.spacing(2, 0),
    textAlign: 'center',

    '&::before, &::after': {
        content: '""',
        display: 'block',
        width: 'calc(50% - 21px)',
        position: 'absolute',
        transform: 'translateY(-50%)',
        zIndex: 1,
        top: '50%',
        height: '1px',
    },

    '&::after': {
        right: 0,
        left: 'auto',
        background: `linear-gradient(90deg, ${theme.palette.custom.gray.divider} 0%, rgba(226, 229, 234, 0.00) 100.87%)`,
    },

    '&::before': {
        left: 0,
        background: `linear-gradient(270deg, ${theme.palette.custom.gray.divider} 0%, rgba(226, 229, 234, 0.00) 100%)`,
    },
});

export const teamButton: SxProps = {
    px: 4,
    py: 3,
    border: '1px solid',
    borderColor: 'custom.gray.light400',
    cursor: 'pointer',
    borderRadius: 1.5,
    color: 'custom.gray.super',
    boxShadow: shadows[3],
    height: 64,
};

export const teamsWrapper: SxProps = {
    maxHeight: 410,
    overflowX: 'auto',
};

import { Box, Button, Stack, Typography } from '@mui/material';
import { RefreshIcon } from '../../components/ConversationRow/RefreshIcon';
import bg from './bg.svg';
import sad from './sad-emoji.png';
import ClerkLogo from '../../elements/IconComponents/clerk-logo-header.svg?react';

export const GlobalErrorView = () => (
    <Stack
        alignItems="center"
        sx={{
            position: 'relative',
            overflow: 'hidden',
            width: 1,
            height: 1,
            background: `url(${bg})`,
            backgroundSize: ['320%', '220%', '100%'],
            backgroundRepeat: 'no-repeat',
        }}
    >
        <Box sx={{ mt: 10, display: 'flex', alignItems: 'center', gap: 2 }}>
            <ClerkLogo style={{ width: 40, height: 40 }} />
            <Typography variant="h4">Clerk Chat</Typography>
        </Box>
        <Stack
            sx={{
                maxWidth: 510,
                mt: [25, 35, 50],
                px: [4, 0, 0],
                textAlign: 'center',
            }}
        >
            <Box width={1}>
                <Box
                    component="img"
                    src={sad}
                    sx={{
                        width: 56,
                        height: 56,
                    }}
                />
            </Box>
            <Typography variant="h2" sx={{ mt: 4 }} textAlign="center">
                We are experiencing a technical issue right now
            </Typography>
            <Typography variant="body3" mt={1}>
                Try to reload the page or back here in a few minutes. We are
                doing our best to resolve this problem.
            </Typography>
            <Stack alignItems="center">
                <Button
                    sx={{ mt: 6, minWidth: 152 }}
                    onClick={() => window.location.reload()}
                    startIcon={<RefreshIcon />}
                    size="large"
                    variant="contained"
                    color="info"
                >
                    Reload page
                </Button>
            </Stack>
        </Stack>
    </Stack>
);

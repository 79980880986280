import { useMemo, useState } from 'react';
import IconButton from '../../elements/Buttons/IconButton';
import EditIcon from '../../icons/EditIcon';
import { useCampaignUpdateQuery } from '../../queries/campaigns';
import styles from './CampaignViewMessageForm.module.scss';
import MessageForm from '../MessageForm';
import { handleError } from './utils';
import { useToastContext } from '../../containers/toast/reducer';
import { Campaign } from '../../api/campaign.types';

export type CampaignViewMessageFormProps = {
    campaign: Campaign;
};

export function CampaignViewMessageForm({
    campaign,
}: CampaignViewMessageFormProps) {
    const [isEditMode, setEditMode] = useState(false);
    const { dispatch: toastDispatch } = useToastContext();

    const { mutateAsync: updateCampaign } = useCampaignUpdateQuery(campaign.id);

    const initialData = useMemo(
        () => ({
            message: campaign.body || '',
            sendAt: campaign.sendAt,
            files: campaign.attachments || [],
        }),
        [campaign],
    );
    if (isEditMode) {
        return (
            <MessageForm
                key={campaign.id}
                initial={initialData}
                onSend={(data) => {
                    updateCampaign({
                        body: data.message.trim(),
                        sendAt: data.sendAt,
                        files: data.files ? data.files.map((f) => f.id) : [],
                    })
                        .then(() => setEditMode(false))
                        .catch(handleError(toastDispatch));
                }}
                onCancel={() => setEditMode(false)}
                templatesAvailable
            />
        );
    }

    return (
        <div className={styles['campaign-message']}>
            <div className={styles['campaign-message__text']}>
                {campaign.body}
            </div>
            <div className={styles['campaign-message__controls']}>
                <IconButton
                    icon={<EditIcon />}
                    type="default"
                    className={styles['campaign-message__edit-btn']}
                    onClick={() => setEditMode(true)}
                />
            </div>
        </div>
    );
}

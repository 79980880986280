import { ChangeEvent, useState } from 'react';
import { useMeQueryData } from '../../../queries/user';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import {
    Divider,
    Grid,
    List,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { SearchTextField } from '../../../elements/SearchTextField/SearchTextField';
import { IntegrationRow } from './IntegrationRow';
import { useFilteredIntegrations } from './useFilteredIntegrations';
import { CompactCollapsible } from '../../../components/CompactCollapsible/CompactCollapsible';
import { ComingSoonItem } from './ComingSoonItem';
import { ConnectedRow } from './ConnectedRow/ConnectedRow';
import { findIntegration } from './utils';
import { useListIntegrations } from '../../../queries/integrations';
import { useIntegrationUpdateEvent } from './use-update-integration-event';
import { SetupIntegration } from './SetupIntegration';
import { useParams } from 'react-router';

type FilterTabs = 'all' | 'data' | 'application' | 'contact';

const getFilterTitle = (filter: string) => {
    switch (filter) {
        case 'application':
            return 'Applications';
        case 'data':
            return 'Data';
        case 'contact':
            return 'Contacts';
        case 'all':
        default:
            return 'All tools';
    }
};

export const Integrations = () => {
    useIntegrationUpdateEvent();
    const me = useMeQueryData();
    const { source, code } = useParams();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [filter, setFilter] = useState<FilterTabs>('all');
    const { data: myIntegrations = [] } = useListIntegrations();

    const {
        comingSoonIntegrations,
        availableIntegrations,
        connectedIntegrations,
    } = useFilteredIntegrations(filter, searchTerm);

    return (
        <SettingsLayout
            title="Integrations"
            category="Workspace"
            description="Connect other tools that your team uses to Clerk Chat"
        >
            <Stack spacing={6}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                    <ToggleButtonGroup
                        value={filter}
                        exclusive
                        onChange={(event) => setFilter(event.target.value)}
                        aria-label="text alignment"
                        sx={{ flex: 1 }}
                    >
                        <ToggleButton value="all" aria-label="left aligned">
                            All
                        </ToggleButton>

                        <ToggleButton
                            value="application"
                            aria-label="left aligned"
                        >
                            Applications
                        </ToggleButton>

                        <ToggleButton value="data" aria-label="left aligned">
                            Data
                        </ToggleButton>

                        <ToggleButton value="contact" aria-label="left aligned">
                            Contacts
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <SearchTextField
                        placeholder="Search integrations"
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setSearchTerm(
                                event.currentTarget.value
                                    .trim()
                                    .toLocaleLowerCase(),
                            )
                        }
                    />
                </Stack>
                <Divider />

                {source && code && (
                    <SetupIntegration source={source} code={code} />
                )}

                <Stack spacing={10}>
                    {!!connectedIntegrations.length && (
                        <CompactCollapsible
                            showCount
                            label="Connected"
                            count={connectedIntegrations.length}
                            autoExpand
                        >
                            <Stack spacing={3}>
                                {connectedIntegrations
                                    .filter((integration) =>
                                        myIntegrations?.some(
                                            findIntegration(integration),
                                        ),
                                    )
                                    .map((integration) => {
                                        const connected = myIntegrations.find(
                                            findIntegration(integration),
                                        );

                                        return (
                                            <ConnectedRow
                                                {...integration}
                                                me={me!}
                                                connected={connected!}
                                                key={`${integration.source}_${integration.integrationType}`}
                                            />
                                        );
                                    })}
                            </Stack>
                        </CompactCollapsible>
                    )}

                    {!!availableIntegrations.length && (
                        <div>
                            <Typography
                                component="div"
                                variant="caption"
                                color="custom.gray.super"
                            >
                                {getFilterTitle(filter)}
                            </Typography>

                            <List>
                                {availableIntegrations.map((integration) => (
                                    <IntegrationRow
                                        {...integration}
                                        me={me!}
                                        key={`${integration.source}_${integration.integrationType}`}
                                    />
                                ))}
                            </List>
                        </div>
                    )}

                    {!!comingSoonIntegrations.length && (
                        <CompactCollapsible
                            label="Will be added soon"
                            autoExpand
                        >
                            <Grid container spacing={4}>
                                {comingSoonIntegrations.map((integration) => (
                                    <Grid
                                        item
                                        key={`${integration.source}_${integration.integrationType}`}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                    >
                                        <ComingSoonItem
                                            {...integration}
                                            key={`${integration.source}_${integration.integrationType}`}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </CompactCollapsible>
                    )}
                </Stack>
            </Stack>
        </SettingsLayout>
    );
};

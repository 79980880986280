import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { CsvIcon, WordIcon } from './ExtensionIcon';
import { SxProps, Theme } from '@mui/material';
import { FileTypePDF } from './CFileTypePDF';
import { FileTypeXLS } from './CFileTypeXLS';

export const MimeTypeIcon = ({
    type,
    sx = {},
}: {
    type: string;
    sx?: SxProps<Theme>;
}) =>
    ({
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
            <FileTypeXLS sx={sx} />
        ),
        'application/vnd.ms-excel': <FileTypeXLS sx={sx} />,
        'application/pdf': <FileTypePDF sx={sx} />,
        'text/csv': <CsvIcon sx={sx} />,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            <WordIcon sx={sx} />,
        'application/msword': <WordIcon sx={sx} />,
    })[type] || <FilePresentOutlinedIcon sx={sx} />;

import { Conversation } from '../../../api/types';
import { useMarkConversationAsRead } from '../../../queries/conversations';
import { useConversationSubscription } from '../../ConversationRow/use-conversation-subscription';
import { InboxListItem } from '../../ConversationsList/Item/InboxListItem';
import {
    Entity,
    usePreserveParams,
} from '../../../pages/NewInbox/query.helpers';
import { AdapterLink } from '../../ConversationsList/AdapterLink';
import { QueryParam } from '../../../pages/NewInbox/query.params';
import { useCallback, useMemo, useState } from 'react';
import { AssistantPopover } from '../../ConversationRow/Assistant/AssistantPopover';
import { BadgeProps, Box } from '@mui/material';
import { SparklesIcon } from '../../../icons/common/SparklesIcon';
import { getBadge } from '../../ConversationRow/styles';
import { useIsAssistantReply } from './use-is-assistant-reply';
import dayjs from 'dayjs';
import { ConversationStatus } from './Status';
import { useMessageStoppedBy } from '../../ConversationRow/stop-phrase/use-message-stopped-by';
import { useMatch } from 'react-router';
import { useByPhones } from '../../Contacts/use-contact-by-phone';
import {
    useConversationAvatar,
    useConversationName,
} from './use-conversation-props';
import { ContextMenu } from './ContextMenu';
import { Indicator } from '../../common/Indicator';
import throttle from 'lodash/throttle';
import { prefetchConversationPage } from '../../../queries/messages';
import { UUID } from '../../../types/uuid';

type Props = {
    conversation: Conversation;
};

const assistantBadgeProps = (stopped: boolean = false): BadgeProps => ({
    overlap: 'circular',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    badgeContent: <SparklesIcon />,
    sx: getBadge(stopped),
    invisible: false,
});

const prefetchThrottle = throttle(
    (conversationId: UUID) => {
        return prefetchConversationPage(conversationId);
    },
    200,
    { trailing: true, leading: false },
);

export const ConversationListItem = ({ conversation }: Props) => {
    useConversationSubscription(conversation.id);
    const [hovered, setHovered] = useState<boolean>(false);
    const title = useConversationName(conversation.members);
    const avatar = useConversationAvatar(conversation.members);
    const memberQueries = useByPhones(conversation.members);
    const isLoading = useMemo(
        () => memberQueries.some(({ isLoading }) => isLoading),
        [memberQueries],
    );

    const isMatch = useMatch(
        `/inbox/${conversation.inboxId}/conversations/${conversation.id}/*`,
    );
    const preserveParams = usePreserveParams();
    const markAsRead = useMarkConversationAsRead();
    const phrase = useMessageStoppedBy(conversation.lastMessageBody);

    const [anchorEl, setAnchorEl] = useState<
        HTMLElement | HTMLButtonElement | null
    >(null);
    const open = Boolean(anchorEl);
    const isAssistantReply = useIsAssistantReply(conversation);

    const onMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            setHovered(true);
            prefetchThrottle(conversation.id);

            if (isAssistantReply && anchorEl !== e.currentTarget) {
                setAnchorEl(e.currentTarget);
            }
        },
        [conversation.id, isAssistantReply, anchorEl],
    );

    const BadgeProps = useMemo(() => {
        if (isAssistantReply) {
            return assistantBadgeProps(!!phrase);
        }
        return {};
    }, [isAssistantReply, phrase]);

    const onClick = () => {
        setAnchorEl(null);
        if (conversation.unreadMessageCount) {
            markAsRead.mutate(conversation.id);
        }
    };

    const lastMessageDate =
        conversation.lastMessageSent ?? conversation.created;

    const date = useMemo(() => {
        if (dayjs(lastMessageDate).isToday()) {
            return dayjs(lastMessageDate).format('h:mm A');
        }
        return dayjs(lastMessageDate).format('D MMM');
    }, [lastMessageDate]);

    return (
        <InboxListItem
            aria-owns={
                open ? `inbox-message-popover-${conversation.id}` : undefined
            }
            avatar={avatar}
            date={date}
            aria-haspopup={isAssistantReply ? 'true' : undefined}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            onMouseLeave={() => {
                setHovered(false);
                prefetchThrottle.cancel();
                if (isAssistantReply || anchorEl) {
                    setAnchorEl(null);
                }
            }}
            href={preserveParams(
                `/inbox/${conversation.inboxId}/${Entity.Conversations}/${conversation.id}/`,
                [QueryParam.ArchiveState, QueryParam.ContactInfo],
            )}
            LinkComponent={AdapterLink}
            loading={isLoading}
            title={title}
            description={<ConversationStatus conversation={conversation} />}
            selected={!!isMatch}
            BadgeProps={BadgeProps}
            sx={{
                ':hover .item-context-menu': {
                    opacity: 1,
                },
            }}
            extraDescription={
                <Box display="flex" alignItems="center" justifyContent="center">
                    {hovered && <ContextMenu conversation={conversation} />}
                    {!hovered && conversation.unreadMessageCount > 0 && (
                        <Indicator
                            sx={{ height: 16 }}
                            data-testid="conversation-unread-count"
                        >
                            {conversation.unreadMessageCount}
                        </Indicator>
                    )}
                </Box>
            }
        >
            {isAssistantReply && (
                <AssistantPopover
                    conversation={conversation}
                    open={isAssistantReply && open}
                    anchorEl={anchorEl}
                />
            )}
        </InboxListItem>
    );
};

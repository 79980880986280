import { SxProps } from '@mui/material';

export const toolbar: SxProps = {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    position: 'relative',
};

export const divider: SxProps = {
    height: 20,
    alignSelf: 'center',
};

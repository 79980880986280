import { colors, Components, tableRowClasses, Theme } from '@mui/material';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
    styleOverrides: {
        root: {
            [`&.${tableRowClasses.hover}`]: {
                ':hover': {
                    backgroundColor: colors.blue[50],
                },
            },
            [`&.${tableRowClasses.selected}`]: {
                backgroundColor: '#ECEFFE3D',
            },
        },
    },
};

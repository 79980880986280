import styles from './TimeInput.module.scss';
import Input from '../../elements/Inputs/Input';
import { WorkflowSchedule } from '../workflows/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from 'react';
dayjs.extend(customParseFormat);

type TimeInputProps = {
    schedule: WorkflowSchedule;
    onChange: (schedule: WorkflowSchedule) => void;
};

export const TimeInput = (props: TimeInputProps) => {
    const [localTime, setLocalTime] = useState<string>(() => {
        const date = dayjs(props.schedule.time, 'HH:mmZ');
        return date.format('hh:mm A');
    });

    useEffect(() => {
        const localDate = dayjs(localTime, 'hh:mm A');
        if (localDate.isValid()) {
            props.onChange({
                ...props.schedule,
                time: localDate.format('HH:mmZ'),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localTime]);
    return (
        <div className={styles['time-input__wrapper']}>
            <Input
                type="text"
                className={styles['time-input']}
                placeholder="hh:mm AM"
                size="small"
                value={localTime}
                onChange={(time) => {
                    setLocalTime(time);
                }}
            />
        </div>
    );
};

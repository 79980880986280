import { History } from '../../main/campaign/v3/History/History';
import {
    MessageForm,
    MessageFormData,
} from '../../components/MessageForm/MessageForm';
import { Box } from '@mui/material';
import { useTrack } from '../../contexts/analytics';
import { useToastContext } from '../../containers/toast/reducer';
import { useCampaign } from '../../queries/campaigns';
import {
    CampaignMessage,
    useCampaignMessageUpdate,
    useCreateCampaignMessage,
} from '../../main/campaign/v3/use-campaign-messages';
import { useEffect, useMemo, useState } from 'react';
import { useConfirmSending } from '../../main/campaign/v3/use-confirm-sending';
import { CampaignType } from '../../api/campaign.types';
import { handleError } from '../../components/MessageFormContainers/utils';
import { history } from '../../main/campaign/v3/campaign.styles';
import { Nullable } from '../../helpers/types';
import { HEADER_HEIGHT, pixelize } from './inbox.styles';
import { useNavigate } from 'react-router';

const messageToFormData = (
    message: Nullable<CampaignMessage>,
): MessageFormData | null => {
    if (!message) {
        return null;
    }
    return {
        message: message.body,
        sendAt: message.timestamp,
        attachments: message.attachments,
    };
};

type Props = {
    campaignId: number;
};

export const CampaignView = ({ campaignId }: Props) => {
    const track = useTrack();
    const navigate = useNavigate();
    const create = useCreateCampaignMessage();
    const { dispatch: toastDispatch } = useToastContext();

    const { data } = useCampaign(campaignId);
    const [campaignMessage, setCampaignMessage] =
        useState<Nullable<CampaignMessage>>(null);
    const update = useCampaignMessageUpdate(campaignMessage?.id);
    const [confirm, ConfirmDialog] = useConfirmSending();

    const reset = () => setCampaignMessage(null);

    useEffect(() => {
        if (
            data?.type &&
            ![CampaignType.INSTANT, CampaignType.PLANNED].includes(data.type)
        ) {
            track('campaign_redirected_to_old');
            navigate(`/campaigns/${data.id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.type]);

    const initialMessage = useMemo(
        () => messageToFormData(campaignMessage),
        [campaignMessage],
    );

    if (!campaignId) {
        return null;
    }

    const handleMessage = async (data: MessageFormData) => {
        if (campaignMessage) {
            return update
                .mutateAsync({
                    body: data.message,
                    attachments: data.attachments?.map(({ id }) => id) ?? [],
                    timestamp: data.sendAt,
                })
                .then(() => {
                    setCampaignMessage(null);
                })
                .catch(handleError(toastDispatch));
        }

        if (!(await confirm())) {
            return Promise.reject();
        }

        return create
            .mutateAsync({
                campaignId,
                attachments: data.attachments?.map(({ id }) => id),
                body: data.message,
                timestamp: data.sendAt ? new Date(data.sendAt) : undefined,
            })
            .then(() => {
                reset();
            })
            .catch(handleError(toastDispatch));
    };

    return (
        <Box
            sx={{
                display: 'grid',
                width: 1,
                gridTemplateRows: '1fr auto',
                height: [
                    `calc(100vh - ${pixelize(HEADER_HEIGHT)} - 61px)`,
                    `calc(100vh - ${pixelize(HEADER_HEIGHT)} - 61px)`,
                    `calc(100vh - ${pixelize(HEADER_HEIGHT)})`,
                ],
            }}
        >
            <Box sx={{ overflow: 'hidden' }}>
                <Box sx={history}>
                    <History
                        campaignId={campaignId}
                        onEdit={(message) => {
                            setCampaignMessage(message);
                        }}
                    />
                </Box>
            </Box>
            <div>
                <MessageForm
                    initial={initialMessage}
                    onSend={handleMessage}
                    disabled={create.isPending || update.isPending}
                    placeholder={`Send another message to ${data?.name}`}
                    templatesAvailable
                />
            </div>
            <ConfirmDialog contactsCount={data?.contactIds.length ?? 0} />
        </Box>
    );
};

import { InboxProvider } from '../../../api/types';
import { ReactNode } from 'react';
import { Twilio } from '../../../icons/carriers/Twilio';
import { Telnyx } from '../../../icons/carriers/Telnyx';

export const iconMap: Record<InboxProvider, ReactNode | null> = {
    [InboxProvider.TWILIO]: <Twilio />,
    [InboxProvider.TELNYX]: <Telnyx />,
    [InboxProvider.BANDWIDTH]: null,
    [InboxProvider.MSTEAMS]: null,
    [InboxProvider.OTHER]: null,
    [InboxProvider.NON_VOIP]: null,
    [InboxProvider.ZOOM]: null,
    [InboxProvider.WEBEX]: null,
};

export const titleMap: Record<InboxProvider, string> = {
    [InboxProvider.TWILIO]: 'Import Twilio Phones',
    [InboxProvider.TELNYX]: 'Import Telnyx Phones',
    [InboxProvider.BANDWIDTH]: 'Import Bandwidth Phones',
    [InboxProvider.MSTEAMS]: 'Import Microsoft Teams Phones',
    [InboxProvider.OTHER]: 'Existing phone',
    [InboxProvider.NON_VOIP]: 'Import Non-VoIP Phones',
    [InboxProvider.ZOOM]: 'Import Zoom Phones',
    [InboxProvider.WEBEX]: 'Import Webex Phones',
};

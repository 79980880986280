import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const ArrowUpwardFilled = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8094 5.55936C10.6385 5.73021 10.3615 5.73021 10.1906 5.55936L7.4375 2.80622L7.4375 12.25C7.4375 12.4916 7.24162 12.6875 7 12.6875C6.75838 12.6875 6.5625 12.4916 6.5625 12.25L6.5625 2.80622L3.80936 5.55936C3.63851 5.73021 3.36149 5.73021 3.19064 5.55936C3.01979 5.3885 3.01979 5.11149 3.19064 4.94064L6.69064 1.44064C6.8615 1.26979 7.1385 1.26979 7.30936 1.44064L10.8094 4.94064C10.9802 5.1115 10.9802 5.3885 10.8094 5.55936Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

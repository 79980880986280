import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import Loading from '../Loading/Loading';
import {
    useListTeamsForAuth,
    useSetupTeamQuery,
    useSwitchTeamQuery,
} from '../../queries/team';
import { setAuthToStorage } from '../../api/team';
import { queryClient } from '../../queries/queryClient';
import { useCallback, useEffect } from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Typography,
    Stack,
    Paper,
    Grid,
    AvatarGroup,
} from '@mui/material';
import { orDivider, teamButton, teamStep, teamsWrapper } from './styles';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import teamPlaceholder from './team-placeholder.svg';
import { ChevronRightIcon } from '../../icons/common/CChevronRightIcon';
import { getSquare } from '../../theme/style.helpers';

const getMembersCountText = (length: number) => {
    if (length === 0) {
        return 'No members yet';
    }
    if (length === 1) {
        return '1 member';
    }
    return `${length} members`;
};

export interface TeamStepProps {
    nextPage: string | null;
}

export const TeamStep = ({ nextPage }: TeamStepProps) => {
    const {
        data: teams = [],
        isFetching,
        isSuccess,
        isError,
    } = useListTeamsForAuth();
    const { mutateAsync: switchTeam } = useSwitchTeamQuery();
    const { mutateAsync: setupTeam } = useSetupTeamQuery();
    const navigate = useNavigate();

    const handleSwitchTeam = useCallback(
        (teamId: string) => {
            switchTeam(teamId)
                .then((data) => {
                    setAuthToStorage(data);
                    queryClient.refetchQueries().then(() => {
                        navigate(nextPage || '/');
                    });
                })
                .catch((error) => {
                    Sentry.captureException(error);
                });
        },
        [navigate, nextPage, switchTeam],
    );

    useEffect(() => {
        const handleSwitchTeam = (teamId: string) => {
            switchTeam(teamId)
                .then((data) => {
                    setAuthToStorage(data);
                    queryClient.refetchQueries().then(() => {
                        navigate(nextPage || '/');
                    });
                })
                .catch((error) => {
                    Sentry.captureException(error);
                });
        };
        if (isSuccess && teams.length === 1) {
            handleSwitchTeam(teams[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, teams]);

    useEffect(() => {
        if (isSuccess && teams.length === 1) {
            handleSwitchTeam(teams[0].id);
        }
    }, [isSuccess, teams, handleSwitchTeam]);

    if (isSuccess && teams.length === 1) {
        return <Loading />;
    }

    if ((isSuccess && teams.length === 0) || isError) {
        return (
            <Stack spacing={6} sx={teamStep}>
                <Alert severity="warning" icon="🧐">
                    <AlertTitle>Don&apos;t see your team?</AlertTitle>
                    Ask one of your teammates to invite you
                </Alert>
                <Box sx={orDivider}>
                    <Typography variant="caption" color="custom.gray.super">
                        OR
                    </Typography>
                </Box>
                <Button
                    size="large"
                    color="primary"
                    onClick={() =>
                        setupTeam()
                            .then(() => navigate(nextPage || '/'))
                            .catch((error) => {
                                Sentry.captureException(error);
                            })
                    }
                    fullWidth
                >
                    Continue with a new workspace
                </Button>
            </Stack>
        );
    }

    return (
        <Box sx={teamStep}>
            {isFetching ? (
                <Loading />
            ) : (
                <Box sx={teamsWrapper}>
                    <Stack spacing={2}>
                        {(teams ?? []).map((team) => {
                            const maxWidth =
                                275 - 25 * team.membersForAvatars.length;

                            return (
                                <Paper
                                    key={team.id}
                                    onClick={() => {
                                        handleSwitchTeam(team.id);
                                    }}
                                    sx={teamButton}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        flexWrap="nowrap"
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={4}
                                            flexWrap="nowrap"
                                        >
                                            <UserAvatar
                                                size={40}
                                                imageUrl={
                                                    team.teamAvatarURL ??
                                                    undefined
                                                }
                                                placeholderUrl={teamPlaceholder}
                                            />
                                            <Stack
                                                spacing={1}
                                                alignItems="start"
                                            >
                                                <Typography
                                                    variant="body3"
                                                    color="primary.dark"
                                                    maxWidth={maxWidth}
                                                    noWrap
                                                >
                                                    {team.name || 'Team'}
                                                </Typography>
                                                <Typography
                                                    variant="body4"
                                                    color="custom.gray.super"
                                                >
                                                    {getMembersCountText(
                                                        team.members,
                                                    )}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            flexWrap="nowrap"
                                            alignItems="center"
                                        >
                                            <AvatarGroup>
                                                {team.membersForAvatars.map(
                                                    (member, index) => (
                                                        <UserAvatar
                                                            key={index}
                                                            size={20}
                                                            username={[
                                                                member.firstname,
                                                                member.lastname,
                                                            ]
                                                                .filter(Boolean)
                                                                .join(' ')}
                                                            imageUrl={
                                                                member.avatarUrl ??
                                                                undefined
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </AvatarGroup>
                                            <ChevronRightIcon
                                                sx={getSquare(20)}
                                            />
                                        </Stack>
                                    </Grid>
                                </Paper>
                            );
                        })}
                    </Stack>
                </Box>
            )}
        </Box>
    );
};

export default TeamStep;

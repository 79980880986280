import { SxProps, Theme } from '@mui/material';

export const popup: SxProps<Theme> = {
    display: 'flex',
    position: 'absolute',
    top: -20,
    right: 50,
    p: 1,

    boxShadow: ({ shadows }) => shadows[1],
    borderRadius: 1.5,
    bgcolor: 'white',
    border: '1px solid',
    borderColor: 'divider',
};

export const container: SxProps = {
    flex: 1,
    display: 'grid',
    gridTemplateRows: '61px 1fr',
    maxHeight: '100vh',
};

export const divider: SxProps = {
    mx: 2,
    height: 16,
    alignSelf: 'center',
};
export const progressHolder: SxProps = {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    width: 1,
};

import { Attachment } from '../../../../api/types';
import isEmpty from 'lodash/isEmpty';
import { Stack, StackProps } from '@mui/material';
import { AttachmentItem } from './AttachmentItem';

type Props = { attachments: Attachment[] } & StackProps;

export const Attachments = ({ attachments, ...props }: Props) => {
    if (isEmpty(attachments)) {
        return null;
    }

    return (
        <Stack
            direction="row"
            spacing={2}
            flexWrap="wrap"
            useFlexGap
            {...props}
        >
            {attachments.map((attachment) => (
                <AttachmentItem key={attachment.id} attachment={attachment} />
            ))}
        </Stack>
    );
};

import cc from 'classcat';
import styles from './Callout.module.scss';

type CalloutProps = {
    style: 'success' | 'warning' | 'error';
    children: React.ReactNode;
};

export const Callout = ({ children, style }: CalloutProps) => (
    <div className={cc([styles['root'], styles[`root__${style}`]])}>
        {children}
    </div>
);

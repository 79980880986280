import styles from './Condition.module.scss';
import AdvancedSelect, {
    OptionBase,
} from '../../../../elements/AdvancedSelect';
import { FilterCondition, FilterOperator, FilterOption } from '../../types';
import Input from '../../../../elements/Inputs/Input';
import Button from '../../../../elements/Buttons';
import Icon from '../../../../icons/Icon';

type ConditionProps = {
    index: number;
    onChange: (f: Partial<FilterCondition>, index: number) => void;
    deleteCondition: (index: number) => void;
    cohortOptions: OptionBase[];
    filterCondition: Partial<FilterCondition>;
};

const entityOptions: OptionBase[] = [
    { id: FilterOption.Body, label: 'Message body' },
    { id: FilterOption.Cohort, label: 'Cohort' },
];

const messageOperatorOptions: OptionBase[] = [
    { id: FilterOperator.Contain, label: 'Contains' },
    { id: FilterOperator.DoesNotContain, label: 'Does not contain' },
];
const cohortOperatorOptions: OptionBase[] = [
    { id: FilterOperator.IncludedInCohort, label: 'Contact in cohort' },
    { id: FilterOperator.ExcludedFromCohort, label: 'Contact not in cohort' },
];

export const Condition = (props: ConditionProps) => {
    return (
        <div className={styles.root}>
            {props.index > 0 && (
                <div className={styles.condition_condition}>
                    <span>{props.filterCondition.condition}</span>
                    <hr />
                </div>
            )}
            <span className={styles.condition_title}>
                Condition #{props.index + 1}
                {props.index > 0 && (
                    <Button
                        icon={<Icon name="close" />}
                        type="icon"
                        onClick={() => props.deleteCondition(props.index)}
                    />
                )}
            </span>
            <AdvancedSelect
                options={entityOptions}
                className={styles.input}
                value={props.filterCondition.option}
                onChange={(v) =>
                    props.onChange(
                        { ...props.filterCondition, option: v as FilterOption },
                        props.index,
                    )
                }
                placeholder="Option"
            />
            <AdvancedSelect
                value={props.filterCondition.operator}
                className={styles.input}
                options={
                    !props.filterCondition.option
                        ? [...cohortOperatorOptions, ...messageOperatorOptions]
                        : props.filterCondition.option === FilterOption.Cohort
                          ? cohortOperatorOptions
                          : messageOperatorOptions
                }
                onChange={(v) =>
                    props.onChange(
                        {
                            ...props.filterCondition,
                            operator: v as FilterOperator,
                        },
                        props.index,
                    )
                }
                placeholder="Operator"
            />
            {props.filterCondition.option === FilterOption.Body ? (
                <Input
                    placeholder="Enter Text Here"
                    value={props.filterCondition.text}
                    fullWidth={true}
                    className={styles.input}
                    onChange={(v) =>
                        props.onChange(
                            {
                                ...props.filterCondition,
                                text: v,
                            },
                            props.index,
                        )
                    }
                />
            ) : (
                props.filterCondition.option === FilterOption.Cohort && (
                    <AdvancedSelect
                        options={props.cohortOptions}
                        value={props.filterCondition.cohortId?.toString()}
                        className={styles.input}
                        onChange={(v) =>
                            props.onChange(
                                {
                                    ...props.filterCondition,
                                    cohortId: parseInt(v),
                                },
                                props.index,
                            )
                        }
                        placeholder="Cohort"
                        searchable={true}
                    />
                )
            )}
        </div>
    );
};

import isEmpty from 'lodash/isEmpty';
import { Box, LinearProgress } from '@mui/material';
import { Attachment } from '../../../../../api/types';
import { AttachmentItem } from './AttachmentItem';
import { attachmentsHolder, uploadProgress } from './styles';

type Props = {
    attachments: Attachment[];
    isAttachmentUploading: boolean;
    removeAttachment: (a: Attachment) => void;
};

export const AttachmentList = ({
    attachments,
    isAttachmentUploading,
    removeAttachment,
}: Props) => {
    return (
        <>
            {isAttachmentUploading && <LinearProgress sx={uploadProgress} />}
            {!isEmpty(attachments) && (
                <Box sx={attachmentsHolder}>
                    {attachments.map((attachment, i) => (
                        <AttachmentItem
                            key={`${attachment.name}/${i}`}
                            attachment={attachment}
                            onRemove={removeAttachment}
                        />
                    ))}
                </Box>
            )}
        </>
    );
};

import { SxProps, Theme } from '@mui/material';

export const navItemStyle: SxProps<Theme> = (theme) => ({
    ...theme.typography.body3,
    color: theme.palette.primary.dark,
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    display: 'flex',
    boxSizing: 'border-box',
    transition: theme.transitions.create(['outline'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
    }),

    '&:focus-visible': {
        outlineWidth: 1,
        outlineStyle: 'solid',
        outlineColor: theme.palette.info.main,
    },

    '&:visited': {
        color: theme.palette.primary.dark,
    },

    '& .MuiListItemIcon-root': { marginRight: 4 },

    '&.active': {
        backgroundColor: theme.palette.custom.gray.actionSelected,
    },
});

export const navItemActiveStyle: SxProps<Theme> = (theme) => ({
    ...navItemStyle(theme),
    backgroundColor: theme.palette.custom.secondary.label,
});

import { useSearchParams } from 'react-router-dom';
import { PlanItem, plansMap } from './plans';
import { useState } from 'react';

export const useSelectedPlan = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pid = searchParams.get('pid');
    const plan = pid ? plansMap[pid] : null;

    const [selectedPlan, setSelectedPlan] = useState<PlanItem | null>(plan);

    const setPlan = (plan: PlanItem) => {
        setSearchParams({ ...searchParams, pid: plan.priceId });
        setSelectedPlan(plan);
    };

    return [selectedPlan, setPlan];
};

import isPlainObject from 'lodash/isPlainObject';
import { Contact, ContactPropertyType } from '../../api/types';
import { UserPropertyModel } from '../../components/UserInfoProperty/types';
import { PropertySource } from '../../components/ContactProperty/types';

export type GroupedProperties = Record<PropertySource, UserPropertyModel[]>;

export const getInitialVlue = () =>
    Object.values(PropertySource).reduce<GroupedProperties>((acc, value) => {
        acc[value] = [];
        return acc;
    }, {} as GroupedProperties);

export const getValueFromProperty = (
    contact: Partial<Contact>,
    property: UserPropertyModel,
) => {
    switch (property.type) {
        case ContactPropertyType.LastEditedTime:
            return contact?.updated;
        case ContactPropertyType.CreatedTime:
            return contact?.created;
        default:
            return isPlainObject(contact?.data?.[property.id])
                ? undefined
                : contact?.data?.[property.id];
    }
};

export const getTitle = (source: PropertySource): string => {
    const titles: Record<PropertySource, string> = {
        [PropertySource.Clerk]: 'About',
        [PropertySource.Google]: 'Google',
        [PropertySource.Microsoft]: 'Microsoft',
        [PropertySource.Salesforce]: 'Salesforce',
        [PropertySource.Shopify]: 'Shopify',
        [PropertySource.Hubspot]: 'Hubspot',
        [PropertySource.Pipedrive]: 'Pipedrive',
    };

    return titles[source] || '';
};

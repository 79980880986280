import styles from './SideBarNavigation.module.scss';
import NavigationItem from '../../../elements/Navigation';
import { AnalyticsIcon } from './AnalyticsIcon';
import { Feature, RoleTypes } from '../../../api/types';
import { useTrack } from '../../../contexts/analytics';
import { SearchFilledIcon } from '../../../icons/common/SearchFilledIcon';
import { useState } from 'react';
import { SearchDialog } from '../../SearchDialog/SearchDialog';
import { WorkflowsIcon } from '../../../icons/common/CWorkflows';
import { SettingsIcon } from '../../../components/Inbox/CSettingsIcon';
import {
    navItemActiveStyle,
    navItemStyle,
} from '../../../elements/Navigation/styles';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    ListItemIcon,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import { useMeQueryData } from '../../../queries/user';
import { AdapterLink } from '../../../components/ConversationsList/AdapterLink';
import { getSquare } from '../../../theme/style.helpers';
import { useOnboardingData } from '../../../hooks/useOnboardingData';
import { useUpdateOnboardingData } from '../../../hooks/useUpdateOnboardingData';
import { WithFeature } from '../../Feature/WithFeature';
import { WithoutFeature } from '../../Feature/WithoutFeature';
import { ContactsIcon } from '../../../icons/common/CContactsIcon';
import { AiChatIcon } from '../../../icons/common/AiChatIcon';
import { CompanyIcon } from '../../../icons/common/CCompanyIcon';

interface Props {
    setMenuOpen: (isOpen: boolean) => void;
}

const IS_MAC = window.navigator.userAgent.indexOf('Mac OS');

export const SideBarNavigation = ({ setMenuOpen }: Props) => {
    const track = useTrack();
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

    const me = useMeQueryData();
    useUpdateOnboardingData();
    const {
        score,
        finished,
        total,
        onboarding,
        isOnboardingBlockedForOldTeams,
    } = useOnboardingData();

    return (
        <nav className={styles['root']}>
            {!!onboarding &&
                score < 100 &&
                !isOnboardingBlockedForOldTeams &&
                [RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
                    me?.activeRole?.type ?? RoleTypes.Member,
                ) && (
                    <Button
                        sx={{
                            elevate: 1,
                            bgcolor: '#fff',
                            width: 'auto',
                            mx: 2,
                            mb: 4,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            '&:focus-within:not(.Mui-disabled)': {
                                bgcolor: '#fff',
                            },
                            '&:hover:not(.Mui-disabled)': {
                                bgcolor: 'primary.hover',
                            },
                        }}
                        variant="outlined"
                        onClick={() => {
                            track('clicked_onboarding_page');
                        }}
                        startIcon={
                            <Box
                                sx={{
                                    ...getSquare(20),
                                    bgcolor: 'custom.action.disabledBackground',
                                    borderRadius: '50%',
                                }}
                            >
                                <CircularProgress
                                    variant="determinate"
                                    size={20}
                                    value={100}
                                    sx={{
                                        position: 'absolute',
                                        color: 'passive.focusVisible',
                                    }}
                                />
                                <CircularProgress
                                    size={20}
                                    color="info"
                                    variant="determinate"
                                    value={score}
                                />
                            </Box>
                        }
                        href="/onboarding-progress"
                        LinkComponent={AdapterLink}
                    >
                        <Typography
                            variant="body3"
                            sx={{ ml: 2, color: 'info.main' }}
                        >
                            Onboarding
                        </Typography>
                        <Typography
                            variant="body4"
                            color="text.secondary"
                            sx={{ ml: 'auto' }}
                        >
                            {finished} of {total}
                        </Typography>
                    </Button>
                )}
            <Tooltip
                disableFocusListener
                arrow
                title={`${IS_MAC ? 'Command' : 'CTRL'} + K`}
                placement="right"
            >
                <MenuItem
                    sx={isSearchOpen ? navItemActiveStyle : navItemStyle}
                    onClick={() => {
                        track('clicked_search_sidebar');
                        setIsSearchOpen((prev) => !prev);
                    }}
                >
                    <ListItemIcon>
                        <SearchFilledIcon />
                    </ListItemIcon>
                    Search
                </MenuItem>
            </Tooltip>
            <SearchDialog open={isSearchOpen} setOpen={setIsSearchOpen} />

            <WithoutFeature feature={Feature.NextContacts}>
                <NavigationItem
                    to="/contacts"
                    icon={<ContactsIcon />}
                    onClick={() => {
                        track('clicked_contacts_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Contacts"
                />
            </WithoutFeature>
            <WithFeature feature={Feature.NextContacts}>
                <NavigationItem
                    icon={<ContactsIcon />}
                    to="/book"
                    onClick={() => {
                        track('clicked_next_contacts_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Contacts"
                />
            </WithFeature>
            <NavigationItem
                to="/analytics"
                icon={<AnalyticsIcon />}
                onClick={() => {
                    track('clicked_analytics_sidebar');
                    setMenuOpen(false);
                }}
                label="Analytics"
            />
            <WithFeature feature={Feature.Campaigns}>
                <NavigationItem
                    to="/campaigns"
                    icon={<CompanyIcon />}
                    onClick={() => {
                        track('clicked_campaigns_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Campaigns"
                />
            </WithFeature>
            <WithFeature feature={Feature.AiWorkflow}>
                <NavigationItem
                    icon={<AiChatIcon />}
                    to="/pipelines"
                    onClick={() => {
                        track('clicked_ai_workflow_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Flows"
                >
                    <Chip label="New" color="info" size="small" />
                </NavigationItem>
            </WithFeature>
            <WithFeature feature={Feature.Workflows}>
                <NavigationItem
                    to="/workflows"
                    icon={<WorkflowsIcon />}
                    onClick={() => {
                        track('clicked_workflows_sidebar');
                        setMenuOpen(false);
                    }}
                    label="Workflows"
                />
            </WithFeature>
            <NavigationItem
                to="/settings/profile"
                icon={<SettingsIcon />}
                onClick={() => {
                    track('clicked_settings_sidebar');
                    setMenuOpen(false);
                }}
                label="Settings"
            />
        </nav>
    );
};

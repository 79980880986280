//styles
import styles from './Backdrop.module.scss';

interface BackdropProps {
    onClick?: () => void;
    children: React.ReactNode;
}

export const Backdrop = ({ children, onClick }: BackdropProps) => {
    return (
        <div className={styles['backdrop']} onClick={onClick}>
            {children}
        </div>
    );
};

import CollapsibleTextSection from '../../components/CollapsibleTextSection';
import { Typography } from '@mui/material';

export const PortDisclaimer = () => (
    <CollapsibleTextSection
        visibleComponent={
            <Typography variant="body4" color="primary.dark" component="div">
                By signing the below, I verify that I am, or represent (for a
                business), the above-named service customer, authorized to
                change the primary carrier(s) for the telephone number(s)
                listed, and am at least 18 years of age. The name and address I
                have provided is the name and address on record with my local
                telephone company for each telephone number listed.
            </Typography>
        }
        hiddenComponent={
            <Typography variant="body4" color="primary.dark">
                <br />I authorize Clerk Chat Inc. or its designated agent to act
                on my behalf and notify my current carrier(s) to change my
                preferred carrier(s) for the listed number(s) and service(s), to
                obtain any information Clerk Chat Inc. deems necessary to make
                the carrier change(s), including, for example, an inventory of
                telephone lines billed to the telephone number(s), carrier or
                customer identifying information, billing addresses, and my
                credit history.
            </Typography>
        }
    />
);

export const HostDisclaimer = () => (
    <CollapsibleTextSection
        visibleComponent={
            <Typography variant="body4" color="primary.dark" component="div">
                By checking the box below next to the words “I agree to the
                terms and conditions”, you represent and warrant that: (1) if
                you are accessing and using our services on behalf of an
                organization (such as your employer), you have the authority to
                bind that entity, (2) you are at least 18 years of age; (3) the
                name and address you have provided is the name and address on
                record with the telephone company is the name and address on
                record with the telephone company for each number provided; (4)
                you have full authority and approval to SMS-enable the telephone
                number(s) provided in conjunction with the services provided by
                Clerk Chat Inc.; and (5) that your use, and Clerk Chat Inc’s
                use, of such telephone number(s) is not in violation of any
                third party licenses, terms, conditions, laws, rules and/or
                regulations respecting the use of such telephone number(s),
                including any company or workplace policies regarding the use of
                such telephone number(s).
            </Typography>
        }
        hiddenComponent={
            <Typography variant="body4" color="primary.dark">
                <br />
                You also authorize Clerk Chat Inc. or its designated agent to
                act on your behalf and SMS enable the phone number(s) provided,
                and obtain any information Clerk Chat Inc. deems necessary to
                make the carrier change(s), including, for example, an inventory
                of telephone lines billed to the telephone number(s), carrier or
                customer identifying information, and billing addresses.
            </Typography>
        }
    />
);

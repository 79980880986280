import { queryClient } from '../queries/queryClient';
import { COHORTS_KEY, COHORT_KEY } from '../queries/cohorts';
import { CohortMeta } from '../api/types';

type DataPayload = {
    id: string;
    contactsCount: number;
};

export const COHORT_UPDATED = 'cohort:updated';

export const handleCohorUpdatedEvent = async ({
    id,
    contactsCount,
}: DataPayload) => {
    const cohortId = Number(id);
    const cohorts = queryClient.getQueryData([COHORTS_KEY]);

    if (cohorts) {
        queryClient.setQueryData([COHORTS_KEY], (cachedCohorts: CohortMeta[]) =>
            cachedCohorts.map((cohort) => {
                if (cohort.id === cohortId) {
                    return {
                        ...cohort,
                        contactsCount,
                    };
                }
                return cohort;
            }),
        );
    }

    await queryClient.refetchQueries({
        queryKey: [COHORT_KEY, cohortId],
    });
};

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const GEFlagIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="ic_gr">
                <path
                    id="Vector"
                    d="M22.2026 4.13794H1.79742C0.804703 4.13794 0 4.94264 0 5.93536V9.37931H24V5.93536C24 4.94264 23.1953 4.13794 22.2026 4.13794Z"
                    fill="#464655"
                />
                <path
                    id="Vector_2"
                    d="M0 18.0646C0 19.0573 0.804703 19.862 1.79742 19.862H22.2026C23.1952 19.862 24 19.0573 24 18.0646V14.6207H0V18.0646Z"
                    fill="#FFE15A"
                />
                <path
                    id="Vector_3"
                    d="M24 9.37921H0V14.6203H24V9.37921Z"
                    fill="#FF4B55"
                />
            </g>
        </svg>
    </SvgIcon>
);

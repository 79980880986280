import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const DiscoverIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
            />
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#EAEDF2"
            />
            <g clipPath="url(#clip0_1963_77934)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.00001 9.76944C1.99971 9.6911 2.01127 9.66262 2.09974 9.66392C2.55353 9.67038 3.0078 9.65765 3.46129 9.6718C4.12848 9.69264 4.73473 9.8878 5.22922 10.3609C5.56959 10.6864 5.78351 11.0851 5.86774 11.5475C6.03548 12.4688 5.77673 13.2506 5.07764 13.8756C4.69598 14.2168 4.23865 14.3883 3.73343 14.4292C3.1766 14.4744 2.61817 14.4385 2.06064 14.4507C1.99393 14.4522 2.00136 14.416 2.00142 14.3739C2.00189 13.9023 2.0029 10.5321 2.00001 9.76944ZM2.92829 13.5511C2.92794 13.6159 2.94144 13.6366 3.00974 13.6357C3.19565 13.6333 3.38191 13.6352 3.56669 13.6144C3.90412 13.5764 4.2206 13.4793 4.46761 13.2311C4.88283 12.8141 5.03541 12.3069 4.9201 11.7331C4.80397 11.1551 4.47262 10.7478 3.89804 10.5691C3.61818 10.482 3.32717 10.4875 3.03752 10.4838C2.95501 10.4828 2.9264 10.4992 2.92735 10.5901C2.93248 11.0804 2.93106 13.0544 2.92829 13.5511Z"
                    fill="#231F20"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.1646 12.4442C30.6637 13.1143 31.1549 13.7738 31.658 14.4493C31.5796 14.4493 31.5184 14.4493 31.4572 14.4493C31.1766 14.4493 30.8958 14.4449 30.6153 14.4519C30.5303 14.454 30.4875 14.4222 30.4435 14.3559C30.0544 13.7705 29.662 13.1873 29.2706 12.6035C29.2654 12.5956 29.259 12.5881 29.2554 12.5795C29.2318 12.5212 29.1809 12.525 29.1359 12.5343C29.0861 12.5446 29.1099 12.5914 29.1098 12.6209C29.1083 13.1939 29.1061 13.7669 29.1112 14.3398C29.112 14.4351 29.0838 14.4536 28.9956 14.4514C28.7498 14.4451 28.5037 14.4474 28.2578 14.4504C28.1958 14.4513 28.1707 14.44 28.1707 14.3693C28.1728 12.8276 28.1725 11.2859 28.1715 9.74427C28.1715 9.69199 28.177 9.66374 28.2414 9.66517C28.7708 9.67624 29.3008 9.64397 29.8296 9.6847C30.1969 9.71301 30.5422 9.81272 30.8246 10.064C31.1632 10.3655 31.2586 10.7611 31.2356 11.1968C31.2017 11.8376 30.8538 12.2608 30.234 12.4185C30.2132 12.4238 30.1933 12.4334 30.1646 12.4442ZM29.1087 11.1479C29.1087 11.3596 29.1125 11.5714 29.1067 11.7829C29.1046 11.8571 29.1281 11.8717 29.1959 11.8689C29.3341 11.8632 29.4723 11.8662 29.6105 11.8516C30.0151 11.8087 30.2453 11.574 30.2753 11.1671C30.2995 10.8394 30.1327 10.5916 29.8223 10.4894C29.6181 10.4222 29.4075 10.4296 29.1971 10.4254C29.1284 10.4241 29.105 10.4391 29.1069 10.5129C29.1123 10.7245 29.1087 10.9362 29.1087 11.1479Z"
                    fill="#231F20"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.9308 9.75941C24.9306 9.68764 24.9451 9.6642 25.0222 9.66449C25.8419 9.66763 26.6616 9.66716 27.4813 9.66503C27.5451 9.66485 27.5682 9.6774 27.5667 9.7468C27.5621 9.96825 27.5621 10.1899 27.5667 10.4114C27.5681 10.4782 27.5434 10.4871 27.486 10.4869C26.9784 10.4849 26.4708 10.4881 25.9632 10.4834C25.8769 10.4827 25.8597 10.5079 25.8612 10.5892C25.8663 10.8771 25.8657 11.1653 25.8614 11.4532C25.8603 11.5251 25.8804 11.5426 25.9508 11.5421C26.4394 11.539 26.9281 11.5425 27.4168 11.5384C27.494 11.5378 27.5088 11.5616 27.5073 11.6328C27.5027 11.8448 27.5028 12.0569 27.5073 12.2689C27.5087 12.3359 27.4897 12.3519 27.4242 12.3515C26.9386 12.3486 26.4531 12.3526 25.9677 12.3475C25.8771 12.3466 25.8604 12.3753 25.8613 12.4586C25.8657 12.8194 25.8651 13.1802 25.8616 13.5411C25.8608 13.6128 25.8757 13.6364 25.9529 13.6359C26.4604 13.6319 26.968 13.6357 27.4756 13.6319C27.5514 13.6314 27.5684 13.653 27.5668 13.7254C27.562 13.9437 27.5635 14.1622 27.5661 14.3806C27.5668 14.4329 27.554 14.4507 27.4987 14.4505C26.6664 14.4488 25.8341 14.4482 25.0017 14.4513C24.926 14.4516 24.932 14.4144 24.932 14.3647C24.9326 13.5923 24.9334 10.5222 24.9308 9.75941Z"
                    fill="#231F20"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.21857 13.0673C8.34396 13.3028 8.5094 13.4946 8.75293 13.602C9.01468 13.7175 9.28416 13.7577 9.55588 13.6358C9.80277 13.525 9.93613 13.3274 9.94674 13.0583C9.9573 12.7917 9.81522 12.6131 9.5852 12.4978C9.32079 12.3653 9.03485 12.2866 8.76467 12.1688C8.50061 12.0536 8.24906 11.9204 8.06676 11.6846C7.95534 11.5405 7.89353 11.3758 7.87195 11.1994C7.77569 10.4139 8.20418 9.8521 8.85414 9.66748C9.533 9.47463 10.1444 9.64409 10.692 10.0762C10.7325 10.1081 10.7463 10.1306 10.7076 10.1793C10.5682 10.3546 10.4319 10.5326 10.3001 10.7137C10.26 10.7688 10.2426 10.7532 10.2061 10.7141C10.0571 10.5545 9.88611 10.4252 9.66452 10.3934C9.38484 10.3533 9.12026 10.3892 8.92556 10.622C8.75151 10.83 8.79015 11.0921 9.01002 11.2489C9.20684 11.3892 9.43621 11.4599 9.65815 11.546C9.86624 11.6268 10.0695 11.717 10.264 11.8277C10.6609 12.0536 10.879 12.385 10.9074 12.8493C10.9554 13.6333 10.5448 14.2954 9.78013 14.4927C8.96425 14.7031 8.16708 14.47 7.64369 13.6944C7.62211 13.6623 7.61975 13.6436 7.64947 13.6153C7.83898 13.4349 8.02671 13.2525 8.21857 13.0673Z"
                    fill="#231F20"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.9396 9.66647C20.263 9.66647 20.5719 9.66854 20.8807 9.66469C20.9425 9.66392 20.9666 9.68619 20.9879 9.74048C21.2722 10.4629 22.0912 12.5315 22.217 12.8507C22.2745 12.7871 23.0928 10.7034 23.4786 9.73723C23.494 9.69868 23.505 9.66469 23.5611 9.66517C23.8762 9.66795 24.1913 9.66653 24.5197 9.66653C24.4448 9.84724 23.0952 13.0619 22.4946 14.4973C22.4708 14.5542 22.4445 14.5728 22.3854 14.5705C22.2658 14.566 22.1458 14.5663 22.0262 14.5705C21.973 14.5724 21.9489 14.5528 21.9291 14.5047C21.559 13.6034 20.2603 10.4536 19.9828 9.77938C19.9685 9.74492 19.9561 9.70975 19.9396 9.66647Z"
                    fill="#231F20"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8721 10.9597C14.6964 10.791 14.528 10.6492 14.3268 10.5558C13.5352 10.1883 12.5823 10.583 12.2772 11.4258C12.0422 12.075 12.1204 12.6923 12.5926 13.2195C12.9083 13.572 13.3254 13.7006 13.7906 13.6956C14.1596 13.6918 14.4649 13.5411 14.7348 13.3017C14.7746 13.2665 14.813 13.2298 14.8516 13.1941C14.8852 13.2125 14.8713 13.241 14.8714 13.2632C14.8724 13.5766 14.8715 13.89 14.8727 14.2034C14.8729 14.2509 14.8659 14.2799 14.8145 14.3048C13.8586 14.7669 12.6494 14.584 11.9015 13.8154C11.4387 13.3398 11.2108 12.7657 11.1964 12.101C11.172 10.9764 11.9184 9.99571 12.9568 9.6807C13.5939 9.48744 14.2159 9.52445 14.816 9.82678C14.8482 9.843 14.8734 9.85704 14.8731 9.90186C14.8713 10.2468 14.8721 10.5916 14.8721 10.9597Z"
                    fill="#231F20"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.27385 14.3449C7.27414 14.4235 7.26252 14.4544 7.17299 14.4518C6.92097 14.4444 6.66853 14.4473 6.41634 14.4506C6.35511 14.4514 6.3363 14.4356 6.33636 14.3721C6.33807 12.8306 6.33819 11.289 6.33618 9.74739C6.33606 9.67793 6.35895 9.66426 6.42235 9.66508C6.67142 9.66846 6.92062 9.67018 7.16957 9.66437C7.25202 9.66242 7.27479 9.68231 7.27438 9.76764C7.27066 10.5337 7.27113 13.5852 7.27385 14.3449Z"
                    fill="#231F20"
                />
                <path
                    d="M17.6863 14.5956C19.0769 14.5956 20.2042 13.4599 20.2042 12.0588C20.2042 10.6578 19.0769 9.52203 17.6863 9.52203C16.2956 9.52203 15.1683 10.6578 15.1683 12.0588C15.1683 13.4599 16.2956 14.5956 17.6863 14.5956Z"
                    fill="url(#paint0_linear_1963_77934)"
                />
                <path
                    d="M17.6863 14.5956C19.0769 14.5956 20.2042 13.4599 20.2042 12.0588C20.2042 10.6578 19.0769 9.52203 17.6863 9.52203C16.2956 9.52203 15.1683 10.6578 15.1683 12.0588C15.1683 13.4599 16.2956 14.5956 17.6863 14.5956Z"
                    fill="url(#paint1_linear_1963_77934)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1963_77934"
                    x1="16.6188"
                    y1="9.76058"
                    x2="18.7675"
                    y2="14.3506"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.3831" stopColor="#E45E26" />
                    <stop offset="1" stopColor="#F8A020" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1963_77934"
                    x1="16.4237"
                    y1="9.86344"
                    x2="18.9636"
                    y2="14.2455"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#80391B" />
                    <stop
                        offset="0.1105"
                        stopColor="#843B1B"
                        stopOpacity="0.8161"
                    />
                    <stop
                        offset="0.2346"
                        stopColor="#92401D"
                        stopOpacity="0.6096"
                    />
                    <stop
                        offset="0.3653"
                        stopColor="#A8481F"
                        stopOpacity="0.3922"
                    />
                    <stop
                        offset="0.4996"
                        stopColor="#C75323"
                        stopOpacity="0.1687"
                    />
                    <stop offset="0.601" stopColor="#E45E26" stopOpacity="0" />
                    <stop offset="1" stopColor="#F8A020" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_1963_77934">
                    <rect
                        width="30"
                        height="6"
                        fill="white"
                        transform="translate(2 9)"
                    />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);

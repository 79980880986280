import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography,
} from '@mui/material';
import { colors } from '../../../theme/palette';
import { section } from '../styles';
import { useWorkflows } from '../../../queries/workflows';
import { WorkflowsIcon } from '../../../icons/common/CWorkflows';
import { NavLink } from 'react-router-dom';
import { useTrack } from '../../../contexts/analytics';

type Props = { query: string };

export const WorkflowsList = ({ query = '' }: Props) => {
    const track = useTrack();
    const { data: workflows = [], isLoading } = useWorkflows();
    const filtered = workflows.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase()),
    );
    if (!isLoading && !filtered.length) {
        return null;
    }
    return (
        <Box sx={section}>
            <Typography
                variant="body4"
                sx={{ color: colors.text.secondary, px: 3 }}
            >
                Workflows
            </Typography>
            {isLoading && 'Loading...'}
            {filtered?.map((workflow) => (
                <MenuItem
                    key={workflow.id}
                    component={NavLink}
                    onClick={() => {
                        track('global_search_navigation', {
                            action: 'visit',
                            type: 'workflow',
                            queryLength: query.length,
                        });
                    }}
                    to={`/workflows/${workflow.id}`}
                >
                    <ListItemIcon>
                        <WorkflowsIcon />
                    </ListItemIcon>
                    <ListItemText>{workflow.name}</ListItemText>
                </MenuItem>
            ))}
        </Box>
    );
};

import { SxProps } from '@mui/material';

export const paper: SxProps = {
    border: '1px solid',
    borderColor: 'divider',
    paddingBottom: 2,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'custom.gray.superLight',
};

import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const AddIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0002 6.70834C14.3453 6.70834 14.6252 6.98816 14.6252 7.33334V13.375H20.6668C21.012 13.375 21.2918 13.6548 21.2918 14C21.2918 14.3452 21.012 14.625 20.6668 14.625H14.6252V20.6667C14.6252 21.0118 14.3453 21.2917 14.0002 21.2917C13.655 21.2917 13.3752 21.0118 13.3752 20.6667V14.625H7.3335C6.98832 14.625 6.7085 14.3452 6.7085 14C6.7085 13.6548 6.98832 13.375 7.3335 13.375H13.3752V7.33334C13.3752 6.98816 13.655 6.70834 14.0002 6.70834Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

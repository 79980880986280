import { Skeleton } from '@mui/material';
import { useMessage } from './use-message.query';
import range from 'lodash/range';

export const MessagePreview = ({ id }: { id: number }) => {
    const message = useMessage(id);

    if (message.isPending) {
        return (
            <>
                {range(3).map((key) => (
                    <Skeleton key={key} />
                ))}
            </>
        );
    }

    if (message.isError) {
        return (
            <>
                An error occurred on message fetched. Maybe someone deleted the
                message
            </>
        );
    }

    return message.data.body;
};

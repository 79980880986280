import { Components } from '@mui/material/styles/components';
import { colors, iconClasses, Theme } from '@mui/material';
import { borderRadius } from './border-radius';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(2, 3),
            borderRadius,
            color: theme.palette.primary.dark,

            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        }),
        message: ({ theme }) => ({
            ...theme.typography.body4,
            padding: theme.spacing(1, 0),
            width: '100%',
        }),
        outlinedInfo: ({ theme }) => ({
            backgroundColor: colors.blue[50],
            border: 'none',
            boxShadow: `0px 0px 1px 0px ${theme.palette.info.main}`,
        }),
        standardWarning: ({ theme }) => ({
            color: theme.palette.custom.warning.alert,
            backgroundColor: theme.palette.custom.warning.background,
            boxShadow: '0px 0px 1px 0px #F98C08',
        }),
        icon: ({ theme }) => ({
            alignItems: 'flex-start',
            padding: theme.spacing(1, 4, 0, 0),
            marginRight: 0,
            [`${iconClasses.root}`]: {
                width: 20,
                height: 20,
            },
        }),
        standardInfo: ({ theme }) => ({
            color: theme.palette.primary.dark,
        }),
        standardError: ({ theme }) => ({
            color: theme.palette.primary.dark,
            backgroundColor: colors.red[50],
            boxShadow: `0px 0px 1px 0px ${theme.palette.error.main}`,
        }),
    },
};

import { SetStateAction, useEffect } from 'react';
import { useTrack } from '../../contexts/analytics';

export const useHotkeys = (
    setOpen: (value: SetStateAction<boolean>) => void,
) => {
    const track = useTrack();

    useEffect(() => {
        function keyHandler(this: Document, e: KeyboardEvent) {
            if (e.code === 'KeyK' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                setOpen((p) => !p);
                track('global_search_toggled_with_hotkey');
            }
        }

        window.document.addEventListener('keydown', keyHandler);

        return () => {
            window.document.removeEventListener('keydown', keyHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

import {
    Box,
    ClickAwayListener,
    Divider,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import * as styles from './styles';

type Props = {
    header: React.ReactNode;
    children: React.ReactNode;
    actions?: React.ReactNode;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    maxContentHeight?: number;
    disableContentPadding?: boolean;
    width?: number;
};

export function ToolbarPopper({
    header,
    children,
    actions,
    handleClose,
    anchorEl,
    maxContentHeight,
    width = 320,
    disableContentPadding = false,
}: Props) {
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Popper open anchorEl={anchorEl} placement="bottom-end">
                <Paper sx={styles.getPaperStyle(width)} elevation={5}>
                    <Box sx={styles.header}>
                        {typeof header === 'string' ? (
                            <Typography
                                variant="body3"
                                fontWeight={500}
                                color="primary.dark"
                            >
                                {header}
                            </Typography>
                        ) : (
                            header
                        )}
                    </Box>
                    <Divider sx={styles.divider} />
                    <Box
                        sx={{
                            ...(disableContentPadding ? {} : styles.content),
                            overflowY: 'auto',
                            maxHeight: maxContentHeight ?? 'auto',
                        }}
                    >
                        {children}
                    </Box>
                    {!!actions && (
                        <>
                            <Divider sx={styles.divider} />
                            <Box sx={styles.actions}>{actions}</Box>
                        </>
                    )}
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
}

import { Box } from '@mui/material';
import { ArrowDropDownFilled } from '../../icons/common/CArrowDropDownFilled';
import { colors } from '../../theme/palette';
import { getSquare } from '../../theme/style.helpers';
import { CArrowDropRightFilled } from '../../main/settings/KnowledgeBase/List/CArrowDropRightFilledIcon';

type Props = {
    open: boolean;
    variant?: 'standard' | 'info';
    disabled?: boolean;
};

export const ToggleButton = ({ open = false, variant = 'standard' }: Props) => (
    <Box
        sx={{
            ...getSquare(20),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            boxShadow: open ? 1 : 0,
            backgroundColor: open
                ? variant === 'info'
                    ? colors.primary.white
                    : 'custom.action.selected'
                : 'none',
            transition: 'background-color 0.2s',
            '&:hover': {
                '& svg': { color: colors.blue[500] },
                backgroundColor: 'custom.action.selected',
            },
            '& svg': {
                width: 20,
                height: 20,
            },
        }}
    >
        {open ? (
            <ArrowDropDownFilled
                data-testid="toggle-open"
                sx={{ color: colors.blue[500] }}
            />
        ) : (
            <CArrowDropRightFilled
                data-testid="toggle-close"
                sx={{
                    color: colors.superGrey[500],
                }}
            />
        )}
    </Box>
);

import styles from './WorkflowPlot.module.scss';
import WorkflowTree from '../WorkflowTree';
import { useCallback, useEffect } from 'react';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import Icon from '../../../icons/Icon';

export const WorkflowPlot = () => {
    const {
        state: { selectedStepId },
        dispatch,
    } = useWorkflowContext();

    useEffect(
        () => () =>
            dispatch({
                type: WorkflowReducerActionTypes.RESET,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleDeleteItem = useCallback(() => {
        if (selectedStepId) {
            dispatch({
                type: WorkflowReducerActionTypes.DELETE_WORKFLOW_STEP,
            });
        }
    }, [selectedStepId, dispatch]);

    return (
        <div className={styles['workflow-plot']}>
            {selectedStepId && (
                <div className={styles['workflow-plot__toolbar']}>
                    <button
                        className={styles['workflow-plot__toolbar-button']}
                        onClick={handleDeleteItem}
                    >
                        <Icon name="delete" />
                    </button>
                </div>
            )}

            <div className={styles['workflow-plot__wrapper']}>
                <WorkflowTree />
            </div>
        </div>
    );
};

import { Box, ButtonBase, SxProps, Theme, Typography } from '@mui/material';
import { Conversation, Feature } from '../../../../api/types';
import { AdapterLink } from '../../../../components/ConversationsList/AdapterLink';

import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import { TimeAgo } from '../TimeAgo';
import { DotDivider } from '../../../../components/common/DotDivider';
import { useTrack } from '../../../../contexts/analytics';
import { base } from './conversation-item.styles';
import { useEnabledFeature } from '../../../../queries/user';
import {
    useConversationAvatar,
    useConversationName,
} from '../../../../components/Inbox/Conversation/use-conversation-props';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';

type Props = ButtonBaseProps & {
    conversation: Conversation;
    sx?: SxProps<Theme>;
};

export const ConversationItem = ({
    conversation,
    sx = {},
    ...props
}: Props) => {
    const track = useTrack();
    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);
    const avatar = useConversationAvatar(conversation.members);
    const recipient = useConversationName(conversation.members);

    return (
        <ButtonBase
            data-testid="conversation-item"
            onClick={() => track('campaign_message_conversation_visit')}
            LinkComponent={AdapterLink}
            href={
                isNewInboxEnabled
                    ? `/inbox/${conversation.inboxId}/conversations/${conversation.id}`
                    : `/inbox/${conversation.inboxId}/${conversation.id}`
            }
            sx={{
                ...base,
                ...sx,
            }}
            {...props}
        >
            <Box display="flex" gap={2} width={1}>
                <UserAvatar size={20} imageUrl={avatar} username={recipient} />
                <div>
                    <Box display="flex" alignItems="center">
                        <Typography fontWeight={500} variant="body4">
                            {recipient}
                        </Typography>
                        <DotDivider />
                        {conversation.lastMessageSent && (
                            <TimeAgo
                                sx={{ color: 'custom.gray.super' }}
                                date={conversation.lastMessageSent}
                            />
                        )}
                    </Box>
                    <Box mt={1}>{conversation.lastMessageBody}</Box>
                </div>
            </Box>
        </ButtonBase>
    );
};

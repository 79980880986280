import cc from 'classcat';
import styles from './WorkflowAside.module.scss';
// assets
import dragIcon from '../../../assets/icons/icons-16/different/drag.svg';
import { useState } from 'react';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import { WorkflowActionTypes, WorkflowConditionTypes } from '../types';
import { STEP_ICON_MAP, STEP_LABEL_MAP } from '../utils';
import Icon from '../../../icons/Icon';

interface WorkflowAsideItemProps {
    label: WorkflowActionTypes | WorkflowConditionTypes;
    disabled?: boolean;
}

export const WorkflowAsideItem = ({
    label,
    disabled,
}: WorkflowAsideItemProps) => {
    const { dispatch } = useWorkflowContext();
    const [isDragging, setDragging] = useState(false);

    const dragStartHandler = (e: React.DragEvent<HTMLLIElement>) => {
        if (e.dataTransfer) {
            e.dataTransfer.setData('text/plain', label);
            e.dataTransfer.dropEffect = 'none';
        }
        setDragging(true);
        dispatch({
            type: WorkflowReducerActionTypes.SET_SHOW_DROP_SPOTS,
            payload: true,
        });
    };
    const dragStopHandler = () => {
        setDragging(false);
        dispatch({
            type: WorkflowReducerActionTypes.SET_SHOW_DROP_SPOTS,
            payload: false,
        });
    };

    return (
        <li
            className={cc([
                styles['aside__box-item'],
                {
                    [styles['aside__box-item_disabled']]: disabled,
                    [styles['aside__box-item_dragging']]: isDragging,
                },
            ])}
            onDragStart={dragStartHandler}
            onDragEnd={dragStopHandler}
            draggable={!disabled}
        >
            <Icon
                size="16px"
                className={styles['aside__icon']}
                name={STEP_ICON_MAP[label]}
            />
            <span className={styles['aside__box-item-text']}>
                {STEP_LABEL_MAP[label]}
            </span>
            <img src={dragIcon} />
        </li>
    );
};

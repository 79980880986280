import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const BlockIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 1.56669C5.34963 1.56669 1.56667 5.34994 1.56667 10.0004C1.56706 14.6503 5.35017 18.4334 10 18.4334C14.6502 18.4334 18.4333 14.6504 18.4333 10.0003C18.4333 5.34994 14.6502 1.56669 10 1.56669ZM10 2.92703C11.6398 2.92703 13.1505 3.48824 14.3515 4.42846L4.42849 14.3516C3.48842 13.1506 2.92714 11.6399 2.927 10.0003C2.927 6.10021 6.10005 2.92703 10 2.92703ZM10 17.073C8.22886 17.073 6.60803 16.4183 5.36565 15.3384L15.3382 5.36564C16.4182 6.60804 17.0729 8.22904 17.0729 10.0004C17.0729 13.9001 13.8999 17.073 10 17.073Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

import { ConnectEmailIntegrationRequest } from '../../../../api/integrations';
import { client } from '../../../../api/http';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { queryClient } from '../../../../queries/queryClient';
import { INTEGRATIONS_LIST_QUERY_KEY } from '../../../../queries/integrations';

export const useSetupEmailIntegration = () => {
    return useMutation<unknown, AxiosResponse, ConnectEmailIntegrationRequest>({
        mutationFn: ({ source, email }: ConnectEmailIntegrationRequest) =>
            client
                .post(`integrations/setup/email`, { source, email })
                .then(({ data }) => data),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });
};

import { useEffect, useMemo, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import EmailStep from './EmailStep';
import CodeStep from './CodeStep';
import TeamStep from './TeamStep';
import { getStepContent, Step } from './utils';
import { useTeams } from 'msteams-react-base-component';
import { signInViaTeams } from '../../api/auth';
import Loading from '../Loading/Loading';
import { getMe } from '../../api/user';
import { queryClient } from '../../queries/queryClient';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { CURRENT_USER_KEY } from '../../queries/user';

export interface LoginFormProps {
    nextPage: string | null;
}

export const LoginForm = ({ nextPage }: LoginFormProps) => {
    const [step, setStep] = useState<Step>(Step.Email);
    const [email, setEmail] = useState('');
    const [loggingInWithTeams, setLoggingInWithTeams] =
        useState<boolean>(false);
    const [methodId, setMethodId] = useState('');
    const [{ inTeams }] = useTeams();
    const navigate = useNavigate();

    useEffect(() => {
        if (inTeams) {
            setLoggingInWithTeams(true);
            microsoftTeams.authentication
                .getAuthToken()
                .then(async (res) => {
                    await signInViaTeams(res);
                    const me = await getMe();
                    queryClient.setQueryData([CURRENT_USER_KEY], me);
                    navigate('/');
                    setLoggingInWithTeams(false);
                })
                .catch((e) => {
                    setLoggingInWithTeams(false);
                    throw new Error(e);
                });
        }
    }, [inTeams, navigate]);

    const content = useMemo(() => getStepContent(step), [step]);

    return loggingInWithTeams ? (
        <Loading />
    ) : (
        <Box component="section" textAlign="center">
            <div>
                <Box
                    display="flex"
                    justifyContent="center"
                    mb={4}
                    fontSize={48}
                    height={48}
                    lineHeight="48px"
                >
                    {content.icon}
                </Box>
                <Typography
                    variant="h1"
                    mb={2}
                    align="center"
                    color="primary.dark"
                >
                    {content.header}
                </Typography>
                <Typography
                    variant="body3"
                    align="center"
                    color="primary.dark"
                    display="block"
                    mb={10}
                >
                    {content.subheader}
                </Typography>
            </div>
            {step === Step.Email && (
                <EmailStep
                    email={email}
                    setEmail={setEmail}
                    setMethodId={setMethodId}
                    next={() => setStep(Step.Code)}
                />
            )}

            {step === Step.Code && (
                <CodeStep
                    methodId={methodId}
                    email={email}
                    setMethodId={setMethodId}
                    next={() => setStep(Step.Team)}
                />
            )}

            {step === Step.Team && <TeamStep nextPage={nextPage} />}
        </Box>
    );
};

export default LoginForm;

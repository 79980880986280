import { SxProps } from '@mui/material';

export const container: SxProps = {
    display: 'grid',
    minHeight: '100vh',
    flex: 1,
    gridTemplateRows: '61px 1fr auto',
};
export const history: SxProps = {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    p: [2, 4, 6],
    maxHeight: 1,
    height: 1,
};

import {
    Box,
    Button,
    CircularProgress,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { CopyIcon } from '../../../icons/common/CopyIcon';
import { useTeamWidgetKey } from '../../../queries/team';

export const Widget = () => {
    const { data: key, isLoading } = useTeamWidgetKey();

    return (
        <SettingsLayout
            title="Clerk Widget"
            category="Data"
            description={
                <>
                    Collect leads on your website by using the Clerk widget.{' '}
                    <a
                        href="https://clerk.chat/tools/form-builder"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Learn more
                    </a>
                    .
                </>
            }
        >
            <Stack spacing={4}>
                {isLoading && <CircularProgress />}
                {key && (
                    <>
                        <Typography variant="body3">
                            Use this key in the form builder to capture contacts
                            on your website
                        </Typography>
                        <Box gap={2} display="flex">
                            <TextField
                                value={key}
                                type="password"
                                sx={{ flex: 1 }}
                            />
                            <Tooltip title="Copy" arrow>
                                <Button
                                    variant="outlined"
                                    sx={{ p: 1.7, minWidth: 'auto' }}
                                    onClick={() =>
                                        navigator.clipboard.writeText(key)
                                    }
                                >
                                    <CopyIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                    </>
                )}
            </Stack>
        </SettingsLayout>
    );
};

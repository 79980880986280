import React, { useRef, useState } from 'react';
import {
    Button,
    ButtonProps,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    SxProps,
    Typography,
} from '@mui/material';
import * as styles from './styles';

type GenericConfirmDialogProps = {
    onConfirm: (() => Promise<void>) | (() => void);
    onSubmit?: undefined;
};

type GenericFormDialogProps = {
    onSubmit: React.FormEvent<HTMLFormElement>;
    onConfirm?: undefined;
};

type GenericDialogProps = {
    IconComponent?: React.ElementType<{ sx?: SxProps }>;
    title: React.ReactNode;
    description: React.ReactNode;
    onClose: (() => Promise<void>) | (() => void);
    open: boolean;
    dismissButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
    dismissLabel?: React.ReactNode;
    confirmLabel?: React.ReactNode;
    confirmButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
    children?: React.ReactNode;
    sx?: SxProps;
} & (GenericConfirmDialogProps | GenericFormDialogProps);

export function GenericDialog({
    onSubmit,
    onClose,
    onConfirm,
    IconComponent,
    title,
    description,
    children,
    open,
    confirmButtonProps,
    dismissButtonProps,
    confirmLabel = 'Submit',
    dismissLabel = 'Dismiss',
    sx = styles.dialogContent,
}: GenericDialogProps) {
    const formElementRef = useRef<HTMLFormElement>(null);
    const [confirming, setConfirming] = useState(false);
    const [canceling, setCanceling] = useState(false);

    const modalContent = (
        <DialogContent sx={sx}>
            {!!IconComponent && <IconComponent sx={styles.icon} />}

            <Typography variant="h3" color="primary.dark" sx={styles.title}>
                {title}
            </Typography>
            <Typography variant="body3" color="custom.gray.super">
                {description}
            </Typography>

            {!!children && (
                <Stack spacing={4} sx={styles.content}>
                    {children}
                </Stack>
            )}
        </DialogContent>
    );

    const hasForm = typeof onSubmit === 'function';
    const hasConfirm = typeof onConfirm === 'function';
    const handleClose = async () => {
        setCanceling(true);
        await onClose();
        setCanceling(false);
    };

    const modalActions = (
        <DialogActions>
            <Button
                key="dissmiss"
                variant="outlined"
                sx={styles.dissmissButton}
                onClick={handleClose}
                disabled={canceling || confirming}
                {...dismissButtonProps}
            >
                {canceling ? <CircularProgress size={16} /> : dismissLabel}
            </Button>
            <Button
                key="confirm"
                type={hasForm ? 'submit' : 'button'}
                sx={styles.submitButton}
                variant="contained"
                disabled={canceling || confirming}
                onClick={
                    hasConfirm
                        ? async () => {
                              setConfirming(true);
                              await onConfirm();
                              setConfirming(false);
                          }
                        : undefined
                }
                {...confirmButtonProps}
            >
                {confirming ? <CircularProgress size={16} /> : confirmLabel}
            </Button>
        </DialogActions>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            {hasForm ? (
                <form onSubmit={onSubmit} ref={formElementRef}>
                    {modalContent}
                    {modalActions}
                </form>
            ) : (
                <>
                    {modalContent}
                    {modalActions}
                </>
            )}
        </Dialog>
    );
}

import { Tab as MaterialTab, TabProps } from '@mui/material';
import cc from 'classcat';
import omit from 'lodash/omit';

/** Mui Tab have limited extend functionality */
export const Tab = (props: TabProps & { color: 'info' }) => (
    <MaterialTab
        {...omit(props, 'color')}
        className={cc([
            props.className,
            props.color && `MuiTab-color-${props.color}`,
        ])}
    />
);

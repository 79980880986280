import { useUpdateTeam } from '../queries/team';
import { useOnboardingData } from './useOnboardingData';

export const useUpdateOnboardingTeams = () => {
    const { mutate: updateTeam } = useUpdateTeam();
    const { onboarding } = useOnboardingData();

    const submitTeamsOnboarding = () => {
        updateTeam({
            onboarding: {
                ...onboarding,
                teamsLicense: true,
            },
        });
    };

    return submitTeamsOnboarding;
};

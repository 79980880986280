import { TextField, Typography } from '@mui/material';
import { GenericDialog } from '../GenericDialog/GenericDialog';
import { IntegrationIcon } from '../../../icons/shared/IntegrationIcon';
import { useForm } from 'react-hook-form';
import { AvailableIntegration } from '../../../main/settings/Integrations/integrations-list';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';
import {
    INTEGRATIONS_LIST_QUERY_KEY,
    useCreateAzureIntegration,
} from '../../../queries/integrations';
import { queryClient } from '../../../queries/queryClient';
import { useEffect } from 'react';

type Props = {
    open: boolean;
    onClose: () => void;
    integration: AvailableIntegration;
};

type Inputs = {
    storageContainer: string;
    storageAccount: string;
    storageAccessKey: string;
};

export function ConnectAzureIntegrationModal({ onClose, open }: Props) {
    const {
        handleSubmit,
        formState: { errors, isValid },
        register,
        reset: resetForm,
    } = useForm<Inputs>({
        defaultValues: {
            storageContainer: '',
            storageAccount: '',
            storageAccessKey: '',
        },
    });

    const { mutateAsync, isError, reset } = useCreateAzureIntegration();

    useEffect(() => {
        reset();
        resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <GenericDialog
            open={open}
            onClose={onClose}
            hasForm={true}
            IconComponent={IntegrationIcon}
            title="Connect Microsoft Azure"
            description="Keep your conversations up-to-date in an Azure Storage Bucket"
            onSubmit={handleSubmit((formData: Inputs) => {
                mutateAsync(formData)
                    .then(() =>
                        queryClient.refetchQueries({
                            queryKey: INTEGRATIONS_LIST_QUERY_KEY,
                        }),
                    )
                    .then(() => {
                        !isError && onClose();
                    });
            })}
            confirmLabel="Connect"
            confirmButtonProps={{
                disabled: !isValid,
            }}
            dismissButtonProps={{
                startIcon: <ArrowLeftIcon />,
                color: 'primary',
            }}
            dismissLabel="Back"
        >
            <TextField
                {...register('storageContainer', {
                    required: true,
                    minLength: 5,
                })}
                error={!!errors.storageContainer}
                fullWidth
                placeholder="Storage Bucket Name"
                label="Enter Storage Bucket Name"
                helperText={
                    !!errors.storageContainer &&
                    'Storage Bucket Name field is required'
                }
            />
            <TextField
                {...register('storageAccount', {
                    required: true,
                    minLength: 5,
                })}
                error={!!errors.storageAccount}
                fullWidth
                type="password"
                placeholder="Storage Account Name"
                label="Enter your storage Account Name"
                helperText={
                    !!errors.storageAccount &&
                    'Storage Bucket Account Name is required'
                }
            />
            <TextField
                {...register('storageAccessKey', {
                    required: true,
                    minLength: 5,
                })}
                error={!!errors.storageAccessKey}
                fullWidth
                type="password"
                placeholder="Storage Access Key"
                label="Enter your storage access key"
                helperText={
                    !!errors.storageAccessKey &&
                    'Storage Bucket Access Key is required'
                }
            />
            {isError && (
                <Typography color="error" variant="body3">
                    Invalid Credentials. Please double check your credentials
                    and try again
                </Typography>
            )}
        </GenericDialog>
    );
}

import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useGoToQueryParam } from '../../query.helpers';
import { QueryParam } from '../../query.params';
import { CommentsIcon } from '../../../../icons/common/CCommentsIcon';
import { useCreateDiscussion } from '../../../../queries/comments';
import { UUID } from '../../../../types/uuid';
import { useTrack } from '../../../../contexts/analytics';

type Props = {
    conversationId: UUID;
    messageId: number;
    discussionId?: number;
};
export const MessageDiscussionButton = ({
    conversationId,
    messageId,
    discussionId,
}: Props) => {
    const track = useTrack();
    const goToQueryParam = useGoToQueryParam();
    const discussionCreation = useCreateDiscussion(conversationId);

    const showDiscussion = () => {
        track('discussion_button_clicked');
        if (discussionId) {
            return goToQueryParam(
                {
                    [QueryParam.DiscussionId]: `${discussionId}`,
                },
                [QueryParam.ContactInfo],
            );
        }
        discussionCreation
            .mutateAsync({
                messageId,
            })
            .then((createdDiscussion) => {
                goToQueryParam(
                    {
                        [QueryParam.DiscussionId]: `${createdDiscussion.id}`,
                    },
                    [QueryParam.ContactInfo],
                );
            })
            // eslint-disable-next-line no-console
            .catch(console.log);
    };

    return (
        <Tooltip title="Show comments">
            <span>
                <IconButton
                    disabled={discussionCreation.isPending}
                    color="primary"
                    data-testid="message-popover-discussion-btn"
                    onClick={showDiscussion}
                >
                    {discussionCreation.isPending ? (
                        <CircularProgress size={20} />
                    ) : (
                        <CommentsIcon />
                    )}
                </IconButton>
            </span>
        </Tooltip>
    );
};

import { memo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { CloseIcon } from '../../../../../icons/shared/CloseIcon';

type Props = { handleCancel: () => void };

export const CancelButton = memo(function CancelButton({
    handleCancel,
}: Props) {
    return (
        <Tooltip arrow title="Cancel edit">
            <IconButton onClick={handleCancel}>
                <CloseIcon />
            </IconButton>
        </Tooltip>
    );
});

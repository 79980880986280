import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const MastercardIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
            />
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#EAEDF2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 17.3278C15.8349 18.3705 14.3236 19 12.6721 19C8.98721 19 6 15.866 6 12C6 8.13401 8.98721 5 12.6721 5C14.3236 5 15.8349 5.62949 17 6.67223C18.1651 5.62949 19.6764 5 21.3279 5C25.0128 5 28 8.13401 28 12C28 15.866 25.0128 19 21.3279 19C19.6764 19 18.1651 18.3705 17 17.3278Z"
                fill="#ED0006"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9999 17.3278C18.4345 16.0439 19.3442 14.1334 19.3442 12C19.3442 9.8666 18.4345 7.9561 16.9999 6.67217C18.165 5.62946 19.6763 5 21.3277 5C25.0126 5 27.9998 8.13401 27.9998 12C27.9998 15.866 25.0126 19 21.3277 19C19.6763 19 18.165 18.3705 16.9999 17.3278Z"
                fill="#F9A000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 17.3278C18.4346 16.0439 19.3442 14.1334 19.3442 12C19.3442 9.86664 18.4346 7.95617 17 6.67224C15.5654 7.95617 14.6558 9.86664 14.6558 12C14.6558 14.1334 15.5654 16.0439 17 17.3278Z"
                fill="#FF5E00"
            />
        </svg>
    </SvgIcon>
);

import { useCallback, useState } from 'react';
import {
    AddressElement,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import styles from './BillingForm.module.scss';
import LockIcon from '../../../assets/icons/payment-flow/lock.svg?react';
import { useTrack } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';

export const BillingForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState('');
    const navigate = useNavigate();
    const me = useMeQueryData();
    const track = useTrack();

    const dispatchPurchaseEvent = useCallback(() => {
        track('user_clicked_purchase');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        dispatchPurchaseEvent();

        if (!stripe || !elements) {
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.location.origin,
            },
        });

        if (error) {
            setError(error.message || '');
        } else {
            setError('');
            navigate('/payment/finish');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles['root__payment-element']}>
                <PaymentElement
                    options={{
                        paymentMethodOrder: ['card'],
                        wallets: {
                            applePay: 'never',
                            googlePay: 'never',
                        },
                        layout: 'accordion',
                    }}
                />
                <Box mb={2} />
                <AddressElement
                    options={{
                        mode: 'billing',
                    }}
                />

                {error && <p className={styles['root__error']}>{error}</p>}
            </div>

            <button className={styles['root__confirm-btn']} type="submit">
                <LockIcon /> <span>Confirm & pay</span>
            </button>

            <p className={styles['root__security-disclaimer']}>
                Your payment is securely protected with AES 256-bit encryption
            </p>
        </form>
    );
};

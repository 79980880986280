import { Check } from '@mui/icons-material';
import { Box, StepIconProps } from '@mui/material';
import { progressStepIcon } from './styles';
import { getSquare } from '../../theme/style.helpers';

export const ProgressStepIcon = (props: StepIconProps) => {
    const { completed, icon } = props;

    return (
        <Box sx={progressStepIcon(completed)}>
            {completed ? (
                <Check sx={{ ...getSquare(24), color: 'info.contrastText' }} />
            ) : (
                icon
            )}
        </Box>
    );
};

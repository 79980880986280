import { Box } from '@mui/material';
import { EmptyConversation } from '../../../pages/NewInbox/EmptyConversation';

const style = {
    display: 'flex',
    width: 1,
    height: 1,
    justifyContent: 'center',
    alignItems: 'center',
};
export const NoMessages = () => (
    <Box sx={style} typography="h3">
        <EmptyConversation
            title="No messages"
            description="This is the beginning of your conversation"
        />
    </Box>
);

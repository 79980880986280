import { Outlet, useParams } from 'react-router';
import clerklogo from '../../assets/images/clerk-logo.png';
import EmojiTrio from '../../components/EmojiTrio';
import ProgressBar from '../../components/ProgressBar';
import Spacer from '../../components/Spacer';
import styles from './PhoneVerification.module.scss';

export type PhoneVerificationProps = {
    inboxId: string;
    '*':
        | 'customer'
        | 'call'
        | 'address'
        | 'phone-type'
        | 'phone-configuration'
        | 'signature';
};

const steps = [
    'customer',
    'call',
    'address',
    'phone-type',
    'phone-configuration',
    'signature',
];

export const PhoneVerification = () => {
    const { '*': step } = useParams<PhoneVerificationProps>();

    return (
        <div className={styles['root']}>
            <div className={styles['root__pb']}>
                <ProgressBar steps={steps} currentStep={steps.indexOf(step!)} />
            </div>
            <div className={styles['root__header']}>
                <img src={clerklogo} alt="logo" />
                <h1>Clerk Chat Inc</h1>
                <h2>Letter Of Authorization (LOA)</h2>
            </div>
            <Spacer width="auto" height={24} />
            <Spacer width="360px" height="auto">
                <EmojiTrio emoji="🔒" />
            </Spacer>
            <div className={styles['root__body']}>
                <Outlet />
            </div>
        </div>
    );
};

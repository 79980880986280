import React, { useState, createContext, useContext } from 'react';
import { TeamsPhone } from '../../../api/types';

interface SetupMicrosoftTeamsInboxContextProps {
    selectedPhones: TeamsPhone[];
    setSelectedPhones: React.Dispatch<React.SetStateAction<TeamsPhone[]>>;
    setActivationDate: React.Dispatch<React.SetStateAction<Date>>;
    activationDate: Date;
}

export const SetupMicrosoftTeamsInboxContext = createContext<
    SetupMicrosoftTeamsInboxContextProps | undefined
>(undefined);

export const SetupMicrosoftTeamsInboxProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [selectedPhones, setSelectedPhones] = useState<TeamsPhone[]>([]);
    const [activationDate, setActivationDate] = useState<Date>(new Date());
    return (
        <SetupMicrosoftTeamsInboxContext.Provider
            value={{
                selectedPhones,
                setSelectedPhones,
                setActivationDate,
                activationDate,
            }}
        >
            {children}
        </SetupMicrosoftTeamsInboxContext.Provider>
    );
};

export const useSetupMicrosoftTeamsInboxContext = () => {
    const context = useContext(SetupMicrosoftTeamsInboxContext);
    if (!context) {
        throw new Error('useInboxContext must be used within an InboxProvider');
    }
    return context;
};

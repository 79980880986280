import Icon from '../../../icons/Icon';
import { useCreateTeamApiKey, useGetTeamApiKey } from '../../../queries/team';
import styles from './Api.module.scss';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { useTeams } from 'msteams-react-base-component';

export const Api = () => {
    const [{ inTeams }] = useTeams();
    const { data: apiKey, refetch } = useGetTeamApiKey();
    const { mutateAsync: createApiKey } = useCreateTeamApiKey();

    return (
        <SettingsLayout title="API" category="Data">
            <div className={styles['root']}>
                <h6 className={styles['root__title']}>API Key</h6>
                <p className={styles['root__description']}>
                    Pay attention - your old API key will be erased!
                </p>

                <div className={styles['root__controls']}>
                    <TextField
                        fullWidth
                        placeholder="API Key"
                        value={apiKey}
                        type="text"
                        disabled
                    />
                    {apiKey && !inTeams && (
                        <Tooltip title="Copy" arrow>
                            <Button
                                size="small"
                                color="blue"
                                variant="outlined"
                                sx={{ p: 1.7, minWidth: 'auto' }}
                                onClick={() =>
                                    navigator.clipboard.writeText(apiKey)
                                }
                            >
                                <Icon name="copy" />
                            </Button>
                        </Tooltip>
                    )}
                </div>

                <Button onClick={() => createApiKey().then(refetch)} confirm>
                    {apiKey ? 'Generate New Key' : 'Generate'}
                </Button>
            </div>
        </SettingsLayout>
    );
};

import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import {
    createTeamApiKey,
    getCallingStatisticsUsage,
    getMessageUsage,
    getOrCreateWidgetKey,
    getTeamApiKey,
    listTeamsForAuth,
    setupTeam,
    switchTeam,
    updateTeam,
    updateTeamAvatar,
} from '../api/team';
import { queryClient } from './queryClient';
import { CURRENT_USER_KEY } from './user';
import { Profile, Team, TeamStatsFilter } from '../api/types';
import { client } from '../api/http';
import { useTrack } from '../contexts/analytics';

const TEAM_LIST_KEY = 'team_list_key';
const TEAM_WIDGET_KEY = ['team_widget_key'];
const GET_TEAM_API_KEY = 'GET_TEAM_API_KEY';
const TEAM_MESSAGE_USAGE_KEY = 'TEAM_MESSAGE_USAGE_KEY';
const PREVIOUS_TEAM_MESSAGE_USAGE_KEY = 'PREVIOUS_TEAM_MESSAGE_USAGE_KEY';
const TEAM_CALL_USAGE_KEY = 'TEAM_CALL_USAGE_KEY';
const PREVIOUS_TEAM_CALL_USAGE_KEY = 'PREVIOUS_TEAM_CALL_USAGE_KEY';

export function useListTeams() {
    return useQuery({
        queryKey: [TEAM_LIST_KEY],
        queryFn: () => client.get<Team[]>('/teams').then(({ data }) => data),
    });
}

export function useListTeamsForAuth() {
    return useQuery({
        queryKey: ['teams_list_for_auth_key'],
        queryFn: listTeamsForAuth,
    });
}

export const useUpdateTeam = () => {
    const track = useTrack();
    return useMutation({
        mutationFn: updateTeam,
        onSuccess: (updated, params) => {
            track('team_updated');
            queryClient.setQueryData<Profile>([CURRENT_USER_KEY], (prev) => {
                if (!prev?.activeTeam || prev.activeTeam.id !== updated.id) {
                    return prev;
                }

                const activeTeam = {
                    ...prev?.activeTeam,
                    name: params?.name ?? prev?.activeTeam.name,
                    assistantResponseDelaySeconds:
                        updated?.assistantResponseDelaySeconds ??
                        prev?.activeTeam.assistantResponseDelaySeconds,
                    assistantInstruction:
                        params.assistantInstruction ??
                        prev?.activeTeam.assistantInstruction,
                    contactsPublicByDefault:
                        params.contactsPublicByDefault ??
                        prev?.activeTeam.contactsPublicByDefault,
                    whitelistDisabled:
                        params.whitelistDisabled ??
                        prev?.activeTeam.whitelistDisabled,
                    onboarding:
                        params.onboarding ?? prev?.activeTeam.onboarding,
                };
                return {
                    ...prev,
                    activeTeam,
                };
            });
            /*todo: can be optimized*/
            queryClient.refetchQueries({ queryKey: [TEAM_LIST_KEY] });
        },
    });
};

export function useUpdateTeamAvatar() {
    return useMutation({
        mutationFn: updateTeamAvatar,
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
        },
    });
}

export function useSwitchTeamQuery() {
    return useMutation({
        mutationKey: ['team_change_key'],
        mutationFn: switchTeam,
        onMutate: async () => {
            await queryClient.cancelQueries();
        },
    });
}

export function useSetupTeamQuery() {
    return useMutation({
        mutationKey: ['team_setup_key'],
        mutationFn: setupTeam,
        onMutate: async () => {
            await queryClient.cancelQueries();
        },
        onSuccess: async () => {
            await queryClient.fetchQuery({ queryKey: [CURRENT_USER_KEY] });
        },
    });
}

export function useGetTeamApiKey() {
    return useQuery({
        queryKey: [GET_TEAM_API_KEY],
        queryFn: getTeamApiKey,
    });
}

export function useCreateTeamApiKey() {
    return useMutation({
        mutationKey: ['CREATE_TEAM_API_KEY'],
        mutationFn: createTeamApiKey,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [GET_TEAM_API_KEY] });
            await queryClient.refetchQueries({ queryKey: [GET_TEAM_API_KEY] });
        },
    });
}

export function useTeamWidgetKey() {
    return useQuery({
        queryKey: TEAM_WIDGET_KEY,
        queryFn: getOrCreateWidgetKey,
    });
}

export const useTeamMessageUsage = (
    filters: TeamStatsFilter,
    isDisabled: boolean = false,
) =>
    useQuery({
        queryKey: [TEAM_MESSAGE_USAGE_KEY, filters],
        queryFn: () => getMessageUsage(filters),
        placeholderData: keepPreviousData,
        enabled: !isDisabled,
    });

export const useTeamPreviousMessageUsage = (
    filters: TeamStatsFilter | null,
    isDisabled: boolean = false,
) =>
    useQuery({
        queryKey: [PREVIOUS_TEAM_MESSAGE_USAGE_KEY, filters],
        queryFn: () => getMessageUsage(filters!),
        enabled: !!filters && !isDisabled,
    });

export const useTeamCallUsage = (filters: TeamStatsFilter) =>
    useQuery({
        queryKey: [TEAM_CALL_USAGE_KEY, filters],
        queryFn: () => getCallingStatisticsUsage(filters),
        placeholderData: keepPreviousData,
    });

export const useTeamPreviousCallUsage = (filters: TeamStatsFilter | null) =>
    useQuery({
        queryKey: [PREVIOUS_TEAM_CALL_USAGE_KEY, filters],
        queryFn: () => getCallingStatisticsUsage(filters!),
        enabled: !!filters,
    });

import { useMemo, useState } from 'react';
import { useInbox } from '../../queries/inboxes';
import {
    Dialog,
    IconButton,
    ListItemIcon,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import { ArrowBackIcon } from '../../icons/common/CArrowBack';
import { InfoIcon } from '../../icons/common/CInfoIcon';
import { AccessTimeIcon } from '../../components/ContactProperty/PropertyIcon/AccessTimeIcon';
import { UrlIcon } from '../../components/ContactProperty/PropertyIcon/UrlIcon';
import { About } from './AboutTab/About';
import { AttachmentIcon } from '../../components/MessageInput/components/Toolbar/FileUploader/AttachmentIcon';

type InboxSettingsProps = {
    inboxId: string;
    onClose?: () => void;
    open: boolean;
};

const tabTitles = {
    about: {
        label: 'About',
        icon: <InfoIcon />,
        disabled: false,
    },
    outOfOffice: {
        label: 'Out of Office',
        icon: <AccessTimeIcon />,
        disabled: true,
    },
    links: {
        label: 'Links',
        icon: <UrlIcon />,
        disabled: true,
    },
    files: {
        label: 'Files',
        icon: <AttachmentIcon />,
        disabled: true,
    },
} as const;

type Tabs = keyof typeof tabTitles;

export const InboxSettings = (props: InboxSettingsProps) => {
    const { data: inbox } = useInbox(props.inboxId);
    const [tab, setTab] = useState<Tabs>('about');

    const content = useMemo(() => {
        if (!inbox) {
            return null;
        }

        switch (tab) {
            case 'about':
                return <About inbox={inbox} />;
            case 'outOfOffice':
            case 'links':
            case 'files':
            default:
                return null;
        }
    }, [tab, inbox]);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                elevation: 5,
                sx: (theme) => ({
                    width: 888,
                    maxWidth: 888,
                    height: 580,
                    maxHeight: 580,
                    borderRadius: 1.5,

                    [theme.breakpoints.down('md')]: {
                        width: 1,
                        maxWidth: 1,
                        height: 1,
                        maxHeight: 1,
                        margin: 0,
                        borderRadius: 0,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    },
                }),
            }}
        >
            <Stack direction="row" alignItems="start" flex={1}>
                <Stack
                    spacing={4}
                    width={248}
                    height={1}
                    bgcolor="custom.gray.superLight"
                    borderRight="1px solid"
                    borderColor="custom.gray.divider"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        px={4}
                        py={5}
                        borderBottom="1px solid"
                        borderColor="custom.gray.divider"
                        spacing={4}
                    >
                        <IconButton onClick={props.onClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            noWrap
                            variant="body3"
                            fontWeight={500}
                            color="primary.dark"
                        >
                            Inbox Settings
                        </Typography>
                    </Stack>
                    <Stack px={2} spacing={2}>
                        {Object.entries(tabTitles).map(([key, value]) => (
                            <MenuItem
                                selected={tab === key}
                                key={key}
                                onClick={() => setTab(key as Tabs)}
                                disabled={value.disabled}
                            >
                                <ListItemIcon>{value.icon}</ListItemIcon>
                                {value.label}
                            </MenuItem>
                        ))}
                    </Stack>
                </Stack>
                <Stack spacing={6} px={10} py={6} flex={1} height={1}>
                    {content}
                </Stack>
            </Stack>
        </Dialog>
    );
};

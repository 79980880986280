import { SxProps } from '@mui/material';
import bg from './assets/bg.png';

export const container: SxProps = {
    background: `white url(${bg}) no-repeat top left`,
    backgroundSize: '100% auto',
};

export const reviewContainer: SxProps = {
    borderRadius: 4,
    backgroundImage: "url('/sign-in-background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    padding: 4,
};

export const comment: SxProps = {
    padding: 4,
    background: `linear-gradient(
    310.18deg,
    rgba(35, 53, 207, 0.2352) 0.64%,
    rgba(119, 49, 210, 0.4368) 46.74%,
    rgba(213, 217, 255, 0) 104.25%
)`,
    borderRadius: 1.5,
    backdropFilter: 'blur(8px)',
};

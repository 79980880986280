import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const SendOutline = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3014 13.695L20.1025 3.89748M10.58 14.1356L12.8024 18.5804C13.3395 19.6546 13.608 20.1916 13.9463 20.3356C14.2399 20.4606 14.5755 20.438 14.8496 20.2748C15.1656 20.0866 15.3596 19.5184 15.7477 18.3819L19.9468 6.0844C20.2849 5.09415 20.454 4.59902 20.3383 4.27148C20.2376 3.98654 20.0135 3.7624 19.7285 3.66173C19.401 3.54601 18.9059 3.71508 17.9156 4.05321L5.61812 8.25236C4.48163 8.64043 3.91338 8.83447 3.72527 9.15038C3.56202 9.42453 3.5394 9.76013 3.66438 10.0537C3.8084 10.392 4.34547 10.6605 5.41961 11.1976L9.86445 13.42C10.0414 13.5085 10.1299 13.5528 10.2066 13.6119C10.2747 13.6643 10.3357 13.7253 10.3881 13.7934C10.4473 13.8701 10.4915 13.9586 10.58 14.1356Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

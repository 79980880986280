import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import cc from 'classcat';
import styles from './ScheduleStep.module.scss';
import { TimeSelect } from './TimeSelect';
import { TIMEZONES, DAYS_OF_WEEK } from './utils';
import { Schedule, ScheduleSettings, WorkflowCondition } from '../../types';
import { useWorkflowContext } from '../../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../../WorkflowProvider/reducer';
import Button from '../../../../elements/Buttons';
import Input from '../../../../elements/Inputs/Input';
import closeIcon from '../../../../assets/icons/icons-24/gray/close.svg';
import AdvancedSelect from '../../../../elements/AdvancedSelect';
import CheckBox from '../../../../elements/CheckBox/CheckBox';
import Icon from '../../../../icons/Icon';
import { STEP_ICON_MAP } from '../../utils';

interface ScheduleStepProps {
    step: WorkflowCondition;
}

export const ScheduleStep = ({ step }: ScheduleStepProps) => {
    const { dispatch } = useWorkflowContext();
    const [conditionalStep, setStep] = useState(cloneDeep(step));

    useEffect(() => {
        setStep(cloneDeep(step));
    }, [step]);

    const onSaveHandler = () => {
        dispatch({
            type: WorkflowReducerActionTypes.UPDATE_WORKFLOW_STEP,
            payload: conditionalStep,
        });
    };

    const { timezone, schedule = {} as Schedule } = (conditionalStep.settings ||
        {}) as ScheduleSettings;

    return (
        <div className={styles['aside']}>
            <header className={styles['aside__header']}>
                <h3 className={styles['aside__title']}>
                    <Icon name={STEP_ICON_MAP[step.kind]} />
                    <span>Schedule</span>
                </h3>
                <button
                    className={styles['aside__close-btn']}
                    onClick={() =>
                        dispatch({
                            type: WorkflowReducerActionTypes.SET_SELECTED_STEP,
                            payload: null,
                        })
                    }
                >
                    <img src={closeIcon} />
                </button>
            </header>

            <div className={styles['aside__content']}>
                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Item name
                    </label>
                    <Input
                        placeholder="Enter name"
                        value={conditionalStep?.name || ''}
                        onChange={(name) =>
                            setStep({ ...conditionalStep, name })
                        }
                        uncontrolled
                        fullWidth
                    />
                </section>

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Timezone
                    </label>
                    <AdvancedSelect
                        options={TIMEZONES}
                        placeholder="Select timezone"
                        value={
                            TIMEZONES.find((tz) => tz.label === timezone)?.id
                        }
                        onChange={(id) => {
                            const timezone = TIMEZONES.find(
                                (tz) => tz.id === id,
                            )?.label;

                            setStep({
                                ...conditionalStep,
                                settings: {
                                    ...conditionalStep.settings,
                                    timezone,
                                },
                            });
                        }}
                        searchable
                    />
                </section>

                <section className={styles['aside__box']}>
                    <label className={styles['aside__box-label']}>
                        Selected items
                    </label>
                    <ul className={styles['aside__schedules-list']}>
                        {DAYS_OF_WEEK.map((dow) => {
                            const daySchedule = schedule[dow.id];

                            const from = daySchedule
                                ? daySchedule.from
                                : { hours: 0, minutes: 0 };

                            const to = daySchedule
                                ? daySchedule.to
                                : { hours: 0, minutes: 0 };

                            return (
                                <li
                                    key={dow.id}
                                    className={styles['aside__schedule-item']}
                                >
                                    <div
                                        className={cc([
                                            styles['aside__schedule-day'],
                                            {
                                                [styles[
                                                    'aside__schedule-day_disabled'
                                                ]]: !daySchedule,
                                            },
                                        ])}
                                    >
                                        <CheckBox
                                            checked={!!daySchedule}
                                            onChange={(checked) => {
                                                const currentSchedule = {
                                                    ...schedule,
                                                };

                                                if (checked) {
                                                    currentSchedule[dow.id] = {
                                                        from: {
                                                            hours: 0,
                                                            minutes: 0,
                                                        },
                                                        to: {
                                                            hours: 0,
                                                            minutes: 0,
                                                        },
                                                    };
                                                } else {
                                                    delete currentSchedule[
                                                        dow.id
                                                    ];
                                                }

                                                setStep({
                                                    ...conditionalStep,
                                                    settings: {
                                                        ...conditionalStep.settings,
                                                        schedule:
                                                            currentSchedule,
                                                    },
                                                });
                                            }}
                                        />
                                        <span>{dow.label}</span>
                                    </div>
                                    <div
                                        className={
                                            styles['aside__schedule-time']
                                        }
                                    >
                                        <TimeSelect
                                            value={`${from.hours}:${from.minutes}`}
                                            onClick={(value) => {
                                                const currentSchedule = {
                                                    ...schedule,
                                                };

                                                currentSchedule[dow.id].from = {
                                                    hours: value.hours,
                                                    minutes: value.minutes,
                                                };

                                                setStep({
                                                    ...conditionalStep,
                                                    settings: {
                                                        ...conditionalStep.settings,
                                                        schedule:
                                                            currentSchedule,
                                                    },
                                                });
                                            }}
                                            disabled={!daySchedule}
                                        />
                                        <span> - </span>
                                        <TimeSelect
                                            value={`${to.hours}:${to.minutes}`}
                                            onClick={(value) => {
                                                const currentSchedule = {
                                                    ...schedule,
                                                };

                                                currentSchedule[dow.id].to = {
                                                    hours: value.hours,
                                                    minutes: value.minutes,
                                                };

                                                setStep({
                                                    ...conditionalStep,
                                                    settings: {
                                                        ...conditionalStep.settings,
                                                        schedule:
                                                            currentSchedule,
                                                    },
                                                });
                                            }}
                                            disabled={!daySchedule}
                                            direction="left"
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </section>
            </div>

            <div className={styles['aside__button-container']}>
                <Button onClick={onSaveHandler} fullWidth>
                    Save
                </Button>
            </div>
        </div>
    );
};

import { Theme, useMediaQuery } from '@mui/material';

export const HideDownSm = ({
    children,
}: {
    children: React.ReactNode;
}): null | React.ReactNode => {
    const isUpSm = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down('sm'),
    );
    return isUpSm ? null : children;
};

import { useRef, useState } from 'react';
import cc from 'classcat';
import Chip from '../../../elements/Chip';
import {
    useCreateProfanityQuery,
    useDeleteWordFromProfanityQuery,
    useProfanityQuery,
    useUpdateProfanityQuery,
} from '../../../queries/profanity';
import styles from './Profanity.module.scss';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import Button from '@mui/material/Button';

export const Profanity = () => {
    const [newWords, setNewWords] = useState<string[]>([]);
    const [value, setValue] = useState('');

    const { data: profanity } = useProfanityQuery();
    const { mutate: create } = useCreateProfanityQuery();
    const { mutate: update } = useUpdateProfanityQuery();
    const { mutate: deleteWord } = useDeleteWordFromProfanityQuery();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleInput = () => {
        const parsedWords = value
            .split(',')
            .map((word) => word.trim())
            .filter((x) => x);
        setNewWords(newWords.concat(parsedWords));
        setValue('');
    };

    const handleClick = () => {
        if (profanity) {
            const updateSet = new Set(profanity.blackList.concat(newWords));
            update(Array.from(updateSet));
        } else {
            const createSet = new Set(newWords);
            create(Array.from(createSet));
        }
        setNewWords([]);
    };

    return (
        <SettingsLayout
            title="Blacklist"
            category="Data"
            description="Modify and add blacklisted words"
        >
            <section className={styles['root']}>
                <h6 className={styles['root__section-title']}>
                    Blacklisted words
                </h6>

                <div className={styles['root__words-editor']}>
                    <div
                        className={styles['root__words-list']}
                        onClick={() => {
                            inputRef.current?.focus();
                        }}
                    >
                        {newWords.map((newWord) => (
                            <Chip
                                key={newWord}
                                label={newWord}
                                onDelete={(e) => {
                                    e.stopPropagation();
                                    setNewWords(
                                        newWords.filter(
                                            (word) => word !== newWord,
                                        ),
                                    );
                                }}
                            />
                        ))}
                        <input
                            className={cc([styles['root__words-input']])}
                            placeholder={
                                newWords.length === 0
                                    ? 'Add words separated by comma'
                                    : ''
                            }
                            ref={inputRef}
                            value={value}
                            onBlur={handleInput}
                            onChange={(e) => setValue(e.currentTarget.value)}
                        />
                    </div>

                    <Button onClick={handleClick}>Add to the list</Button>
                </div>

                <h6 className={styles['root__section-title']}>Words</h6>

                {profanity?.blackList && (
                    <div className={styles['root__blacklist']}>
                        {profanity?.blackList.map((word) => (
                            <Chip
                                key={word}
                                label={word}
                                onDelete={() => deleteWord(word)}
                            />
                        ))}
                    </div>
                )}
            </section>
        </SettingsLayout>
    );
};

import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { InfoEmoji } from '../../../icons/common/CInfoEmoji';
import { getSquare } from '../../../theme/style.helpers';

interface Props {
    toggleMode: () => void;
    isLimitReached: boolean;
}

export const ConversationAlert = ({ toggleMode, isLimitReached }: Props) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'baseline',
            height: '100%',
            width: 1,
            p: 6,
        }}
    >
        {isLimitReached && (
            <Alert
                fullWidth
                severity="info"
                variant="standard"
                icon={<InfoEmoji sx={getSquare(20)} />}
                sx={{
                    width: '100%',
                    '& .MuiAlert-message': {
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: 4,
                    },
                }}
            >
                <div>
                    <AlertTitle>Reached a phone carrier group limit</AlertTitle>
                    <Typography variant="body4">
                        For messages to over 10 contacts, switch to campaigns
                        for one-to-many broadcasts.
                    </Typography>
                </div>

                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={toggleMode}
                >
                    Switch to Campaigns
                </Button>
            </Alert>
        )}
    </Box>
);

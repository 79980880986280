import { IconButton } from '@mui/material';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';
import { useLocation } from 'react-router-dom';
import { AdapterLink } from '../../../components/ConversationsList/AdapterLink';

export const BackFromEntityButton = () => {
    const { pathname } = useLocation();
    const prevUrl = pathname.split('/').slice(0, 4).join('/');
    return (
        <IconButton
            href={prevUrl}
            LinkComponent={AdapterLink}
            aria-label="back"
        >
            <ArrowLeftIcon />
        </IconButton>
    );
};

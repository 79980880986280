import range from 'lodash/range';
import { ItemSkeleton } from '../ConversationsList/Item/ItemSkeleton';

export const ListSkeleton = () => (
    <>
        {range(10).map((key) => (
            <ItemSkeleton key={key} />
        ))}
    </>
);

import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { ToastActions, ToastModalState, useToastContext } from './reducer';
import { getSquare } from '../../theme/style.helpers';

const DEFUALT_TOAST_DURATION = 5000;

export default function Toast({ toast }: { toast: ToastModalState }) {
    const { dispatch: toastDispatch } = useToastContext();

    if (!toast.length) {
        return null;
    }

    const Icon = toast[0].icon;

    return (
        <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={toast[0].duration || DEFUALT_TOAST_DURATION}
            onClose={() => {
                toastDispatch({
                    type: ToastActions.REMOVE,
                    payload: { id: toast[0].id },
                });
            }}
        >
            <Alert
                icon={Icon ? <Icon sx={getSquare(20)} /> : undefined}
                onClose={() => {
                    toastDispatch({
                        type: ToastActions.REMOVE,
                        payload: { id: toast[0].id },
                    });
                }}
                severity={toast[0].severity}
            >
                <AlertTitle>{toast[0].title}</AlertTitle>
                {toast[0].description}
            </Alert>
        </Snackbar>
    );
}

import { useState } from 'react';
import styles from './AddNewProperty.module.scss';
import PropertyTypesList from '../PropertyTypesList';
import { useCreateProperty } from '../../../queries/properties';
import PropertyConfigurator from '../PropertyConfigurator';
import { ContactPropertyType } from '../../../api/types';
import { propertyTypeIconNameMap } from '../typeIconMap';
import { Box, Popover } from '@mui/material';
import { Add } from '../../ConversationsList/CAdd';
import { getSquare } from '../../../theme/style.helpers';

const AddNewProperty = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        window.setTimeout(() => {
            setSelectedType(null);
        }, 200);
    };
    const open = Boolean(anchorEl);

    const [selectedType, setSelectedType] =
        useState<ContactPropertyType | null>(null);

    const { mutateAsync: createProperty } = useCreateProperty();

    return (
        <div className={styles['root']}>
            <button className={styles['root__button']} onClick={handleClick}>
                <Add sx={getSquare(20)} />
                <span>Add a new property</span>
            </button>
            <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
                <Box minWidth={220}>
                    {selectedType ? (
                        <PropertyConfigurator
                            type={selectedType}
                            onNameChange={(name) => {
                                setSelectedType(null);

                                createProperty({
                                    name,
                                    type: selectedType,
                                    icon: propertyTypeIconNameMap[selectedType],
                                }).then(() => handleClose());
                            }}
                            onRemove={() => {
                                handleClose();
                            }}
                        />
                    ) : (
                        <PropertyTypesList
                            onClick={(type: ContactPropertyType) => {
                                setSelectedType(type);
                            }}
                        />
                    )}
                </Box>
            </Popover>
        </div>
    );
};

export default AddNewProperty;

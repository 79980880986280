import { Box, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { header } from './styles';

interface ContactPanelHeaderProps {
    onBack?: () => void;
}

export const ContactPanelHeader = (props: ContactPanelHeaderProps) => (
    <Box sx={header}>
        <Typography variant="body2">Contact Information</Typography>
        <IconButton onClick={props.onBack}>
            <CloseIcon />
        </IconButton>
    </Box>
);

import { colors } from '../../../theme/palette';
import { SxProps, Theme } from '@mui/material';

export const itemButton: SxProps<Theme> = {
    px: 3,
    border: '1px solid',
    borderColor: 'custom.gray.light400',
    borderRadius: 1.5,

    '&.selected-item': {
        backgroundColor: colors.blue[50],
    },
};

export const checkbox: SxProps = {
    padding: 0,

    ':hover': {
        backgroundColor: 'transparent',
    },
};

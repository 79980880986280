import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const UnmuteIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.24564 4.09191C8.51485 2.90731 10.2259 2.25 12 2.25C13.7742 2.25 15.4852 2.90731 16.7544 4.09191C18.0254 5.27814 18.75 6.89852 18.75 8.6C18.75 11.1534 19.3908 12.9145 20.1125 14.0955L20.1211 14.1095C20.4996 14.7288 20.7936 15.2099 20.9905 15.5562C21.089 15.7295 21.1736 15.887 21.2343 16.0209C21.2645 16.0876 21.2959 16.1639 21.3199 16.2433C21.3394 16.3081 21.3766 16.4453 21.3623 16.6089C21.3518 16.7293 21.3282 16.9221 21.2185 17.1177C21.1088 17.3133 20.9566 17.434 20.8594 17.5057C20.7399 17.5939 20.6188 17.6349 20.5492 17.655C20.4707 17.6777 20.3927 17.6918 20.3244 17.7014C20.1882 17.7206 20.0271 17.7309 19.8539 17.7373C19.5089 17.75 19.0261 17.75 18.4123 17.75H15.75V18C15.75 20.0711 14.0711 21.75 12 21.75C9.92895 21.75 8.25002 20.0711 8.25002 18L8.25043 17.75H5.58773C4.97397 17.75 4.49118 17.75 4.14615 17.7373C3.97299 17.7309 3.81185 17.7206 3.67566 17.7014C3.60737 17.6918 3.52931 17.6777 3.45088 17.655C3.38126 17.6349 3.26012 17.5939 3.14068 17.5057C3.04348 17.434 2.89127 17.3133 2.78157 17.1177C2.67187 16.9221 2.64825 16.7293 2.63772 16.6089C2.62341 16.4453 2.66063 16.3081 2.68017 16.2433C2.70411 16.1639 2.73554 16.0876 2.76576 16.0209C2.82643 15.887 2.91105 15.7295 3.00957 15.5562C3.20648 15.2098 3.50053 14.7287 3.87906 14.1093L3.88752 14.0955C4.60925 12.9145 5.25002 11.1534 5.25002 8.6C5.25002 6.89852 5.97468 5.27814 7.24564 4.09191ZM9.75043 17.75L9.75002 18C9.75002 17.9998 9.75002 18.0002 9.75002 18C9.75035 19.2424 10.7576 20.25 12 20.25C13.2427 20.25 14.25 19.2426 14.25 18V17.75H9.75043ZM12 3.75C10.5916 3.75 9.25035 4.27268 8.26912 5.18849C7.28964 6.10267 6.75002 7.33105 6.75002 8.6C6.75002 11.419 6.0384 13.4525 5.16743 14.8777C4.80028 15.4784 4.52876 15.9229 4.34498 16.2426C4.6492 16.2499 5.06116 16.25 5.60607 16.25H18.394C18.9389 16.25 19.3508 16.2499 19.6551 16.2426C19.4713 15.9229 19.1998 15.4784 18.8326 14.8777C17.9616 13.4525 17.25 11.419 17.25 8.6C17.25 7.33105 16.7104 6.10267 15.7309 5.18849C14.7497 4.27268 13.4085 3.75 12 3.75Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

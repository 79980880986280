import { TeamStats } from '../types';
import { PickedStats } from './fill-empty-dates-in-range';

export const addTotalsForMessages = (data: PickedStats): TeamStats => {
    const totalIncoming = data.incoming.reduce(
        (acc, item) => acc + item.count,
        0,
    );
    const totalOutgoing = data.outgoing.reduce(
        (acc, item) => acc + item.count,
        0,
    );
    return {
        ...data,
        totalIncoming,
        totalOutgoing,
        total: totalIncoming + totalOutgoing,
    };
};

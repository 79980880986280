import { useNavigate } from 'react-router';
import { UsersThree } from 'phosphor-react';
import { ConversationsList } from './ConversationsList';
import Loading from '../Loading/Loading';
import { useGetCampaignConversations } from '../../queries/conversations';
import { Campaign, CampaignStatus } from '../../api/campaign.types';

interface CampaignConversationsListProps {
    campaign: Campaign;
}

export const CampaignConversationsList = ({
    campaign,
}: CampaignConversationsListProps) => {
    const navigate = useNavigate();

    const {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    } = useGetCampaignConversations(
        campaign.id,
        campaign.status !== CampaignStatus.RUNNING,
    );

    if (!campaign) {
        return null;
    }

    if (isPending) {
        return <Loading />;
    }

    return (
        <ConversationsList
            name={campaign.name}
            icon={<UsersThree size={20} color="#8689A4" />}
            isCampaignRunning={campaign.status === CampaignStatus.RUNNING}
            conversations={conversations}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            getHref={(conversationId) =>
                `/campaigns/${campaign.id}/${conversationId}`
            }
            onTouchEnd={(conversationId) =>
                navigate(`/campaigns/${campaign.id}/${conversationId}`)
            }
            view="Campaign"
            isLoading={isPending}
        />
    );
};

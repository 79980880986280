import { CarrierCredentials } from '../types';
import { InboxProvider } from '../../../api/types';
import { TwilioConnect } from './TwilioConnect';
import { TelnyxConnect } from './TelnyxConnect';
import { useSetupInboxContext } from '../setup-inbox.context';
import { PhoneConnect } from './PhoneConnect';

export type CarrierConnectProps = {
    setCredentials: (credentials: CarrierCredentials) => void;
    carrier: InboxProvider;
    goForward: string;
    goBackward: string;
};

const goBackward = '/setup-inbox/existing-number';
const goForward = '/setup-inbox/carrier-number';

export const CarrierConnect = () => {
    const { carrier, setCarrierCredentials } = useSetupInboxContext();

    switch (carrier) {
        case InboxProvider.TWILIO:
            return (
                <TwilioConnect
                    setCredentials={setCarrierCredentials}
                    carrier={carrier}
                    goForward={goForward}
                    goBackward={goBackward}
                />
            );
        case InboxProvider.TELNYX:
            return (
                <TelnyxConnect
                    setCredentials={setCarrierCredentials}
                    carrier={carrier}
                    goForward={goForward}
                    goBackward={goBackward}
                />
            );
        case InboxProvider.ZOOM:
        case InboxProvider.WEBEX:
            return <PhoneConnect />;
        default:
            return null;
    }
};

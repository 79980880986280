import { ListItemText } from '@mui/material';

export function BasicPhoneItem<T = string>({ phone }: { phone: T }) {
    return (
        <ListItemText
            primary={String(phone)}
            sx={{
                margin: 0,
            }}
            primaryTypographyProps={{
                variant: 'body3',
                color: 'primary.dark',
            }}
        />
    );
}

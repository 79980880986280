import {
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography,
} from '@mui/material';
import { colors } from '../../../theme/palette';
import { useInboxesQueryData } from '../../../queries/inboxes';
import { section } from '../styles';
import { NavLink } from 'react-router-dom';
import { renderInboxIcon } from '../../../utils/inboxes';
import { useTrack } from '../../../contexts/analytics';

type Props = { query: string };

export const InboxesList = ({ query = '' }: Props) => {
    const track = useTrack();
    const inboxes = useInboxesQueryData() || [];
    const filtered = inboxes
        .filter(
            (inbox) =>
                inbox.name?.toLowerCase().includes(query) ||
                inbox.phone.toLowerCase().includes(query),
        )
        .slice(0, 3);
    if (!filtered.length) {
        return null;
    }

    return (
        <Box sx={section}>
            <Typography
                variant="body4"
                sx={{ color: colors.text.secondary, px: 3 }}
            >
                Inboxes
            </Typography>
            {filtered.map((inbox) => (
                <MenuItem
                    key={inbox.id}
                    component={NavLink}
                    to={`inbox/${inbox.id}`}
                    onClick={() => {
                        track('global_search_navigation', {
                            action: 'visit',
                            type: 'inbox',
                            queryLength: query.length,
                        });
                    }}
                >
                    <ListItemIcon>{renderInboxIcon(inbox.icon)}</ListItemIcon>
                    <ListItemText>{inbox.name}</ListItemText>
                </MenuItem>
            ))}
        </Box>
    );
};

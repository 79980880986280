import { useTrack } from '../../../contexts/analytics';
import { Box, Link, Stack } from '@mui/material';
import { MailPlus } from '../../../icons/common/CMailPlus';
import InviteMsTeamsUserModal from '../../../components/Modals/InviteMsTeamsUserModal/InviteMsTeamsUserModal';
import { useState } from 'react';
import { useMeQueryData } from '../../../queries/user';
import { RoleTypes } from '../../../api/types';
import { InviteUserModal } from '../../../components/Modals/InviteUserModal/InviteUserModal';
import { getSquare } from '../../../theme/style.helpers';

export const SideBarInvite = () => {
    const [showInviteModal, setShowInviteModal] = useState(false);
    const track = useTrack();
    const me = useMeQueryData();

    return (
        <>
            <Box
                sx={{
                    px: 4,
                    py: 3,
                    borderBottom: '1px solid',
                    borderBottomColor: 'custom.gray.divider',
                }}
            >
                <Link
                    onClick={() => {
                        track('clicked_invite_team');
                        setShowInviteModal(true);
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={4}>
                        <MailPlus sx={getSquare(20)} /> <span>Invite team</span>
                    </Stack>
                </Link>
            </Box>
            {[RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
                me?.activeRole?.type as RoleTypes,
            ) ? (
                <InviteUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                />
            ) : (
                <InviteMsTeamsUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                />
            )}
        </>
    );
};

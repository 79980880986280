import { Tabs as MaterialTabs, TabsProps } from '@mui/material';
import cc from 'classcat';
import omit from 'lodash/omit';

/** Mui Tabs have limited extend functionality */
export const Tabs = (props: TabsProps & { color: 'info' }) => (
    <MaterialTabs
        {...omit(props, 'color')}
        className={cc([
            props.className,
            props.color && `MuiTabs-color-${props.color}`,
        ])}
    />
);

import { useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';

interface PaymentStatusProps {
    onPaymentSuccess: () => void;
    onPaymentFail: () => void;
}

const PaymentStatus = ({
    onPaymentSuccess,
    onPaymentFail,
}: PaymentStatusProps) => {
    const stripe = useStripe();

    useEffect(() => {
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret',
        );

        if (!stripe || !clientSecret) {
            return;
        }

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            switch (setupIntent?.status) {
                case 'succeeded':
                case 'processing':
                    onPaymentSuccess();
                    break;

                case 'canceled':
                case 'requires_action':
                case 'requires_payment_method':
                case 'requires_confirmation':
                    onPaymentFail();
                    break;

                default:
                    onPaymentFail();
                    break;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    return null;
};

export default PaymentStatus;

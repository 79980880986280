import { NavLink } from 'react-router-dom';
import styles from './TierUpgradeOverlay.module.scss';
import PartyIcon from '../../assets/icons/add-inbox/party.png';

export const TierUpgradeOverlay = () => (
    <div className={styles['root']}>
        <div className={styles['root__inner-container']}>
            <div className={styles['root__title']}>
                <img src={PartyIcon} className={styles['root__icon']} />

                <h3 className={styles['root__title_text']}>
                    You are on the free plan
                </h3>
            </div>

            <p className={styles['root__text']}>
                Click <NavLink to="/payment/plan">here to upgrade</NavLink> your
                account now ↗
            </p>
        </div>
    </div>
);

import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Inbox, InboxStatus } from '../../api/types';
import { useInboxUpdateQuery } from '../../queries/inboxes';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import { useUnreadMessagesCountForInbox } from '../../queries/messages';
import AttentionIcon from '../../assets/icons/different/attention.svg?react';
import GreenCheckIcon from '../../assets/icons/different/greencheck.svg?react';
import Tooltip from '../Tooltip';
import EmojiPicker from '../../components/EmojiPicker';
import dayjs from 'dayjs';
import {
    Box,
    CircularProgress,
    IconButton,
    ListItemText,
    Popover,
    Stack,
} from '@mui/material';
import { Indicator } from '../../components/common/Indicator';
import { renderInboxIcon } from '../../utils/inboxes';
import { useMenu } from '../../components/Inbox/use-menu';
import { useTrack } from '../../contexts/analytics';

interface Props {
    index: number;
    inbox: Inbox;
    isMuted?: boolean;
    countryCode?: string;
    onClick?: () => void;
}

const INBOX_NAME_PLACEHOLDER = 'Inbox';

const getPath = (inbox: Inbox) => {
    switch (inbox.status) {
        case InboxStatus.ACTIVE:
            return `/inbox/${inbox.id}`;
        case InboxStatus.PENDING:
            return `/inbox/${inbox.id}/verify/customer`;
        case InboxStatus.PORT_SUBMITTED:
            return '#';
        default:
            return '#';
    }
};

function getStatusText(status: InboxStatus, inbox: Inbox): string {
    switch (status) {
        case InboxStatus.PENDING:
            return 'Please click to verify phone';
        case InboxStatus.PORT_SUBMITTED:
            return `Verified. Your number will be active on ${
                inbox.requestedActivationDate
                    ? dayjs(inbox.requestedActivationDate).format(
                          'MMMM D, hh:mm A',
                      )
                    : 'the next business day'
            }`;
        case InboxStatus.PORT_FAILED:
            return 'Verification failed. Please schedule onboarding here https://calendly.com/clerkchat/verify';
        default:
            return '';
    }
}

export const InboxItem = ({ index, inbox, countryCode, onClick }: Props) => {
    const track = useTrack();
    const menu = useMenu();
    const {
        id,
        icon,
        phone = '',
        name = INBOX_NAME_PLACEHOLDER,
        status,
    } = inbox;
    const unreadCount = useUnreadMessagesCountForInbox(id).data || 0;
    const { mutateAsync, isPending } = useInboxUpdateQuery();

    useEffect(() => {
        if (menu.open) {
            track('inbox_icon_picker_open', {
                inboxId: id,
                origin: 'sidebar inbox list',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu.open]);

    return (
        <>
            <Popover
                onClose={menu.handleClose}
                transformOrigin={{
                    horizontal: -35,
                    vertical: 'center',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={menu.open}
                anchorEl={menu.anchorEl}
                slotProps={{
                    paper: {
                        sx: { borderRadius: 2 },
                    },
                }}
            >
                <EmojiPicker
                    onClick={(val) => {
                        if (!val) {
                            return;
                        }
                        const hex = val.codePointAt(0);
                        if (hex) {
                            mutateAsync({
                                icon: hex.toString(16),
                                id: inbox.id,
                            }).then(() => {
                                track('inbox_icon_changed', {
                                    inboxId: id,
                                    origin: 'sidebar inbox list',
                                });
                            });
                        }
                        menu.handleClose();
                    }}
                    onClose={menu.handleClose}
                />
            </Popover>
            <Draggable key={inbox.id} draggableId={inbox.id} index={index}>
                {(providedDraggable: DraggableProvided, snapshot) => {
                    return (
                        <Box
                            component="li"
                            sx={{ listStyle: 'none' }}
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                        >
                            <Box
                                component={NavLink}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: 1,
                                    mx: 2,
                                    backgroundColor: 'custom.gray.superLight',

                                    '&:first-of-type': {
                                        marginTop: 1,
                                    },

                                    '&:focus, &:active': {
                                        outline: 'none',
                                        boxShadow: 'none',
                                    },

                                    '&:hover, &.active': {
                                        backgroundColor:
                                            'custom.gray.actionSelected',
                                    },

                                    ...(snapshot.isDragging
                                        ? {
                                              border: '1px solid',
                                              borderColor: 'info.main',
                                          }
                                        : {}),
                                }}
                                onClick={onClick}
                                to={getPath(inbox)}
                            >
                                <IconButton
                                    color="primary"
                                    sx={{ fontSize: 20, ml: 2, mr: 2 }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        menu.handleOpen(e);
                                    }}
                                >
                                    {isPending ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        renderInboxIcon(icon)
                                    )}
                                </IconButton>

                                <ListItemText
                                    data-testid="inbox-item"
                                    primary={name || INBOX_NAME_PLACEHOLDER}
                                    primaryTypographyProps={{
                                        /* @ts-ignore */
                                        'data-testid': 'inbox-li-name',
                                        variant: 'body3',
                                        color: 'primary.dark',
                                        noWrap: true,
                                    }}
                                    secondary={formatPhoneNumber(
                                        phone,
                                        countryCode,
                                    )}
                                    secondaryTypographyProps={{
                                        /* @ts-ignore */
                                        'data-testid': 'inbox-li-phone',
                                        variant: 'body4',
                                        color: 'custom.gray.super',
                                    }}
                                />
                                <Stack pr={2} spacing={1} alignItems="end">
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        {status !== InboxStatus.ACTIVE && (
                                            <Tooltip
                                                width="150px"
                                                text={getStatusText(
                                                    status,
                                                    inbox,
                                                )}
                                            >
                                                <Box>
                                                    <GreenCheckIcon />
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {status === InboxStatus.DISABLED && (
                                            <Tooltip
                                                width="150px"
                                                text="This phone number has been disabled"
                                            >
                                                <AttentionIcon />
                                            </Tooltip>
                                        )}
                                    </Stack>
                                    {unreadCount > 0 && (
                                        <Indicator data-testid="inbox-unread-count">
                                            {unreadCount < 99
                                                ? unreadCount
                                                : '99+'}
                                        </Indicator>
                                    )}
                                </Stack>
                            </Box>
                        </Box>
                    );
                }}
            </Draggable>
        </>
    );
};

export default InboxItem;

import { SxProps, Theme } from '@mui/material';
import { colors } from '../../../theme/palette';

export const formControl: SxProps = {
    minWidth: 100,
    justifyContent: 'space-between',
    ml: 0,
    color: 'text.secondary',
    px: 3,
    py: 2,
};

export const switchHolder: SxProps<Theme> = {
    alignSelf: 'flex-start',
    borderRadius: 1,
    boxShadow: ({ shadows }) => shadows[3],
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary.gray.borderEnabled,
    pr: 3,
};

import cc from 'classcat';
import { CSSProperties } from 'react';
import styles from './Table.module.scss';

interface TableRowProps {
    style?: CSSProperties;
    className?: string;
    isHead?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    dataTestid?: string;
    children: React.ReactNode;
}

export const TableRow = (props: TableRowProps) =>
    props.isHead ? (
        <div
            style={props.style}
            className={cc([styles['table-head-row'], props.className])}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    ) : (
        <div
            data-testid={props.dataTestid}
            style={props.style}
            className={cc([styles['table-row'], props.className])}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );

import { useState, useCallback, useMemo } from 'react';
import { StepShell } from '../../Onboarding/StepShell';
import { InboxProvider } from '../../../api/types';
import {
    LIST_CHANNELS_KEY,
    useInboxesQueryData,
    useListChannelsQueryData,
} from '../../../queries/inboxes';
import { useSetupInboxContext } from '../setup-inbox.context';
import { Button, FormHelperText, Link, Stack, Typography } from '@mui/material';
import { PhonesList } from './PhonesList';
import { useCreateInboxes } from '../hooks/useCreateInboxes';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { iconMap, titleMap } from './constants';
import { Link as RouterLink } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const CarrierPhoneNumber = () => {
    const {
        newInboxes,
        carrier = 'twilio',
        setNewInboxes,
        setCarrierCredentials,
    } = useSetupInboxContext();
    const queryClient = useQueryClient();

    const [selectedPhones, setSelectedPhones] = useState<string[]>(
        newInboxes
            .filter((inbox) => !!inbox.phone)
            .map((inbox) => inbox.phone ?? '') ?? [],
    );
    const [createInboxes, { error, isLoading, resetError }] =
        useCreateInboxes();

    const inboxes = useInboxesQueryData();
    const phones = useListChannelsQueryData(carrier as InboxProvider);

    const handleSubmit = useCallback(async () => {
        const inboxes = selectedPhones.map((phone) => ({
            phone,
            provider: carrier as InboxProvider,
        }));
        setNewInboxes(inboxes);
        await createInboxes(inboxes);
    }, [carrier, createInboxes, selectedPhones, setNewInboxes]);

    const availablePhones = useMemo(() => {
        if (!inboxes) {
            return phones;
        }

        const existing = new Set(inboxes.map((i) => i.phone));
        return phones.filter((channel) => !existing.has(channel));
    }, [phones, inboxes]);

    return (
        <StepShell
            title={(carrier && titleMap[carrier]) ?? 'Import Phones'}
            subtitle="Please select any of the phone numbers below."
            icon={carrier && iconMap[carrier]}
            goBackward="/setup-inbox/existing-number"
        >
            <Stack spacing={6} width={320}>
                {availablePhones.length === 0 ? (
                    <>
                        <Typography
                            variant="body3"
                            color="primary.dark"
                            textAlign="center"
                        >
                            We didn&apos;t find any numbers in your account.
                            Please double-check your credentials or sign in with
                            a different account
                        </Typography>
                        <Button
                            component={RouterLink}
                            to="/setup-inbox/existing-number"
                            fullWidth
                        >
                            Retry with another account
                        </Button>
                    </>
                ) : (
                    <>
                        <PhonesList
                            phones={availablePhones}
                            selected={selectedPhones}
                            handleSelect={(phone) => {
                                setSelectedPhones((phones) =>
                                    phones.includes(phone)
                                        ? phones.filter((p) => p !== phone)
                                        : phones.concat(phone),
                                );

                                resetError();
                            }}
                        />
                        {!!error && (
                            <FormHelperText error>{error}</FormHelperText>
                        )}
                        <Button
                            onClick={handleSubmit}
                            disabled={
                                !selectedPhones.length || !!error || isLoading
                            }
                            fullWidth
                        >
                            {isLoading ? <ButtonDotLoader /> : 'Finish'}
                        </Button>
                        <Stack textAlign="center">
                            <Typography variant="body4" color="primary.dark">
                                Not the right phone numbers appearing?
                            </Typography>
                            <Link
                                component={RouterLink}
                                onClick={() => {
                                    queryClient.removeQueries({
                                        queryKey: [LIST_CHANNELS_KEY, carrier],
                                    });
                                    setCarrierCredentials(undefined);
                                }}
                                to="/setup-inbox/existing-number"
                                variant="body4"
                                color="info.main"
                            >
                                Retry with another account -&gt;
                            </Link>
                        </Stack>
                    </>
                )}
            </Stack>
        </StepShell>
    );
};

import React, { forwardRef } from 'react';
import Icon from '../../icons/Icon';
import { Icons } from '../../icons/iconsMap';
import styles from './FormInput.module.scss';
import { HintText } from './HintText';
import { Label } from './Label';

interface FormInputProps extends React.HTMLAttributes<HTMLInputElement> {
    label?: string;
    required?: boolean;
    height?: 32 | 40;
    error?: boolean;
    success?: boolean;
    info?: boolean;
    iconName?: Icons;
    hint?: string;
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
    function FormInput(props, ref) {
        const {
            label: _,
            error,
            info,
            success,
            required: __,
            height = 40,
            ...rest
        } = props;
        return (
            <div className={styles['root']}>
                {props.label && (
                    <Label
                        info={info}
                        required={props.required}
                        text={props.label}
                    />
                )}
                <div className={styles['root__input-wrapper']}>
                    {props.iconName && (
                        <div className={styles['root__icon']}>
                            <Icon name={props.iconName} size="20px" />
                        </div>
                    )}
                    <input
                        {...rest}
                        ref={ref}
                        style={{ height }}
                        className={styles['root__input']}
                        data-is-error={error}
                        data-is-success={success}
                        data-has-icon={!!props.iconName}
                    />
                </div>
                {props.hint && (
                    <HintText
                        text={props.hint}
                        variant={
                            error ? 'error' : success ? 'success' : 'default'
                        }
                    />
                )}
            </div>
        );
    },
);

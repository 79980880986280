import { SxProps, Theme } from '@mui/material';

export const frame: SxProps<Theme> = {
    borderRadius: 1.5,
    display: 'flex',
    padding: (theme) => theme.spacing(2, 4),
    width: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: 0,
    gap: 2,
    border: '1px solid',
    borderColor: (theme) => theme.palette.custom.gray.light400,
};

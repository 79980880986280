import styles from './PhoneVerification.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from '../../elements/FormInput';
import Callout from '../../components/Callout';
import Spacer from '../../components/Spacer';
import { useBrandQuery, useBrandUpdateQuery } from '../../queries/settings';
import { useState } from 'react';
import DotLoader from '../../elements/DotLoader';
import { useNavigate, useParams } from 'react-router';
import { Brand, CompanyEntityType } from '../../api/types';
import {
    countriesOptions,
    organizationLegalFormOptions,
    renderCountryOption,
    renderCountryValue,
    stateOptions,
} from '../../main/settings/Compliance/utils';
import AdvancedSelect from '../../elements/AdvancedSelect';
import FormControl from '../../elements/FormControl';
import { PhoneVerificationProps } from './PhoneVerification';
import { Button } from '@mui/material';

interface AddressVerificationStepFormData extends Brand {
    companyAddress: string;
    city: string;
    state: string;
    companyZip: string;
    companyCountry: string;
    organizationLegalForm: CompanyEntityType;
    ein: string;
}

export const AddressVerificationStep = () => {
    const { inboxId } = useParams<PhoneVerificationProps>();
    const navigate = useNavigate();
    const { control, handleSubmit, clearErrors } =
        useForm<AddressVerificationStepFormData>();
    const { data: brand, isLoading: brandLoading } = useBrandQuery();
    const { mutateAsync: updateBrand } = useBrandUpdateQuery();

    const [isLoading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<AddressVerificationStepFormData> = async (
        data: AddressVerificationStepFormData,
    ) => {
        setLoading(true);
        await updateBrand({
            id: brand!.id,
            brand: data,
        });
        setLoading(false);
        clearErrors();

        navigate(`/inbox/${inboxId}/verify/phone-type`);
    };

    return brandLoading ? (
        <DotLoader />
    ) : (
        <form
            className={styles['root__form']}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Callout style="warning">
                <span>☝️ </span>
                Please ensure the address matches to the one currently on file
                with your carrier.
            </Callout>
            <Controller
                name="companyAddress"
                control={control}
                defaultValue={brand?.companyAddress}
                rules={{ required: true, minLength: 5 }}
                render={({ field, fieldState }) => (
                    <FormInput
                        label="Address"
                        required
                        autoFocus
                        iconName="address"
                        error={fieldState.isDirty && fieldState.invalid}
                        hint={
                            fieldState.isDirty && fieldState.invalid
                                ? 'Address is required'
                                : undefined
                        }
                        {...field}
                    />
                )}
            />
            <div className={styles['form-row']}>
                <Controller
                    name="city"
                    control={control}
                    rules={{ required: true, minLength: 3 }}
                    defaultValue={brand?.city}
                    render={({ field, fieldState }) => (
                        <FormInput
                            label="City"
                            required
                            iconName="state"
                            error={fieldState.isDirty && fieldState.invalid}
                            hint={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'City must be 3 characters long'
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
                <Controller
                    name="state"
                    control={control}
                    rules={{ required: true, minLength: 2, maxLength: 2 }}
                    defaultValue={brand?.state}
                    render={({ field, fieldState }) => (
                        <Spacer height={0} width="100%">
                            <FormControl label="State">
                                <AdvancedSelect
                                    options={stateOptions}
                                    value={field.value}
                                    error={fieldState?.error?.message}
                                    onChange={(value) => {
                                        field.onChange(value as string);
                                    }}
                                    searchable
                                />
                            </FormControl>
                        </Spacer>
                    )}
                />
            </div>
            <div className={styles['form-row']}>
                <Controller
                    name="companyCountry"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={brand?.companyCountry || 'US'}
                    render={({ field, fieldState }) => (
                        <Spacer height={0} width="100%">
                            <FormControl label="Country">
                                <AdvancedSelect
                                    options={countriesOptions}
                                    renderValue={renderCountryValue}
                                    value={field.value}
                                    renderOption={renderCountryOption}
                                    error={fieldState?.error?.message}
                                    onChange={(value) => {
                                        field.onChange(value as string);
                                    }}
                                    searchable
                                />
                            </FormControl>
                        </Spacer>
                    )}
                />
                <Controller
                    name="companyZip"
                    control={control}
                    rules={{ required: true, minLength: 3 }}
                    defaultValue={brand?.companyZip}
                    render={({ field, fieldState }) => (
                        <FormInput
                            label="Zip / Postal Code"
                            required
                            iconName="zipcode"
                            error={fieldState.isDirty && fieldState.invalid}
                            hint={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'Company must be 3 characters long'
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </div>
            <div className={styles['form-row']}>
                <Controller
                    name="organizationLegalForm"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                        brand?.organizationLegalForm ||
                        CompanyEntityType.PRIVATE_PROFIT
                    }
                    render={({ field, fieldState }) => (
                        <Spacer height={0} width="100%">
                            <FormControl label="Entity Type">
                                <AdvancedSelect
                                    value={field.value}
                                    options={organizationLegalFormOptions}
                                    error={fieldState?.error?.message}
                                    onChange={(value) => {
                                        field.onChange(
                                            value as CompanyEntityType,
                                        );
                                    }}
                                />
                            </FormControl>
                        </Spacer>
                    )}
                />
                <Controller
                    name="ein"
                    control={control}
                    rules={{ minLength: 3 }}
                    defaultValue={brand?.ein}
                    render={({ field, fieldState }) => (
                        <FormInput
                            label="EIN / Tax ID"
                            iconName="number"
                            error={fieldState.isDirty && fieldState.invalid}
                            hint={
                                fieldState.isDirty && fieldState.invalid
                                    ? 'Company must be 3 characters long'
                                    : undefined
                            }
                            {...field}
                        />
                    )}
                />
            </div>
            <Spacer height={0} width="auto" />
            <div className={styles['form-row']}>
                <Spacer height="auto" width="50%">
                    <Spacer height="auto" width="96px">
                        <Button
                            variant="outlined"
                            isLoading={isLoading}
                            onClick={() =>
                                navigate(`/inbox/${inboxId}/verify/customer`)
                            }
                        >
                            Back
                        </Button>
                    </Spacer>
                </Spacer>
                <Spacer height="auto" width="50%">
                    <Button fullWidth type="submit" isLoading={isLoading}>
                        Proceed
                    </Button>
                </Spacer>
            </div>
        </form>
    );
};

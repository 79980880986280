import { UUID } from '../../types/uuid';
import { AvatarGroup, Box, Skeleton, Typography } from '@mui/material';
import * as styles from '../../main/campaign/v3/styles';

import { getSquare } from '../../theme/style.helpers';
import { useMemo } from 'react';
import { getAvatarGradient } from '../../elements/Avatar/get-avatar-color';
import { ConversationToolbar } from '../../components/ConversationToolbar/ConversationToolbar';
import { ActionsSkeleton } from './Conversation/HeaderActionsSkeleton';
import { HideUpMd } from '../../components/MediaHelpers/HideUpMd';
import { BackFromEntityButton } from './Conversation/BackFromEntityButton';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';

import { useConversationName } from '../../components/Inbox/Conversation/use-conversation-props';
import { useConversation } from '../../queries/conversations';
import { useByPhones } from '../../components/Contacts/use-contact-by-phone';
import { getPlaceholder } from '../../components/Contacts/get-placeholder';

type Props = {
    conversationId: UUID;
};

export const ConversationHeader = ({ conversationId }: Props) => {
    const { data, isLoading } = useConversation(conversationId);
    const memberQueries = useByPhones(data?.members ?? []);
    const name = useConversationName(data?.members ?? []);
    const memberContacts =
        data?.members.map(
            (phone) =>
                memberQueries.find((query) => query.data?.phone === phone)
                    ?.data ?? getPlaceholder(phone),
        ) ?? [];
    const background = useMemo(() => getAvatarGradient(name || ''), [name]);

    return (
        <Box sx={styles.header}>
            <HideUpMd>
                <BackFromEntityButton />
            </HideUpMd>
            {isLoading ? (
                <Skeleton variant="circular" sx={getSquare(20)} />
            ) : (
                <AvatarGroup
                    total={data?.members.length || 0}
                    max={3}
                    componentsProps={{
                        additionalAvatar: {
                            sx: {
                                ...getSquare(20),
                                fontSize: 10,
                                background,
                            },
                        },
                    }}
                >
                    {memberQueries.map(({ isLoading, data: contact }, i) => {
                        if (isLoading) {
                            return <Skeleton key={i} width={20} height={20} />;
                        }
                        if (!contact) {
                            return null;
                        }
                        return (
                            <UserAvatar
                                key={contact.id + contact.phone}
                                size={20}
                                imageUrl={contact.avatarURL}
                                alt={contact.name}
                                username={contact.name}
                            />
                        );
                    })}
                </AvatarGroup>
            )}
            <Typography
                variant="body3"
                color="primary.dark"
                noWrap
                component="h1"
                data-testid="conversation-header-name"
            >
                {isLoading ? <Skeleton width={90} /> : name}
            </Typography>
            <Box sx={styles.toolbar}>
                {data ? (
                    <ConversationToolbar
                        conversation={data}
                        contacts={memberContacts}
                    />
                ) : (
                    <ActionsSkeleton />
                )}
            </Box>
        </Box>
    );
};

import { usePropertiesQuery } from '../../../queries/properties';
import { FilterValue, UserPropertyModel } from '../../ContactProperty/types';

export const useFilterMeta = () => {
    const { data: properties = [] } = usePropertiesQuery();
    return (
        model: UserPropertyModel | FilterValue,
    ): Partial<UserPropertyModel> => {
        const property = properties.find((prop) => prop.id === model.id);
        return {
            type: model.type ?? property?.type,
            name: model.name ?? property?.name,
            source: model.source ?? property?.source,
            icon: model.icon ?? property?.icon,
            readonly: model.readonly ?? property?.readonly,
        };
    };
};

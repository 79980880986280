import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const BRFlagIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.2026 4.13788H1.79742C0.804703 4.13788 0 4.94258 0 5.9353V18.0647C0 19.0573 0.804703 19.8621 1.79742 19.8621H22.2026C23.1952 19.8621 24 19.0574 24 18.0647V5.9353C24 4.94258 23.1953 4.13788 22.2026 4.13788Z"
                fill="#73AF00"
            />
            <path
                d="M11.7848 6.33782L3.06341 11.6465C2.79852 11.8077 2.79852 12.1921 3.06341 12.3534L11.7848 17.6621C11.9169 17.7426 12.0829 17.7426 12.2151 17.6621L20.9365 12.3534C21.2014 12.1922 21.2014 11.8078 20.9365 11.6465L12.2151 6.33782C12.083 6.25743 11.9169 6.25743 11.7848 6.33782Z"
                fill="#FFE15A"
            />
            <path
                d="M12 15.3103C13.8282 15.3103 15.3103 13.8282 15.3103 11.9999C15.3103 10.1717 13.8282 8.68964 12 8.68964C10.1718 8.68964 8.6897 10.1717 8.6897 11.9999C8.6897 13.8282 10.1718 15.3103 12 15.3103Z"
                fill="#41479B"
            />
            <path
                d="M9.15934 10.3065C9.00315 10.568 8.88254 10.8521 8.80286 11.1539C10.638 11.0158 13.3526 11.5292 15.0805 13.2057C15.1932 12.9179 15.2677 12.6112 15.2956 12.2906C13.5115 10.7891 11.045 10.2551 9.15934 10.3065Z"
                fill="#F5F5F5"
            />
            <path
                d="M12.1371 13.1296L12.2252 13.3939L12.5038 13.3961C12.5399 13.3964 12.5548 13.4424 12.5258 13.4638L12.3017 13.6293L12.3857 13.8949C12.3966 13.9293 12.3574 13.9578 12.3281 13.9368L12.1015 13.7748L11.8748 13.9368C11.8455 13.9577 11.8064 13.9293 11.8172 13.8949L11.9013 13.6293L11.6772 13.4638C11.6482 13.4424 11.6631 13.3964 11.6992 13.3961L11.9777 13.3939L12.0659 13.1296C12.0773 13.0954 12.1257 13.0954 12.1371 13.1296Z"
                fill="#F5F5F5"
            />
            <path
                d="M13.2198 13.8133L13.2578 13.927L13.3777 13.9279C13.3932 13.928 13.3996 13.9478 13.3871 13.9571L13.2907 14.0283L13.3268 14.1426C13.3314 14.1574 13.3147 14.1696 13.302 14.1606L13.2044 14.0909L13.1069 14.1606C13.0943 14.1696 13.0774 14.1574 13.0821 14.1426L13.1182 14.0283L13.0217 13.9571C13.0092 13.9479 13.0157 13.9281 13.0312 13.9279L13.1511 13.927L13.189 13.8133C13.194 13.7985 13.2149 13.7985 13.2198 13.8133Z"
                fill="#F5F5F5"
            />
            <path
                d="M11.6689 12.6275L11.7068 12.7413L11.8267 12.7422C11.8422 12.7423 11.8487 12.7621 11.8362 12.7714L11.7397 12.8426L11.7759 12.9569C11.7805 12.9717 11.7637 12.9839 11.7511 12.9749L11.6535 12.9052L11.556 12.9749C11.5434 12.9839 11.5265 12.9717 11.5311 12.9569L11.5673 12.8426L11.4708 12.7714C11.4583 12.7622 11.4648 12.7423 11.4803 12.7422L11.6002 12.7413L11.6381 12.6275C11.6431 12.6129 11.664 12.6129 11.6689 12.6275Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.56859 12.4896L9.60651 12.6034L9.72641 12.6043C9.74193 12.6044 9.7484 12.6242 9.73588 12.6335L9.63941 12.7047L9.67555 12.819C9.68019 12.8338 9.66341 12.846 9.65071 12.837L9.55316 12.7673L9.45562 12.837C9.44301 12.846 9.42613 12.8338 9.43082 12.819L9.46696 12.7047L9.37049 12.6335C9.35798 12.6243 9.36444 12.6044 9.37996 12.6043L9.49987 12.6034L9.53779 12.4896C9.54285 12.475 9.56366 12.475 9.56859 12.4896Z"
                fill="#F5F5F5"
            />
            <path
                d="M11.3256 13.774L11.3636 13.8878L11.4835 13.8887C11.499 13.8888 11.5055 13.9086 11.4929 13.9179L11.3965 13.9891L11.4326 14.1034C11.4373 14.1182 11.4205 14.1304 11.4078 14.1214L11.3103 14.0517L11.2127 14.1214C11.2001 14.1304 11.1832 14.1182 11.1879 14.1034L11.224 13.9891L11.1276 13.9179C11.1151 13.9087 11.1215 13.8888 11.137 13.8887L11.2569 13.8878L11.2948 13.774C11.2999 13.7593 11.3208 13.7593 11.3256 13.774Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.3589 12.4103L10.39 12.5033L10.488 12.5041C10.5007 12.5042 10.506 12.5204 10.4958 12.528L10.4169 12.5862L10.4465 12.6796C10.4503 12.6917 10.4366 12.7018 10.4262 12.6944L10.3464 12.6374L10.2666 12.6944C10.2563 12.7018 10.2425 12.6917 10.2463 12.6796L10.2759 12.5862L10.197 12.528C10.1869 12.5204 10.1921 12.5042 10.2048 12.5041L10.3029 12.5033L10.3339 12.4103C10.3379 12.3983 10.3549 12.3983 10.3589 12.4103Z"
                fill="#F5F5F5"
            />
            <path
                d="M13.304 10.4903L13.3428 10.6067L13.4654 10.6076C13.4813 10.6077 13.4879 10.628 13.4751 10.6375L13.3764 10.7103L13.4134 10.8273C13.4183 10.8424 13.401 10.8549 13.3881 10.8457L13.2883 10.7743L13.1885 10.8457C13.1755 10.855 13.1583 10.8424 13.1631 10.8273L13.2001 10.7103L13.1015 10.6375C13.0887 10.6281 13.0953 10.6078 13.1111 10.6076L13.2338 10.6067L13.2726 10.4903C13.2776 10.4751 13.299 10.4751 13.304 10.4903Z"
                fill="#F5F5F5"
            />
            <path
                d="M9.70368 11.8413L9.73471 11.9343L9.83277 11.9351C9.84543 11.9352 9.85072 11.9514 9.84055 11.959L9.76166 12.0172L9.79124 12.1107C9.79508 12.1227 9.7813 12.1328 9.77094 12.1254L9.69116 12.0684L9.61138 12.1254C9.60102 12.1328 9.58729 12.1227 9.59108 12.1107L9.62066 12.0172L9.54177 11.959C9.53155 11.9514 9.5368 11.9352 9.54955 11.9351L9.64762 11.9343L9.67865 11.8413C9.68263 11.8293 9.69965 11.8293 9.70368 11.8413Z"
                fill="#F5F5F5"
            />
            <path
                d="M10.1771 13.1523L10.2241 13.2931L10.3726 13.2942C10.3918 13.2944 10.3998 13.3189 10.3843 13.3303L10.2648 13.4186L10.3096 13.5601C10.3154 13.5784 10.2946 13.5936 10.2789 13.5824L10.1581 13.496L10.0372 13.5824C10.0216 13.5936 10.0007 13.5784 10.0065 13.5601L10.0514 13.4186L9.93187 13.3303C9.9164 13.3189 9.92437 13.2944 9.94359 13.2942L10.0921 13.2931L10.1391 13.1523C10.1453 13.134 10.1711 13.134 10.1771 13.1523Z"
                fill="#F5F5F5"
            />
            <path
                d="M13.8236 13.8428L13.8531 13.9314L13.9466 13.9321C13.9587 13.9322 13.9637 13.9476 13.954 13.9548L13.8788 14.0103L13.907 14.0994C13.9106 14.1109 13.8975 14.1204 13.8876 14.1134L13.8117 14.0591L13.7356 14.1134C13.7258 14.1204 13.7127 14.1109 13.7163 14.0994L13.7444 14.0103L13.6693 13.9548C13.6596 13.9476 13.6646 13.9322 13.6767 13.9321L13.7701 13.9314L13.7997 13.8428C13.8035 13.8313 13.8198 13.8313 13.8236 13.8428Z"
                fill="#F5F5F5"
            />
            <path
                d="M14.1504 13.1664L14.1883 13.2801L14.3083 13.2811C14.3238 13.2812 14.3302 13.301 14.3177 13.3102L14.2213 13.3814L14.2574 13.4958C14.262 13.5106 14.2453 13.5228 14.2326 13.5138L14.1351 13.444L14.0375 13.5138C14.0249 13.5228 14.008 13.5106 14.0127 13.4958L14.0488 13.3814L13.9524 13.3102C13.9399 13.301 13.9463 13.2812 13.9618 13.2811L14.0818 13.2801L14.1197 13.1664C14.1246 13.1516 14.1455 13.1516 14.1504 13.1664Z"
                fill="#F5F5F5"
            />
        </svg>
    </SvgIcon>
);

import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';
import { SxProps } from '@mui/material';

export const teamSelectorBtn: SxProps = {
    height: HEADER_HEIGHT,
    px: 4,
    overflow: 'hidden',
    justifyContent: 'flex-start',
    '& .MuiButton-icon': { ml: 0 },
};
export const teamName: SxProps = {
    overflow: 'hidden',
    fontWeight: 500,
    color: 'text.primary',
    textAlign: 'left',
    width: 1,
    textWrap: 'no-wrap',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
};
export const avatar: SxProps = {
    height: 1,
    width: 1,
    objectFit: 'cover',
};
export const limitedName: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    textWrap: 'wrap',
};

export const buttonLikeStyle: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    width: 28,
    height: 28,
    '& svg': {
        width: 16,
        height: 16,
    },
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
    },
    '&:focus': {
        outline: '2px solid rgba(0, 0, 0, 0.12)',
    },
};

import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CompanyEntityType } from '../../../api/types';
import {
    canadaStateOptions,
    countriesOptions,
    stateOptions,
} from '../../../main/settings/Compliance/utils';
import {
    useBrandCreateQuery,
    useBrandQuery,
    useBrandUpdateQuery,
} from '../../../queries/settings';
import { StepShell } from '../../Onboarding/StepShell';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    Stack,
    TextField,
} from '@mui/material';
import { ArrowBackIcon } from '../../../icons/common/CArrowBack';
import { useNavigate } from 'react-router';
import { AddressIcon } from '../../../icons/common/CAddressIcon';
import { CompanyIcon } from '../../../icons/common/CCompanyIcon';
import { MapIcon } from '../../../icons/common/CMap';
import { EnhancedSelect } from '../../../components/EnhancedSelect/EnhancedSelect';
import { CountryIcon } from '../../../icons/common/CCountryIcon';
import { ZipIcon } from '../../../icons/common/CZipIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrivateUser } from '../../../icons/common/CPrivateUser';
import * as Yup from 'yup';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { MSTeams } from '../../../icons/carriers/MSTeams';
import { Link } from 'react-router-dom';
import { useTrack } from '../../../contexts/analytics';

export type FormData = {
    companyName: string;
    organizationLegalForm: CompanyEntityType;
    ein?: string;
    companyCountry: string;
    city: string;
    state: string;
    companyZip: string;
    companyAddress: string;
    fullName: string;
};

const validationSchema = Yup.object({
    fullName: Yup.string().required().max(255).trim().matches(/\s+/g),
    companyName: Yup.string().required().max(255),
    companyCountry: Yup.string().required().min(2).max(2),
    state: Yup.string().required().max(20),
    city: Yup.string().required().max(100),
    companyZip: Yup.string().required().max(10),
    companyAddress: Yup.string().required().max(100),
}).transform((values) => {
    omitBy(values, isEmpty);
    return values;
});

const rowDirection = { xs: 'column', sm: 'row' } as const;

export const MSTeamsInformationStep = () => {
    const { data: brand, isLoading: brandLoading } = useBrandQuery();
    const { mutate: createBrand } = useBrandCreateQuery();
    const { mutate: updateBrand } = useBrandUpdateQuery();
    const navigate = useNavigate();
    const track = useTrack();

    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors },
        control,
        watch,
        reset,
    } = useForm<FormData>({
        defaultValues: {
            organizationLegalForm: CompanyEntityType.PRIVATE_PROFIT,
        },
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (brand) {
            reset({
                fullName: [
                    brand?.contactFirstName,
                    brand?.contactLastName,
                ].join(' '),
                companyName: brand?.companyName,
                companyAddress: brand?.companyAddress,
                companyCountry: brand?.companyCountry,
                companyZip: brand?.companyZip,
                city: brand?.city,
                state: brand?.state,
            });
        }
    }, [brand, reset]);

    const watchCountry = watch('companyCountry');

    const [isLoading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<FormData> = ({ fullName, ...data }) => {
        setLoading(true);

        const name = fullName.split(' ', 2);
        const brandData = {
            ...data,
            contactFirstName: name[0] ?? [],
            contactLastName: name[1] ?? '',
        };

        if (brand?.id) {
            updateBrand({
                id: brand.id,
                brand: brandData,
            });
        } else {
            createBrand(brandData);
        }
        setLoading(false);
        clearErrors();
        track('onboarding_user_completed_information');
        navigate('/setup-inbox/ms-teams/activation-date');
    };

    return (
        <StepShell
            title="Verification to Enable SMS"
            icon={<MSTeams />}
            subtitle="Providing your business information helps us verify that your phone numbers belong to you"
            goBackward="/setup-inbox/ms-teams/select"
        >
            <Box px={10} width="100%">
                {brandLoading ? (
                    <div>Loading...</div>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={10}>
                            <Stack spacing={6}>
                                <Stack
                                    spacing={6}
                                    direction={{ xs: 'column', sm: 'row' }}
                                >
                                    <TextField
                                        {...register('fullName', {
                                            required: true,
                                        })}
                                        error={!!errors.fullName}
                                        helperText={
                                            errors.fullName
                                                ? errors.fullName.message
                                                : null
                                        }
                                        fullWidth
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        label="Full name"
                                        InputProps={{
                                            notched: false,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PrivateUser />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <TextField
                                        {...register('companyName')}
                                        error={!!errors.companyName}
                                        helperText={
                                            errors.companyName
                                                ? errors.companyName.message
                                                : null
                                        }
                                        fullWidth
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        label="Legal company name"
                                        InputProps={{
                                            notched: false,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CompanyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>

                                <Stack spacing={6} direction={rowDirection}>
                                    <Controller
                                        name="companyCountry"
                                        control={control}
                                        render={({
                                            field: { value, onChange },
                                            fieldState,
                                        }) => (
                                            <FormControl
                                                error={!!fieldState.error}
                                                fullWidth
                                            >
                                                <InputLabel htmlFor="country">
                                                    Country
                                                </InputLabel>
                                                <EnhancedSelect
                                                    variant="outlined"
                                                    error={!!fieldState.error}
                                                    id="country"
                                                    searchable
                                                    options={countriesOptions}
                                                    value={value ?? ''}
                                                    onChange={onChange}
                                                    startAdornment={
                                                        !value && (
                                                            <InputAdornment position="start">
                                                                <CountryIcon />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                />
                                                {!!fieldState.error && (
                                                    <FormHelperText>
                                                        {
                                                            fieldState.error
                                                                .message
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        )}
                                    />

                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({
                                            field: { value, onChange },
                                            fieldState,
                                        }) => (
                                            <FormControl
                                                error={!!fieldState.error}
                                                fullWidth
                                            >
                                                <InputLabel htmlFor="state">
                                                    State / Province
                                                </InputLabel>
                                                <EnhancedSelect
                                                    variant="outlined"
                                                    error={!!fieldState.error}
                                                    id="state"
                                                    searchable
                                                    options={
                                                        watchCountry === 'CA'
                                                            ? canadaStateOptions
                                                            : stateOptions
                                                    }
                                                    value={value ?? ''}
                                                    onChange={onChange}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <MapIcon />
                                                        </InputAdornment>
                                                    }
                                                />
                                                {!!fieldState.error && (
                                                    <FormHelperText>
                                                        {
                                                            fieldState.error
                                                                .message
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        )}
                                    />
                                </Stack>

                                <TextField
                                    {...register('companyAddress', {
                                        required: true,
                                    })}
                                    error={!!errors.companyAddress}
                                    helperText={
                                        errors.companyAddress
                                            ? errors.companyAddress.message
                                            : null
                                    }
                                    fullWidth
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                    label="Address"
                                    InputProps={{
                                        notched: false,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Stack spacing={6} direction={rowDirection}>
                                    <TextField
                                        {...register('city', {
                                            required: true,
                                        })}
                                        error={!!errors.city}
                                        helperText={
                                            errors.city
                                                ? errors.city.message
                                                : null
                                        }
                                        fullWidth
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        label="City"
                                        InputProps={{
                                            notched: false,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MapIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <TextField
                                        {...register('companyZip', {
                                            required: true,
                                        })}
                                        error={!!errors.companyZip}
                                        helperText={
                                            errors.companyZip
                                                ? errors.companyZip.message
                                                : null
                                        }
                                        fullWidth
                                        inputProps={{
                                            autoComplete: 'off',
                                        }}
                                        label="ZIP / Postal Code"
                                        InputProps={{
                                            notched: false,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ZipIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Stack>

                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    component={Link}
                                    startIcon={<ArrowBackIcon />}
                                    sx={{ width: 104 }}
                                    variant="outlined"
                                    to="/setup-inbox/ms-teams/select"
                                >
                                    Back
                                </Button>

                                <Button sx={{ width: 104 }} type="submit">
                                    {isLoading ? <ButtonDotLoader /> : 'Next'}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Box>
        </StepShell>
    );
};

import { useByPhones } from '../../Contacts/use-contact-by-phone';
import { useMemo } from 'react';
import { CURRENT_USER_KEY, useMeQueryData } from '../../../queries/user';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import type { CountryCode } from 'libphonenumber-js/types';
import { getPlaceholder } from '../../Contacts/get-placeholder';
import { useQueryClient } from '@tanstack/react-query';
import { Profile } from '../../../api/types';

export const useName = (phones: string[], defaultValue = 'Conversation') => {
    const country =
        useQueryClient().getQueryData<Profile>([CURRENT_USER_KEY])?.activeTeam
            .countryCode || 'US';
    const memberQueries = useByPhones(phones);

    return {
        isLoading: memberQueries.some(({ isLoading }) => isLoading),
        data:
            phones
                .map(
                    (phone) =>
                        memberQueries.find(
                            (query) => query.data?.phone === phone,
                        )?.data ?? getPlaceholder(phone),
                )
                .map((contact) => {
                    return (
                        contact.name ||
                        formatPhoneNumber(contact.phone, country)
                    );
                })
                .join(', ') || defaultValue,
    };
};

export const useConversationName = (
    phones: string[],
    defaultValue = 'Conversation',
) => {
    const countryCode: CountryCode =
        useMeQueryData()?.activeTeam.countryCode || 'US';
    const memberQueries = useByPhones(phones);

    return useMemo<string>(
        () =>
            phones
                .map(
                    (phone) =>
                        memberQueries.find(
                            (query) => query.data?.phone === phone,
                        )?.data ?? getPlaceholder(phone),
                )
                .map((contact) => {
                    return (
                        contact.name ||
                        formatPhoneNumber(contact.phone, countryCode)
                    );
                })
                .join(', ') || defaultValue,
        [phones, defaultValue, memberQueries, countryCode],
    );
};

export const useConversationAvatar = (phones: string[]) => {
    const memberQueries = useByPhones(phones);
    return useMemo<string | undefined>(
        () =>
            phones
                .map(
                    (phone) =>
                        memberQueries.find(
                            (query) => query.data?.phone === phone,
                        )?.data ?? getPlaceholder(phone),
                )
                .find(({ avatarURL }) => avatarURL)?.avatarURL,
        [memberQueries, phones],
    );
};

import { Conversation } from '../../../api/types';
import { ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { useName } from '../../../components/Inbox/Conversation/use-conversation-props';
import dayjs from 'dayjs';

type Props = {
    conversation: Pick<
        Conversation,
        'members' | 'lastMessageBody' | 'lastMessageSent' | 'created'
    >;
};

export const ConversationListItemText = ({ conversation }: Props) => {
    const name = useName(conversation.members);
    const lastMessageDate =
        conversation.lastMessageSent ?? conversation.created;

    const date = (() => {
        if (dayjs(lastMessageDate).isToday()) {
            return dayjs(lastMessageDate).format('h:mm A');
        }
        return dayjs(lastMessageDate).format('D MMM');
    })();

    return (
        <ListItemText
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            primary={
                <Stack direction="row" justifyContent="space-between">
                    {name.isLoading ? <Skeleton width={125} /> : name.data}
                    <Typography
                        variant="body4"
                        color="custom.gray.super"
                        whiteSpace="nowrap"
                    >
                        {date}
                    </Typography>
                </Stack>
            }
            secondary={conversation.lastMessageBody}
            secondaryTypographyProps={{
                variant: 'body4',
            }}
        />
    );
};

import { SxProps } from '@mui/material';

export const wrapper: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 1,
    gap: 2,
    maxWidth: 620,
};

export const container: SxProps = {
    display: 'flex',
    gap: 2,
    alignItems: 'start',
};

export const header: SxProps = {
    display: 'flex',
    gap: 2,
    alignItems: 'center',

    '& img': {
        height: 16,
        width: 'auto',
    },
};

export const description: SxProps = {
    display: 'flex',
    alignItems: 'start',
    marginTop: 1,
    gap: 2,

    '& img': {
        maxHeight: 64,
        width: 'auto',
        height: 'auto',
        maxWidth: 128,
        borderRadius: 1.5,
    },
};

import { Divider, Skeleton, Stack } from '@mui/material';
import range from 'lodash/range';

export const ActionsSkeleton = () => (
    <Stack direction="row" gap={3}>
        {range(4).map((i) => (
            <Skeleton key={i} width={23} height={23} />
        ))}
        <Divider
            orientation="vertical"
            flexItem
            sx={{ height: 20, alignSelf: 'center' }}
        />
        <Skeleton width={23} height={23} />
    </Stack>
);

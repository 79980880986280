import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import { useState } from 'react';
import { useTrack } from '../../../../contexts/analytics';
import { DialogAttentionIcon } from '../../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../../elements/Dialog/DialogTitleTwoLine';
import { DeleteIcon } from '../../../../icons/common/CDeleteIcon';
import { ButtonDotLoader } from '../../../../components/Forms/ButtonDotLoader';

type DialogProps = { cardDetails: string; isLoading: boolean };
export const useConfirmDelete = (): [
    () => Promise<boolean>,
    ({ cardDetails, isLoading }: DialogProps) => React.ReactElement,
] => {
    const track = useTrack();
    const [open, setOpen] = useState<boolean>(false);
    const [rejector, setRejector] = useState(() => () => setOpen(false));
    const [resolver, setResolver] = useState(() => () => setOpen(false));

    const dialog = ({ cardDetails, isLoading }: DialogProps) => (
        <Dialog open={open} onClose={() => rejector()} maxWidth="xs">
            <DialogAttentionIcon />
            <DialogTitleTwoLine
                title="Delete a credit card"
                subtitle={
                    <Typography variant="body3" color="text.secondary">
                        Are you sure want to delete a credit card (
                        <Typography
                            variant="body3"
                            color="text.secondary"
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {cardDetails}
                        </Typography>
                        )?
                    </Typography>
                }
            />
            <DialogActions>
                <Button
                    onClick={() => {
                        rejector();
                    }}
                    sx={{ minWidth: 96 }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    color="error"
                    size="large"
                    onClick={() => {
                        resolver();
                    }}
                    sx={{ minWidth: 200 }}
                    fullWidth
                    disabled={isLoading}
                    variant="outlined"
                    startIcon={!isLoading && <DeleteIcon />}
                >
                    {isLoading ? <ButtonDotLoader /> : 'Yes, delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return [
        () => {
            setOpen(true);
            return new Promise((resolve, reject) => {
                setResolver(() => () => {
                    setOpen(false);
                    resolve(true);
                });
                setRejector(() => () => {
                    track('payment_method_deletion_canceled');
                    setOpen(false);
                    reject(false);
                });
            });
        },
        dialog,
    ];
};

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const AUFlagIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.2026 4.13788H1.79742C0.804703 4.13788 0 4.94258 0 5.9353V18.0647C0 19.0573 0.804703 19.8621 1.79742 19.8621H22.2026C23.1952 19.8621 24 19.0574 24 18.0647V5.9353C24 4.94258 23.1953 4.13788 22.2026 4.13788Z"
                fill="#41479B"
            />
            <path
                d="M0.413813 11.9999H1.13259L4.96556 9.48866V11.9999H7.03453V9.48866L10.8675 11.9999H11.5862C11.8148 11.9999 12 11.8146 12 11.5861V11.2579L8.71153 9.10339H12V7.03442H8.71148L12 4.87991V4.55169C12 4.32313 11.8147 4.13788 11.5862 4.13788H10.8674L7.03444 6.64916V4.13788H4.96547V6.64916L1.25831 4.22028C0.825469 4.35622 0.463594 4.65168 0.240609 5.0375L3.28856 7.03442H0V9.10339H3.28852L0 11.2579V11.5861C0 11.8146 0.185203 11.9999 0.413813 11.9999Z"
                fill="#F5F5F5"
            />
            <path
                d="M12 7.44824H6.62067V4.13788H5.37933V7.44824H0V8.68958H5.37933V11.9999H6.62067V8.68958H12V7.44824Z"
                fill="#FF4B55"
            />
            <path
                d="M0.38562 4.8235L3.79165 7.03445H4.55168L0.684683 4.52429C0.573636 4.61195 0.473276 4.7124 0.38562 4.8235Z"
                fill="#FF4B55"
            />
            <path
                d="M7.79446 7.03442L11.937 4.34539C11.865 4.22357 11.738 4.13788 11.5861 4.13788H11.4966L7.03442 7.03442H7.79446Z"
                fill="#FF4B55"
            />
            <path
                d="M4.19406 9.10339L0.0594482 11.7873C0.130745 11.9121 0.259745 11.9999 0.413776 11.9999H0.491823L4.95414 9.10339H4.19406Z"
                fill="#FF4B55"
            />
            <path
                d="M11.9934 11.6187L8.11843 9.10345H7.3584L11.7587 11.9598C11.8906 11.8986 11.981 11.7704 11.9934 11.6187Z"
                fill="#FF4B55"
            />
            <path
                d="M6.12522 14.2561L6.43755 15.2872L7.43847 14.8886C7.52388 14.8545 7.6015 14.9519 7.54938 15.0276L6.93794 15.9146L7.87366 16.4486C7.95349 16.4942 7.92578 16.6156 7.8341 16.622L6.7593 16.697L6.92528 17.7615C6.93944 17.8524 6.82722 17.9064 6.76507 17.8387L6.03625 17.0452L5.30744 17.8387C5.24528 17.9064 5.13307 17.8523 5.14722 17.7615L5.31321 16.697L4.23841 16.622C4.14672 16.6156 4.11897 16.4942 4.19885 16.4486L5.13457 15.9146L4.52313 15.0276C4.471 14.9519 4.54863 14.8545 4.63403 14.8886L5.63496 15.2872L5.94728 14.2561C5.97405 14.1681 6.0986 14.1681 6.12522 14.2561Z"
                fill="#F5F5F5"
            />
            <path
                d="M18.4349 6.92479L18.5875 7.42846L19.0764 7.23374C19.1181 7.21715 19.156 7.26468 19.1305 7.30167L18.8319 7.73503L19.289 7.99588C19.328 8.0181 19.3144 8.07745 19.2696 8.08054L18.7446 8.1172L18.8257 8.63718C18.8326 8.68157 18.7778 8.70796 18.7474 8.67487L18.3914 8.28726L18.0354 8.67487C18.005 8.70792 17.9502 8.68152 17.9571 8.63718L18.0381 8.1172L17.5131 8.08054C17.4683 8.0774 17.4548 8.0181 17.4938 7.99588L17.9508 7.73503L17.6522 7.30167C17.6267 7.26468 17.6647 7.21715 17.7063 7.23374L18.1953 7.42846L18.3479 6.92479C18.361 6.88176 18.4219 6.88176 18.4349 6.92479Z"
                fill="#F5F5F5"
            />
            <path
                d="M15.0329 10.7908L15.1855 11.2945L15.6745 11.0997C15.7161 11.0831 15.7541 11.1307 15.7286 11.1677L15.43 11.601L15.887 11.8619C15.926 11.8841 15.9125 11.9434 15.8677 11.9465L15.3427 11.9832L15.4238 12.5032C15.4307 12.5476 15.3759 12.574 15.3455 12.5409L14.9895 12.1533L14.6335 12.5409C14.6031 12.5739 14.5483 12.5475 14.5552 12.5032L14.6363 11.9832L14.1113 11.9465C14.0665 11.9434 14.0529 11.8841 14.0919 11.8619L14.549 11.601L14.2503 11.1677C14.2248 11.1307 14.2628 11.0831 14.3045 11.0997L14.7934 11.2945L14.946 10.7908C14.9591 10.7478 15.0199 10.7478 15.0329 10.7908Z"
                fill="#F5F5F5"
            />
            <path
                d="M21.6536 9.1356L21.8062 9.63927L22.2951 9.44455C22.3368 9.42796 22.3748 9.47549 22.3493 9.51247L22.0506 9.94583L22.5077 10.2067C22.5467 10.2289 22.5332 10.2883 22.4884 10.2913L21.9634 10.328L22.0445 10.848C22.0514 10.8924 21.9965 10.9188 21.9662 10.8857L21.6102 10.4981L21.2541 10.8857C21.2238 10.9187 21.169 10.8923 21.1759 10.848L21.257 10.328L20.732 10.2913C20.6872 10.2882 20.6736 10.2289 20.7126 10.2067L21.1697 9.94583L20.8711 9.51247C20.8456 9.47549 20.8835 9.42796 20.9252 9.44455L21.4142 9.63927L21.5667 9.1356C21.5797 9.09266 21.6406 9.09266 21.6536 9.1356Z"
                fill="#F5F5F5"
            />
            <path
                d="M18.4349 15.7563L18.5875 16.26L19.0764 16.0652C19.1181 16.0486 19.156 16.0962 19.1305 16.1332L18.8319 16.5665L19.289 16.8274C19.328 16.8496 19.3144 16.9089 19.2696 16.912L18.7446 16.9487L18.8257 17.4687C18.8326 17.5131 18.7778 17.5394 18.7474 17.5064L18.3914 17.1187L18.0354 17.5064C18.005 17.5394 17.9502 17.513 17.9571 17.4687L18.0381 16.9487L17.5131 16.912C17.4683 16.9089 17.4548 16.8496 17.4938 16.8274L17.9508 16.5665L17.6522 16.1332C17.6267 16.0962 17.6647 16.0486 17.7063 16.0652L18.1953 16.26L18.3479 15.7563C18.361 15.7134 18.4219 15.7134 18.4349 15.7563Z"
                fill="#F5F5F5"
            />
            <path
                d="M19.6154 11.6959L19.493 12.0632L19.1058 12.0662C19.0557 12.0666 19.0349 12.1305 19.0753 12.1603L19.3867 12.3903L19.2699 12.7595C19.2548 12.8073 19.3092 12.8468 19.3499 12.8176L19.6649 12.5925L19.9799 12.8176C20.0207 12.8467 20.0751 12.8072 20.06 12.7595L19.9432 12.3903L20.2547 12.1603C20.295 12.1305 20.2742 12.0666 20.2241 12.0662L19.837 12.0632L19.7145 11.6959C19.6985 11.6483 19.6313 11.6483 19.6154 11.6959Z"
                fill="#F5F5F5"
            />
        </svg>
    </SvgIcon>
);

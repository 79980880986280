import { UUID } from '../types/uuid';
import { PublicFile } from './types';

export enum CampaignType {
    INSTANT = 'instant', // with campaign messages
    PLANNED = 'planned', // with campaign messages
}

export enum CampaignStatus {
    OK = 'ok',
    SCHEDULED = 'scheduled',
    RUNNING = 'running',
    ERROR = 'error',
}

export interface CampaignContact {
    id: UUID;
    originalContactId: UUID;
    name?: string;
    phone?: string;
    email?: string;
    avatarURL?: string;
}

export interface Campaign {
    id: number;
    teamId: UUID;
    inboxId: UUID;
    name: string;
    body?: string;
    contactIds: string[];
    type: CampaignType;
    status: CampaignStatus;
    sendAt?: string;
    created: string;
    latestTimestamp: string;
    attachments?: PublicFile[];
}

export interface CampaignMeta {
    id: number;
    name: string;
    status: CampaignStatus;
}

export interface CreateCampaignDto {
    inboxId: UUID;
    fileIds?: string[];
    contactIds: string[];
    message: string;
    sendAt?: string;
    templateId?: number;
}

export interface UpdateCampaignDto {
    body: string;
    sendAt?: string;
    files?: string[];
}

export type CampaignOrMessageCreateParams = {
    inboxId: UUID;
    attachments?: UUID[];
    contacts: string[];
    phones: string[];
    body: string;
    timestamp?: string;
    templateId?: number;
};

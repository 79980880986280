import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const MSTeams = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
            <path
                d="M33.2173 18.1667H45.2715C46.4103 18.1667 47.3335 19.1592 47.3335 20.3834V32.1866C47.3335 36.686 43.9405 40.3334 39.755 40.3334H39.7192C35.5337 40.334 32.1403 36.6871 32.1396 32.1877C32.1396 32.1873 32.1396 32.187 32.1396 32.1866V19.3253C32.1397 18.6854 32.6222 18.1667 33.2173 18.1667Z"
                fill="#5059C9"
            />
            <path
                d="M41.3644 15.8335C44.0616 15.8335 46.2482 13.483 46.2482 10.5835C46.2482 7.684 44.0616 5.3335 41.3644 5.3335C38.6672 5.3335 36.4807 7.684 36.4807 10.5835C36.4807 13.483 38.6672 15.8335 41.3644 15.8335Z"
                fill="#5059C9"
            />
            <path
                d="M26.1707 15.8334C30.0667 15.8334 33.225 12.4382 33.225 8.25009C33.225 4.06192 30.0667 0.666748 26.1707 0.666748C22.2748 0.666748 19.1165 4.06192 19.1165 8.25009C19.1165 12.4382 22.2748 15.8334 26.1707 15.8334Z"
                fill="#7B83EB"
            />
            <path
                d="M35.5766 18.1667H15.6792C14.5539 18.1967 13.6638 19.2006 13.6899 20.4103V33.8724C13.5328 41.1317 18.8753 47.1558 25.6279 47.3334C32.3805 47.1558 37.723 41.1317 37.5659 33.8724V20.4103C37.592 19.2006 36.7018 18.1967 35.5766 18.1667Z"
                fill="#7B83EB"
            />
            <path
                opacity="0.1"
                d="M26.7133 18.1667V37.0317C26.7079 37.8968 26.2202 38.674 25.4761 39.0034C25.2392 39.1112 24.9845 39.1667 24.7272 39.1667H14.6451C14.504 38.7817 14.3738 38.3967 14.2652 38.0001C13.8853 36.6614 13.6915 35.2697 13.69 33.8701V20.4067C13.6639 19.199 14.5526 18.1967 15.6761 18.1667H26.7133Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M25.628 18.1667V38.1984C25.628 38.475 25.5763 38.7487 25.4761 39.0034C25.1697 39.8034 24.4467 40.3276 23.642 40.3334H15.1551C14.9706 39.9484 14.797 39.5634 14.6451 39.1667C14.4931 38.7701 14.3738 38.3967 14.2652 38.0001C13.8853 36.6614 13.6915 35.2697 13.69 33.8701V20.4067C13.6639 19.199 14.5526 18.1967 15.6761 18.1667H25.628Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M25.628 18.1667V35.8651C25.6197 37.0405 24.7354 37.9912 23.642 38.0001H14.2652C13.8853 36.6614 13.6915 35.2697 13.69 33.8701V20.4067C13.6639 19.199 14.5526 18.1967 15.6761 18.1667H25.628Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M24.5427 18.1667V35.8651C24.5345 37.0405 23.6501 37.9912 22.5567 38.0001H14.2652C13.8853 36.6614 13.6915 35.2697 13.69 33.8701V20.4067C13.6639 19.199 14.5526 18.1967 15.6761 18.1667H24.5427Z"
                fill="black"
            />
            <path
                opacity="0.1"
                d="M26.7133 12.1352V15.8102C26.5288 15.8219 26.3552 15.8336 26.1707 15.8336C25.9862 15.8336 25.8126 15.8219 25.6281 15.8102C25.2617 15.7841 24.8984 15.7216 24.5428 15.6236C22.3451 15.0641 20.5295 13.4072 19.6591 11.1669C19.5093 10.7907 19.393 10.4 19.3118 10.0002H24.7273C25.8224 10.0047 26.7092 10.9579 26.7133 12.1352Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M25.6279 13.302V15.8103C25.2616 15.7842 24.8983 15.7217 24.5427 15.6237C22.345 15.0642 20.5293 13.4073 19.6589 11.167H23.6419C24.737 11.1714 25.6238 12.1247 25.6279 13.302Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M25.6279 13.302V15.8103C25.2616 15.7842 24.8983 15.7217 24.5427 15.6237C22.345 15.0642 20.5293 13.4073 19.6589 11.167H23.6419C24.737 11.1714 25.6238 12.1247 25.6279 13.302Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M24.5427 13.302V15.6236C22.345 15.0642 20.5293 13.4073 19.6589 11.167H22.5566C23.6518 11.1715 24.5385 12.1247 24.5427 13.302Z"
                fill="black"
            />
            <path
                d="M2.65604 11.167H22.5534C23.6521 11.167 24.5427 12.1244 24.5427 13.3055V34.6951C24.5427 35.8762 23.6521 36.8336 22.5534 36.8336H2.65604C1.55738 36.8336 0.666748 35.8762 0.666748 34.6951V13.3055C0.666748 12.1244 1.5574 11.167 2.65604 11.167Z"
                fill="#5A62C3"
            />
            <path
                d="M17.8401 19.3075H13.8625V30.9508H11.3285V19.3075H7.36938V17.0488H17.8401V19.3075Z"
                fill="white"
            />
        </svg>
    </SvgIcon>
);

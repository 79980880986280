import { Suspense, lazy, useState, useRef } from 'react';

//components
import Search from '../../elements/Inputs/Search';
import useOnClickOutside from '../../hooks/useOnClickOutside';

//types
import { EmojiPicker as EmojiPickerProps } from '../../types/ComponentProps';
import { EmojiTab } from './EmojiList';

//styles
import styles from './EmojiPicker.module.scss';

const EmojiList = lazy(() => import('./EmojiList'));

const emojiPickerTabs = [
    { groupKey: 0, groupName: 'Smileys & Emotion', groupEmoji: '😀' },
    { groupKey: 1, groupName: 'People & Body', groupEmoji: '👋' },
    { groupKey: 3, groupName: 'Animals & Nature', groupEmoji: '🐵' },
    { groupKey: 4, groupName: 'Food & Drink', groupEmoji: '🍇' },
    { groupKey: 5, groupName: 'Travel & Places', groupEmoji: '🌍️' },
    { groupKey: 6, groupName: 'Activities', groupEmoji: '🎃' },
    { groupKey: 7, groupName: 'Objects', groupEmoji: '👓️' },
    { groupKey: 8, groupName: 'Symbols', groupEmoji: '🚫' },
    { groupKey: 9, groupName: 'Flags', groupEmoji: '🏁' },
];

function EmojiPicker({ onClick, onClose }: EmojiPickerProps) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, () => {
        onClose();
    });

    return (
        <div className={styles['clerk-emoji-picker']} ref={wrapperRef}>
            <div className={styles['clerk-emoji-picker__tabs']}>
                {emojiPickerTabs.map((emoji: EmojiTab, index) => (
                    <button
                        key={emoji.groupName}
                        className={`${styles['clerk-emoji-picker__tab']} ${
                            activeTabIndex === index
                                ? styles['clerk-emoji-picker__tab_active']
                                : ''
                        }`}
                        onClick={() => setActiveTabIndex(index)}
                    >
                        <span
                            className={`${styles['clerk-emoji-picker__emoji']} ${styles['clerk-emoji-picker__emoji-tab']}`}
                            title={emoji.groupName}
                        >
                            {emoji.groupEmoji}
                        </span>
                    </button>
                ))}
            </div>
            <div className={styles['clerk-emoji-picker__menu']}>
                <div className={styles['clerk-emoji-picker__menu-search']}>
                    <Search fullWidth={true} onSearch={setSearchValue} />
                </div>
                <div
                    className={`${styles['clerk-emoji-picker__menu-title']} clerk-label-0`}
                >
                    {emojiPickerTabs[activeTabIndex].groupName}
                </div>
                <Suspense
                    fallback={
                        <div
                            className={`${styles['clerk-emoji-picker__emoji-list']} loading`}
                        />
                    }
                >
                    <EmojiList
                        group={emojiPickerTabs[activeTabIndex]}
                        query={searchValue}
                        onSelect={onClick}
                    />
                </Suspense>
            </div>
        </div>
    );
}

export default EmojiPicker;

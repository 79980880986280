import { SxProps } from '@mui/material';

export const base = (selected = false): SxProps => ({
    position: 'relative',
    width: 1,
    minHeight: 72,

    gap: 4,
    px: 4,
    py: 2,

    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    bgcolor: selected ? 'custom.gray.active' : 'transparent',
    maxHeight: 72,

    transition: 'background-color .2s',

    ':hover': {
        bgcolor: 'custom.gray.sideBack',
    },

    ':focus-within': {
        outline: '1px solid',
        outlineColor: 'custom.gray.sideBack',
    },
});
export const mainText: SxProps = {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 500,
};
export const textLines: SxProps = {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    width: 1,
    gap: 2,
};
export const container: SxProps = {
    display: 'flex',
    width: 1,
    overflow: 'hidden',
    flexDirection: 'column',
};
export const avatar = (background: string): SxProps => ({
    background,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 500,
    letterSpacing: 1.5,
});

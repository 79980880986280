import { usePopper } from 'react-popper';
import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { PersonSimpleRun } from 'phosphor-react';
import styles from './ConversationsList.module.scss';
import ConversationsListFilter from '../ConversationsListFilter';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Conversation,
    ConversationsFilter,
    Inbox,
    InboxStatus,
} from '../../api/types';
import InboxItemActions from '../../elements/InboxItem/InboxItemActions';
import {
    useArchiveAllForInbox,
    useMarkAllAsReadForInbox,
} from '../../queries/inboxes';
import { useMeQueryData, useUpdateMe } from '../../queries/user';
import { InboxSettings } from '../../containers/InboxSettings/InboxSettings';
import { useModalContext } from '../../containers/modal/reducer';
import Loading from '../Loading/Loading';
import { Box, ButtonBase, IconButton, Tooltip } from '@mui/material';
import { Campaign } from '../../api/campaign.types';
import { useIsVerified } from '../../queries/settings';
import { getSquare } from '../../theme/style.helpers';
import { useNavigate } from 'react-router';
import { InboxCampaignList } from './InboxCampaignsList';
import { DotDivider } from '../common/DotDivider';
import { getViewSelector } from './styles';
import { useTrack } from '../../contexts/analytics';
import { CampaignConversationList } from './CampaignConversationList';
import { EmptyList } from './EmptyList';
import { AddIcon } from '../../icons/common/AddIcon';
import { MoreHorizFilledIcon } from '../../icons/common/MoreHorizFilled';
import { isMobile } from '../../utils/mobile';
import { TruncatedTypography } from '../TruncatedText/TruncatedText';

interface ConversationsListProps {
    view: 'Inbox' | 'Cohort' | 'Campaign';
    conversations: Conversation[];
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
    filter?: ConversationsFilter;
    name?: string;
    icon?: JSX.Element;
    inbox?: Inbox;
    campaigns?: Campaign[];
    isCampaignRunning?: boolean;
    placeholder?: boolean;
    isLoading?: boolean;
    fetchNextPage: () => void;
    getHref: (conversationId: string | number) => string;
    setFilter?: (filter: ConversationsFilter) => void;
    onCreateClick?: () => void;
}

enum Mode {
    Conversations,
    Campaigns,
}

const detectMode = (): Mode =>
    window.location.pathname.match(
        /^\/inbox\/([a-f\d-]+)\/campaigns(?:\/([a-f\d-]+))?.*/,
    )
        ? Mode.Campaigns
        : Mode.Conversations;

export const ConversationsList = ({
    name = 'Inbox',
    icon,
    isCampaignRunning,
    campaigns,
    conversations,
    hasNextPage,
    isFetchingNextPage,
    filter,
    inbox,
    view,
    isLoading,
    fetchNextPage,
    onCreateClick,
    setFilter,
}: ConversationsListProps) => {
    const track = useTrack();
    const navigate = useNavigate();

    const isVerified = useIsVerified();
    const [mode, setMode] = useState<Mode>(detectMode());
    const [contextMenuVisible, showContextMenu] = useState(false);

    const { dispatch } = useModalContext();

    const markAsReadMutation = useMarkAllAsReadForInbox();
    const { mutate: archiveAll } = useArchiveAllForInbox();
    const { mutate: updateMe } = useUpdateMe();
    const me = useMeQueryData();
    const isMuted = inbox
        ? me?.mutedInboxIds.includes(inbox!.id) || false
        : false;

    const [referenceElement, setReferenceElement] =
        useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null,
    );

    // Set the popper hook
    const { styles: popperStyles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [190, 0],
                    },
                },
            ],
        },
    );

    const hasItems = useMemo(() => {
        return conversations.length > 0 || (campaigns && campaigns.length > 0);
    }, [conversations, campaigns]);

    const openSettings = useCallback(() => {
        dispatch({
            type: 'PUSH',
            payload: {
                content: () => <InboxSettings inboxId={inbox!.id} />,
            },
        });
        showContextMenu(false);
    }, [dispatch, inbox]);

    const handleContextMenuClick = useCallback(() => {
        showContextMenu(true);
    }, []);

    return (
        <section className={styles['conversations']}>
            <header className={styles['conversations__header']}>
                <div
                    className={styles['conversations__title-container']}
                    ref={setReferenceElement}
                >
                    {icon && (
                        <div className={styles['conversations__header-icon']}>
                            {icon}
                        </div>
                    )}
                    <TruncatedTypography
                        lines={2}
                        variant="h3"
                        fontWeight={500}
                        color="primary.dark"
                    >
                        {name}
                    </TruncatedTypography>
                </div>

                {view === 'Inbox' && !isMobile() && (
                    <Box mr={1}>
                        <IconButton
                            color="primary"
                            onClick={handleContextMenuClick}
                        >
                            <MoreHorizFilledIcon />
                        </IconButton>
                    </Box>
                )}
                {onCreateClick && (
                    <Tooltip title="New chat">
                        <span>
                            <IconButton
                                size="medium"
                                color="info"
                                disabled={inbox?.status === InboxStatus.PENDING}
                                onClick={onCreateClick}
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                {contextMenuVisible &&
                    view === 'Inbox' &&
                    createPortal(
                        <div
                            ref={setPopperElement}
                            style={popperStyles.popper}
                            {...attributes.popper}
                        >
                            <InboxItemActions
                                isMuted={isMuted}
                                onMarkReadClick={() => {
                                    showContextMenu(false);
                                    markAsReadMutation.mutate({
                                        inboxId: inbox!.id,
                                    });
                                }}
                                onArchiveAllClick={() =>
                                    archiveAll({ inboxId: inbox!.id })
                                }
                                onOutsideClick={() => showContextMenu(false)}
                                onSettingsClick={openSettings}
                                onMute={() => {
                                    showContextMenu(false);
                                    updateMe({
                                        mutedInboxIds: [
                                            ...me!.mutedInboxIds,
                                            inbox!.id,
                                        ],
                                    });
                                }}
                                onUnmute={() => {
                                    showContextMenu(false);
                                    updateMe({
                                        mutedInboxIds: me!.mutedInboxIds.filter(
                                            (i) => i !== inbox!.id,
                                        ),
                                    });
                                }}
                            />
                        </div>,
                        document.querySelector('#portal')!,
                    )}
            </header>
            <section className={styles['conversations__list']}>
                {view === 'Inbox' && filter && setFilter && (
                    <>
                        <ConversationsListFilter
                            filter={filter}
                            onChange={(newFilter) => setFilter(newFilter)}
                        />
                        <Box
                            display="flex"
                            alignItems="center"
                            mt={-2}
                            mx={4}
                            width={1}
                        >
                            <ButtonBase
                                sx={getViewSelector(
                                    mode === Mode.Conversations,
                                )}
                                onClick={() => {
                                    track('inbox_conversations_clicked');
                                    setMode(Mode.Conversations);
                                }}
                            >
                                Conversations
                            </ButtonBase>
                            <DotDivider />
                            <ButtonBase
                                sx={getViewSelector(mode === Mode.Campaigns)}
                                onClick={() => {
                                    setMode(Mode.Campaigns);
                                    navigate(`/inbox/${inbox!.id}/campaigns`);
                                    track('inbox_campaigns_clicked');
                                }}
                            >
                                Campaigns{' '}
                                {!isVerified && (
                                    <Tooltip title="Brand registration required">
                                        <InfoOutlinedIcon
                                            sx={{ ...getSquare(12), ml: 1 }}
                                        />
                                    </Tooltip>
                                )}
                            </ButtonBase>
                        </Box>
                    </>
                )}

                {isLoading && (
                    <div className={styles['conversations__loader']}>
                        <Loading />
                    </div>
                )}

                {!isLoading && isCampaignRunning && (
                    <div className={styles['empty-list']}>
                        <PersonSimpleRun
                            className={styles['empty-list__icon']}
                            weight="bold"
                        />
                        <p className={styles['empty-list__text']}>
                            Campaign is still running...
                        </p>
                    </div>
                )}

                {mode === Mode.Conversations &&
                    (hasItems ? (
                        <CampaignConversationList
                            loadMore={() => {
                                if (!isFetchingNextPage && hasNextPage) {
                                    fetchNextPage();
                                }
                            }}
                            hasMore={hasNextPage}
                            campaigns={campaigns ?? []}
                            conversations={conversations ?? []}
                        />
                    ) : (
                        <EmptyList
                            type={filter?.type}
                            status={inbox?.status}
                            onClick={onCreateClick}
                        />
                    ))}
                {mode === Mode.Campaigns && inbox?.id && (
                    <InboxCampaignList
                        inboxId={inbox.id}
                        startChat={onCreateClick}
                    />
                )}
            </section>
        </section>
    );
};

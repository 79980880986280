import {
    Button,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { EmailIcon } from '../../../icons/common/CEmailIcon';
import { isEmailValid } from '../../../utils/invite';
import { useRequestMsTeamsAdminAccess } from '../../../queries/integrations';
import { useMemo, useState } from 'react';
import { CheckFilledIcon } from '../../../icons/filled/CheckFilledIcon';
import { colors } from '../../../theme/palette';
import { useTrack } from '../../../contexts/analytics';
import { AxiosError } from 'axios';
import { useMeQueryData } from '../../../queries/user';

export function RequestAdminAccess() {
    const [clientError, setClientError] = useState<string | null>(null);
    const {
        mutateAsync: requestAdminAccess,
        isSuccess,
        isPending,
        isError,
        error,
    } = useRequestMsTeamsAdminAccess();
    const me = useMeQueryData();
    const hasError = isError || !!clientError;

    const track = useTrack();
    const [email, setEmail] = useState('');

    const errorMessage = useMemo(() => {
        if (!hasError) {
            return undefined;
        }

        if (clientError) {
            return clientError;
        }

        if ((error as AxiosError)?.response?.status === 400) {
            return 'Invalid email address';
        }

        return 'Something went wrong. Please try again later.';
    }, [clientError, error, hasError]);

    if (isSuccess) {
        return (
            <Stack
                maxWidth={320}
                spacing={4}
                alignItems="center"
                sx={{
                    color: colors.green[600],
                }}
            >
                <CheckFilledIcon />

                <Stack spacing={1}>
                    <Typography
                        textAlign="center"
                        variant="h4"
                        color="primary.dark"
                    >
                        We have sent a note
                    </Typography>
                    <Typography
                        textAlign="center"
                        variant="body4"
                        color="custom.gray.super"
                    >
                        Your IT administrator will finalize your phone number
                        activation shortly.
                    </Typography>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack spacing={6}>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Typography
                        textAlign="center"
                        variant="h4"
                        color="primary.dark"
                    >
                        Not an administrator?
                    </Typography>
                    <Typography
                        textAlign="center"
                        variant="body4"
                        color="custom.gray.super"
                    >
                        Send a note to your Microsoft admin for help.
                    </Typography>
                </Stack>
                <TextField
                    error={hasError}
                    helperText={errorMessage}
                    name="token"
                    value={email}
                    onChange={(event) => {
                        setClientError(null);
                        setEmail((event.target.value ?? '').trim());
                    }}
                    InputProps={{
                        'aria-label': 'Email',
                        placeholder: 'Type in an admin email',
                        type: 'email',
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
            </Stack>
            <Button
                disabled={!email || !isEmailValid(email) || isPending}
                variant="contained"
                color="primary"
                size="large"
                sx={{ width: 320 }}
                onClick={() => {
                    if (email === me?.email) {
                        setClientError(
                            'You cannot request access for yourself',
                        );
                        return;
                    }

                    track('clicked_request_admin_access', {
                        admin_email: email,
                    });
                    requestAdminAccess(email).then(() => {
                        setEmail('');
                    });
                }}
            >
                Request access
            </Button>
        </Stack>
    );
}

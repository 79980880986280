import { SxProps } from '@mui/material';

export const list: SxProps = {
    mt: 0,
    listStyle: 'none',
};

export const createInboxLink: SxProps = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
};

export const createInboxLinkIcon: SxProps = {
    marginRight: 4,
    width: 20,
    height: 20,
};

import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const AccessTimeIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM10 5.20833C10.3452 5.20833 10.625 5.48816 10.625 5.83333V9.64613L12.4049 10.7141C12.7009 10.8917 12.7969 11.2756 12.6193 11.5716C12.4417 11.8675 12.0578 11.9635 11.7618 11.7859L9.67844 10.5359C9.49019 10.423 9.375 10.2195 9.375 10V5.83333C9.375 5.48816 9.65482 5.20833 10 5.20833Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { Box } from '@mui/material';
import { CampaignHeader } from '../CampaignHeader';

import { useMemo } from 'react';
import { CampaignMessageView } from '../../../../pages/NewInbox/CampaignMessageView';
import { useLocation } from 'react-router';
import { container } from './styles';

type Props = {
    campaignId: number;
    campaignMessageId: number;
};

export const CampaignMessage = ({ campaignId, campaignMessageId }: Props) => {
    const { pathname } = useLocation();
    const prevUrl = useMemo(
        () => pathname.split('/').slice(0, 5).join('/'),
        [pathname],
    );

    return (
        <Box sx={container}>
            <CampaignHeader prevUrl={prevUrl} campaignId={campaignId} />
            <CampaignMessageView campaignMessageId={campaignMessageId} />
        </Box>
    );
};

import styles from './PhoneVerification.module.scss';
import Callout from '../../components/Callout';
import FormButton from '../../elements/FormButton';
import Spacer from '../../components/Spacer';
import { useNavigate, useParams } from 'react-router';
import { PhoneVerificationProps } from './PhoneVerification';
import { useInboxQueryData, useInboxUpdateQuery } from '../../queries/inboxes';
import { InboxProvider } from '../../api/types';
import { Controller, useForm } from 'react-hook-form';
import AdvancedSelect, { OptionBase } from '../../elements/AdvancedSelect';
import FormControl from '../../elements/FormControl';
import { AnalyticsActions, useAnalytics } from '../../contexts/analytics';
import FormInput from '../../elements/FormInput';

const ReachOutStep = () => {
    const navigate = useNavigate();

    return (
        <Spacer height="auto" width="460px">
            <Spacer height={20} width="auto" />
            <Callout style="warning">
                <span>☝️ </span>
                <p>
                    We take security seriously. Please call{' '}
                    <b>
                        <a href="tel:+18885725375">1-(888) 572-5375</a>
                    </b>{' '}
                    and select <b>Option “0”</b>.
                </p>
            </Callout>
            <Spacer height={20} width="auto" />
            <FormButton
                variant="clear"
                text="Go Back"
                onClick={() => navigate(`/`)}
            />
        </Spacer>
    );
};

type PhoneConfigurationStepFormData = {
    locationType: 'IVR' | 'USER';
    pin: string;
};

const LocationTypeOptions: OptionBase[] = [
    {
        id: 'IVR',
        label: 'IVR',
    },
    {
        id: 'USER',
        label: 'User',
    },
];

const PhoneConfigurationStepForm = (props: PhoneVerificationProps) => {
    const { control, handleSubmit } = useForm<PhoneConfigurationStepFormData>();
    const navigate = useNavigate();

    const inbox = useInboxQueryData(props.inboxId);
    const { mutateAsync: updateInbox } = useInboxUpdateQuery();
    const analytics = useAnalytics();

    const onSubmit = async (data: PhoneConfigurationStepFormData) => {
        await updateInbox({
            id: props.inboxId,
            locationType: data.locationType,
            pin: data.pin,
        });

        analytics.dispatch({
            type: AnalyticsActions.TRACK,
            payload: {
                eventName: 'verification_phone_location_type',
            },
        });

        navigate(`/inbox/${props.inboxId}/verify/signature`);
    };

    return (
        <form
            className={styles['root__form']}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Callout style="warning">
                <span>📞</span>
                <div>
                    Please specify if your phone number is associated to a user
                    or an IVR Menu / Auto-Attendant.{' '}
                    <a
                        href="https://support.clerk.chat/microsoft-teams/phone-numbers"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn more
                    </a>
                </div>
            </Callout>
            <div className={styles['form-row']}>
                <Controller
                    name="locationType"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={inbox?.locationType}
                    render={({ field, fieldState }) => (
                        <Spacer height="55px" width="100%">
                            <FormControl label="Type">
                                <AdvancedSelect
                                    options={LocationTypeOptions}
                                    value={field.value}
                                    error={fieldState?.error?.message}
                                    onChange={(value) => {
                                        field.onChange(value as 'IVR' | 'USER');
                                    }}
                                />
                            </FormControl>
                        </Spacer>
                    )}
                />
            </div>
            <div className={styles['form-row']}>
                <Spacer height="55px" width="100%">
                    <Controller
                        name="pin"
                        control={control}
                        rules={{ required: true, minLength: 2 }}
                        defaultValue={inbox?.pin}
                        render={({ field, fieldState }) => (
                            <FormInput
                                label="PIN Code"
                                type="text"
                                error={fieldState.isDirty && fieldState.invalid}
                                iconName="lock"
                                hint={
                                    fieldState.isDirty && fieldState.invalid
                                        ? 'Please enter a valid pin'
                                        : undefined
                                }
                                {...field}
                            />
                        )}
                    />
                    <a
                        href="https://support.clerk.chat/microsoft-teams/pin"
                        target="_blank"
                        className={styles['form-row__pin_code']}
                        rel="noreferrer"
                    >
                        How do I find my PIN code?
                    </a>
                </Spacer>
            </div>
            <Spacer height={20} width="auto" />
            <FormButton variant="primary" text="Proceed" />
        </form>
    );
};

export const PhoneConfigurationStep = (props) => {
    const { inboxId } = useParams<PhoneVerificationProps>();
    const inbox = useInboxQueryData(inboxId ?? '');
    return inbox?.provider !== InboxProvider.MSTEAMS ? (
        <ReachOutStep />
    ) : (
        <PhoneConfigurationStepForm {...props} />
    );
};

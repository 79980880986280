import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const UnfoldMoreOutlined = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7841 5.33158L21.6697 8.26767C22.3716 8.98185 21.8814 10.2062 20.8898 10.2062H15.1186C14.127 10.2062 13.6257 8.98185 14.3276 8.26767L17.2132 5.33158C17.6477 4.88947 18.3496 4.88947 18.7841 5.33158ZM17.2159 18.6683L14.3303 15.7322C13.6284 15.018 14.1186 13.7937 15.1102 13.7937H20.8814C21.873 13.7937 22.3743 15.018 21.6724 15.7322L18.7868 18.6683C18.3523 19.1104 17.6504 19.1104 17.2159 18.6683Z"
                fill="currentColor"
                fillOpacity="0.8"
            />
        </svg>
    </SvgIcon>
);

import { Components, Theme } from '@mui/material';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
    styleOverrides: {
        elevation1: {
            boxShadow: '0px 1px 1px 0px rgba(31, 41, 54, 0.16)',
        },
        elevation5: {
            boxShadow: '0 6px 32px 0 rgba(31, 41, 54, 0.08)',
        },
    },
};

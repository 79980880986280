import { Inbox } from '../api/types';
import { UUID } from '../types/uuid';
import { formatPhoneNumber } from './phoneNumber';

export const renderInboxIcon = (icon?: string) => {
    if (!icon) {
        return '📱';
    }
    let emoji: string;

    try {
        emoji = String.fromCodePoint(parseInt(`0x${icon}`));
    } catch (e) {
        emoji = '📱';
    }
    return emoji;
};

export const renderInboxName = (inbox: Inbox) =>
    inbox.name || formatPhoneNumber(inbox.phone);

export const sortInboxesByPredefinedOrder = (
    inboxes: Inbox[],
    order: UUID[],
) => {
    const result = new Array(order.length);

    inboxes.forEach((inbox) => {
        const idx = order.indexOf(inbox.id);
        if (idx === -1) {
            result.push(inbox);
        } else {
            result[idx] = inbox;
        }
    });

    // If there are any ids of not anymore existing inboxes
    // we need to filter out that gaps from array
    return result.filter((x) => x);
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

/**
 * Used in checkbox component
 **/
export const CheckboxEmpty = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        {props.fontSize === 'small' ? (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6667 0.833333H3.33333C1.95262 0.833333 0.833333 1.95262 0.833333 3.33333V16.6667C0.833333 18.0474 1.95262 19.1667 3.33333 19.1667H16.6667C18.0474 19.1667 19.1667 18.0474 19.1667 16.6667V3.33333C19.1667 1.95262 18.0474 0.833333 16.6667 0.833333ZM3.33333 0C1.49238 0 0 1.49238 0 3.33333V16.6667C0 18.5076 1.49238 20 3.33333 20H16.6667C18.5076 20 20 18.5076 20 16.6667V3.33333C20 1.49238 18.5076 0 16.6667 0H3.33333Z"
                    fill="currentColor"
                />
            </svg>
        ) : (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 21"
                fill="none"
            >
                <path
                    d="M15.8333 4.66667V16.3333H4.16667V4.66667H15.8333ZM15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3Z"
                    fill="currentColor"
                />
            </svg>
        )}
    </SvgIcon>
);

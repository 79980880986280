import { useEffect, useMemo } from 'react';
import { useEnabledFeature, useMeQuery } from '../../../queries/user';
import { Feature } from '../../../api/types';
import { useNavigate } from 'react-router';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { ActivePlan } from './ActivePlan';
import { PlanUsage } from './PlanUsage/PlanUsage';
import { Button, Divider } from '@mui/material';
import {
    useInvoices,
    useRenewTeamSubscription,
    useTeamPaymentMethods,
    useTeamSubscription,
} from '../../../queries/billing';
import Loading from '../../../components/Loading/Loading';
import { PaymentMethods } from './PaymentMethods';
import { Invoices } from './Invoices';
import { CancelSubscription } from './CancelSubscription';
import { AdapterLink } from '../../../components/ConversationsList/AdapterLink';
import { RefreshIcon } from '../../../icons/common/RefreshIcon';
import { isString } from 'lodash/fp';

export const Billing = () => {
    const { data: me, isFetchedAfterMount } = useMeQuery();
    const canManageBilling = useEnabledFeature(Feature.ManageBilling);
    const navigate = useNavigate();
    const { data: mainSubscription, isLoading: isMainSubscriptionLoading } =
        useTeamSubscription({
            enabled: canManageBilling && !!me?.activeTeam.stripeSubscriptionId,
            teamId: me?.activeTeam.id,
        });

    const {
        data: { paymentMethods, defaultPaymentMethodId } = {
            paymentMethods: [],
            defaultPaymentMethodId: '',
        },
        isLoading: isPaymentMethodsLoading,
    } = useTeamPaymentMethods({
        enabled: canManageBilling && !!me?.activeTeam.stripeSubscriptionId,
        teamId: me?.activeTeam.id,
    });

    const defaultPaymentMethodIdFromExisted = useMemo(() => {
        const methodId = isString(defaultPaymentMethodId)
            ? defaultPaymentMethodId
            : (defaultPaymentMethodId?.id ?? '');
        return paymentMethods.find((method) => method.id === methodId)?.id;
    }, [paymentMethods, defaultPaymentMethodId]);

    const { mutateAsync: renew, isPending } = useRenewTeamSubscription();

    const { data: invoices = [], isLoading: isInvoiceLoading } = useInvoices({
        enabled: canManageBilling && !!me?.activeTeam.stripeSubscriptionId,
        teamId: me?.activeTeam.id,
    });

    const isSubscriptionCanceled = useMemo(() => {
        return (
            me?.activeTeam.tier === 'free' ||
            mainSubscription?.status === 'canceled'
        );
    }, [me?.activeTeam.tier, mainSubscription?.status]);

    useEffect(() => {
        if (!canManageBilling) {
            navigate('/');
        }
    }, [canManageBilling, navigate]);

    useEffect(() => {
        if (me && !me?.activeTeam.stripeSubscriptionId && isFetchedAfterMount) {
            navigate('/payment/plan');
        }
    }, [me, navigate, isFetchedAfterMount]);

    if (
        isMainSubscriptionLoading ||
        isPaymentMethodsLoading ||
        isInvoiceLoading
    ) {
        return <Loading />;
    }

    return (
        <div>
            {isFetchedAfterMount && (
                <SettingsLayout
                    title="Billing"
                    description="Update your plan and payment methods, access or edit the billing period, and view or download invoices."
                    category="Data"
                    action={
                        isSubscriptionCanceled ? (
                            <Button
                                size="large"
                                color="info"
                                startIcon={<RefreshIcon />}
                                LinkComponent={AdapterLink}
                                href="/payment/plan"
                                sx={{ flex: '1 0 auto' }}
                            >
                                Renew subscription
                            </Button>
                        ) : mainSubscription?.cancelAtPeriodEnd ? (
                            <Button
                                size="large"
                                color="info"
                                startIcon={<RefreshIcon />}
                                onClick={() => {
                                    renew();
                                }}
                                disabled={isPending}
                                sx={{ flex: '1 0 auto' }}
                            >
                                Renew subscription
                            </Button>
                        ) : null
                    }
                >
                    {!isSubscriptionCanceled && (
                        <>
                            <ActivePlan subscription={mainSubscription} />
                            <Divider />
                            <PlanUsage />
                            <Divider />
                            <PaymentMethods
                                paymentMethods={paymentMethods}
                                subscription={mainSubscription}
                                defaultPaymentMethodId={
                                    defaultPaymentMethodIdFromExisted
                                }
                            />
                            <Divider />
                        </>
                    )}

                    <Invoices invoices={invoices} />
                    {!isSubscriptionCanceled && (
                        <CancelSubscription subscription={mainSubscription} />
                    )}
                </SettingsLayout>
            )}
        </div>
    );
};

import cc from 'classcat';
import styles from './DroplistItems.module.scss';

interface DroplistItemsProps {
    className?: string;
    children: React.ReactNode;
}

/** @deprecated use mui/list instead */
export const DroplistItems = ({ className, children }: DroplistItemsProps) => (
    <div className={cc([styles['root'], className])}>{children}</div>
);

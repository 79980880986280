import { CustomEditor } from '../../../slate';
import { insertNodes } from '../../../utils';
import { TextSerializer } from '../../../serializers/text';

export const insertSnippet = (
    editor: CustomEditor,
    snippetString: string,
    regEx: RegExp,
) => {
    const nodes = TextSerializer.deserialize(snippetString, regEx);
    if (nodes.length) {
        insertNodes(editor, nodes, true);
    }
};

import { IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';
import { useGoToQueryParam } from '../../pages/NewInbox/query.helpers';
import { ContactInfoIcon } from '../../icons/common/CContactInfoIcon';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { useSearchParams } from 'react-router-dom';

export function ContactInfoButton() {
    const isUpLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
    const goToQueryParam = useGoToQueryParam();
    const [searchParams] = useSearchParams();
    const handleClick = () => {
        const state = searchParams.get(QueryParam.ContactInfo);
        const discussionId = searchParams.get(QueryParam.DiscussionId);

        let next = 'true';
        if (state) {
            next = state === 'false' ? 'true' : 'false';
        } else if (isUpLg && !discussionId) {
            next = 'false';
        }

        goToQueryParam(
            {
                [QueryParam.ContactInfo]: next,
            },
            [QueryParam.DiscussionId],
        );
    };

    return (
        <Tooltip arrow placement="bottom" title="Show more info">
            <IconButton color="primary" onClick={handleClick}>
                <ContactInfoIcon />
            </IconButton>
        </Tooltip>
    );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import client from '../../../../api/http';
import { useTrack } from '../../../../contexts/analytics';
import { queryClient } from '../../../../queries/queryClient';

export type StopPhrase = { id: number; phrase: string };

const QUERY_KEY: readonly string[] = ['stop_words'];

export const useKeywords = (enabled = true) =>
    useQuery({
        queryKey: QUERY_KEY,
        staleTime: 10 * 6000,
        gcTime: 10 * 6000,
        queryFn: () =>
            client()
                .get<StopPhrase[]>('/assistant/stop-phrases')
                .then(({ data }) => data),
        enabled,
    });

export const useDeleteKeyword = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (id: number) =>
            client()
                .delete<null>(`/assistant/stop-phrases/${id}`)
                .then(() => id),
        onSuccess: (id: number) => {
            track('assistant_keyword_deleted');
            queryClient.setQueryData<StopPhrase[]>(
                QUERY_KEY,
                (prev) => prev?.filter((phrase) => phrase.id !== id) || [],
            );
        },
    });
};

export function useCreateKeyword() {
    const track = useTrack();

    return useMutation({
        mutationFn: (phrase: { phrase: string }) =>
            client()
                .post<StopPhrase[]>('/assistant/stop-phrases', phrase)
                .then(({ data }) => data),
        onSuccess: (created) => {
            track('assistant_keyword_added');
            queryClient.setQueryData<StopPhrase[]>(QUERY_KEY, (prev) =>
                (prev || []).concat(created),
            );
        },
    });
}

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const MXFlagIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.79742 4.13782C0.804703 4.13782 0 4.94252 0 5.93524V18.0646C0 19.0572 0.804703 19.862 1.79742 19.862H8.00002V4.13782H1.79742Z"
                fill="#73AF00"
            />
            <path
                d="M16.0003 4.138H8.00012V19.8622H16.0003V4.138Z"
                fill="#F5F5F5"
            />
            <path
                d="M22.2026 4.13782H16V19.8619H22.2026C23.1953 19.8619 24 19.0572 24 18.0645V5.93524C24 4.94252 23.1953 4.13782 22.2026 4.13782Z"
                fill="#FF4B55"
            />
            <path
                d="M12.7137 12.37L12 12.7269V13.0837H12.3569L12.7137 12.37Z"
                fill="#FFD250"
            />
            <path
                d="M12 13.9758C11.9014 13.9758 11.8215 13.896 11.8215 13.7974V13.0837C11.8215 12.9851 11.9014 12.9053 12 12.9053C12.0986 12.9053 12.1784 12.9851 12.1784 13.0837V13.7974C12.1784 13.896 12.0986 13.9758 12 13.9758Z"
                fill="#00C3A0"
            />
            <path
                d="M11.9999 13.2621C11.2991 13.2621 10.6224 12.9917 10.0943 12.5007C10.0222 12.4336 10.018 12.3207 10.0851 12.2486C10.1527 12.1766 10.2655 12.1728 10.3372 12.2393C10.7992 12.6688 11.3897 12.9053 12 12.9053C12.6104 12.9053 13.2009 12.6688 13.6628 12.2393C13.7348 12.1726 13.8475 12.1763 13.9149 12.2486C13.982 12.3207 13.9778 12.4336 13.9057 12.5007C13.3775 12.9917 12.7008 13.2621 11.9999 13.2621Z"
                fill="#00C3A0"
            />
            <path
                d="M12 13.9758C11.5426 13.9758 11.0995 13.8422 10.7186 13.5892L10.9162 13.2919C11.5602 13.7199 12.4398 13.7199 13.0838 13.2919L13.2813 13.5892C12.9005 13.8422 12.4574 13.9758 12 13.9758Z"
                fill="#00AADC"
            />
            <path
                d="M11.7175 11.5671L11.3235 12.1767L11.2566 12.3775L12.2825 12.1396L11.7175 11.5671Z"
                fill="#732D37"
            />
            <path
                d="M10.9081 12.5735C10.4364 12.5735 9.87028 12.1766 9.72009 11.2921L10.0721 11.2326C10.1969 11.968 10.6494 12.2172 10.921 12.2165C11.0043 12.2142 11.0912 12.1855 11.098 12.1266C11.106 12.0562 11.108 12.0395 10.9214 11.9764C10.8199 11.9421 10.7049 11.9033 10.6243 11.8109C10.2979 11.4382 10.7398 11.0438 10.9521 10.8542C10.9895 10.8207 11.005 10.7923 11.001 10.7815C10.9894 10.751 10.9193 10.7049 10.8404 10.7049C10.6137 10.7049 10.4417 10.5768 10.3918 10.3709C10.3398 10.1576 10.4379 9.93282 10.6247 9.8363L10.7886 10.1534C10.7448 10.1761 10.7274 10.2405 10.7385 10.2865C10.7429 10.305 10.7535 10.348 10.8405 10.348C11.059 10.348 11.2669 10.477 11.3345 10.6544C11.3634 10.73 11.4069 10.9265 11.1899 11.1204C10.9555 11.3297 10.8152 11.4872 10.893 11.5759C10.9105 11.5959 10.986 11.6216 11.0358 11.6383C11.1965 11.6927 11.4955 11.7938 11.4526 12.1675C11.4258 12.4025 11.216 12.5656 10.931 12.5733C10.9232 12.5733 10.9155 12.5735 10.9081 12.5735Z"
                fill="#AAD264"
            />
            <path
                d="M12.3569 10.5858C12.3569 10.5858 12.7137 9.87214 12 9.51528C12 9.51528 14.4979 9.15842 14.1411 12.3701L14.1409 12.37C13.9067 12.1826 13.559 11.8402 13.4348 11.5672L12.3569 10.9427V10.5858Z"
                fill="#874150"
            />
            <path
                d="M12 11.2994L13.7842 12.37C13.7842 12.37 13.7842 12.37 13.0705 12.7268L12 11.6563V11.2994Z"
                fill="#732D37"
            />
            <path
                d="M11.2864 10.2289C11.4834 10.2289 11.6432 10.3887 11.6432 10.5858L11.6429 10.5862C11.43 10.7991 11.3772 11.1243 11.5118 11.3936L11.5925 11.5548C11.6261 11.6221 11.6703 11.6835 11.7235 11.7366L12.7138 12.727V12.2338C12.7138 11.6194 12.5707 11.0134 12.296 10.4639C12.1146 10.1013 11.7439 9.87213 11.3385 9.87213H11.2864V10.2289Z"
                fill="#783C46"
            />
            <path
                d="M11.2863 9.87207L10.9294 10.2289V10.5858C10.9294 10.5858 11.3539 10.3706 11.497 10.302C11.597 10.2541 11.5788 10.1408 11.5214 10.0862C11.4768 10.0437 11.2863 9.87207 11.2863 9.87207Z"
                fill="#FFD250"
            />
            <path
                d="M12 14.6895C10.3274 14.6895 8.9668 13.3289 8.9668 11.6563H9.32366C9.32366 13.1322 10.5242 14.3327 12 14.3327C13.4759 14.3327 14.6764 13.1322 14.6764 11.6563H15.0333C15.0332 13.3289 13.6725 14.6895 12 14.6895Z"
                fill="#AAD264"
            />
            <path
                d="M9.34311 12.9573C9.47685 12.9573 9.58526 12.8489 9.58526 12.7152C9.58526 12.5814 9.47685 12.473 9.34311 12.473C9.20937 12.473 9.10095 12.5814 9.10095 12.7152C9.10095 12.8489 9.20937 12.9573 9.34311 12.9573Z"
                fill="#FFE15A"
            />
            <path
                d="M9.87839 13.8123C10.0121 13.8123 10.1205 13.7039 10.1205 13.5702C10.1205 13.4364 10.0121 13.328 9.87839 13.328C9.74465 13.328 9.63623 13.4364 9.63623 13.5702C9.63623 13.7039 9.74465 13.8123 9.87839 13.8123Z"
                fill="#FFE15A"
            />
            <path
                d="M10.7479 14.4587C10.8816 14.4587 10.99 14.3503 10.99 14.2166C10.99 14.0828 10.8816 13.9744 10.7479 13.9744C10.6142 13.9744 10.5057 14.0828 10.5057 14.2166C10.5057 14.3503 10.6142 14.4587 10.7479 14.4587Z"
                fill="#FFE15A"
            />
            <path
                d="M14.6826 12.9573C14.8163 12.9573 14.9247 12.8489 14.9247 12.7152C14.9247 12.5814 14.8163 12.473 14.6826 12.473C14.5488 12.473 14.4404 12.5814 14.4404 12.7152C14.4404 12.8489 14.5488 12.9573 14.6826 12.9573Z"
                fill="#FFE15A"
            />
            <path
                d="M14.1468 13.8123C14.2806 13.8123 14.389 13.7039 14.389 13.5702C14.389 13.4364 14.2806 13.328 14.1468 13.328C14.0131 13.328 13.9047 13.4364 13.9047 13.5702C13.9047 13.7039 14.0131 13.8123 14.1468 13.8123Z"
                fill="#FFE15A"
            />
            <path
                d="M13.2773 14.4587C13.4111 14.4587 13.5195 14.3503 13.5195 14.2166C13.5195 14.0828 13.4111 13.9744 13.2773 13.9744C13.1436 13.9744 13.0352 14.0828 13.0352 14.2166C13.0352 14.3503 13.1436 14.4587 13.2773 14.4587Z"
                fill="#FFE15A"
            />
            <path
                d="M12.1784 14.1542H11.8216C11.6245 14.1542 11.4647 13.9944 11.4647 13.7974C11.4647 13.6003 11.6245 13.4405 11.8216 13.4405H12.1784C12.3755 13.4405 12.5353 13.6003 12.5353 13.7974C12.5353 13.9945 12.3755 14.1542 12.1784 14.1542Z"
                fill="#FFB478"
            />
            <path
                d="M13.4348 11.5671C13.5538 11.9165 13.8065 12.2362 14.1409 12.3699L14.1411 12.37C14.4979 9.15842 12 9.51528 12 9.51528C13.7842 9.51528 13.4348 11.5671 13.4348 11.5671Z"
                fill="#9B4B5A"
            />
        </svg>
    </SvgIcon>
);

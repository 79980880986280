import { BaseRange } from 'slate';
import { emojiShortcodes } from './utils';
import {
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import { ReactEditor, useSlate } from 'slate-react';
import { useEffect, useRef } from 'react';

export function SearchEmojiPicker({
    targetRange,
    search,
    searchIndex,
    emojis,
    onInsert,
}: {
    targetRange?: BaseRange;
    search: string;
    searchIndex: number;
    emojis: string[];
    onInsert: (emoji: string) => void;
}) {
    const editor = useSlate();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current && targetRange) {
            const domRange = ReactEditor.toDOMRange(editor, targetRange);
            const rect = domRange.getBoundingClientRect();
            ref.current.style.top = `${rect.top + window.scrollY - ref.current.clientHeight}px`;
            ref.current.style.left = `${rect.left + window.scrollX}px`;
        } else if (ref.current) {
            ref.current.style.top = '-9999px';
            ref.current.style.left = '-9999px';
        }
    }, [editor, targetRange, searchIndex, search]);

    if (!targetRange || !emojis.length) {
        return null;
    }

    return (
        <Popper
            ref={ref}
            placement="top-start"
            style={{ top: -9999, left: -9999 }}
            sx={{
                position: 'absolute',
            }}
            open={true}
        >
            <Paper
                sx={{
                    minWidth: 270,
                    border: '1px solid',
                    borderColor: 'custom.gray.divider',
                }}
            >
                <MenuList>
                    {emojis.map((emoji, idx) => (
                        <MenuItem
                            key={emoji}
                            onClick={() => {
                                onInsert(emoji);
                            }}
                            selected={searchIndex === idx}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Typography>
                                    {emojiShortcodes[emoji]}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    color="custom.gray.super"
                                >
                                    :{emoji}:
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
                </MenuList>
            </Paper>
        </Popper>
    );
}

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Stack,
} from '@mui/material';
import { DialogAttentionIcon } from '../../../elements/Dialog/DialogAttentionIcon';
import { useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { getScheduleButtonLabel } from './utils';
import { CalendarIcon } from '../../../icons/common/CСalendarIcon';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { DateTimePickerLayout } from '../../../theme/DateTimePickerLayout';

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'hh:mm A';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

type Props = {
    open: boolean;
    disabled?: boolean;
    onClose: () => void;
    minDate?: Date;
    timestamp: dayjs.ConfigType;
    onChange: (d: Date) => void;
    title?: string;
    children: React.ReactNode;
};

type Inputs = {
    dateTime: string;
};

export const scheduledMessageSchema = object({
    dateTime: string().trim().required(),
});

export const TimestampChangeDialog = ({
    open,
    disabled = false,
    onClose,
    minDate,
    children,
    onChange,
    title = 'Change timestamp',
    timestamp = new Date(),
}: Props) => {
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [isTimeOpen, setIsTimeOpen] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        watch,
        control,
    } = useForm<Inputs>({
        resolver: yupResolver(scheduledMessageSchema),
        defaultValues: {
            dateTime: dayjs(timestamp).format(DATE_TIME_FORMAT),
        },
    });

    const dateTime = watch('dateTime');

    const onSubmit = (data: Inputs) => {
        onChange(dayjs(data.dateTime).toDate());
    };

    return (
        <Dialog open={open} id="ts-dialog-portal" onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogAttentionIcon color="primary" />
                <DialogTitle textAlign="center">{title}</DialogTitle>
                <DialogContent sx={{ maxWidth: 420 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" alignItems="end" spacing={4}>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        label="Scheduled time"
                                        minDate={dayjs(minDate)}
                                        open={isDateOpen}
                                        onClose={() => setIsDateOpen(false)}
                                        disablePast
                                        slotProps={{
                                            layout: {
                                                sx: DateTimePickerLayout,
                                            },
                                            textField: {
                                                inputProps: {
                                                    'data-testid':
                                                        'tcd-date-input',
                                                },
                                                fullWidth: true,
                                                onClick: () =>
                                                    setIsDateOpen(true),
                                            },
                                            inputAdornment: {
                                                position: 'start',
                                            },
                                        }}
                                        onChange={(date) => {
                                            if (date) {
                                                onChange(
                                                    dayjs(date).format(
                                                        DATE_TIME_FORMAT,
                                                    ),
                                                );
                                            }
                                        }}
                                        slots={{
                                            inputAdornment: () => (
                                                <InputAdornment position="start">
                                                    <CalendarIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={disabled}
                                        format={DATE_FORMAT}
                                        value={dayjs(value)}
                                    />
                                )}
                                name="dateTime"
                            />
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TimePicker
                                        timeSteps={{
                                            minutes: 1,
                                        }}
                                        disablePast={dayjs(dateTime).isToday()}
                                        open={isTimeOpen}
                                        onClose={() => setIsTimeOpen(false)}
                                        onChange={(time) => {
                                            if (time) {
                                                onChange(
                                                    dayjs(time).format(
                                                        DATE_TIME_FORMAT,
                                                    ),
                                                );
                                            }
                                        }}
                                        slotProps={{
                                            layout: {
                                                sx: DateTimePickerLayout,
                                            },
                                            textField: {
                                                inputProps: {
                                                    'data-testid':
                                                        'tcd-time-input',
                                                },
                                                sx: { width: 162 },
                                                onClick: () =>
                                                    setIsTimeOpen(true),
                                            },
                                        }}
                                        slots={{
                                            inputAdornment: () => null,
                                        }}
                                        data-testid="tcd-time-input"
                                        disabled={disabled}
                                        format={TIME_FORMAT}
                                        value={dayjs(value)}
                                    />
                                )}
                                name="dateTime"
                            />
                        </Stack>
                    </LocalizationProvider>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button
                        data-testid="tcd-dismiss"
                        disabled={disabled}
                        onClick={onClose}
                        variant="outlined"
                        size="large"
                        color="primary"
                        sx={{ minWidth: 96 }}
                    >
                        Dismiss
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        data-testid="tcd-submit"
                        disabled={!!errors.dateTime || disabled}
                        color="primary"
                        type="submit"
                    >
                        {getScheduleButtonLabel(dayjs(dateTime))}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

import { useMutation, useQuery } from '@tanstack/react-query';
import client from '../../../api/http';
import { Source } from './source.types';
import { useTrack } from '../../../contexts/analytics';
import { queryClient } from '../../../queries/queryClient';

type Answer = {
    answer: string;
};

const SOURCES_KEY = 'knowledge-base/sources';

export const useSources = () =>
    useQuery({
        queryKey: [SOURCES_KEY],
        queryFn: () =>
            client()
                .get<Source[]>('knowledge-base/sources')
                .then(({ data }) => data),
    });

export const useAskQuestion = (key?: string) => {
    const track = useTrack();

    return useMutation({
        mutationKey: ['ai-answer', key],
        mutationFn: (
            params: { question: string } | { conversationId: string },
        ) =>
            client()
                .post<Answer>(`ai/knowledge-base-answer`, params)
                .then(({ data }) => data.answer),
        onSuccess: () => {
            track('assistant_asked');
        },
    });
};

export const useSourceFileMutation = () => {
    const { refetch } = useSources();
    const track = useTrack();

    return useMutation({
        mutationFn: (file: File) => {
            const form = new FormData();
            form.append('input', file);

            return client()
                .post<Source>('knowledge-base/source-files', form)
                .then(({ data }) => data);
        },
        onSuccess: (source) => {
            const parts = source.title.split('.');
            track('assistant_source_added', {
                type: source.type,
                extension: parts[parts.length - 1],
            });
            refetch();
        },
        onError: () => {
            track('assistant_source_add_failed', {
                type: 'file',
            });
        },
    });
};

export function useTextSourceMutation() {
    const track = useTrack();

    return useMutation({
        mutationFn: (raw: string) =>
            client()
                .post<Source>(SOURCES_KEY, { input: raw })
                .then(({ data }) => data),
        onSuccess: (source) => {
            track('assistant_source_added', {
                type: source.type,
            });
            queryClient.setQueryData<Source[]>([SOURCES_KEY], (data) => {
                return [...(data || []), source];
            });
        },
        onError: () => {
            track('assistant_source_add_failed', {
                type: 'raw',
            });
        },
    });
}

export const useDeleteSourceMutation = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (id: number) =>
            client()
                .delete<null>(`/knowledge-base/sources/${id}`)
                .then(({ data }) => data),
        onSuccess: (_, sourceId) => {
            track('assistant_source_deleted');
            queryClient.setQueryData<Source[]>([SOURCES_KEY], (data) =>
                data?.filter(({ id }) => id !== sourceId),
            );
        },
    });
};

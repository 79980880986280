import { TOS } from './tos';
import { useUpdateMe } from '../../queries/user';
import { useNavigate } from 'react-router';
import { Box, Stack, Button } from '@mui/material';
import { StepShell } from '../Onboarding/StepShell';
import { ButtonDotLoader } from '../../components/Forms/ButtonDotLoader';

export const TermsOfService = () => {
    const { mutateAsync, isPending } = useUpdateMe();
    const navigate = useNavigate();

    return (
        <StepShell
            icon="📝"
            title="Terms of Service"
            subtitle="Please agree to the terms of service before continuing"
        >
            <Stack spacing={12} px={10} alignItems="center">
                <Box
                    maxHeight={440}
                    overflow={{
                        overflowY: 'scroll',
                    }}
                >
                    <TOS />
                </Box>
                <Button
                    onClick={() =>
                        mutateAsync({ acceptedTermsOfService: true }).then(() =>
                            navigate('/'),
                        )
                    }
                    sx={{ width: 320 }}
                    size="large"
                    disabled={isPending}
                >
                    {isPending ? <ButtonDotLoader /> : 'I Agree'}
                </Button>
            </Stack>
        </StepShell>
    );
};

import { Components, Theme } from '@mui/material';
import { CheckboxEmpty } from '../icons/CheckboxEmpty';
import { CheckboxChecked } from '../icons/CheckboxChecked';
import { CheckboxIndeterminate } from '../icons/CheckboxIndeterminate';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
    defaultProps: {
        disableRipple: true,
        icon: <CheckboxEmpty />,
        checkedIcon: <CheckboxChecked />,
        indeterminateIcon: <CheckboxIndeterminate />,
    },
    styleOverrides: {
        colorPrimary: ({ theme, ownerState }) => ({
            ':hover': {
                backgroundColor: ownerState.disabled
                    ? undefined
                    : theme.palette.primary.hover,
            },
            ':focus-within': {
                backgroundColor: ownerState.disabled
                    ? undefined
                    : theme.palette.primary.focusVisible,
            },
        }),
        sizeSmall: ({ theme }) => ({
            '&.MuiCheckbox-colorInfo:not(.Mui-checked):not(.MuiCheckbox-indeterminate)':
                {
                    color: theme.palette.custom.gray.light400,
                },

            '.MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        }),
    },
};

import { FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import {
    useGetSetting,
    useUpdateMeSettings,
} from '../../../../../queries/user';
import { UserTeamSettingType } from '../../../../../api/types';
import React, { memo } from 'react';
import { isMobile } from '../../../../../utils/mobile';

export const SignatureButton = memo(function SignatureButton({
    disabled = false,
}: {
    disabled?: boolean;
}) {
    const { mutate: updateSettings } = useUpdateMeSettings();
    const isSignatureEnabled =
        useGetSetting(UserTeamSettingType.Signature).data?.enabled ?? false;
    const toggleSignature = () =>
        updateSettings({
            enabled: !isSignatureEnabled,
            type: UserTeamSettingType.Signature,
        });

    return (
        <Tooltip title="Enable/Disable Signature" arrow placement="top">
            <span>
                <FormControlLabel
                    disabled={disabled}
                    control={
                        <Switch
                            sx={{ ml: 1 }}
                            size="small"
                            color="info"
                            onChange={toggleSignature}
                            checked={isSignatureEnabled}
                            data-testid="signature-switch"
                            inputProps={
                                {
                                    'data-testid': 'signature-switch-input',
                                } as React.InputHTMLAttributes<HTMLInputElement>
                            }
                        />
                    }
                    labelPlacement="start"
                    sx={{ ml: 0 }}
                    label={
                        <Typography
                            color={
                                disabled
                                    ? 'custom.gray.disabledText'
                                    : 'primary.dark'
                            }
                            variant="body4"
                        >
                            {isMobile() ? 'Sign' : 'Signature'}
                        </Typography>
                    }
                />
            </span>
        </Tooltip>
    );
});

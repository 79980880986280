import { ReactNode, useCallback, useState } from 'react';
import cc from 'classcat';
import styles from './CollapsibleSection.module.scss';
import { ToggleButton } from '../../elements/ToggleButton/ToggleButton';

interface CollapsibleSectionProps {
    title: string;
    initiallyExpanded?: boolean;
    extraButton?: ReactNode;
    children: React.ReactNode;
}

export const CollapsibleSection = ({
    title,
    initiallyExpanded = false,
    extraButton,
    children,
}: CollapsibleSectionProps) => {
    const [isExpanded, setExpanded] = useState(initiallyExpanded);

    const clickHandler = useCallback(
        () => setExpanded(!isExpanded),
        [isExpanded],
    );

    return (
        <div
            className={cc([
                styles['root'],
                { [styles['root_open']]: isExpanded },
            ])}
        >
            <div className={styles['root__container']}>
                <button onClick={clickHandler}>
                    <ToggleButton open={isExpanded} />
                </button>

                <h6 className={styles['root__title']}>{title}</h6>

                {extraButton}
            </div>

            {isExpanded && children}
        </div>
    );
};

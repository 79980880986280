import { UUID } from '../../../types/uuid';
import { useEffect, useRef, useState } from 'react';
import { CONVERSATION_MESSAGES_KEY } from '../../../queries/messages';
import { useQueryClient } from '@tanstack/react-query';
import { PagedData } from '../../../types/PagedData';
import { Message } from '../../../api/types';
import { useLocation } from 'react-router';

// Scroll on conversation change or on new message if you are already on the bottom
export const useHistoryScroll = (conversationId: UUID) => {
    const location = useLocation();
    const [scrollMode, setScrollMode] = useState<'auto' | 'manual'>('auto');
    const scroll = useRef<HTMLDivElement>(null);
    const bottom = useRef<HTMLDivElement>(null);
    const queryClient = useQueryClient();
    const state = queryClient.getQueryState<PagedData<Message>>([
        CONVERSATION_MESSAGES_KEY,
        conversationId,
    ]);

    const [observer, setObserver] = useState<IntersectionObserver | null>(null);

    useEffect(() => {
        setScrollMode('auto');
    }, [conversationId, location.pathname]);

    useEffect(() => {
        if (observer) {
            setObserver(null);
        }
        const observerOptions = {
            root: scroll.current,
            rootMargin: '250px',
            threshold: 1,
        };

        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                setScrollMode(entry.isIntersecting ? 'auto' : 'manual');
            });
        };
        if (!observer) {
            setObserver(
                new IntersectionObserver(observerCallback, observerOptions),
            );
        }
        if (observer && bottom.current) {
            observer.observe(bottom.current);
        }

        return () => {
            observer && observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId, state, bottom.current]);

    useEffect(() => {
        if (scrollMode === 'auto') {
            bottom.current && bottom.current.scrollIntoView();
        }
    }, [scrollMode, state]);

    return { scroll, bottom };
};

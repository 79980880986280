import React from 'react';
import Loading from '../../../../components/Loading/Loading';

const Lazy = React.lazy(() => import('./BlockList'));

export const BlockListPage = () => (
    <React.Suspense fallback={<Loading />}>
        <Lazy />
    </React.Suspense>
);

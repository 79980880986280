import React from 'react';
import { Text, Range, Path, NodeEntry } from 'slate';

const TEXT_LINK_REGEX = /(https?:\/\/[^\s<]+)/g;

const truncateLinks = (text: string): string => {
    return text.length > 40 ? `${text.slice(0, 39)}…` : text;
};

export const addHyperLinksToTextLinks = (text: string): React.ReactNode[] => {
    return text
        .split(TEXT_LINK_REGEX)
        .reduce((acc: React.ReactNode[], part, index) => {
            if (TEXT_LINK_REGEX.test(part)) {
                return acc.concat(
                    React.createElement(
                        'a',
                        {
                            key: index,
                            href: part,
                            target: '_blank',
                            rel: 'noreferrer noopener',
                        },
                        truncateLinks(part),
                    ),
                );
            }
            return acc.concat(part);
        }, []);
};

const getUrlsAndIndexes = (text: string): [string, number][] => {
    const matches = text.match(TEXT_LINK_REGEX);

    return matches
        ? matches.map((m) => [m.trim(), text.indexOf(m.trim())])
        : [];
};

export const linkDecorator = (entry: NodeEntry): Range[] => {
    const [node, path] = entry as [Text, Path];
    const nodeText = node.text;

    if (!nodeText) {
        return [];
    }

    const urls = getUrlsAndIndexes(nodeText);

    return urls.map(([url, index]) => {
        return {
            anchor: {
                path,
                offset: index,
            },
            focus: {
                path,
                offset: index + url.length,
            },
            decoration: 'link',
        };
    });
};

import {
    Divider,
    FormControl,
    ListSubheader,
    MenuItem,
    Select,
    Theme,
    Typography,
} from '@mui/material';
import { useMeQueryData } from '../../../../queries/user';
import { useUpdateTeam } from '../../../../queries/team';
import { menu, select, tip } from './styles';
import { ListIcon } from '../../../../icons/common/ListIcon';
import { useTrack } from '../../../../contexts/analytics';

export const DelayRow = () => {
    const me = useMeQueryData();
    const update = useUpdateTeam();
    const minutes = (me?.activeTeam?.assistantResponseDelaySeconds || 0) / 60;
    const track = useTrack();

    const onChange = (delay: number) => {
        update
            .mutateAsync({
                assistantResponseDelayMinutes: delay,
            })
            .then(() => {
                track('assistant_delay_changed', { delay });
            });
    };

    return (
        <>
            <div>
                <Typography
                    variant="body3"
                    component="label"
                    htmlFor="ai-assistant-delay-select"
                    id="ai-assistant-delay-label"
                    fontWeight={500}
                >
                    Delay
                </Typography>
                <Typography
                    variant="body4"
                    component="div"
                    color="custom.gray.super"
                    mt={1}
                >
                    Select how long the assistant will wait before replying
                </Typography>
            </div>
            <FormControl sx={{ width: 260 }}>
                <Select
                    IconComponent={ListIcon}
                    labelId="ai-assistant-delay-label"
                    id="ai-assistant-delay-select"
                    disabled={update.isPending}
                    value={minutes}
                    aria-label="Delay"
                    onChange={({ target }) => onChange(Number(target?.value))}
                    sx={select}
                    MenuProps={{
                        sx: menu,
                    }}
                >
                    <MenuItem value={0}>
                        <div className="flex flex-col gap-1">
                            Immediately
                            <Typography
                                className="drop-list-tip"
                                variant="body4"
                                sx={tip}
                            >
                                The Assistant will reply after a short delay
                            </Typography>
                        </div>
                    </MenuItem>
                    <MenuItem value={35_000_000}>
                        <div className="flex flex-col gap-1">
                            Until review
                            <Typography
                                className="drop-list-tip"
                                variant="body4"
                                sx={tip}
                            >
                                The Assistant will wait for a user review
                            </Typography>
                        </div>
                    </MenuItem>
                    <Divider sx={{ m: 0 }} />
                    <ListSubheader
                        sx={(theme: Theme) => ({
                            ...theme.typography.body4,
                            px: 3,
                            py: 2,
                            color: theme.palette.custom.gray.super,
                        })}
                    >
                        Time delay
                    </ListSubheader>
                    {[3, 5, 10].map((minute) => (
                        <MenuItem value={minute} key={minute}>
                            {minute} Minutes
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

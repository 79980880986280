import WorkflowProvider from '../../components/workflows/WorkflowProvider';
import { Workflows } from './Workflows';

const WorkflowsContainerProps = () => (
    <WorkflowProvider>
        <Workflows />
    </WorkflowProvider>
);

export default WorkflowsContainerProps;

import {
    Box,
    CircularProgress,
    Paper,
    Stepper,
    Typography,
} from '@mui/material';
import {
    canvas,
    content,
    icon,
    onboardingProgress,
    statusBlock,
} from './styles';
import { OnboardingFooter } from './OnboardingFooter';
import { InboxAlternative } from '../../icons/common/CInboxAlternative';
import { Members } from '../../icons/common/CMembers';
import { SendOutline } from '../../icons/common/CSendOutline';
import { Integrations } from '../../icons/common/CIntegrations';
import { OnboardingStep } from './OnboardingStep';
import { NavLink, useNavigate } from 'react-router-dom';
import { Add } from '../ConversationsList/CAdd';
import { CompanyIcon } from '../../icons/common/CCompanyIcon';

import { useOnboardingData } from '../../hooks/useOnboardingData';
import confetti from 'canvas-confetti';
import { useEffect, useLayoutEffect } from 'react';
import { useUpdateOnboardingTeams } from '../../hooks/useUpdateOnboardingTeams';
import { useUpdateOnboardingData } from '../../hooks/useUpdateOnboardingData';

export const OnboardingProgress = () => {
    const inactiveInboxId: string | undefined = useUpdateOnboardingData();
    const { score, onboarding, isOnboardingBlockedForOldTeams } =
        useOnboardingData();
    const navigate = useNavigate();
    const updateTeams = useUpdateOnboardingTeams();

    useEffect(() => {
        if (score === 100 || isOnboardingBlockedForOldTeams) {
            // Delay navigation to allow confetti to finish
            setTimeout(() => {
                navigate('/');
            }, 3000);
        }
    }, [score, isOnboardingBlockedForOldTeams, navigate]);

    useLayoutEffect(() => {
        if (score !== 100) {
            return;
        }
        const canvas = document.getElementById(
            'onboarding-finish-canvas',
        ) as HTMLCanvasElement;

        if (canvas) {
            const myConfetti = confetti.create(canvas, { resize: true });
            myConfetti({
                particleCount: 200,
                spread: 150,
                origin: { y: 0.1 },
            });
        }
    }, [score]);

    return (
        <Box sx={onboardingProgress}>
            <Paper elevation={1} sx={statusBlock}>
                <Box
                    component="canvas"
                    id="onboarding-finish-canvas"
                    sx={canvas}
                />
                <Box sx={content}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                            pb: 6,
                            flexDirection: 'column',
                        }}
                    >
                        <span style={{ fontSize: 56, lineHeight: '56px' }}>
                            💻
                        </span>
                        <Typography variant="h2">Onboarding process</Typography>
                    </Box>
                    {onboarding ? (
                        <Stepper
                            orientation="vertical"
                            nonLinear
                            connector={null}
                        >
                            <OnboardingStep
                                completed={!!onboarding.inboxActivation}
                                title="Activate your inbox"
                                subtitle={
                                    <Typography
                                        variant="body3"
                                        color="custom.text.secondary"
                                    >
                                        To enable messaging bring your existing
                                        phone number.
                                    </Typography>
                                }
                                icon={<InboxAlternative sx={icon} />}
                                index={0}
                                buttonText="Activate"
                                buttonLink={
                                    inactiveInboxId
                                        ? `/inbox/${inactiveInboxId}`
                                        : '/setup-inbox/existing-number'
                                }
                                buttonIcon={<Add />}
                            />
                            <OnboardingStep
                                completed={!!onboarding.teamsLicense}
                                title="Update Microsoft Teams licenses"
                                subtitle={
                                    <Typography
                                        variant="body3"
                                        color="custom.text.secondary"
                                    >
                                        To maintain calling on your existing
                                        phone numbers update your users to
                                        Microsoft Teams{' '}
                                        <NavLink
                                            target="_blank"
                                            to="https://admin.microsoft.com/#/users"
                                        >
                                            Phone Standard License
                                        </NavLink>
                                        .
                                    </Typography>
                                }
                                icon={<Members sx={icon} />}
                                index={1}
                                content={
                                    <Box
                                        sx={{
                                            width: 276,
                                            height: 150,
                                            borderRadius: 3,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <iframe
                                            width="276"
                                            style={{ border: 'none' }}
                                            title="How to Enable Calling and SMS in Microsoft Teams with Clerk Chat"
                                            src="https://www.youtube.com/embed/PKS83g-Er-o?si=3_9scFbtYQmh2BYc"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                            allowFullScreen
                                        />
                                    </Box>
                                }
                                buttonAction={updateTeams}
                            />
                            <OnboardingStep
                                completed={!!onboarding.integration}
                                title="Add an integration"
                                subtitle={
                                    <Typography
                                        variant="body3"
                                        color="custom.text.secondary"
                                    >
                                        Connect your contacts in a few clicks.
                                    </Typography>
                                }
                                icon={<Integrations sx={icon} />}
                                index={3}
                                isLast={true}
                                buttonText="Connect"
                                buttonLink="/settings/integrations"
                                buttonIcon={<Add />}
                            />
                            <OnboardingStep
                                completed={!!onboarding.brandRegistration}
                                title="Register Your Brand"
                                subtitle={
                                    <Typography
                                        variant="body3"
                                        color="custom.text.secondary"
                                    >
                                        Register for 10DLC to ensure your SMS
                                        are compliant, secure, and optimized for
                                        deliverability.
                                    </Typography>
                                }
                                icon={<SendOutline sx={icon} />}
                                index={2}
                                buttonText="Register"
                                buttonLink="/settings/compliance"
                                buttonIcon={<CompanyIcon />}
                            />
                        </Stepper>
                    ) : (
                        <CircularProgress size={18} />
                    )}
                </Box>

                <OnboardingFooter />
            </Paper>
        </Box>
    );
};

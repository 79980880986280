import { useState } from 'react';
import { Inbox } from '../../../api/types';
import { DeleteInbox } from './DeleteInbox';
import { Header } from '../Header';
import { HeaderActions } from './InboxForm/HeaderActions';
import { Divider, Stack } from '@mui/material';
import { FormRow } from './InboxForm/FormRow';
import { PhoneNumberRow } from './InboxForm/PhoneNumberRow';
import { DeleteRow } from './InboxForm/DeleteRow';

export function About({ inbox }: { inbox: Inbox }) {
    const [deleteInbox, setDeleteInbox] = useState(false);
    if (deleteInbox) {
        return (
            <DeleteInbox inbox={inbox} onClose={() => setDeleteInbox(false)} />
        );
    }

    return (
        <>
            <Header title="About inbox" description="General information">
                <HeaderActions inboxId={inbox.id} />
            </Header>
            <Divider />
            <Stack spacing={4} flex={1}>
                <FormRow inbox={inbox} />
                <PhoneNumberRow inbox={inbox} />
                <Divider />
                <DeleteRow onDeleteInbox={() => setDeleteInbox(true)} />
            </Stack>
        </>
    );
}

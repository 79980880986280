import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const FilterIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 5.00008C2.5 4.53984 2.8731 4.16675 3.33333 4.16675H16.6667C17.1269 4.16675 17.5 4.53984 17.5 5.00008C17.5 5.46032 17.1269 5.83342 16.6667 5.83342H3.33333C2.8731 5.83342 2.5 5.46032 2.5 5.00008ZM4.16667 10.0001C4.16667 9.53984 4.53976 9.16675 5 9.16675H15C15.4602 9.16675 15.8333 9.53984 15.8333 10.0001C15.8333 10.4603 15.4602 10.8334 15 10.8334H5C4.53976 10.8334 4.16667 10.4603 4.16667 10.0001ZM5.83333 14.1667C5.3731 14.1667 5 14.5398 5 15.0001C5 15.4603 5.3731 15.8334 5.83333 15.8334H14.1667C14.6269 15.8334 15 15.4603 15 15.0001C15 14.5398 14.6269 14.1667 14.1667 14.1667H5.83333Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

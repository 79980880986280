import React, { createContext, useState } from 'react';

interface MessageUpdaterContextValue {
    message: string | undefined;
    setMessage: (message: string | undefined) => void;
}

export const MessageUpdateContext = createContext<MessageUpdaterContextValue>({
    message: undefined,
    setMessage: () => {},
});

type Props = {
    children: React.ReactNode;
};
export const MessageUpdateProvider = ({ children }: Props) => {
    const [message, setMessage] = useState<string | undefined>(undefined);

    return (
        <MessageUpdateContext.Provider value={{ message, setMessage }}>
            {children}
        </MessageUpdateContext.Provider>
    );
};

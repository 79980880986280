import { useEnabledFeature } from '../../queries/user';
import { Feature } from '../../api/types';
import { FC } from 'react';

interface WithFeatureProps {
    feature: Feature;
    children: React.ReactNode;
}

type Props = WithFeatureProps;

export const WithoutFeature: FC<Props> = (props: Props) => {
    const enabled = useEnabledFeature(props.feature);

    if (enabled) {
        return null;
    }
    return props.children;
};

import { useCallback, useEffect, useState } from 'react';
import { ConversationsList } from './ConversationsList';
import {
    ConversationsFilter,
    ConversationsFilterType,
    Feature,
    Inbox,
} from '../../api/types';
import { useConversationsPaged } from '../../queries/conversations';
import { useScheduledCampaignsQuery } from '../../queries/campaigns';
import { renderInboxIcon, renderInboxName } from '../../utils/inboxes';
import { useEnabledFeature } from '../../queries/user';

interface InboxConversationsListProps {
    inbox?: Inbox;
    campaignId?: number;
    onCreateClick?: () => void;
}

export const InboxConversationsList = ({
    inbox,
    campaignId,
    onCreateClick,
}: InboxConversationsListProps) => {
    const [filter, setFilter] = useState<ConversationsFilter>({
        type: ConversationsFilterType.Active,
        cohortsList: [],
    });

    const {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
        refetch,
    } = useConversationsPaged(inbox!, filter);
    const { data: campaigns } = useScheduledCampaignsQuery();

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);
    const getHref = useCallback(
        (itemId: string | number): string =>
            inbox
                ? isNewInboxEnabled
                    ? `/inbox/${inbox.id}/conversations/${itemId}`
                    : `/inbox/${inbox.id}/${itemId}`
                : '',
        [inbox, isNewInboxEnabled],
    );

    if (!inbox) {
        return null;
    }

    return (
        <ConversationsList
            name={renderInboxName(inbox)}
            icon={<div>{renderInboxIcon(inbox.icon)}</div>}
            campaigns={campaigns}
            campaignId={campaignId}
            conversations={conversations ?? []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            inbox={inbox}
            getHref={getHref}
            onCreateClick={onCreateClick}
            filter={filter}
            setFilter={setFilter}
            view="Inbox"
            isLoading={isPending}
            placeholder
        />
    );
};

import { SxProps, Theme } from '@mui/material';

export const root: SxProps<Theme> = {
    boxSizing: 'border-box',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gridColumn: '2/4',
    color: (theme) => theme.palette.primary.dark,
    background: 'white',
};

export const canvas: SxProps = {
    gridColumn: '1/2',
    gridRow: '1/2',
    height: '100vh',
    width: 1,
};

export const contentWrapper: SxProps = {
    ...canvas,
    paddingTop: '30vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
};

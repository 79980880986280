import { SelectedCohortsList } from '../ConversationsListFilter/SelectedCohortsList';
import isEmpty from 'lodash/isEmpty';
import { useCohorts } from '../../queries/cohorts';
import { useMemo } from 'react';
import { CohortMeta, ConversationsFilterType } from '../../api/types';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useGoToQueryParam } from '../../pages/NewInbox/query.helpers';
import { QueryParam } from '../../pages/NewInbox/query.params';

type Props = {
    selectedCohortIds: number[];
    sx?: SxProps<Theme>;
};
export const SelectedCohortList = ({ selectedCohortIds, sx }: Props) => {
    const cohorts = useCohorts();
    const goToQueryParam = useGoToQueryParam();
    const list = useMemo<CohortMeta[]>(() => {
        return (
            cohorts.data?.filter((cohort) => {
                return selectedCohortIds.includes(cohort.id);
            }) ?? []
        );
    }, [cohorts.data, selectedCohortIds]);

    if (isEmpty(selectedCohortIds)) {
        return null;
    }

    return (
        <SelectedCohortsList
            sx={sx}
            filter={{
                type: ConversationsFilterType.ByCohorts,
                cohortsList: list,
            }}
            onChange={(filter) => {
                if (filter.type === ConversationsFilterType.ByCohorts) {
                    return goToQueryParam({
                        [QueryParam.CohortIds]: filter.cohortsList
                            .map(({ id }) => id)
                            .join(','),
                    });
                }
                return goToQueryParam({}, [QueryParam.CohortIds]);
            }}
        />
    );
};

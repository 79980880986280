/* eslint-disable react-hooks/exhaustive-deps */
import { Conversation } from '../../../api/types';
import { useNow } from '../../ConversationRow/use-now';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useMessageStoppedBy } from '../../ConversationRow/stop-phrase/use-message-stopped-by';

export const useIsAssistantReply = (conversation: Conversation) => {
    const now = useNow({
        interval: 30_000,
        disabled: !conversation.assistantTimestamp,
    });
    const phrase = useMessageStoppedBy(conversation.lastMessageBody);

    return useMemo(() => {
        const isScheduled =
            !!conversation.assistantMessageId &&
            dayjs(conversation.assistantTimestamp).diff(now, 'seconds') >= 30;
        return conversation.isLastMessageInbound && (isScheduled || !!phrase);
    }, [
        conversation.isLastMessageInbound &&
            conversation.assistantMessageId &&
            conversation.assistantTimestamp &&
            now,
        phrase,
    ]);
};

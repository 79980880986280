import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const JcbIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
            />
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#EAEDF2"
            />
            <g clipPath="url(#clip0_1963_77936)">
                <path
                    d="M8.66576 3.75739C7.2742 3.75739 6.00271 5.01254 6.00271 6.42027C6.00271 7.19513 6.00211 10.2619 6.0015 13.3103C6.25631 13.4706 7.48328 13.8642 7.97664 13.8991C9.05714 13.9755 9.72714 13.6182 9.83084 12.7178L9.82538 9.37341H12.2155V12.6414C12.09 14.5132 10.4477 14.8945 7.94815 14.7685C7.30119 14.7358 6.4216 14.569 6.0012 14.4595C6.0006 17.5105 6 20.2427 6 20.2427C6 20.2427 9.12045 20.2427 10.1597 20.2427C11.2947 20.2427 12.7722 19.182 12.7722 17.5692C12.7722 14.8625 12.7722 3.75739 12.7722 3.75739C12.7722 3.75739 9.72714 3.75739 8.66576 3.75739Z"
                    fill="url(#paint0_linear_1963_77936)"
                />
                <path
                    d="M16.2974 3.75739C14.9059 3.75739 13.6344 5.01254 13.6344 6.42027C13.6344 6.91118 13.6342 8.32209 13.6338 10.0653C14.4688 9.41316 15.8146 9.09338 17.5271 9.22531C18.5015 9.30031 19.2224 9.43628 19.6153 9.55271V10.7096C19.1822 10.4935 18.3274 10.1716 17.6143 10.1202C15.9991 10.0038 15.1513 10.7608 15.1513 11.9842C15.1513 13.0853 15.8063 14.0275 17.6071 13.902C18.2011 13.8606 19.1896 13.5145 19.6103 13.3144L19.6153 14.4368C19.2532 14.551 18.1269 14.7962 17.0668 14.8048C15.4709 14.8178 14.3449 14.4789 13.633 13.9289C13.6324 17.1864 13.6317 20.2427 13.6317 20.2427C13.6317 20.2427 16.7521 20.2427 17.7913 20.2427C18.9264 20.2427 20.4039 19.182 20.4039 17.5692C20.4039 14.8625 20.4039 3.75739 20.4039 3.75739C20.4039 3.75739 17.3589 3.75739 16.2974 3.75739Z"
                    fill="url(#paint1_linear_1963_77936)"
                />
                <path
                    d="M24.191 12.3351C23.7354 12.3393 22.7418 12.3305 22.562 12.3348L22.5593 13.7914L24.1882 13.7967C24.5049 13.7967 24.9031 13.5255 24.9031 13.053C24.9032 12.635 24.5594 12.3316 24.191 12.3351Z"
                    fill="url(#paint2_linear_1963_77936)"
                />
                <path
                    d="M24.7053 10.8731C24.7053 10.4552 24.3912 10.1732 24.0573 10.181C23.588 10.1919 22.7417 10.1694 22.5567 10.1858L22.5512 11.5118L24.0901 11.5227C24.3461 11.5175 24.7053 11.283 24.7053 10.8731Z"
                    fill="url(#paint3_linear_1963_77936)"
                />
                <path
                    d="M23.8937 3.75737C22.5021 3.75737 21.2306 5.01253 21.2306 6.42025C21.2306 6.84202 21.2304 7.94349 21.2301 9.35079H25.1842C25.971 9.35079 26.8976 9.68733 26.8976 10.644C26.8976 11.1571 26.6245 11.7382 25.6399 11.9265V11.9483C26.2147 11.9483 27.2115 12.2848 27.2115 13.2999C27.2115 14.3476 26.1347 14.6386 25.558 14.6386L21.2291 14.6429C21.2285 17.6191 21.2279 20.2426 21.2279 20.2426C21.2279 20.2426 24.3484 20.2426 25.3875 20.2426C26.5226 20.2426 28.0001 19.182 28.0001 17.5691C28.0001 14.8624 28.0001 3.75732 28.0001 3.75732C28.0001 3.75732 24.955 3.75737 23.8937 3.75737Z"
                    fill="url(#paint4_linear_1963_77936)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1963_77936"
                    x1="6"
                    y1="12"
                    x2="12.7722"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2B327B" />
                    <stop offset="1" stopColor="#0078C0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1963_77936"
                    x1="13.6317"
                    y1="12"
                    x2="20.4039"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#872D34" />
                    <stop offset="1" stopColor="#FF1146" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1963_77936"
                    x1="22.5594"
                    y1="13.0651"
                    x2="24.9031"
                    y2="13.0651"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#008144" />
                    <stop offset="1" stopColor="#00BB36" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1963_77936"
                    x1="22.5512"
                    y1="10.8511"
                    x2="24.7054"
                    y2="10.8511"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#008144" />
                    <stop offset="1" stopColor="#00BB36" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1963_77936"
                    x1="21.2279"
                    y1="12"
                    x2="28"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#008144" />
                    <stop offset="1" stopColor="#00BB36" />
                </linearGradient>
                <clipPath id="clip0_1963_77936">
                    <rect
                        width="22"
                        height="22"
                        fill="white"
                        transform="translate(6 1)"
                    />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);

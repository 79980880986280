import { TeamStats } from '../types';
import { PickedStats } from './fill-empty-dates-in-range';

export const addTotalsForCalls = (data: PickedStats): TeamStats => {
    const totalIncoming = data.incoming.reduce(
        (acc, item) => acc + item.count,
        0,
    );
    const totalOutgoing = data.outgoing.reduce(
        (acc, item) => acc + item.count,
        0,
    );

    const totalDurationIncoming = data.incoming.reduce(
        (acc, item) => acc + (item?.duration ?? 0),
        0,
    );
    const totalDurationOutgoing = data.outgoing.reduce(
        (acc, item) => acc + (item?.duration ?? 0),
        0,
    );
    return {
        ...data,
        totalIncoming,
        totalOutgoing,
        total: totalIncoming + totalOutgoing,
        totalDuration: totalDurationIncoming + totalDurationOutgoing,
    };
};

import { CustomEditor, TemplateElement } from '../../../slate';
import { Transforms } from 'slate';

export function findTemplates(str: string) {
    return [...str.matchAll(/{{{([\w\d_-]+)}}}/gi)];
}

export function createTemplateNode(template: string): TemplateElement {
    return {
        type: 'template',
        template,
        children: [{ text: `{{{${template}}}}` }],
    };
}

export function insertTemplate(editor: CustomEditor, template: string) {
    Transforms.insertNodes(editor, createTemplateNode(template));
    Transforms.move(editor);
}

import { useTrack } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import { StepShell } from '../../Onboarding/StepShell';
import { useNavigate } from 'react-router';
import { Box, Button, Stack, Typography } from '@mui/material';
import { phoneNumberType } from './styles';
import existingPhoneImg from './images/existingPhone.png';
import newPhoneImg from './images/newPhone.png';
import { Link } from 'react-router-dom';

export const PhoneNumberType = () => {
    const me = useMeQueryData();
    const navigate = useNavigate();
    const track = useTrack();

    const onClick = () => {
        track('onboarding_user_selected_new_number');
        navigate(
            me?.activeTeam.tier !== 'free'
                ? '/setup-inbox/new-number'
                : '/payment',
        );
    };

    return (
        <StepShell
            title="Setup your number"
            subtitle="Would you like to get a new phone number or activate your existing one?"
            icon="🧑🏻‍💻"
        >
            <Stack direction="row" spacing={6}>
                <Box sx={phoneNumberType}>
                    <Stack spacing={6} alignItems="center">
                        <img
                            src={existingPhoneImg}
                            alt="Activate existing number"
                        />
                        <Box textAlign="center">
                            <Typography variant="body2" color="primary.dark">
                                Activate existing number
                            </Typography>
                            <Typography
                                variant="body3"
                                color="custom.gray.super"
                            >
                                Enable messaging on your existing phone number
                            </Typography>
                        </Box>
                        <Button
                            component={Link}
                            fullWidth
                            size="large"
                            color="info"
                            to="/setup-inbox/existing-number"
                        >
                            Proceed
                        </Button>
                    </Stack>
                </Box>
                <Box sx={phoneNumberType}>
                    <Stack spacing={6} alignItems="center">
                        <img src={newPhoneImg} alt="Get a new number" />
                        <Box textAlign="center">
                            <Typography variant="body2" color="primary.dark">
                                Get a new number
                            </Typography>
                            <Typography
                                variant="body3"
                                color="custom.gray.super"
                            >
                                Perfect for demos or to get started quickly
                            </Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant="outlined"
                            size="large"
                            color="info"
                            onClick={onClick}
                        >
                            Proceed
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </StepShell>
    );
};

import styles from './Messages.module.scss';
import startMessagingIcon from '../../assets/icons/placeholders/start-messaging.svg';

// @deprecated - use EmptyConversation
export const EmptyChat = () => (
    <div className={styles['clerk-chat-window__messages']}>
        <div className={styles['clerk-chat-window__empty']}>
            <img
                className={styles['clerk-chat-window__empty-icon']}
                src={startMessagingIcon}
                alt="empty"
            />
            <p className={styles['clerk-chat-window__empty-text']}>
                There is nothing here yet
            </p>
        </div>
    </div>
);

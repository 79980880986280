import { getConversationClassifications } from '../../../api/conversation';
import { useQuery } from '@tanstack/react-query';
import { Classification } from '../../../api/types';
import { UUID } from '../../../types/uuid';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

export const useConversationClassifications = (conversationId: UUID) => {
    const query = useQuery<string, AxiosError, Classification[]>({
        queryKey: ['conversation-classifications', conversationId],
        queryFn: () => getConversationClassifications(conversationId),
    });

    const { data, isLoading, error } = query;

    return {
        classifications: data,
        isLoading,
        error,
    };
};

export const useLatestConversationClassifications = (
    conversationId: UUID,
): Classification[] => {
    const query = useQuery<string, AxiosError, Classification[]>({
        queryKey: ['conversation-classifications', conversationId],
        queryFn: () => getConversationClassifications(conversationId),
    });

    const { data = [] } = query;

    return useMemo(() => {
        const sorted = orderBy(data, 'createdAt', 'desc');
        return uniqBy(sorted, (c) => c.classifier.name);
    }, [data]);
};

export const useLatestConversationClassificationLabels = (
    conversationId: UUID,
): string[] => {
    const classifications =
        useLatestConversationClassifications(conversationId);

    return classifications.map((classification) =>
        [classification.classifier.name, ': ', classification.value].join(''),
    );
};

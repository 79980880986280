import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cc from 'classcat';
import styles from './WorkflowsList.module.scss';
import itemsIcon from '../../../assets/icons/icons-16/different/items.svg';
import assignedIcon from '../../../assets/icons/icons-16/different/assigned.svg';
import Search from '../../../elements/Inputs/Search';
import CheckBox from '../../../elements/CheckBox/CheckBox';
import AdvancedSelect from '../../../elements/AdvancedSelect';
import {
    useWorkflowAttachQuery,
    useWorkflowDetachQuery,
    useWorkflowsQuery,
} from '../../../queries/workflows';
import { countNodes } from '../utils';
import { useWorkflowContext } from '../WorkflowProvider';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { useInboxes } from '../../../queries/inboxes';

export const WorkflowsList = () => {
    const {
        state: { selectedWorkflows },
        dispatch,
    } = useWorkflowContext();

    const { workflows } = useWorkflowsQuery();
    const attachWorkflow = useWorkflowAttachQuery();
    const detachWorkflow = useWorkflowDetachQuery();
    const { data: inboxes = [] } = useInboxes();

    const [searchTerm, setSearchTerm] = useState('');

    const filteredWorkflows = useMemo(
        () =>
            searchTerm
                ? workflows.filter(({ name }) => name.includes(searchTerm))
                : workflows,
        [workflows, searchTerm],
    );

    return (
        <div className={styles['workflows-list']}>
            <div className={styles['workflows-list__search-bar']}>
                <Search onSearch={(value) => setSearchTerm(value)} />
            </div>
            <div className={styles['workflows-list__table-container']}>
                <div className={styles['workflows-list__table']}>
                    <div className={styles['workflows-list__table-head-row']}>
                        <div
                            className={cc([
                                styles['workflows-list__name-col'],
                                styles['workflows-list__head-cell'],
                            ])}
                        >
                            NAME
                        </div>
                        <div
                            className={cc([
                                styles['workflows-list__steps-col'],
                                styles['workflows-list__head-cell'],
                            ])}
                        >
                            <img src={itemsIcon} />
                            STEPS
                        </div>
                        <div
                            className={cc([
                                styles['workflows-list__inbox-col'],
                                styles['workflows-list__head-cell'],
                            ])}
                        >
                            <img src={assignedIcon} />
                            ASSIGNED PHONE
                        </div>
                    </div>
                    {filteredWorkflows.map((workflow) => (
                        <div
                            key={workflow.id}
                            className={styles['workflows-list__table-row']}
                        >
                            <div
                                className={cc([
                                    styles['workflows-list__name-col'],
                                    styles['workflows-list__cell'],
                                ])}
                            >
                                <CheckBox
                                    checked={selectedWorkflows.some(
                                        (id) => id === workflow.id,
                                    )}
                                    onChange={() =>
                                        dispatch({
                                            type: WorkflowReducerActionTypes.TOGGLE_SELECTED_WORKFLOW,
                                            payload: workflow.id,
                                        })
                                    }
                                />
                                <NavLink to={`/workflows/${workflow.id}`}>
                                    {workflow.name}
                                </NavLink>
                            </div>
                            <div
                                className={cc([
                                    styles['workflows-list__steps-col'],
                                    styles['workflows-list__cell'],
                                ])}
                            >
                                {countNodes(workflow)}
                            </div>
                            <div
                                className={cc([
                                    styles['workflows-list__inbox-col'],
                                    styles['workflows-list__inbox-cell'],
                                ])}
                            >
                                <AdvancedSelect
                                    placeholder="Select an inbox..."
                                    options={inboxes.map((inbox) => ({
                                        id: inbox.id,
                                        label:
                                            inbox.name ||
                                            formatPhoneNumber(inbox.phone),
                                    }))}
                                    value={
                                        workflow.inboxes
                                            ? workflow.inboxes.map(
                                                  (inbox) => inbox.id,
                                              )
                                            : []
                                    }
                                    onChange={(optionId) => {
                                        const isAttached =
                                            workflow.inboxes.some(
                                                (inb) => inb.id === optionId,
                                            );

                                        if (isAttached) {
                                            detachWorkflow.mutate({
                                                workflowId: workflow.id,
                                                inboxId: optionId,
                                            });
                                        } else {
                                            attachWorkflow.mutate({
                                                workflowId: workflow.id,
                                                inboxId: optionId,
                                            });
                                        }
                                    }}
                                    multiselect
                                    searchable
                                    small
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

import { useKeywords } from '../../../main/settings/KnowledgeBase/Keywords/keywords.query';
import { includesPhrase } from './includes-phrase';

export const useMessageStoppedBy = (message: string = ''): string => {
    const { data: phrases, isFetched } = useKeywords(!!message);

    if (!message || !isFetched || !phrases?.length) {
        return '';
    }

    return (
        phrases.find(({ phrase }) => includesPhrase(message, phrase))?.phrase ||
        ''
    );
};

import { Button } from '@mui/material';
import { useInboxes } from '../../../queries/inboxes';
import { useMeQueryData, useUpdateMe } from '../../../queries/user';
import { useMemo } from 'react';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';

export const NotificationSettings = () => {
    const currentUser = useMeQueryData();
    const mutedInboxesIds = currentUser?.mutedInboxIds || [];
    const { data: inboxes = [] } = useInboxes();
    const { mutate, isPending } = useUpdateMe();

    const mutedInboxes = useMemo(
        () => inboxes.filter((inbox) => mutedInboxesIds.includes(inbox.id)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inboxes.map(({ id }) => id), mutedInboxesIds],
    );

    const allInboxesMuted: boolean = useMemo(
        () => mutedInboxes.length === inboxes.length,
        [mutedInboxes.length, inboxes.length],
    );

    const muteInboxes = () => {
        mutate({
            mutedInboxIds:
                mutedInboxes.length === inboxes.length
                    ? []
                    : inboxes.map(({ id }) => id),
        });
    };

    return (
        <SettingsLayout title="Notifications" category="Workspace">
            <Button
                variant="outlined"
                disableRipple
                onClick={muteInboxes}
                disabled={isPending}
                sx={{ width: 200 }}
            >
                {allInboxesMuted ? 'Unmute all Inboxes' : 'Mute all Inboxes'}
            </Button>
        </SettingsLayout>
    );
};

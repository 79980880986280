import cc from 'classcat';
import styles from './DialogTitle.module.scss';
import { DialogCloseButton } from './DialogCloseButton';

export interface DialogTitleProps {
    className?: string;
    onClose?: () => void;
    children: React.ReactNode;
}

export const DialogTitle = (props: DialogTitleProps) => {
    return (
        <div
            className={cc([
                styles['root'],
                {
                    [styles['root_with-close']]: !!props.onClose,
                },
                props.className,
            ])}
        >
            {props.children}
            {props.onClose && <DialogCloseButton onClick={props.onClose} />}
        </div>
    );
};

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { CloseIcon } from '../../../../../icons/common/CCloseIcon';
import { prettySize } from '../../../../../main/settings/KnowledgeBase/SourceForm/pretty-bytes';
import { Attachment } from '../../../../../api/types';

import { MimeTypeIcon } from '../../../../../icons/common/files/MimeTypeIcon';
import { getSquare } from '../../../../../theme/style.helpers';
import {
    attachmentTitle,
    fileAttachment,
    imageAttachment,
    overlay,
    img,
    removeButton,
} from './styles';

type Props = {
    attachment: Attachment;
    onRemove: (a: Attachment) => void;
};

export const AttachmentItem = ({ attachment, onRemove }: Props) => {
    if (attachment.type?.includes('image/')) {
        return (
            <Box sx={imageAttachment}>
                <Box className="overlay" sx={overlay} />
                <Box
                    component="img"
                    src={attachment.url}
                    alt={attachment.name}
                    sx={img}
                />
                <IconButton
                    onClick={() => onRemove(attachment)}
                    size="small"
                    sx={removeButton}
                    aria-label="remove"
                >
                    <CloseIcon sx={getSquare(16)} />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                ...fileAttachment,
                color: attachment.size > 25_000_000 ? 'error.main' : undefined,
            }}
        >
            <Box className="overlay" sx={overlay} />
            <MimeTypeIcon type={attachment.type} sx={getSquare(32)} />
            <Stack justifyContent="space-between">
                <Typography
                    sx={attachmentTitle}
                    variant="body4"
                    color="primary.dark"
                    noWrap
                >
                    {attachment.name}
                </Typography>
                <Typography variant="body5" color="custom.gray.super">
                    {prettySize(attachment.size)}
                </Typography>
            </Stack>
            <IconButton
                onClick={() => onRemove(attachment)}
                size="small"
                sx={removeButton}
                aria-label="remove"
            >
                <CloseIcon sx={getSquare(13)} />
            </IconButton>
        </Box>
    );
};

interface IconProps {
    size?: string;
    title?: string;
    fill?: string;
    stroke?: string;
    [key: string]: unknown;
}

/** @deprecated use src/icons/common/CEditIcon.tsx*/
const EditIcon = ({
    title,
    size = '24px',
    fill = 'currentColor',
    stroke = 'currentColor',
    ...props
}: IconProps) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        {...props}
    >
        {title && <title>{title}</title>}

        <path
            d="M8.58422 13.6979L8.18677 15.6646C8.18677 15.8824 8.35785 16.0535 8.5756 16.0535L10.6239 15.7376C10.725 15.7376 10.8261 15.6987 10.896 15.621L19.3882 7.13658L17.1853 4.93361L8.70087 13.418C8.62311 13.4958 8.58422 13.5891 8.58422 13.6979ZM21.6434 4.88134C21.9467 4.57805 21.9467 4.08812 21.6434 3.78483L20.537 2.67837C20.2337 2.37507 19.7438 2.37507 19.4405 2.67837L18.0174 4.1015L20.2203 6.30448L21.6434 4.88134Z"
            fill={fill}
        />
        <path
            d="M19 12V18C19 19.1046 18.1046 20 17 20H6C4.89543 20 4 19.1046 4 18V7C4 5.89543 4.89543 5 6 5H11.5"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export default EditIcon;

import cc from 'classcat';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import styles from './WorkflowHeader.module.scss';
import { useWorkflowContext } from '../WorkflowProvider';
import {
    useWorkflowCreateQuery,
    useWorkflowRemoveQuery,
    useWorkflowRenameQuery,
    useWorkflowsQuery,
    useWorkflowUpdateQuery,
} from '../../../queries/workflows';
import LegacyButton from '../../../elements/Buttons';
import ArrowLeftIcon from '../../../assets/icons/icons-24/gray/arrow-left.svg?react';
import PlusIcon from '../../../assets/icons/icons-24/white/plus.svg?react';
import SaveIcon from '../../../assets/icons/icons-24/white/save.svg?react';
import CloseIcon from '../../../assets/icons/icons-24/gray/close.svg?react';
import CheckIcon from '../../../assets/icons/icons-24/gray/check.svg?react';
import DeletePurpleIcon from '../../../assets/icons/icons-24/purple/delete.svg?react';
import ChevronDownIcon from '../../../assets/icons/icons-16/dark/chevron_down.svg?react';
import { WorkflowReducerActionTypes } from '../WorkflowProvider/reducer';
import Input from '../../../elements/Inputs/Input';
import { HeaderMenu } from './HeaderMenu';
import { Button } from '@mui/material';

export const WorkflowHeader = () => {
    const {
        state: { workflow, selectedWorkflows },
        dispatch,
    } = useWorkflowContext();
    const navigate = useNavigate();

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isRenaming, setRenaming] = useState(false);
    const [newName, setNewName] = useState('');

    const { workflows } = useWorkflowsQuery();
    const createQuery = useWorkflowCreateQuery();
    const updateQuery = useWorkflowUpdateQuery();
    const renameQuery = useWorkflowRenameQuery();
    const removeQuery = useWorkflowRemoveQuery();

    const handleSaveName = async () => {
        if (!workflow) {
            return;
        }

        let nextWorkflow = { ...workflow, name: newName || workflow?.name };
        if (workflow.id === -1) {
            nextWorkflow = await createQuery.mutateAsync({
                name: newName,
                spec: workflow.spec,
                trigger: workflow.trigger!,
                schedules: workflow?.schedules,
            });
        } else {
            await renameQuery.mutateAsync({
                workflowId: workflow.id,
                name: newName,
            });
        }

        setRenaming(false);
        dispatch({
            type: WorkflowReducerActionTypes.SET_WORKFLOW,
            payload: nextWorkflow,
        });
    };

    return (
        <header className={styles['header']}>
            <div className={styles['header__breadcrumbs']}>
                {workflow && (
                    <button
                        className={styles['header__back-btn']}
                        onClick={() => navigate('/workflows')}
                    >
                        <ArrowLeftIcon />
                    </button>
                )}
                <h3
                    className={cc([
                        styles['header__title'],
                        {
                            [styles['header__title_secondary']]: !!workflow,
                        },
                    ])}
                >
                    Workflows
                </h3>
                {workflow && (
                    <div className={styles['header__name-container']}>
                        <span className={styles['header__divider']}>/</span>

                        {isRenaming ? (
                            <Input
                                autoFocus
                                className={styles['header__rename-field']}
                                value={newName}
                                onChange={setNewName}
                                onKeyDown={(event: KeyboardEvent) => {
                                    if (event.key === 'Enter') {
                                        handleSaveName();
                                    } else if (event.key === 'Escape') {
                                        setRenaming(false);
                                    }
                                }}
                                suffix={
                                    <div
                                        className={
                                            styles['header__rename-actions']
                                        }
                                    >
                                        <button
                                            className={
                                                styles[
                                                    'header__rename-action-btn'
                                                ]
                                            }
                                            onClick={handleSaveName}
                                        >
                                            <CheckIcon />
                                        </button>
                                        <button
                                            className={
                                                styles[
                                                    'header__rename-action-btn'
                                                ]
                                            }
                                            onClick={() => setRenaming(false)}
                                        >
                                            <CloseIcon />
                                        </button>
                                    </div>
                                }
                                uncontrolled
                            />
                        ) : (
                            <span className={styles['header__name']}>
                                {workflow.name}
                            </span>
                        )}

                        <div className={styles['header__menu']}>
                            <button
                                className={styles['header__menu-button']}
                                onClick={() => setMenuOpen(!isMenuOpen)}
                                aria-label="Workflow menu"
                            >
                                <ChevronDownIcon
                                    className={cc([
                                        styles['header__menu-arrow'],
                                        {
                                            [styles[
                                                'header__menu-arrow_active'
                                            ]]: isMenuOpen,
                                        },
                                    ])}
                                />
                            </button>

                            {isMenuOpen && (
                                <HeaderMenu
                                    onOutsideClick={() => setMenuOpen(false)}
                                    onEditClick={() => {
                                        setNewName(workflow.name);
                                        setRenaming(true);
                                        setMenuOpen(false);
                                    }}
                                    onDeleteClick={() => {
                                        setMenuOpen(false);
                                        removeQuery
                                            .mutateAsync([workflow.id])
                                            .finally(() =>
                                                navigate('/workflows'),
                                            );
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
                {!workflow && workflows.length > 0 && (
                    <span className={styles['header__counter']}>
                        {workflows.length}
                    </span>
                )}
            </div>

            <div className={styles['header__controls']}>
                {!workflow && selectedWorkflows.length > 0 && (
                    <>
                        <LegacyButton
                            type="text"
                            onClick={() => {
                                dispatch({
                                    type: WorkflowReducerActionTypes.SELECT_MULTIPLE_WORKFLOWS,
                                    payload:
                                        selectedWorkflows.length ===
                                        workflows.length
                                            ? []
                                            : workflows.map(({ id }) => id),
                                });
                            }}
                        >
                            {selectedWorkflows.length === workflows.length
                                ? 'Deselect all'
                                : 'Select all'}
                        </LegacyButton>
                        <div className={styles['header__vertical-divider']} />

                        <div
                            className={
                                styles['header__selected-counter-wrapper']
                            }
                        >
                            <div className={styles['header__selected-counter']}>
                                {`${selectedWorkflows.length} items selected`}
                            </div>

                            <LegacyButton
                                type="icon"
                                icon={<DeletePurpleIcon />}
                                onClick={() => {
                                    removeQuery
                                        .mutateAsync(selectedWorkflows)
                                        .finally(() =>
                                            dispatch({
                                                type: WorkflowReducerActionTypes.SELECT_MULTIPLE_WORKFLOWS,
                                                payload: [],
                                            }),
                                        );
                                }}
                            />
                        </div>
                        <div className={styles['header__vertical-divider']} />
                    </>
                )}

                {!workflow && (
                    <Button
                        startIcon={<PlusIcon />}
                        onClick={() => navigate('/workflows/new')}
                    >
                        Create workflow
                    </Button>
                )}

                {workflow && (
                    <LegacyButton
                        className={styles['header__action-btn']}
                        icon={<SaveIcon />}
                        onClick={() => {
                            if (workflow.id === -1) {
                                createQuery.mutate({
                                    name: workflow.name,
                                    spec: workflow.spec,
                                    trigger: workflow.trigger!,
                                    schedules: workflow?.schedules,
                                });
                            } else {
                                updateQuery.mutate({
                                    workflowId: workflow.id,
                                    name: workflow.name,
                                    spec: workflow.spec,
                                    trigger: workflow.trigger!,
                                    schedules: workflow?.schedules,
                                });
                            }

                            navigate('/workflows');
                        }}
                    >
                        Save workflow
                    </LegacyButton>
                )}
            </div>
        </header>
    );
};

import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useMessageTemplatesQuery } from '../../../../../../queries/message-templates';
import Loading from '../../../../../Loading/Loading';
import { MessageTemplate } from '../../../../../../api/types';
import { Box, Button, List, Stack, Typography } from '@mui/material';
import { PlusIcon } from '../../../../../../icons/common/CPlusIcon';
import { SearchTextField } from '../../../../../../elements/SearchTextField/SearchTextField';
import { MessageTemplateItem } from './MessageTemplateItem';
import { isMobile } from '../../../../../../utils/mobile';

export type MessageTemplatesListProps = {
    onCreateClick: () => void;
    onSelect: (template: MessageTemplate) => void;
    onEdit: (template: MessageTemplate) => void;
    withProperties: boolean;
};

export const MessageTemplatesList = ({
    onCreateClick,
    onSelect,
    onEdit,
    withProperties,
}: MessageTemplatesListProps) => {
    const [search, setSearch] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);

    const { templates, isPending } = useMessageTemplatesQuery({
        withProperties,
    });

    useEffect(() => {
        searchInputRef.current?.focus();
    }, [templates]);

    const filteredTemplates = useMemo(() => {
        if (search) {
            const lowerSearch = search.toLowerCase();
            return templates.filter(
                (t) =>
                    t.name.toLowerCase().includes(lowerSearch) ||
                    t.body.toLowerCase().includes(lowerSearch),
            );
        } else {
            return templates;
        }
    }, [templates, search]);

    if (isPending) {
        return <Loading />;
    }

    return (
        <Box pb={4}>
            <Stack
                useFlexGap
                spacing={1}
                flexWrap="wrap"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                px={3}
                py={4}
            >
                <Box flex={1}>
                    <SearchTextField
                        sx={{
                            maxWidth: 1,
                            width: {
                                xs: 'auto',
                                md: 320,
                            },
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setSearch(
                                event.currentTarget.value
                                    .trim()
                                    .toLocaleLowerCase(),
                            )
                        }
                    />
                </Box>

                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={isMobile() ? undefined : <PlusIcon />}
                    onClick={onCreateClick}
                    sx={{
                        width: { sx: 'auto', md: 129 },
                        whiteSpace: 'nowrap',
                    }}
                >
                    Create new
                </Button>
            </Stack>
            {templates.length === 0 ? (
                <Box
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                >
                    <Typography variant="body3" color="custom.gray.super">
                        No snippets yet.
                    </Typography>
                </Box>
            ) : (
                <List
                    sx={{
                        py: 0,
                        px: 2,
                        maxHeight: 300,
                        overflowY: 'auto',
                    }}
                >
                    {filteredTemplates.map((template) => (
                        <MessageTemplateItem
                            template={template}
                            key={template.id}
                            onSelect={onSelect}
                            onEdit={onEdit}
                        />
                    ))}
                </List>
            )}
        </Box>
    );
};

import { Components } from '@mui/material/styles';
import { listItemIconClasses, svgIconClasses, Theme } from '@mui/material';
import { colors } from './palette';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
    defaultProps: {
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            ...theme.typography.subtitle2,
            color: theme.palette.primary.dark,
            textTransform: 'initial',
            padding: theme.spacing(2, 3),
            borderRadius: 4,

            '&:hover': {
                backgroundColor: theme.palette.custom.gray.actionSelected,
            },

            [`& .${listItemIconClasses.root}`]: {
                marginRight: theme.spacing(2),
                minWidth: 'auto',
                color: theme.palette.custom.gray.super,

                [`& .${svgIconClasses.root} svg`]: {
                    width: 20,
                    height: 20,
                },
            },
            '&.Mui-selected': {
                backgroundColor: colors.action.selected,
                '.MuiListItemIcon-root': {
                    color: 'custom.gray.super',
                },
            },
        }),
    },
};

import { useEffect } from 'react';

const useClarity = (clarityId = 'krp95alh1m') => {
    useEffect(() => {
        if (import.meta.env.MODE === 'development') {
            return;
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://www.clarity.ms/tag/${clarityId}`;

        (function (c, l, a, r, i, t, y) {
            c[a] =
                c[a] ||
                function (...args) {
                    (c[a].q = c[a].q || []).push(args);
                };
            t = l.createElement(r);
            t.async = 1;
            t.src = `https://www.clarity.ms/tag/${i}`;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, 'clarity', 'script', clarityId);

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
            delete window.clarity;
        };
    }, [clarityId]);
};

export default useClarity;

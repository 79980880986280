import styles from './ConversationTitle.module.scss';
import { Contact } from '../../api/types';
import { useMeQueryData } from '../../queries/user';
import { getContactName } from '../../utils/contacts';
import { AvatarWithUsername } from '../Avatar/AvatarWithUsername';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { useMemo } from 'react';
import { Box } from '@mui/material';

interface ConversationTitleProps {
    members: Contact[];
    customName?: string;
    onToggleContactInfo?: () => void;
}

export const ConversationTitle = ({
    members,
    customName,
}: ConversationTitleProps) => {
    const me = useMeQueryData();

    const avatar = members.length === 1 ? members[0].avatarURL : undefined;

    const name = useMemo(
        () =>
            customName ||
            members
                .map((m) => getContactName(m, me?.activeTeam.countryCode))
                .join(', '),
        [customName, me?.activeTeam.countryCode, members],
    );

    return (
        <div className={styles['conversation-title']}>
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
                typography="body3"
            >
                {members.length === 1 && (
                    <AvatarWithUsername
                        size="medium"
                        imageUrl={avatar}
                        username={name}
                        hideInitials={isPossiblePhoneNumber(
                            name,
                            me?.activeTeam.countryCode,
                        )}
                    />
                )}
                {members.length !== 1 && (
                    <Box
                        sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        data-testid="conversation-title-name"
                    >
                        {name}
                    </Box>
                )}
            </Box>
        </div>
    );
};

import { useSearchParams } from 'react-router-dom';

export enum ArchiveState {
    Active = 'active',
    Archived = 'archived',
}

export const isArchiveState = (state: unknown): state is ArchiveState =>
    [ArchiveState.Active, ArchiveState.Archived].includes(
        state as ArchiveState,
    );

export enum Entity {
    New = 'new',
    Conversations = 'conversations',
    Campaigns = 'campaigns',
}

export const useWithQueryParam = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (query: Record<string, string>, unset: string[] = []) => {
        const params = searchParams;
        Object.entries(query).forEach(([key, value]) => {
            params.set(key, value);
        });
        unset.forEach((key) => {
            params.delete(key);
        });
        setSearchParams(params, { replace: true });
    };
};

export const useGoToQueryParam = () => {
    const withQueryParam = useWithQueryParam();
    return (query: Record<string, string>, unset: string[] = []) =>
        withQueryParam(query, unset);
};

export const usePreserveParams = () => {
    const [searchParams] = useSearchParams();

    return (path: string, params: string[]): string => {
        const currentParams = Object.fromEntries(searchParams);
        const transfer = params
            .filter((key) => key in currentParams)
            .map((key) => [key, currentParams[key]]);

        const next = new URL(`${window.location.origin}${path}`);
        const nextParams = new URLSearchParams(next.search);
        transfer.forEach(([key, value]) => nextParams.set(key, value));

        next.search = nextParams.toString();
        return `${next.pathname}${next.search}`;
    };
};

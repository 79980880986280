import { SxProps, Theme } from '@mui/material';

export const getPaperStyle =
    (width: number): SxProps<Theme> =>
    (theme) => ({
        width,
        py: 1,
        borderRadius: 1.5,
        border: '1px solid',
        borderColor: theme.palette.custom.gray.borderEnabled,
        marginTop: 2,
    });

export const header: SxProps = {
    py: 2,
    px: 3,
};

export const divider: SxProps = {
    my: 1,
};

export const content: SxProps = {
    py: 2,
    px: 3,
};

export const actions: SxProps = {
    py: 2,
    px: 3,
};

import cc from 'classcat';
import styles from './DropdownList.module.scss';

type DropdownListProps = {
    className?: string;
    children: React.ReactNode;
};

export const DropdownList = ({ children, className }: DropdownListProps) => {
    return (
        <ul className={cc([styles['dropdown-list'], className])}>{children}</ul>
    );
};

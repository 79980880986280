import { SxProps } from '@mui/material';
import { HEADER_HEIGHT } from '../../../pages/NewInbox/inbox.styles';

export const header: SxProps = {
    display: 'flex',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    gap: 1,
    px: 6,
    py: 4,
    borderBottom: '1px solid',
    borderColor: 'divider',
};

export const toolbar: SxProps = {
    marginLeft: 3,
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
};

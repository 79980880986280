import { SxProps } from '@mui/material';

export const author: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
};
export const body: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    px: 3,
    py: 2.5,
    gap: 4,
    borderRadius: 1.5,
    bgcolor: 'custom.secondary.orange.light',
    width: 'content-fit',
    whiteSpace: 'pre-wrap',
};
export const container: SxProps = {
    display: 'flex',
    width: 1,
    gap: 2,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
};
export const arrow: SxProps = {
    width: 12,
    height: 16,
    borderRadius: '0 0 0 16px',
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderColor: 'custom.gray.divider',
};
export const messagesLink: SxProps = {
    ml: 1,
    py: 1,
    display: 'flex',
};

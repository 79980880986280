import { Box, Typography } from '@mui/material';
import { SourceForm } from './SourceForm/SourceForm';
import { SettingsRow } from './SourceForm/SettingsRow';
import { KeywordsRow } from './KeywordsRow';
import { SourceList } from './List/SourceList';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { DelayRow } from './DelayForm/DelayRow';
import { InboxRow } from './InboxRow/InboxRow';
import { AssistantInstruction } from './AssistantInstruction';
import { useAssistantInstructionEnabled } from '../../../helpers/use-assistant-instruction-enabled.hook';

export const KnowledgeBase = () => {
    const isAssistantInstructionEnabled = useAssistantInstructionEnabled();

    return (
        <SettingsLayout
            title="Knowledge Base"
            category="Workspace"
            description="Automate responses to customers by creating a knowledge base"
        >
            <Box sx={{ maxWidth: 1200, width: 1, py: 6 }}>
                <Box mt={1}>
                    <Typography
                        variant="caption"
                        component="div"
                        color="custom.gray.super"
                    >
                        Knowledge base
                    </Typography>
                    <Typography variant="body4" mt={1} component="div">
                        Upload files or add text to train your assistant
                    </Typography>
                </Box>
                <SourceForm sx={{ mt: 4 }} />
                <SourceList />
                <Box mt={10}>
                    <Typography
                        variant="caption"
                        component="div"
                        color="custom.gray.super"
                    >
                        AI Settings
                    </Typography>
                    <SettingsRow sx={{ mt: 4 }}>
                        <DelayRow />
                    </SettingsRow>
                    <SettingsRow sx={{ mt: 4, borderTopWidth: 0 }}>
                        <InboxRow />
                    </SettingsRow>
                    {isAssistantInstructionEnabled && (
                        <SettingsRow sx={{ mt: 4, borderTopWidth: 0 }}>
                            <AssistantInstruction />
                        </SettingsRow>
                    )}
                </Box>
                <Box mt={10}>
                    <KeywordsRow />
                </Box>
            </Box>
        </SettingsLayout>
    );
};

import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { CancelSubscriptionModal } from './CancelSubscriptionModal';
import { StripeMainSubscription } from '../../../api/billing';

export const CancelSubscription = ({
    subscription,
}: {
    subscription?: StripeMainSubscription;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Box sx={{ pb: 10 }}>
            <Button
                variant="outlined"
                color="error"
                onClick={() => setIsModalOpen(true)}
                size="small"
                disabled={
                    !subscription?.id ||
                    subscription.status === 'canceled' ||
                    subscription.cancelAtPeriodEnd
                }
            >
                Cancel subscription
            </Button>
            {isModalOpen && (
                <CancelSubscriptionModal
                    subscription={subscription}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </Box>
    );
};

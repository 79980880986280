import { useCampaignQuery } from '../../queries/campaigns';
import ContactsList from '../ContactsList';
import { WorkflowHeader } from '../ConversationHeader/WorkflowHeader';
import { CampaignViewMessageForm } from '../MessageFormContainers/CampaignViewMessageForm';
import styles from './InboxWorkflow.module.scss';
import Loading from '../Loading/Loading';
import { emptyFunction } from '../../helpers/function.helpers';

interface InboxCampaignWorkflowProps {
    campaignId?: number;
    toggleContactInfo: () => void;
}

export const InboxCampaignWorkflow = ({
    campaignId,
    toggleContactInfo,
}: InboxCampaignWorkflowProps) => {
    const { campaign, isPending } = useCampaignQuery(campaignId as number);

    return isPending ? (
        <Loading />
    ) : (
        <section className={styles['inbox-workflow']}>
            <div className={styles['inbox-workflow__header']}>
                <WorkflowHeader
                    cohortName={campaign?.name}
                    onToggleContactInfo={toggleContactInfo}
                />
            </div>

            <div className={styles['inbox-workflow__content']}>
                <ContactsList
                    contactIds={campaign?.contactIds || []}
                    isEmpty={campaign?.contactIds?.length === 0}
                    isLoading={isPending}
                    setActiveView={emptyFunction}
                    toogleSelectedContact={emptyFunction}
                    setDraft={emptyFunction}
                />
            </div>

            <div className={styles['inbox-workflow__controls']}>
                {campaign && <CampaignViewMessageForm campaign={campaign} />}
            </div>
        </section>
    );
};

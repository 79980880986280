import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const Other = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30" fill="none">
            <path
                d="M37.5 10H34.1667V6.66667C34.1671 6.33824 34.1028 6.01295 33.9773 5.70944C33.8518 5.40593 33.6677 5.13016 33.4354 4.89792C33.2032 4.66569 32.9274 4.48156 32.6239 4.35608C32.3204 4.2306 31.9951 4.16623 31.6667 4.16667H18.9583C18.5863 4.16675 18.2188 4.08375 17.8829 3.92371C17.547 3.76368 17.2511 3.53067 17.0167 3.24167L15.3833 1.23334C15.072 0.848095 14.6784 0.537389 14.2314 0.323985C13.7844 0.11058 13.2953 -0.000117758 12.8 2.22836e-06H3.33334C3.00491 -0.000436554 2.67963 0.0639286 2.37612 0.18941C2.0726 0.314891 1.79683 0.499023 1.5646 0.731257C1.33236 0.963491 1.14823 1.23926 1.02275 1.54277C0.897269 1.84629 0.832904 2.17157 0.833343 2.5V27.1417C0.831619 27.517 0.905222 27.8889 1.04979 28.2353C1.19436 28.5817 1.40696 28.8956 1.67501 29.1583C1.93777 29.4264 2.25164 29.639 2.59804 29.7836C2.94444 29.9281 3.31632 30.0017 3.69168 30H31.7417C32.3608 29.9998 32.9633 29.7992 33.459 29.4282C33.9547 29.0572 34.317 28.5357 34.4917 27.9417L39.1 12.1333C39.1719 11.885 39.1851 11.6233 39.1386 11.3689C39.0921 11.1146 38.9872 10.8745 38.832 10.6677C38.6769 10.4608 38.4758 10.2928 38.2446 10.177C38.0135 10.0611 37.7586 10.0005 37.5 10Z"
                fill="#3F5DF4"
            />
            <path
                d="M37.4989 10H13.8104C13.1919 10.0001 12.5902 10.2007 12.0955 10.5719C11.6008 10.943 11.2398 11.4646 11.0667 12.0583L6.43332 27.9428C6.26023 28.5363 5.89932 29.0576 5.40476 29.4285C4.91021 29.7995 4.30869 30 3.69049 30H31.7437C32.3622 30.0001 32.9641 29.7995 33.459 29.4283C33.9538 29.0572 34.3149 28.5355 34.4881 27.9417L39.099 12.1333C39.1714 11.8849 39.1851 11.6231 39.1388 11.3685C39.0925 11.1139 38.9876 10.8736 38.8323 10.6666C38.677 10.4596 38.4757 10.2916 38.2443 10.1759C38.0129 10.0602 37.7577 9.99999 37.4989 10Z"
                fill="#8C9EF8"
            />
        </svg>
    </SvgIcon>
);

import { RefObject, useEffect } from 'react';

type Ref<T> = RefObject<T> | null | undefined;
type Handler = (event: KeyboardEvent) => void;

function useOnKeyDown<T extends HTMLElement>(
    ref: Ref<T> | null,
    key: string,
    allowDefault: boolean | Handler,
    handler?: Handler,
) {
    const inerAllowDefault =
        typeof allowDefault === 'boolean' ? allowDefault : false;
    const innerHandler =
        typeof allowDefault === 'boolean' && typeof handler === 'function'
            ? handler
            : (allowDefault as Handler);

    useEffect(() => {
        const keydownListener = (event: KeyboardEvent) => {
            let eventKey = event.key;

            if (!eventKey) {
                eventKey = String.fromCharCode(
                    event.which || Number.parseInt(event.code, 10),
                );
            }

            if (eventKey.toLowerCase() === key.toLowerCase()) {
                if (!inerAllowDefault) {
                    event.preventDefault();
                }
                innerHandler(event);
            }
        };

        const current = ref?.current;

        if (ref) {
            current?.addEventListener('keydown', keydownListener);
            return () =>
                current?.removeEventListener('keydown', keydownListener);
        } else {
            document.documentElement.addEventListener(
                'keydown',
                keydownListener,
            );
            return () =>
                document.documentElement.removeEventListener(
                    'keydown',
                    keydownListener,
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerHandler]);
}

export default useOnKeyDown;

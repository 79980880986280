import { SxProps } from '@mui/material';

export const newConversation: SxProps = {
    position: 'relative',
    height: { xs: 'calc(100vh - 61px)', md: '100vh' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
};

export const message: SxProps = {
    flexShrink: 0,
};

import { useMemo } from 'react';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
} from '@hello-pangea/dnd';
import { Inbox } from '../../../api/types';
import CollapsibleSection from '../../../components/CollapsibleSection';
import InboxItem from '../../../elements/InboxItem';
import { useMeQueryData } from '../../../queries/user';
import { useInboxes } from '../../../queries/inboxes';
import { list } from '../SideBarInboxes/styles';
import { Box } from '@mui/material';

interface SideBarMutedInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarMutedInboxes = ({
    setMenuOpen,
}: SideBarMutedInboxesProps) => {
    const currentUser = useMeQueryData();

    const { data: inboxes = [] } = useInboxes();

    const mutedInboxes = useMemo(() => {
        const mutedInboxesIds = currentUser?.mutedInboxIds || [];
        return inboxes.filter((inbox) => mutedInboxesIds.includes(inbox.id));
    }, [inboxes, currentUser?.mutedInboxIds]);

    if (mutedInboxes.length > 0) {
        return (
            <CollapsibleSection title={`Muted Phones (${mutedInboxes.length})`}>
                {/* eslint-disable-next-line no-console */}
                <DragDropContext onDragEnd={() => console.log('not yet')}>
                    <Droppable droppableId="inboxes-list">
                        {(providedDroppable: DroppableProvided) => (
                            <Box
                                component="ul"
                                sx={list}
                                ref={providedDroppable.innerRef}
                                {...providedDroppable}
                            >
                                {mutedInboxes.map((inbox: Inbox, i: number) => (
                                    <InboxItem
                                        index={i}
                                        key={inbox.id}
                                        inbox={inbox}
                                        countryCode={
                                            currentUser?.activeTeam.countryCode
                                        }
                                        onClick={() => setMenuOpen(false)}
                                        isMuted
                                    />
                                ))}
                                {providedDroppable.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </CollapsibleSection>
        );
    }

    return null;
};

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const Twilio = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" fill="none">
            <path
                d="M27.0667 22.2445C27.0667 24.889 24.889 27.0667 22.2445 27.0667C19.6001 27.0667 17.4223 24.889 17.4223 22.2445C17.4223 19.6001 19.6001 17.4223 22.2445 17.4223C24.889 17.4223 27.0667 19.6001 27.0667 22.2445ZM22.2445 28.9334C19.6001 28.9334 17.4223 31.1112 17.4223 33.7556C17.4223 36.4001 19.6001 38.5779 22.2445 38.5779C24.889 38.5779 27.0667 36.4001 27.0667 33.7556C27.0667 31.1112 24.889 28.9334 22.2445 28.9334ZM51.3334 28.0001C51.3334 40.9112 40.9112 51.3334 28.0001 51.3334C15.089 51.3334 4.66675 40.9112 4.66675 28.0001C4.66675 15.089 15.089 4.66675 28.0001 4.66675C40.9112 4.66675 51.3334 15.089 51.3334 28.0001ZM45.1112 28.0001C45.1112 18.5112 37.489 10.889 28.0001 10.889C18.5112 10.889 10.889 18.5112 10.889 28.0001C10.889 37.489 18.5112 45.1112 28.0001 45.1112C37.489 45.1112 45.1112 37.489 45.1112 28.0001ZM33.7556 28.9334C31.1112 28.9334 28.9334 31.1112 28.9334 33.7556C28.9334 36.4001 31.1112 38.5779 33.7556 38.5779C36.4001 38.5779 38.5779 36.4001 38.5779 33.7556C38.5779 31.1112 36.4001 28.9334 33.7556 28.9334ZM33.7556 17.4223C31.1112 17.4223 28.9334 19.6001 28.9334 22.2445C28.9334 24.889 31.1112 27.0667 33.7556 27.0667C36.4001 27.0667 38.5779 24.889 38.5779 22.2445C38.5779 19.6001 36.4001 17.4223 33.7556 17.4223Z"
                fill="#F22F46"
            />
        </svg>
    </SvgIcon>
);

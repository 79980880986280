import { Outlet, useParams } from 'react-router';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { container } from './styles';

export const PaymentFlow = () => {
    const navigate = useNavigate();

    const params = useParams<{ step: string }>();

    useEffect(() => {
        if (
            !params.step ||
            !['plan', 'billing-info', 'finish'].includes(params.step)
        ) {
            navigate('/payment/plan', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={container}>
            <Outlet />
        </Box>
    );
};

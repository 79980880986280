import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ExpandMoreFilledIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.4107 13.0893C9.73614 13.4147 10.2638 13.4147 10.5892 13.0893L15.5892 8.08925C15.9147 7.76382 15.9147 7.23618 15.5892 6.91074C15.2638 6.58531 14.7361 6.58531 14.4107 6.91074L9.99996 11.3215L5.58921 6.91074C5.26378 6.58531 4.73614 6.58531 4.4107 6.91074C4.08527 7.23618 4.08527 7.76382 4.4107 8.08925L9.4107 13.0893Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const SelectedCheckmarkIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 13" fill="none">
            <circle cx="6" cy="6.5" r="4" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5ZM9.0511 5.13371C9.33206 4.82934 9.31308 4.35485 9.00871 4.0739C8.70434 3.79294 8.22985 3.81192 7.9489 4.11629L5.03846 7.26926L4.0511 6.19962C3.77015 5.89526 3.29566 5.87628 2.99129 6.15723C2.68692 6.43818 2.66794 6.91268 2.9489 7.21704L4.48736 8.88371C4.62934 9.03752 4.82914 9.125 5.03846 9.125C5.24779 9.125 5.44758 9.03752 5.58956 8.88371L9.0511 5.13371Z"
                fill="#01AF2C"
            />
        </svg>
    </SvgIcon>
);

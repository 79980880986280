import confetti from 'canvas-confetti';
import styles from './InboxSetupFinish.module.scss';
import PartyIcon from '../../assets/icons/add-inbox/party.png';
import { useLayoutEffect, useMemo } from 'react';
import { useMeQueryData } from '../../queries/user';
import { Box } from '@mui/material';

export const InboxSetupFinish = () => {
    const me = useMeQueryData();

    const phoneNumber = useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('n');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.search]);

    useLayoutEffect(() => {
        const canvas = document.getElementById(
            'inbox-finish-canvas',
        ) as HTMLCanvasElement;

        if (canvas) {
            const myConfetti = confetti.create(canvas, { resize: true });
            myConfetti({
                particleCount: 200,
                spread: 150,
                origin: { y: 0.1 },
            });
        }
    }, []);

    return (
        <div className={styles['root']}>
            <canvas
                id="inbox-finish-canvas"
                className={styles['root__canvas']}
            />
            <div className={styles['root__content-wrapper']}>
                <div className={styles['root__content']}>
                    <div className={styles['root__icon']}>
                        <Box
                            component="img"
                            src={PartyIcon}
                            sx={{ width: 48 }}
                        />
                    </div>

                    <h1 className={styles['root__header']}>
                        {phoneNumber
                            ? 'Your temporary number'
                            : 'Congratulations!'}
                    </h1>

                    {phoneNumber && (
                        <p className={styles['root__greeting']}>
                            The activation process can take up to 72 hours. Once
                            completed we will send you a notification.
                        </p>
                    )}

                    {!phoneNumber && me?.activeTeam.tier === 'free' && (
                        <p className={styles['root__greeting']}>
                            We have activated your{' '}
                            <span>14-day free trial</span>.
                        </p>
                    )}
                    {phoneNumber && (
                        <div className={styles['root__phone-number']}>
                            {atob(phoneNumber)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

import { Box } from '@mui/material';
import { popoverContent } from '../styles';
import { AiAssistantIcon } from './AiAssistantIcon';
import { memo } from 'react';

export const PopoverHeader = memo(function PopoverHeader({
    isStopped = false,
}: {
    isStopped: boolean;
}) {
    return (
        <Box sx={popoverContent} typography="body3" color="secondary.main">
            <AiAssistantIcon fontSize="small" />
            {isStopped ? 'Assistant stop-words detected ' : 'Assistant'}
        </Box>
    );
});

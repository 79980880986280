import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            color: theme.palette.info.main,
            textDecoration: 'none',
            cursor: 'pointer',

            '&:hover': {
                textDecoration: 'underline',
            },
        }),
    },
};

import { useNavigate } from 'react-router';
import cc from 'classcat';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Message, ScheduledMessage } from '../../api/types';
import { useConversation } from '../../queries/conversations';
import { Messages } from '../Chat/Messages';
import { WorkflowHeader } from '../ConversationHeader/WorkflowHeader';
import { ConversationMessageForm } from '../MessageFormContainers/ConversationMessageForm';
import styles from './InboxWorkflow.module.scss';
import Loading from '../Loading/Loading';
import { useContactsQueryData } from '../../queries/contacts';
import { Box } from '@mui/material';
import { TypingIndicator } from '../TypingIndicator/TypingIndicator';
import { getByPhoneOrEmpty } from '../../queries/contact-library.helpers';

interface InboxConversationWorkflowProps {
    inboxId: string;
    isContactInfoShown?: boolean;
    conversationId?: string;
    isMessagePreview?: boolean;
    toggleContactInfo?: () => void;
    setContactForDetails?: (contact: string | null) => void;
    setActiveDiscussion?: (discussionId: number | null) => void;
}

export const InboxConversationWorkflow = ({
    inboxId,
    isContactInfoShown,
    conversationId,
    isMessagePreview = false,
    toggleContactInfo,
    setContactForDetails,
    setActiveDiscussion,
}: InboxConversationWorkflowProps) => {
    const contacts = useContactsQueryData();
    const [smForEdit, setSmForEdit] = useState<ScheduledMessage | null>(null);
    const [messageForEdit, setMessageForEdit] = useState<Message | null>(null);
    const navigate = useNavigate();
    const { data: conversation, isPending } = useConversation(conversationId);

    const isConversationDisabled = useMemo(() => {
        if (conversation && conversation.members.length === 1) {
            const contact = getByPhoneOrEmpty(
                conversation.members[0],
                contacts,
            );
            return !!(contact && contact?.optOut);
        }
        return false;
    }, [conversation, contacts]);

    useEffect(() => {
        if (
            conversation &&
            conversation.members.length === 1 &&
            setContactForDetails
        ) {
            setContactForDetails(conversation.members[0]);
        }
        // Consider removing this if the parent component already handles it
        return () => {
            setContactForDetails && setContactForDetails(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation?.members]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onBackClick = useCallback(() => navigate(`/inbox/${inboxId}`), []);
    const handleActiveDiscussion = useCallback(
        (discussionId: number | null) => {
            setActiveDiscussion && setActiveDiscussion(discussionId);
            if (discussionId && isContactInfoShown) {
                toggleContactInfo && toggleContactInfo();
            }
        },
        [isContactInfoShown, toggleContactInfo, setActiveDiscussion],
    );
    if (isPending) {
        return <Loading />;
    }

    return (
        <section
            className={cc([
                styles['inbox-workflow'],
                isMessagePreview && styles['message-preview'],
            ])}
        >
            {isMessagePreview
                ? conversation && (
                      <Messages
                          conversation={conversation}
                          setSmForEdit={setSmForEdit}
                          setMessageForEdit={setMessageForEdit}
                          setActiveDiscussion={handleActiveDiscussion}
                      />
                  )
                : conversation && (
                      <>
                          <Box
                              sx={{
                                  display: 'grid',
                                  gridTemplateColumns: 'minmax(0, 1fr)',
                              }}
                          >
                              <WorkflowHeader
                                  conversation={conversation}
                                  onBackClick={onBackClick}
                                  onToggleContactInfo={toggleContactInfo}
                              />
                          </Box>
                          <div className={styles['inbox-workflow__content']}>
                              <Messages
                                  conversation={conversation}
                                  setSmForEdit={setSmForEdit}
                                  setMessageForEdit={setMessageForEdit}
                                  setActiveDiscussion={handleActiveDiscussion}
                              />
                          </div>
                          <div>
                              <TypingIndicator
                                  conversationId={conversation.id}
                              />
                          </div>
                          <div>
                              <ConversationMessageForm
                                  conversation={conversation}
                                  smForEdit={smForEdit}
                                  messageForEdit={messageForEdit}
                                  onEditCancel={() => {
                                      setSmForEdit(null);
                                      setMessageForEdit(null);
                                  }}
                                  disabled={isConversationDisabled}
                              />
                          </div>
                      </>
                  )}
        </section>
    );
};

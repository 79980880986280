import { Conversation } from '../../../api/types';
import { useUserTyping } from '../../ConversationRow/use-conversation-typing';
import { useIsAssistantReply } from './use-is-assistant-reply';
import { getTextFromJSON } from '../../MessageInput/serializers/json';
import { Typography } from '@mui/material';
import { AssistantReplyStatus } from '../../ConversationRow/Assistant/AssistantReplyStatus';
import { useMessageStoppedBy } from '../../ConversationRow/stop-phrase/use-message-stopped-by';

type Props = {
    conversation: Conversation;
};
export const ConversationStatus = ({ conversation }: Props) => {
    const userTyping = useUserTyping(conversation.id);
    const isAssistantReply = useIsAssistantReply(conversation);
    const phrase = useMessageStoppedBy(conversation.lastMessageBody);
    const draft = getTextFromJSON(
        window.localStorage.getItem(`${conversation.id}_draft`) ?? '',
    );

    if (userTyping) {
        return (
            <Typography component="span" variant="inherit" color="info.main">
                {userTyping} is typing ...
            </Typography>
        );
    }
    if (isAssistantReply && !phrase) {
        return (
            <Typography variant="inherit" color="secondary.main">
                <AssistantReplyStatus conversation={conversation} />
            </Typography>
        );
    }
    if (!conversation.unreadMessageCount && draft) {
        return <Typography variant="inherit">Draft: {draft}</Typography>;
    }

    return (
        <Typography variant="inherit">
            {conversation.lastMessageBody}
        </Typography>
    );
};

import { useCallback, useState } from 'react';
import cc from 'classcat';
import styles from './CollapsiblePropertiesSection.module.scss';
import { ToggleButton } from '../../elements/ToggleButton/ToggleButton';

interface Props {
    title: string;
    initiallyExpanded?: boolean;
    children: React.ReactNode;
}

export const CollapsiblePropertiesSection = (props: Props) => {
    const [isExpanded, setExpanded] = useState(
        props.initiallyExpanded || false,
    );

    const clickHandler = useCallback(
        () => setExpanded(!isExpanded),
        [isExpanded],
    );

    return (
        <div
            className={cc([
                styles['root'],
                { [styles['root_open']]: isExpanded },
            ])}
        >
            <div
                className={cc([
                    styles['root__head'],
                    { [styles['root__head_open']]: isExpanded },
                ])}
            >
                <button onClick={clickHandler}>
                    <ToggleButton open={isExpanded} />
                </button>

                <h6 className={styles['root__title']}>{props.title}</h6>
            </div>

            {isExpanded && (
                <div className={styles['root__content']}>{props.children}</div>
            )}
        </div>
    );
};

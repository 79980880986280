import cc from 'classcat';
import { forwardRef, useEffect, useState } from 'react';
import styles from './Input.module.scss';
import { InputProps } from '../../types/ElementsProps';

/** @deprecated use mui/material */
const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
    function Input(props, ref) {
        const {
            value,
            placeholder,
            disabled,
            wrapperClassName,
            className,
            color = 'blue',
            label,
            prefix,
            blurOnEnter = false,
            onBlur,
            suffix,
            error,
            validator,
            onChange,
            size = 'normal',
            type = 'text',
            isTextArea,
            fullWidth,
            uncontrolled,
            width,
            ...rest
        } = props;

        const [inputValue, setInputValue] = useState<{ value: string }>({
            value: value || '',
        });

        useEffect(() => {
            setInputValue({ value: value || '' });
        }, [value]);

        const inputClassNames = cc([
            styles['clerk-input'],
            className,
            {
                [styles['clerk-input_disabled']]: disabled,
                [styles['clerk-input_error']]: error,
                [styles['clerk-input_size-large']]: size === 'large',
                [styles['clerk-input_color-blue']]: color === 'blue',
                [styles['clerk-input_color-purple']]: color === 'purple',
                [styles['clerk-input_has-suffix']]: !!suffix,
                [styles['clerk-input_textarea']]: isTextArea,
            },
        ]);

        const inputWrapperClassName = cc([
            styles['clerk-input__wrapper'],
            wrapperClassName,
            {
                [styles['clerk-input__wrapper_full-width']]: fullWidth,
            },
        ]);

        const handleInputChange = (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const { value } = e.target as HTMLInputElement;
            const formattedValue = validator ? validator(value) : value;
            setInputValue({ value: formattedValue });
            onChange && onChange(formattedValue);
        };

        const onBlurHandler = (
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            if (uncontrolled) {
                const { value } = e.target as HTMLInputElement;
                onBlur?.(value);
            } else {
                onBlur?.(inputValue.value);
            }
        };

        return (
            <div
                className={inputWrapperClassName}
                style={width ? { width } : {}}
            >
                {label && (
                    <span className={styles['clerk-input__label']}>
                        {label}
                    </span>
                )}
                <div className={inputClassNames}>
                    {prefix && !suffix && (
                        <span
                            className={`${styles['clerk-input__icon']} ${styles['clerk-input__icon_prefix']}`}
                        >
                            {prefix}
                        </span>
                    )}
                    {isTextArea ? (
                        <textarea
                            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
                            // type={type}
                            className={cc([
                                styles['clerk-input__tag'],
                                {
                                    [styles['clerk-input__tag_error']]: error,
                                },
                            ])}
                            onBlur={onBlurHandler}
                            value={uncontrolled ? value : inputValue.value}
                            placeholder={placeholder}
                            disabled={disabled}
                            onKeyDown={(
                                e: React.KeyboardEvent<HTMLTextAreaElement>,
                            ) => {
                                e.keyCode === 13
                                    ? blurOnEnter &&
                                      e.currentTarget &&
                                      e.currentTarget.blur()
                                    : false;
                            }}
                            onChange={handleInputChange}
                            {...rest}
                        />
                    ) : (
                        <input
                            ref={ref as React.ForwardedRef<HTMLInputElement>}
                            type={type}
                            className={cc([
                                styles['clerk-input__tag'],
                                {
                                    [styles['clerk-input__tag_error']]: error,
                                },
                            ])}
                            onBlur={onBlurHandler}
                            value={uncontrolled ? value : inputValue.value}
                            placeholder={placeholder}
                            disabled={disabled}
                            onKeyDown={(
                                e: React.KeyboardEvent<HTMLInputElement>,
                            ) => {
                                e.keyCode === 13
                                    ? blurOnEnter &&
                                      e.currentTarget &&
                                      e.currentTarget.blur()
                                    : false;
                            }}
                            onChange={handleInputChange}
                            {...rest}
                        />
                    )}
                    {suffix && !prefix && (
                        <span
                            className={`${styles['clerk-input__icon']} ${styles['clerk-input__icon_suffix']}`}
                        >
                            {suffix}
                        </span>
                    )}
                </div>
                {/* TODO: Disable this error for a while, until figure out how to represrnt it
                    correctly in the UI from the UX point of view
                */}
                {/* {error && (
                    <span className={styles['clerk-input__error-label']}>
                        <FiAlertCircle
                            className={styles['clerk-input__error-icon']}
                        />{' '}
                        {error}
                    </span>
                )} */}
            </div>
        );
    },
);

export default Input;

import { UUID } from '../../../types/uuid';
import { useConversationMessages } from '../../../queries/messages';
import { useMemo } from 'react';
import { ChatMessage } from './Message/ChatMessage';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { Message } from '../../../api/types';
import { DateDivider } from './DateDivider';
import { HistorySkeleton } from './HistorySkeleton';
import InfiniteScroll from 'react-infinite-scroller';
import { Box } from '@mui/material';
import { useHistoryScroll } from './use-history-scroll';
import { historyHolder } from './styles';
import { FetchingSkeleton } from './FetchingSkeleton';
import { EmptyConversation } from '../EmptyConversation';
import { groupMessages } from './group-messages';

export type MessageGroup = {
    date: string;
    inbound: boolean;
    messages: Message[];
};

type Props = {
    conversationId: UUID;
    onEdit: (m: Message) => void;
};

export const History = ({ conversationId, onEdit }: Props) => {
    const { scroll, bottom } = useHistoryScroll(conversationId);
    const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
        useConversationMessages(conversationId);

    const lastIsFull = useMemo(
        () => data?.pages[data?.pages.length - 1].length === 50,
        [data?.pages],
    );

    const dated = useMemo(() => {
        const messages = data?.pages.flat() ?? [];
        const grouped = groupBy(groupMessages(messages) || [], (message) =>
            dayjs(message.date).format('YYYY-MM-DD'),
        );
        return Object.keys(grouped)
            .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
            .reduce(
                (acc: (MessageGroup | string)[], key) => [
                    key,
                    ...grouped[key],
                    ...acc,
                ],
                [],
            );
    }, [data?.pages]);

    if (isLoading) {
        return <HistorySkeleton />;
    }

    if (isEmpty(dated)) {
        return (
            <EmptyConversation
                title="No messages"
                description="This is the beginning of your conversation"
            />
        );
    }

    return (
        <Box ref={scroll} sx={historyHolder} data-testid="chat-history">
            <InfiniteScroll
                loadMore={() => {
                    if (!isFetchingNextPage && hasNextPage && lastIsFull) {
                        fetchNextPage();
                    }
                }}
                initialLoad={false}
                hasMore={hasNextPage && lastIsFull}
                useWindow={false}
                isReverse
            >
                <>
                    {hasNextPage && lastIsFull && <FetchingSkeleton key={0} />}
                    {dated.map((message, index) => {
                        if (typeof message === 'string') {
                            return (
                                (index !== 0 ||
                                    !(
                                        !isFetchingNextPage &&
                                        hasNextPage &&
                                        lastIsFull
                                    )) && (
                                    <DateDivider key={message} date={message} />
                                )
                            );
                        }
                        return (
                            <ChatMessage
                                key={message.date}
                                group={message}
                                onMessageEdit={onEdit}
                            />
                        );
                    })}
                </>
            </InfiniteScroll>
            <div ref={bottom} />
        </Box>
    );
};

import { Avatar, Box, Stack } from '@mui/material';
import { Message, SenderType } from '../../../../api/types';
import { DotDivider } from '../../../../components/common/DotDivider';
import dayjs from 'dayjs';
import { MessageGroup } from '../History';
import { getInitials } from '../../../../main/campaign/v3/string.helpers';
import { getAvatarGradient } from '../../../../elements/Avatar/get-avatar-color';
import { getSquare } from '../../../../theme/style.helpers';
import { MessageItem } from './MessageItem';
import { ScheduledIcon } from '../../../../icons/common/ScheduledIcon';
import { useIsPlannedMessage } from './use-is-planned-message';
import {
    container,
    info,
    messages,
    unfurlWrapper,
} from './chat-message.styles';
import { useMessageSender } from './use-message-sender';
import avatarPlaceholder from '../../../../icons/common/avatarPlaceholder.svg';
import { checkIsTooLate } from '../../../../components/ConversationRow/Assistant/check-is-until-review';
import { useMessageAvatarSrc } from './use-message-avatar-src';
import Unfurls from '../../../../components/Unfurls/Unfurls';
import isEmpty from 'lodash/isEmpty';

type Props = {
    group: MessageGroup;
    onMessageEdit: (m: Message) => void;
};

export const ChatMessage = ({ group, onMessageEdit }: Props) => {
    const first = group.messages[0];

    const getSender = useMessageSender();
    const author = getSender(first);
    const gradient = getAvatarGradient(author);
    const src = useMessageAvatarSrc(first);
    const isPlanned = useIsPlannedMessage(first);

    return (
        <Box sx={unfurlWrapper}>
            <Box sx={container(first.inbound)} data-testid="chat-message">
                <Avatar
                    src={src}
                    sx={{
                        ...getSquare(40),
                        background: gradient,
                        order: first.inbound ? 0 : 1,
                    }}
                >
                    {getInitials(author) || (
                        <Box
                            src={avatarPlaceholder}
                            sx={{ width: 1 }}
                            component="img"
                            alt="avatar"
                        />
                    )}
                </Avatar>
                <Box sx={messages(first.inbound)}>
                    <Box typography="body5" sx={info(first.inbound)}>
                        <Box
                            data-testid="message-author"
                            fontWeight={500}
                            color={({ palette: { secondary, primary } }) =>
                                first.senderType === SenderType.ASSISTANT
                                    ? secondary.main
                                    : primary.dark
                            }
                        >
                            {author}
                        </Box>
                        <DotDivider />
                        <Box
                            data-testid="message-time"
                            color="custom.text.secondary"
                            whiteSpace="nowrap"
                        >
                            {isPlanned ? (
                                <Box display="flex" alignItems="center">
                                    <ScheduledIcon
                                        sx={{
                                            ...getSquare(16),
                                            mr: 1,
                                        }}
                                    />
                                    {checkIsTooLate(first.timestamp)
                                        ? 'Waiting for review'
                                        : `Scheduled for ${dayjs(first.timestamp).format('h:mm A')}`}
                                </Box>
                            ) : (
                                dayjs(first.timestamp).format('hh:mm A')
                            )}
                        </Box>
                    </Box>
                    <Stack
                        gap={1}
                        width={1}
                        direction="column"
                        alignItems={first.inbound ? 'flex-start' : 'flex-end'}
                    >
                        {group.messages.map((message) => (
                            <MessageItem
                                key={message.id}
                                message={message}
                                onEdit={() => onMessageEdit(message)}
                            />
                        ))}
                    </Stack>
                </Box>
            </Box>
            {!isEmpty(first.unfurl) && (
                <Unfurls
                    unfurls={first.unfurl ?? {}}
                    messageId={first.id}
                    conversationId={first.conversationId}
                />
            )}
        </Box>
    );
};

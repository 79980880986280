import { QueryClient } from '@tanstack/react-query';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { createStore, del, get, keys, set } from 'idb-keyval';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { compress, decompress } from 'lz-string';
import isString from 'lodash/isString';

const version = import.meta.env.VITE_CACHE_DB_VERSION || '0';

const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};
const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const db = createStore('clerk-cache', 'app_query');
const storage = {
    getItem: (key: string) => get(key, db),
    setItem: (key: string, value: string) => set(key, value, db),
    removeItem: (key: string) => del(key, db),
};

export const persister = (() => {
    if (IS_SAFARI && inIframe()) {
        /* NOTICE: This should be tested, as the performance is significantly worse*/
        const prefix = `clerk_query_offline_cache`;
        const key = `${prefix}_${version}`;
        setTimeout(() => {
            Object.keys(window.localStorage)
                .filter((item) => item.startsWith(prefix) && item !== key)
                .forEach((item) => {
                    window.localStorage.removeItem(item);
                });
        }, 200);

        return createSyncStoragePersister({
            key,
            storage: window.localStorage,
            serialize: (data) => compress(JSON.stringify(data)),
            deserialize: (data) => JSON.parse(decompress(data)),
        });
    }

    const key = `CLERK_QUERY_OFFLINE_CACHE_${version}`;
    setTimeout(() => {
        keys(db).then((all) => {
            all.filter((stored) => isString(stored) && stored !== key).forEach(
                (item) => storage.removeItem(`${item}`),
            );
        });
    }, 200);
    return createAsyncStoragePersister({
        storage,
        key,
    });
})();

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 5, //5 minutes
        },
    },
});

import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { colors } from '../../../theme/palette';
import { EditIcon } from '../../../icons/common/CEditIcon';

type Props = {
    title: string;
    subtitle?: React.ReactNode;
    onChange: () => void;
    disabled?: boolean;
    cardType: PlanCardType;
};

export enum PlanCardType {
    Plan = 'plan',
    Period = 'period',
}

const planCardName: Record<PlanCardType, string> = {
    [PlanCardType.Plan]: 'plan',
    [PlanCardType.Period]: 'billing period',
};

export const PlanCard = ({
    title,
    subtitle,
    onChange,
    disabled = false,
    cardType,
}: Props) => {
    return (
        <Box
            sx={{
                borderRadius: 1.5,
                border: '1px solid',
                borderColor: colors.lightGrey[400],
                background: 'rgba(236, 239, 254, 0.24)',
                px: 4,
                py: 3,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 4,
                alignItems: 'center',
                flex: 1,
                minWidth: { xs: '100%', md: 'auto' },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body3" fontWeight={500}>
                    {title}
                </Typography>
                <Typography variant="body4" color="text.secondary">
                    {subtitle}
                </Typography>
            </Box>
            <Tooltip
                PopperProps={{ sx: { textAlign: 'center' } }}
                title={
                    disabled
                        ? `You are not allowed to change ${planCardName[cardType]} because the subscription has been canceled.`
                        : ''
                }
            >
                <Box sx={{ cursor: 'pointer' }}>
                    <IconButton
                        onClick={onChange}
                        sx={{ boxShadow: 1 }}
                        size="large"
                        color="white-contained"
                        disabled={disabled}
                    >
                        <EditIcon color="info" />
                    </IconButton>
                </Box>
            </Tooltip>
        </Box>
    );
};

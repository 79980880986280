import { StepShell } from '../../Onboarding/StepShell';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useSetupMicrosoftTeamsInboxContext } from './setup-teams-phone-context';
import { useNavigate } from 'react-router';
import {
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    Stack,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Calendar } from '../../../icons/common/CCalendar';
import { MSTeams } from '../../../icons/carriers/MSTeams';
import { toolbar } from './styles';
import { Link } from 'react-router-dom';
import { useTrack } from '../../../contexts/analytics';
import { isUSHoliday } from './usHolidays';
import { useCarrierCheck } from '../../../queries/inboxes';
import Loading from '../../../components/Loading/Loading';

interface ActivationDateFormData {
    activationDate: Date;
}

const defaultDate = dayjs().add(1, 'day').hour(11).minute(0).second(0);

export const ActivationDate = () => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const { selectedPhones, setActivationDate, activationDate } =
        useSetupMicrosoftTeamsInboxContext();

    const { data: allPhonesMatchBandwidth, isLoading: carrierLookupLoading } =
        useCarrierCheck(
            selectedPhones.map((phone) => phone.phone),
            'BANDWIDTH',
        );

    const {
        control,
        handleSubmit,
        setValue,
        setError,
        resetField,
        formState: { errors },
        getValues,
    } = useForm<ActivationDateFormData>({
        defaultValues: {
            activationDate: defaultDate.toDate(),
        },
    });

    const navigate = useNavigate();
    const track = useTrack();
    const selectedDate = getValues('activationDate');

    const onSubmit = useCallback(
        (data: ActivationDateFormData) => {
            const requestedDate = dayjs(data.activationDate);
            if (requestedDate.day() === 0 || requestedDate.day() === 6) {
                setError('activationDate', {
                    type: 'manual',
                    message: `Activation date can't be on a weekend`,
                });
            } else if (requestedDate.isBefore(dayjs())) {
                setError('activationDate', {
                    type: 'manual',
                    message: 'Activation date must be in the future',
                });
            } else if (
                !allPhonesMatchBandwidth?.match &&
                requestedDate.diff(dayjs(), 'hours') < 72
            ) {
                setError('activationDate', {
                    type: 'manual',
                    message: 'Number must be activated 3 days from now',
                });
            } else {
                setActivationDate?.(data.activationDate);
                track('onboarding_user_selected_activation_date');
                navigate('/setup-inbox/ms-teams/confirm');
            }
        },
        [setActivationDate, setError, allPhonesMatchBandwidth, track, navigate],
    );

    const setDate = (date: Date) => {
        resetField('activationDate');
        setValue('activationDate', date);
    };

    const ctaText = useMemo(() => {
        return `Activate SMS ${dayjs(selectedDate).fromNow()}`;
    }, [selectedDate]);

    return carrierLookupLoading ? (
        <Loading />
    ) : (
        <StepShell
            title="Activate SMS"
            icon={<MSTeams />}
            subtitle="SMS will be activated exactly on the time you set"
            goBackward="/setup-inbox/ms-teams/info"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack width={320} spacing={6}>
                    <Controller
                        name="activationDate"
                        control={control}
                        defaultValue={activationDate || defaultDate}
                        render={({ field }) => (
                            <FormControl error={!!errors.activationDate}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        disablePast
                                        shouldDisableDate={(date) =>
                                            ['sa', 'su'].includes(
                                                date.format('dd').toLowerCase(),
                                            ) || isUSHoliday(date.toDate())
                                        }
                                        minDateTime={defaultDate}
                                        format="DD MMM, YYYY, hh:mm a"
                                        value={dayjs(field.value)}
                                        onChange={(date) =>
                                            setDate(dayjs(date!).toDate())
                                        }
                                        open={datePickerOpen}
                                        onClose={() => setDatePickerOpen(false)}
                                        slotProps={{
                                            textField: {
                                                InputProps: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Calendar />
                                                        </InputAdornment>
                                                    ),
                                                },
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    setDatePickerOpen(true);
                                                },
                                            },
                                            toolbar: {
                                                sx: toolbar,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                {!!errors.activationDate && (
                                    <FormHelperText>
                                        {errors.activationDate?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />

                    <Stack gap={6}>
                        <Button
                            type="submit"
                            disabled={!!errors.activationDate}
                        >
                            {ctaText}
                        </Button>
                        <Button
                            component={Link}
                            variant="outlined"
                            to="/setup-inbox/ms-teams/info"
                        >
                            Back to Company Information
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </StepShell>
    );
};

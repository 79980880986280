import { Campaign } from '../../api/campaign.types';
import { Conversation, Feature } from '../../api/types';
import { NavLink } from 'react-router-dom';
import CampaignRow from '../CampaignRow';
import styles from './ConversationsList.module.scss';
import { ConversationRow } from '../ConversationRow/ConversationRow';
import { Virtuoso } from 'react-virtuoso';
import { useMemo } from 'react';
import { useEnabledFeature } from '../../queries/user';

const isConversation = (item: Campaign | Conversation): item is Conversation =>
    item && 'isLastMessageInbound' in item;

const renderEntity = (
    entity: Campaign | Conversation,
    newlinks: boolean = false,
) =>
    isConversation(entity) ? (
        <NavLink
            key={entity.id}
            className={({ isActive }) =>
                isActive
                    ? styles['conversations__list-item_active']
                    : styles['conversations__list-item']
            }
            to={
                newlinks
                    ? `/inbox/${entity.inboxId}/conversations/${entity.id}`
                    : `/inbox/${entity.inboxId}/${entity.id}`
            }
        >
            <ConversationRow conversation={entity} />
        </NavLink>
    ) : (
        <NavLink
            key={entity.id}
            className={({ isActive }) =>
                isActive
                    ? styles['conversations__list-item_active']
                    : styles['conversations__list-item']
            }
            to={`/inbox/${entity.inboxId}/campaigns/${entity.id}`}
        >
            <CampaignRow campaign={entity} />
        </NavLink>
    );

export const CampaignConversationList = (props: {
    loadMore: () => void;
    hasMore: boolean | undefined;
    campaigns: Campaign[];
    conversations: Conversation[];
}) => {
    const items: (Campaign | Conversation)[] = useMemo(
        () => [...props.campaigns, ...props.conversations].filter(Boolean),
        [props.campaigns, props.conversations],
    );
    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);

    return (
        <Virtuoso
            increaseViewportBy={450}
            overscan={450}
            data={items}
            style={{
                height: 'calc(100vh - 145px)',
            }}
            endReached={() => {
                if (props.hasMore) {
                    props.loadMore();
                }
            }}
            itemContent={(_, entity) => renderEntity(entity, isNewInboxEnabled)}
        />
    );
};

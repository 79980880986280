import { Box, Typography } from '@mui/material';
import { onboardingFooter } from './styles';
import { NavLink } from 'react-router-dom';

export const OnboardingFooter = () => {
    return (
        <Box sx={onboardingFooter}>
            <Typography color="custom.text.secondary" variant="body3">
                Need help? Contact our
                <NavLink
                    to="https://support.clerk.chat"
                    target="blank"
                >{` support team ->`}</NavLink>
            </Typography>
        </Box>
    );
};

import * as storage from '../utils/local-storage';
import createClient, { client } from './http';
import {
    OnboardingProgress,
    Team,
    TeamStats,
    TeamForAuth,
    TeamStatsFilter,
} from './types';
import { AtLeastOne } from '../utils/types';
import dayjs from 'dayjs';
import { fillEmptyDatesInUsage } from './utils/fill-empty-dates-in-range';
import { getEndDateWithProperTime } from './utils/getEndDateWithProperTime';
import { addTotalsForMessages } from './utils/add-totals-for-messages';
import { addTotalsForCalls } from './utils/add-totals-for-calls';

export type AuthResponse = { access_token: string };

export const setAuthToStorage = (data: AuthResponse) => {
    storage.set('auth', data.access_token);
    const { teamId } = storage.parseJwt(data.access_token);
    storage.set('teamId', teamId);
};

export function listTeams() {
    return client.get<Team[]>('/teams').then((res) => res.data);
}

export function listTeamsForAuth() {
    return createClient()
        .get<TeamForAuth[]>('/teams/for-auth')
        .then((res) => res.data);
}

export function switchTeam(teamId: string): Promise<AuthResponse> {
    return createClient()
        .post<AuthResponse>('/auth/switchTeam', { teamId })
        .then((res) => res.data);
}

export const updateTeam = ({
    name,
    assistantResponseDelayMinutes,
    assistantInstruction,
    contactsPublicByDefault,
    onboarding,
    whitelistDisabled,
}: AtLeastOne<{
    name: string;
    assistantResponseDelayMinutes: number;
    assistantInstruction: string | null;
    contactsPublicByDefault: boolean;
    whitelistDisabled: boolean;
    onboarding: OnboardingProgress;
}>) =>
    client
        .put('/teams', {
            name,
            assistantInstruction,
            whitelistDisabled,
            assistantResponseDelayMinutes,
            contactsPublicByDefault,
            onboarding,
        })
        .then(({ data }) => data);

export function updateTeamAvatar({ file }: { file: File }) {
    if (file.size > 10000000) {
        throw Error('Image max size exceeded.');
    }

    const formData = new FormData();
    formData.append('file', file);

    return createClient().post(`/teams/avatar`, formData);
}

export function setupTeam() {
    return createClient()
        .post('/auth/setupTeam')
        .then((res) => setAuthToStorage(res.data));
}

export function getTeamApiKey() {
    return createClient()
        .get('/teams/api-key')
        .then((res) => res.data);
}

export function createTeamApiKey() {
    return createClient()
        .post('/teams/api-key')
        .then((res) => res.data);
}

export function getOrCreateWidgetKey() {
    return createClient()
        .get<string>('/teams/widget-key')
        .then((res) => res.data);
}

export const getMessageUsage = (filters: TeamStatsFilter) => {
    const startDate = dayjs.utc(filters.start).startOf('day').toISOString();
    const endDate = getEndDateWithProperTime(
        dayjs.utc(filters.end).toISOString(),
    );
    const params = new URLSearchParams();
    params.append('start', startDate);
    params.append('end', endDate);
    filters.inboxes?.forEach((inbox) => {
        params.append('inboxes[]', inbox);
    });
    filters.users?.forEach((user) => {
        params.append('users[]', user);
    });
    return client
        .get<TeamStats>('teams/message-usage', { params })
        .then(({ data }) =>
            addTotalsForMessages(
                fillEmptyDatesInUsage(data, startDate, endDate),
            ),
        );
};

export const createNewTeam = () =>
    client.post<AuthResponse>('/teams').then(({ data }) => data);

export const getCallingStatisticsUsage = (filters: TeamStatsFilter) => {
    const startDate = dayjs.utc(filters.start).startOf('day').toISOString();
    const endDate = getEndDateWithProperTime(
        dayjs.utc(filters.end).toISOString(),
    );
    const params = new URLSearchParams();
    params.append('start', startDate);
    params.append('end', endDate);

    filters.users?.forEach((user) => {
        params.append('users[]', user);
    });

    filters.callTypes?.forEach((type) => {
        params.append('callType[]', type);
    });
    return client
        .get<TeamStats>('teams/calling-statistics', { params })
        .then(
            ({ data }) =>
                addTotalsForCalls(
                    fillEmptyDatesInUsage(data, startDate, endDate),
                ) as TeamStats,
        );
};
export const deleteTeam = () =>
    client.delete('/teams').then(({ data }) => data);

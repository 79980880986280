import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import styles from './ContactPanel.module.scss';
import Avatar from '../../components/Avatar';
import { EditableField } from '../../components/EditableField';
import { useTrack } from '../../contexts/analytics';
import {
    useContactCreateQuery,
    useContactUpdateQuery,
} from '../../queries/contacts';
import { useGetOrCreateConversation } from '../../queries/conversations';
import { useInboxes } from '../../queries/inboxes';
import Icon from '../../icons/Icon';
import { uploadFile } from '../../api/files';
import { Contact, Conversation, Feature } from '../../api/types';
import { useEnabledFeature, useMeQueryData } from '../../queries/user';
import { UUID } from '../../types/uuid';
import Droplist from '../../elements/Droplist';
import { DroplistItem } from '../../elements/Droplist/DroplistItem';
import { DroplistItems } from '../../elements/Droplist/DroplistItems';
import { DroplistHeader } from '../../elements/Droplist/DroplistHeader';
import { renderInboxIcon } from '../../utils/inboxes';
import { Box, Button, Popover } from '@mui/material';
import { SendIcon } from '../../icons/common/SendIcon';
import { useTeams } from 'msteams-react-base-component';
import { call } from '@microsoft/teams-js';
import { useNavigate } from 'react-router';
import { description } from './styles';
import { emptyFunction } from '../../helpers/function.helpers';

interface ContactPanelInfoProps {
    contact: Partial<Contact>;
    onCreate?: (c: Contact) => void;
}

export const ContactPanelInfo = ({
    contact,
    onCreate = emptyFunction,
}: ContactPanelInfoProps) => {
    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [{ inTeams }] = useTeams();
    const track = useTrack();

    const me = useMeQueryData();
    /* State */
    const { data: inboxes = [] } = useInboxes();

    /* Mutations */
    const { mutateAsync: updateContact } = useContactUpdateQuery();
    const { mutateAsync: createContact } = useContactCreateQuery();
    const { mutateAsync: getOrCreate } = useGetOrCreateConversation();

    const contactChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.currentTarget.name;
            const value = e.currentTarget.value;
            track('user_add_edit_contact', {
                [name]: true,
            });

            if (contact.id && contact.id !== 'draft') {
                // @ts-ignore
                await updateContact({
                    ...contact,
                    [name]: value,
                });
            } else if (contact.phone) {
                await createContact({
                    ...contact,
                    [name]: value,
                }).then(onCreate);
            } else {
                // @ts-ignore
                await createContact({
                    ...contact.data,
                    ...contact,
                    // @ts-ignore
                    id: undefined,
                    [name]: value,
                }).then(onCreate);
            }
        },
        [track, contact, updateContact, createContact, onCreate],
    );

    const sendToContact = useCallback(
        (inboxId: UUID) => {
            if (contact && contact.phone) {
                getOrCreate({
                    inboxId,
                    members: [contact.phone],
                }).then((conversation: Conversation) =>
                    navigate(
                        isNewInboxEnabled
                            ? `/inbox/${inboxId}/conversations/${conversation.id}`
                            : `/inbox/${inboxId}/${conversation.id}`,
                    ),
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contact, getOrCreate, isNewInboxEnabled],
    );

    const handleSendMessageButtonClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (inboxes.length > 1) {
                handleClick(e);
            } else if (inboxes.length === 1) {
                sendToContact(inboxes[0].id);
            }
        },
        [inboxes, sendToContact],
    );

    const handleCallButtonClick = useCallback(() => {
        if (inTeams && contact.phone) {
            track('clicked_contact_pannel_call_button');
            call.startCall({
                targets: [me!.email, `4:${contact.phone}`],
                requestedModalities: [call.CallModalities.Audio],
                source: 'Clerk Chat',
            }).catch((error) => {
                Sentry.captureException(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inTeams, contact.phone]);

    return (
        <Box component="section" sx={description}>
            <Box position="relative">
                <Avatar
                    className={styles['root__avatar']}
                    imageUrl={contact?.avatarURL}
                    username={contact.name}
                    size="extra-large"
                    editable={!!(contact && contact.id)}
                    uploadImage={async ({ file }) => {
                        if (contact) {
                            const data = await uploadFile({ file });
                            // @ts-ignore
                            return updateContact({
                                ...contact,
                                avatarURL: data.url,
                            });
                        }
                    }}
                />
                {contact.optOut && (
                    <span className={styles['root__optout-icon']}>
                        <Icon size="16px" name="opt-out" />
                    </span>
                )}
            </Box>
            <Box mb={2} className={styles['root__name']}>
                <EditableField value={contact?.name} onChange={contactChange} />
            </Box>
            {contact.createdByUserId === me?.id && !contact.public && (
                <div className={styles['root__visibility']}>
                    <Icon name="lock" size="16px" />
                    <span>Personal Contact</span>
                </div>
            )}
            {contact.optOut && (
                <span className={styles['root__optOut']}>
                    User has been unsubscribed from SMS updates
                </span>
            )}
            {!contact.optOut && contact.phone && (
                <Box width={1}>
                    <Box
                        gap={2}
                        display="flex"
                        alignItems="center"
                        maxWidth="100%"
                        width="100%"
                        px={4}
                        mt={4}
                    >
                        <Button
                            size="small"
                            color="info"
                            startIcon={<SendIcon />}
                            fullWidth
                            onClick={handleSendMessageButtonClick}
                        >
                            Message
                        </Button>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            <Droplist className={styles['root__inboxes-list']}>
                                <DroplistHeader
                                    className={
                                        styles['root__inboxes-list-header']
                                    }
                                    title={`You have ${inboxes.length} inboxes.`}
                                    subtitle="Which inbox would you like to use?"
                                />
                                <DroplistItems>
                                    {inboxes.map((inbox) => (
                                        <DroplistItem
                                            key={inbox.id}
                                            className={
                                                styles['root__inboxes-item']
                                            }
                                            onClick={() =>
                                                sendToContact(inbox.id)
                                            }
                                        >
                                            <span>
                                                {renderInboxIcon(inbox.icon)}
                                            </span>

                                            <span>{inbox.name || 'Inbox'}</span>
                                        </DroplistItem>
                                    ))}
                                </DroplistItems>
                            </Droplist>
                        </Popover>

                        {inTeams && (
                            <Button
                                variant="outlined"
                                onClick={handleCallButtonClick}
                                size="small"
                                fullWidth
                                startIcon={<Icon name="microsoft-teams-logo" />}
                            >
                                Call
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

import { CampaignMessage } from '../use-campaign-messages';
import { Box, ButtonBase } from '@mui/material';
import { AdapterLink } from '../../../../components/ConversationsList/AdapterLink';
import { arrow, messagesLink } from './message.styles';
import { pluralize } from '../../../../utils/pluralize';

export const MessagesLink = (props: { message: CampaignMessage }) => (
    <ButtonBase
        data-testid="message-link"
        LinkComponent={AdapterLink}
        href={`${window.location.pathname}/campaign-message/${props.message.id}`}
        sx={{
            display: 'flex',
            px: 2,
            mt: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            color: 'info.main',
            ':hover': {
                color: 'info.light',
            },
        }}
    >
        <Box sx={arrow} role="presentation" />
        <Box sx={messagesLink}>
            {pluralize(
                props.message.sentCount,
                'message sent',
                'messages sent',
                'No messages',
            )}
        </Box>
    </ButtonBase>
);

import { Components, tableCellClasses, Theme } from '@mui/material';
import { pixelize } from '../pages/NewInbox/inbox.styles';

export const MuiTableHead: Components<Theme>['MuiTableHead'] = {
    styleOverrides: {
        root: {
            [`.${tableCellClasses.root}`]: {
                fontSize: 12,
                fontWeight: 600,
                lineHeight: pixelize(16),
                letterSpacing: pixelize(0.2),
            },
        },
    },
};

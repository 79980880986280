import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const UnionPayIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
            />
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#EAEDF2"
            />
            <path
                d="M7.2 12.5226C7.06452 13.1097 6.79355 13.5613 6.3871 13.8774C5.93548 14.1935 5.39355 14.3742 4.71613 14.3742C4.08387 14.3742 3.5871 14.1935 3.31613 13.8774C3.09032 13.6516 3 13.3355 3 12.9742C3 12.8387 3 12.6581 3.04516 12.4774L3.76774 9H4.85161L4.12903 12.4323C4.12903 12.5226 4.08387 12.6129 4.08387 12.7032C4.08387 12.8839 4.12903 13.0194 4.21935 13.1548C4.35484 13.3355 4.58064 13.4258 4.85161 13.4258C5.16774 13.4258 5.48387 13.3355 5.66452 13.2C5.89032 13.0194 6.02581 12.7935 6.07097 12.4774L6.79355 9.04516H7.96774L7.2 12.5226Z"
                fill="#FF7F29"
            />
            <path
                d="M20.9292 9.22577H23.1872C23.6388 9.22577 23.955 9.31609 24.1808 9.49674C24.4066 9.67738 24.5421 9.94835 24.5421 10.3548C24.5421 10.4451 24.5421 10.4903 24.5421 10.5806C24.5421 10.6709 24.4969 10.7613 24.4969 10.8516C24.4066 11.3483 24.1808 11.7096 23.8195 12.0258C23.4582 12.2967 23.0517 12.4774 22.555 12.4774H21.3356L20.9743 14.2838H19.9356L20.9292 9.22577ZM21.4711 11.5742H22.4646C22.7356 11.5742 22.9163 11.529 23.0969 11.3935C23.2324 11.258 23.3227 11.0774 23.413 10.8516C23.413 10.8064 23.413 10.7613 23.413 10.7161C23.413 10.6709 23.413 10.6258 23.413 10.6258C23.413 10.4451 23.3679 10.3096 23.2324 10.2193C23.0969 10.129 22.9163 10.0838 22.6453 10.0838H21.7872L21.4711 11.5742Z"
                fill="#1398E2"
            />
            <path
                d="M26.6195 11.3937L26.2582 11.6646C25.8969 11.3485 25.5356 11.1678 24.8582 11.484C23.955 11.8904 23.1872 15.142 25.6711 14.0582L25.8066 14.2388L26.8001 14.284L27.4324 11.3485L26.6195 11.3937ZM26.0324 13.0195C25.8518 13.4711 25.5356 13.7872 25.2647 13.6969C24.9937 13.6066 24.9034 13.1549 25.0389 12.7033C25.2195 12.2517 25.5356 11.9356 25.8066 12.0259C26.1227 12.1162 26.2131 12.5678 26.0324 13.0195Z"
                fill="#0872B9"
            />
            <path
                d="M7.2 12.5226C7.06452 13.1097 6.79355 13.5613 6.3871 13.8774C5.93548 14.1935 5.39355 14.3742 4.71613 14.3742C4.08387 14.3742 3.5871 14.1935 3.31613 13.8774C3.09032 13.6516 3 13.3355 3 12.9742C3 12.8387 3 12.6581 3.04516 12.4774L4.62581 9H4.85161L4.12903 12.4323C4.12903 12.5226 4.08387 12.6129 4.08387 12.7032C4.08387 12.8839 4.12903 13.0194 4.21935 13.1548C4.35484 13.3355 4.58064 13.4258 4.85161 13.4258C5.16774 13.4258 5.48387 13.3355 5.66452 13.2C5.89032 13.0194 6.02581 12.7935 6.07097 12.4774L7.65161 9.04516H7.96774L7.2 12.5226Z"
                fill="#EA2227"
            />
            <path
                d="M8.2387 11.1679H9.00644L8.91612 11.6195L9.00644 11.484C9.27741 11.2131 9.54838 11.0776 9.90967 11.0776C10.2258 11.0776 10.4516 11.1679 10.6322 11.3485C10.7677 11.5292 10.8129 11.8002 10.7677 12.1163L10.3613 14.2389H9.54838L9.90967 12.2969C9.95483 12.1163 9.95483 11.9356 9.8645 11.8453C9.81934 11.755 9.68386 11.7098 9.50321 11.7098C9.27741 11.7098 9.14192 11.755 9.00644 11.8905C8.87096 12.026 8.78063 12.2066 8.73547 12.4324L8.37418 14.2389H7.56128L8.2387 11.1679Z"
                fill="#EA2227"
            />
            <path
                d="M17.0904 11.1679H17.8581L17.7678 11.6195L17.8581 11.484C18.1291 11.2131 18.4001 11.0776 18.7614 11.0776C19.0775 11.0776 19.3033 11.1679 19.4839 11.3485C19.6194 11.5292 19.6646 11.8002 19.6194 12.1163L19.213 14.2389H18.4001L18.8065 12.2969C18.8517 12.1163 18.8517 11.9356 18.7614 11.8453C18.7162 11.755 18.5807 11.7098 18.4001 11.7098C18.1743 11.7098 18.0388 11.755 17.9033 11.8905C17.7678 12.026 17.6775 12.2066 17.6323 12.4324L17.271 14.2389H16.4581L17.0904 11.1679Z"
                fill="#A5002F"
            />
            <path
                d="M11.8066 11.1678H12.6646L11.9872 14.2839H11.1292L11.8066 11.1678ZM12.0775 10.0388H12.9356L12.755 10.8065H11.8969L12.0775 10.0388Z"
                fill="#EA2227"
            />
            <path
                d="M13.4321 14.0582C13.2063 13.8324 13.116 13.5614 13.116 13.2001C13.116 13.1549 13.116 13.0646 13.116 12.9743C13.116 12.884 13.116 12.8388 13.1611 12.7485C13.2515 12.2517 13.4773 11.8453 13.7934 11.5291C14.1095 11.213 14.516 11.0775 15.0127 11.0775C15.4192 11.0775 15.6902 11.1678 15.916 11.3936C16.1418 11.6194 16.2321 11.8904 16.2321 12.2969C16.2321 12.342 16.2321 12.4324 16.2321 12.5227C16.2321 12.613 16.2321 12.6582 16.1869 12.7485C16.0966 13.2453 15.8708 13.6517 15.5547 13.9227C15.2385 14.1936 14.8321 14.3743 14.3353 14.3743C13.974 14.3743 13.6579 14.2388 13.4321 14.0582ZM15.0579 13.4259C15.1934 13.2453 15.3289 13.0195 15.374 12.7033C15.374 12.6582 15.374 12.613 15.4192 12.5227C15.4192 12.4775 15.4192 12.4324 15.4192 12.3872C15.4192 12.2065 15.374 12.0259 15.2837 11.9356C15.1934 11.8453 15.0579 11.7549 14.8773 11.7549C14.6515 11.7549 14.4256 11.8453 14.2902 12.0259C14.1547 12.2065 14.0192 12.4324 13.974 12.7936C13.974 12.8388 13.974 12.884 13.9289 12.9291C13.9289 12.9743 13.9289 13.0194 13.9289 13.0646C13.9289 13.2453 13.974 13.4259 14.0644 13.5162C14.1547 13.6065 14.2902 13.6517 14.4708 13.6517C14.7418 13.6517 14.9224 13.6065 15.0579 13.4259Z"
                fill="#EA2227"
            />
            <path
                d="M21.787 9.22577H23.1419C23.5935 9.22577 23.9096 9.31609 24.1354 9.49674C24.3612 9.67738 24.4967 9.94835 24.4967 10.3548C24.4967 10.4451 24.4967 10.4903 24.4967 10.5806C24.4967 10.6709 24.4515 10.7613 24.4515 10.8516C24.3612 11.3483 24.1354 11.7096 23.7741 12.0258C23.4128 12.2967 23.0064 12.4774 22.5096 12.4774H21.2903L20.929 14.2838H19.8903L21.787 9.22577ZM21.4709 11.5742H22.4645C22.7354 11.5742 22.9161 11.529 23.0967 11.3935C23.2322 11.258 23.3225 11.0774 23.4128 10.8516C23.4128 10.8064 23.4128 10.7613 23.4128 10.7161C23.4128 10.6709 23.4128 10.6258 23.4128 10.6258C23.4128 10.4451 23.3677 10.3096 23.2322 10.2193C23.0967 10.129 22.9161 10.0838 22.6451 10.0838H21.787L21.4709 11.5742Z"
                fill="#0872B9"
            />
            <path
                d="M29.1483 14.8708C28.8322 15.5934 28.5161 15.9999 28.3354 16.1805C28.1548 16.3612 27.7935 16.8128 26.8903 16.7676L26.9806 16.2257C27.7032 15.9999 28.1096 14.9612 28.3354 14.5095L28.0645 11.1676H28.6515H29.1483L29.1935 13.2902L30.0967 11.1676H30.9999L29.1483 14.8708Z"
                fill="#055093"
            />
            <path
                d="M24.9934 14.2841C25.1741 14.2389 25.3999 14.1937 25.6708 14.1034L25.8063 14.2841L26.7999 14.3292L27.4321 11.3937H27.2063C26.7547 12.5228 25.9418 13.5163 24.9934 14.2841Z"
                fill="#055093"
            />
            <path
                d="M15.9162 11.3937C15.6904 11.1679 15.3743 11.0776 15.013 11.0776C14.9227 11.0776 14.8775 11.0776 14.7872 11.0776C14.6969 11.3485 14.5614 11.6195 14.4259 11.8905C14.5614 11.8002 14.6969 11.755 14.8775 11.755C15.0582 11.755 15.1936 11.8002 15.284 11.9356C15.3743 12.026 15.4194 12.2066 15.4194 12.3873C15.4194 12.4324 15.4194 12.4776 15.4194 12.5227C15.4194 12.5679 15.4194 12.6131 15.3743 12.7034C15.3291 13.0195 15.1936 13.2905 15.0582 13.426C14.9227 13.6066 14.6969 13.6518 14.4711 13.6518C14.2904 13.6518 14.1549 13.6066 14.0646 13.5163C13.9743 13.426 13.9291 13.2453 13.9291 13.0647C13.9291 13.0195 13.9291 12.9743 13.9291 12.9292C13.9291 12.884 13.9291 12.8389 13.9743 12.7937C13.9743 12.7034 14.0194 12.6582 14.0194 12.6131C13.7936 12.9743 13.4775 13.3356 13.1614 13.6518C13.2065 13.8324 13.2969 13.9679 13.4323 14.1034C13.6582 14.3292 13.9743 14.4195 14.3356 14.4195C14.7872 14.4195 15.1936 14.284 15.5549 13.9679C15.8711 13.6969 16.0969 13.2905 16.1872 12.7937C16.1872 12.7034 16.2323 12.6582 16.2323 12.5679C16.2323 12.4776 16.2323 12.4324 16.2323 12.3421C16.2323 11.8905 16.142 11.6195 15.9162 11.3937Z"
                fill="#A5002F"
            />
        </svg>
    </SvgIcon>
);

export const pluralize = (
    count: number,
    singular: string,
    plural: string,
    none?: string,
    stringCount?: string,
) => {
    if (count === 0) {
        return none || `0 ${plural}`;
    }
    if (count === 1) {
        return `${stringCount ?? count} ${singular}`.trim();
    }

    return `${stringCount ?? count} ${plural}`.trim();
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const Esc = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
            <path
                d="M2.20105 9.625C0.830508 9.625 0 8.70694 0 7.22713V7.22294C0 5.75572 0.838691 4.8125 2.15196 4.8125C3.46523 4.8125 4.28346 5.73476 4.28346 7.13072V7.5122H1.18235C1.19462 8.27096 1.59147 8.71113 2.2256 8.71113C2.74927 8.71113 3.03974 8.42607 3.12157 8.25L3.13384 8.22485H4.24664L4.23846 8.26677C4.09936 8.84527 3.51841 9.625 2.20105 9.625ZM2.17241 5.73056C1.65283 5.73056 1.26826 6.09108 1.19462 6.74505H3.13793C3.06429 6.07431 2.69199 5.73056 2.17241 5.73056Z"
                fill="currentColor"
            />
            <path
                d="M7.05319 9.625C5.82992 9.625 5.13442 9.06326 5.04851 8.27096L5.04442 8.25419H6.21859L6.22677 8.27096C6.32087 8.55602 6.59497 8.76143 7.06546 8.76143C7.52367 8.76143 7.8346 8.56021 7.8346 8.25419V8.24581C7.8346 7.99848 7.65459 7.84337 7.19638 7.73857L6.4436 7.57508C5.58445 7.38643 5.15897 6.95465 5.15897 6.26296V6.25877C5.15897 5.39101 5.91175 4.8125 7.03682 4.8125C8.21917 4.8125 8.87376 5.41616 8.92285 6.15816V6.17492H7.81414L7.81005 6.15396C7.74459 5.90663 7.47867 5.67607 7.03273 5.67607C6.61952 5.67607 6.32496 5.87309 6.32496 6.18331V6.1875C6.32496 6.43483 6.49269 6.57736 6.95909 6.68216L7.70777 6.84566C8.60783 7.04268 9.02922 7.42835 9.02922 8.11166V8.11585C9.02922 9.02973 8.2069 9.625 7.05319 9.625Z"
                fill="currentColor"
            />
            <path
                d="M11.9912 9.625C10.5839 9.625 9.78609 8.74886 9.78609 7.21456V7.20617C9.78609 5.68864 10.5798 4.8125 11.9912 4.8125C13.1859 4.8125 13.8936 5.47904 14 6.51029V6.52287H12.8872L12.8831 6.51448C12.8054 6.06593 12.5067 5.77248 11.9953 5.77248C11.353 5.77248 11.0012 6.28392 11.0012 7.20617V7.21456C11.0012 8.14939 11.3571 8.66502 11.9953 8.66502C12.4863 8.66502 12.7767 8.42607 12.8831 7.96494L12.8913 7.94817H13.9959L13.9918 7.97332C13.8691 8.97942 13.1654 9.625 11.9912 9.625Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { Box, DialogTitle, Typography } from '@mui/material';

type Props = {
    title: string;
    subtitle: string | React.ReactElement;
};
export const DialogTitleTwoLine = ({ title, subtitle }: Props) => (
    <DialogTitle textAlign="center">
        <Box id="alert-dialog-title" mb={1}>
            {title}
        </Box>
        <Typography
            id="alert-dialog-description"
            variant="body3"
            color="#667085"
        >
            {subtitle}
        </Typography>
    </DialogTitle>
);

import { EventNames, EventParams } from '@socket.io/component-emitter';
import { Socket } from 'socket.io-client';
import { UUID } from '../types/uuid';
import {
    Action,
    CONVERSATION_UPDATED_EVENT,
    ConversationUpdateHandler,
} from './event-handlers/handle-conversation.updated.event';
import { UserTyping } from '../components/ConversationRow/use-conversation-typing';
import {
    CONTACT_UPDATED_EVENT,
    ContactUpdateHandler,
} from './event-handlers/contact-updated.event-handler';
import {
    MESSAGE_UPDATED_EVENT,
    MessageUpdateHandler,
} from './event-handlers/handle-message.updated.event';
import { MessageCreateHandler, NEW_MESSAGE_EVENT } from './new-message-event';
import { Conversation, Message } from '../api/types';
import {
    INBOX_UPDATED_EVENT,
    InboxUpdatedHandler,
} from './event-handlers/inbox-updated.event-handler';
import {
    INBOX_DELETED_EVENT,
    InboxDeletedHandler,
} from './event-handlers/inbox-deleted.event-handler';

export enum MessageEvent {
    Created = 'message:created',
    Updated = 'message:updated',
}

export type ClerkSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

export type ClientToServerEvents = {
    conversations: (args: { id: UUID; act: Action }) => void;
    inboxes: (args: { id: UUID; act: Action }) => void;
    'conversation/typing': (args: { id: UUID }) => void;
};

export type ServerToClientEvents = {
    typing: (args: UserTyping) => void;
    'integration:updated': () => void;
    [INBOX_UPDATED_EVENT]: InboxUpdatedHandler;
    [INBOX_DELETED_EVENT]: InboxDeletedHandler;
    [CONVERSATION_UPDATED_EVENT]: ConversationUpdateHandler;
    [CONTACT_UPDATED_EVENT]: ContactUpdateHandler;
    [MESSAGE_UPDATED_EVENT]: MessageUpdateHandler;
    [NEW_MESSAGE_EVENT]: MessageCreateHandler;
    [MessageEvent.Created]: (payload: {
        message: Message;
        conversation: Conversation;
    }) => void;
};

export type Pair<
    Ev extends
        EventNames<ClientToServerEvents> = EventNames<ClientToServerEvents>,
> = {
    ev: Ev;
    args: EventParams<ClientToServerEvents, Ev>;
};

export interface SocketMethods {
    getSocket: () => ClerkSocket | undefined;
    init: () => void;
    disconnect: () => void;
    stick: <
        Ev extends
            EventNames<ClientToServerEvents> = EventNames<ClientToServerEvents>,
    >(
        key: string,
        ev: Ev,
        ...args: EventParams<ClientToServerEvents, Ev>
    ) => void;
    unstick: <
        Ev extends
            EventNames<ClientToServerEvents> = EventNames<ClientToServerEvents>,
    >(
        key: string,
        ev: Ev,
        ...args: EventParams<ClientToServerEvents, Ev>
    ) => void;
}

import { SxProps } from '@mui/material';

export const iconWrapper: SxProps = {
    fontSize: 56,
    lineHeight: '56px',
    '& svg': {
        width: 56,
        height: 56,
    },
};

export const paperWithFrame: SxProps = {
    justifySelf: 'flex-start',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 744,
    borderRadius: 1.5,
    border: '1px solid',
    borderColor: 'custom.gray.light400',
};

export const logoButton: SxProps = { position: 'absolute', left: 40, top: 40 };

export const getWrapperStyles = (disableFrame: boolean): SxProps => ({
    position: 'relative',
    overflow: 'auto',

    ...(disableFrame
        ? {
              background: 'white',
          }
        : {}),
});

import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
    styleOverrides: {
        switchBase: ({ theme }) => ({
            '&.Mui-disabled': {
                color: theme.palette.custom.gray.disabledBackground,
            },

            '&:not(.Mui-checked) .MuiSwitch-thumb': {
                border: '1px solid',
                borderColor: theme.palette.custom.gray.light400,
            },
        }),
        sizeSmall: ({ theme }) => ({
            '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
                border: '1px solid',
                borderColor: theme.palette.custom.gray.light400,
                background: theme.palette.custom.gray.disabledBackground,
                opacity: 1,
            },
        }),
    },
};

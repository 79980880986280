import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

/**
 * Used in Indeterminate component
 **/
export const CheckboxIndeterminate = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        {props.fontSize === 'small' ? (
            <svg
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    id="btn"
                    d="M0 3.33333C0 1.49238 1.49238 0 3.33333 0H16.6667C18.5076 0 20 1.49238 20 3.33333V16.6667C20 18.5076 18.5076 20 16.6667 20H3.33333C1.49238 20 0 18.5076 0 16.6667V3.33333Z"
                    fill="currentColor"
                />
                <path
                    id="btn (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6667 0.833333H3.33333C1.95262 0.833333 0.833333 1.95262 0.833333 3.33333V16.6667C0.833333 18.0474 1.95262 19.1667 3.33333 19.1667H16.6667C18.0474 19.1667 19.1667 18.0474 19.1667 16.6667V3.33333C19.1667 1.95262 18.0474 0.833333 16.6667 0.833333ZM3.33333 0C1.49238 0 0 1.49238 0 3.33333V16.6667C0 18.5076 1.49238 20 3.33333 20H16.6667C18.5076 20 20 18.5076 20 16.6667V3.33333C20 1.49238 18.5076 0 16.6667 0H3.33333Z"
                    fill="#D5DBE5"
                />
                <path
                    id="Icon"
                    d="M5 10L15 10"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ) : (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H7V11H17V13Z"
                    fill="currentColor"
                />
            </svg>
        )}
    </SvgIcon>
);

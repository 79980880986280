import { Button, Stack, Typography } from '@mui/material';

export function DeleteRow({ onDeleteInbox }: { onDeleteInbox: () => void }) {
    return (
        <Stack
            spacing={{
                xs: 1,
                md: 6,
            }}
            useFlexGap
            direction={{
                xs: 'column',
                md: 'row',
            }}
            alignItems={{
                xs: 'flex-start',
                md: 'center',
            }}
        >
            <Stack spacing={1} flex={1}>
                <Typography variant="body3" color="primary.dark">
                    Delete inbox
                </Typography>
                <Typography variant="body4" color="custom.gray.super">
                    By deleting this phone number you will lose access to all
                    messages.
                </Typography>
            </Stack>
            <Button
                variant="outlined"
                color="error"
                size="small"
                sx={{
                    width: 104,
                }}
                onClick={onDeleteInbox}
            >
                Delete
            </Button>
        </Stack>
    );
}

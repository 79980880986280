import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const CardFilled = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16" fill="none">
            <path
                d="M22 3.77273V2.54545C22 1.13964 20.9227 0 19.5938 0H2.40625C1.07731 0 0 1.13964 0 2.54545V3.77273C0 3.89823 0.096207 4 0.214844 4H21.7852C21.9038 4 22 3.89823 22 3.77273Z"
                fill="currentColor"
            />
            <path
                d="M0 5.68137V13.4541C0 14.8599 1.07731 15.9996 2.40625 15.9996H19.5938C20.9227 15.9996 22 14.8599 22 13.4541V5.68137C22 5.55587 21.9038 5.4541 21.7852 5.4541H0.214844C0.096207 5.4541 0 5.55587 0 5.68137ZM5.5 11.6359C5.5 12.0376 5.19217 12.3632 4.8125 12.3632H4.125C3.74533 12.3632 3.4375 12.0376 3.4375 11.6359V10.9086C3.4375 10.507 3.74533 10.1814 4.125 10.1814H4.8125C5.19217 10.1814 5.5 10.507 5.5 10.9086V11.6359Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

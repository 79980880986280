import {
    Box,
    Chip,
    ChipOwnProps,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { StripeInvoice } from '../../../api/billing';
import { AdapterLink } from '../../../components/ConversationsList/AdapterLink';
import dayjs from 'dayjs';
import { Download } from '../../../icons/common/CDownload';
import { getSquare } from '../../../theme/style.helpers';

type StripeInvoiceStatus =
    | 'deleted'
    | 'draft'
    | 'open'
    | 'paid'
    | 'uncollectible'
    | 'void';

const statusColor: Record<StripeInvoiceStatus, ChipOwnProps['color']> = {
    deleted: 'error',
    draft: 'info',
    open: 'warning',
    paid: 'success',
    uncollectible: 'error',
    void: 'default',
};

export const Invoices = ({ invoices }: { invoices: StripeInvoice[] }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 6,
                width: 1,
            }}
        >
            <Typography variant="body2" fontWeight={500}>
                Invoices
            </Typography>
            <TableContainer>
                <Table
                    size="small"
                    sx={{
                        borderCollapse: 'separate',
                        '& th': {
                            textTransform: 'none',
                            '&:first-of-type': { pl: 0 },
                            '&:last-child': { pr: 0 },
                        },
                        '& td': {
                            '&:first-of-type': { pl: 0 },
                            '&:last-child': { pr: 0, width: 30 },
                        },
                        '& tr:last-child td': { borderBottom: 'none' },
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                bgcolor: 'white',
                            }}
                        >
                            <TableCell>Date of issue</TableCell>
                            <TableCell>Invoice number</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice.id}>
                                <TableCell sx={{ color: 'text.secondary' }}>
                                    {dayjs(invoice.created * 1000).format(
                                        'MMMM DD, YYYY',
                                    )}
                                </TableCell>
                                <TableCell>{invoice.number}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={invoice.status}
                                        variant="outlined"
                                        color={
                                            statusColor[
                                                invoice.status as StripeInvoiceStatus
                                            ]
                                        }
                                        size="small"
                                        sx={{
                                            textTransform: 'capitalize',
                                            minWidth: 90,
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            position: 'relative',
                                            '&:before': {
                                                content:
                                                    invoice.total < 0
                                                        ? '"-"'
                                                        : '""',
                                                position: 'absolute',
                                                left: -8,
                                            },
                                        }}
                                    >
                                        ${Math.abs(invoice.total) / 100}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        LinkComponent={AdapterLink}
                                        href={invoice.invoice_pdf}
                                        sx={getSquare(20)}
                                    >
                                        <Download
                                            sx={{
                                                color: 'custom.text.secondary',
                                            }}
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

import {
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import * as styles from './styles';
import { MessageTemplates } from './MessageTemplates/MessageTemplates';
import { MessageTemplate } from '../../../../../api/types';
import { CloseIcon } from '../../../../../icons/common/CCloseIcon';

export function SnippetPopover({
    anchorEl,
    withProperties,
    onSelect,
    onClose,
}: {
    anchorEl: HTMLElement;
    withProperties: boolean;
    onSelect: (template: MessageTemplate) => void;
    onClose: () => void;
}) {
    return (
        <ClickAwayListener onClickAway={onClose}>
            <Popper
                open={true}
                anchorEl={anchorEl}
                placement="top"
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -8],
                        },
                    },
                ]}
                sx={{
                    width: anchorEl?.clientWidth,
                }}
            >
                <Paper sx={styles.paper} elevation={5}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        p={3}
                    >
                        <Box flex={1}>
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                fontWeight={500}
                            >
                                Templates
                            </Typography>
                        </Box>
                        <IconButton onClick={onClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <MessageTemplates
                        withProperties={withProperties}
                        onSelect={onSelect}
                    />
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
}

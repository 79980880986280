import styles from './PaymentPlan.module.scss';
import CheckIcon from '../../assets/icons/different/check.svg?react';
import PopularIcon from '../../assets/icons/payment-flow/popular.svg?react';
import { PlanItem } from '../../containers/PaymentFlow/plans';
import { useState } from 'react';
import { createIntent } from '../../api/payment-flow';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { featureItem, featureList } from './styles';

interface PaymentPlanProps {
    planItem: PlanItem;
    basicButton?: boolean;
    onClick: (clientSecret: string) => void;
}

export const PaymentPlan = ({
    planItem,
    basicButton,
    onClick,
}: PaymentPlanProps) => {
    const [isLoading, setLoading] = useState(false);

    return (
        <article className={styles['root']}>
            {planItem.isPopular && (
                <PopularIcon className={styles['root__popular']} />
            )}
            <Typography
                variant="h3"
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
                {planItem.titleIcon} {planItem.title}
            </Typography>
            <Typography variant="body4" color="custom.gray.super" mt={4}>
                {planItem.subtitle}
            </Typography>

            <p className={styles['root__price']}>
                ${planItem.price}{' '}
                <span className={styles['root__billing-period']}>
                    user / month
                </span>
            </p>

            <Box component="ul" sx={featureList}>
                {planItem.features.map((feature) => {
                    return (
                        <Typography
                            variant="body4"
                            key={feature}
                            component="li"
                            sx={featureItem}
                        >
                            <span className={styles['root__mark']}>
                                <CheckIcon />
                            </span>
                            {feature}
                        </Typography>
                    );
                })}
            </Box>

            <Button
                variant={basicButton ? 'outlined' : 'contained'}
                onClick={() => {
                    setLoading(true);
                    createIntent(planItem.type, planItem.priceId)
                        .then((data) => {
                            setLoading(false);
                            onClick(data.secret);
                        })
                        .catch((e) => {
                            setLoading(false);
                            // eslint-disable-next-line no-console
                            console.log(e);
                        });
                }}
                disabled={isLoading}
                fullWidth
            >
                {isLoading ? (
                    <CircularProgress size={18} />
                ) : (
                    `Let’s go with ${planItem.title}`
                )}
            </Button>
        </article>
    );
};

import { Box, Typography } from '@mui/material';

export const Reseller = () => (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Box
            sx={{
                width: 1,
                maxWidth: 480,
                p: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Typography fontSize={56} lineHeight="56px">
                🧑‍💻
            </Typography>
            <Typography variant="h2" mt={3}>
                ContactReseller
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body3" component={Box}>
                    This Clerk Chat team is managed by a reseller.
                </Typography>
                <Typography variant="body3" component={Box}>
                    Please contact the reseller to add users or inboxes to the
                    team.
                </Typography>
            </Box>
        </Box>
    </Box>
);

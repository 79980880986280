import { useState, forwardRef } from 'react';
import cc from 'classcat';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import { InputProps } from '../../../../types/ElementsProps';
import '../../../../assets/styles/react-datepicker-theme.scss';
import Input from '../../../../elements/Inputs/Input';
import { fuzzyDate } from '../../../../utils/format';

const DatePickerCustomInput = forwardRef<HTMLInputElement, InputProps>(
    function DatePickerCustomInput({ value, onClick }, ref) {
        return (
            <Input
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                onClick={onClick}
                value={value}
                ref={ref}
                fullWidth
                readonly
                uncontrolled
            />
        );
    },
);

const renderValue = (val: string, fuzzy = true) => {
    if (fuzzy) {
        return fuzzyDate(new Date(val));
    }
    return dayjs(val).toString();
};

export const DateValue = ({ userProperty, readonly, onChange }: ValueProps) => {
    const [isEditEnabled, setEditEnabled] = useState(false);

    if (isEditEnabled && !readonly) {
        return (
            <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={
                    userProperty.value
                        ? new Date(userProperty.value)
                        : new Date()
                }
                customInput={<DatePickerCustomInput />}
                onCalendarClose={() => {
                    setEditEnabled(false);
                }}
                onChange={(date: Date) =>
                    onChange?.({ ...userProperty, value: date })
                }
            />
        );
    }

    return (
        <div
            className={cc([styles['value'], styles['limited-value']])}
            title={userProperty.value}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                renderValue(userProperty.value)
            ) : (
                <span className={styles['value__empty']}>
                    {readonly ? 'Empty' : '+ Set a value'}
                </span>
            )}
        </div>
    );
};

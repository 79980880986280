import chunk from 'lodash/chunk';
import range from 'lodash/range';

const gradients = [
    'linear-gradient(180deg, #DE97FF 0%, #A530CE 100%)',
    'linear-gradient(180deg, #ADB0FF 0%, #985FF6 100%)',
    'linear-gradient(180deg, #97BAFF 0%, #305CCE 100%)',
    'linear-gradient(180deg, #97F9FF 0%, #309FCE 100%)',
    'linear-gradient(180deg, #BDFFE3 0%, #1FB79B 100%)',
    'linear-gradient(180deg, #CAFFBD 0%, #65B823 100%)',
    'linear-gradient(180deg, #FFE0B5 0%, #F2AC6A 100%)',
    'linear-gradient(180deg, #FFB1AC 0%, #C65650 100%)',
];

const charRange = chunk(range(97, 122, 1), 25 / gradients.length);

export const getGradient = (code: number) => {
    for (let i = 0; i < charRange.length; i++) {
        if (charRange[i] && charRange[i].includes(code)) {
            return gradients[i % gradients.length];
        }
    }
    return gradients[0];
};

import cc from 'classcat';
import styles from './DescendantNodes.module.scss';
import { useWorkflowContext } from '../WorkflowProvider';
import DropSpot from '../DropSpot';

interface BracketProps {
    parentId: string;
    branch?: 'left' | 'right';
    isDirectConnection?: boolean;
}

const VerticalBar = ({ parentId, isDirectConnection }: BracketProps) => {
    const {
        state: { showDropSpots },
    } = useWorkflowContext();

    return (
        <div className={styles['bracket']}>
            {isDirectConnection && showDropSpots && (
                <div className={styles['bracket__vertical-line-drop-spot']}>
                    <DropSpot parentId={parentId} />
                </div>
            )}
            <div className={styles['bracket__vertical-line']} />
        </div>
    );
};

const LeftBranch = ({ parentId, branch }: BracketProps) => {
    const {
        state: { showDropSpots },
    } = useWorkflowContext();

    return (
        <div className={cc([styles['bracket'], styles['bracket__branchable']])}>
            {showDropSpots && (
                <div className={styles['bracket__branch-drop-spot']}>
                    <DropSpot parentId={parentId} branch={branch} />
                </div>
            )}
            <div className={styles['bracket__label']}>False</div>
            <div className={styles['bracket__left-branch']} />
        </div>
    );
};

const RightBranch = ({ parentId, branch }: BracketProps) => {
    const {
        state: { showDropSpots },
    } = useWorkflowContext();

    return (
        <div className={cc([styles['bracket'], styles['bracket__branchable']])}>
            {showDropSpots && (
                <div className={styles['bracket__branch-drop-spot']}>
                    <DropSpot parentId={parentId} branch={branch} />
                </div>
            )}
            <div className={styles['bracket__label']}>True</div>
            <div className={styles['bracket__right-branch']} />
        </div>
    );
};

interface DescendantNodesProps {
    parentId: string;
    singleNode?: JSX.Element;
    leftNode?: JSX.Element;
    rightNode?: JSX.Element;
}

export const DescendantNodes = ({
    parentId,
    singleNode,
    leftNode,
    rightNode,
}: DescendantNodesProps): JSX.Element => (
    <div className={styles['brackets']}>
        <VerticalBar
            parentId={parentId}
            isDirectConnection={!!singleNode || parentId === 'trigger'}
        />

        {singleNode && (
            <div className={styles['brackets__node']}>{singleNode}</div>
        )}

        {!singleNode && parentId !== 'trigger' && (
            <div className={styles['brackets__nodes']}>
                <div className={styles['brackets__node']}>
                    <LeftBranch parentId={parentId} branch="left" />
                    {leftNode}
                </div>
                <div className={styles['brackets__node']}>
                    <RightBranch parentId={parentId} branch="right" />
                    {rightNode}
                </div>
            </div>
        )}
    </div>
);

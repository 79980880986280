import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const CloseFilledIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.48">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 18.3333C14.6024 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39762 14.6024 1.66666 10 1.66666C5.39765 1.66666 1.66669 5.39762 1.66669 10C1.66669 14.6024 5.39765 18.3333 10 18.3333ZM7.14878 6.46446C6.95982 6.2755 6.65345 6.2755 6.46449 6.46446C6.27552 6.65343 6.27552 6.9598 6.46449 7.14876L9.31572 10L6.46449 12.8512C6.27552 13.0402 6.27552 13.3466 6.46449 13.5355C6.65345 13.7245 6.95982 13.7245 7.14878 13.5355L10 10.6843L12.8513 13.5355C13.0402 13.7245 13.3466 13.7245 13.5356 13.5355C13.7245 13.3466 13.7245 13.0402 13.5356 12.8512L10.6843 10L13.5356 7.14876C13.7245 6.9598 13.7245 6.65343 13.5356 6.46446C13.3466 6.2755 13.0402 6.2755 12.8513 6.46446L10 9.3157L7.14878 6.46446Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </SvgIcon>
);

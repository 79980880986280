import { Message } from '../../../api/types';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { MessageGroup } from './History';

export const groupMessages = (messages: Message[]): MessageGroup[] => {
    if (isEmpty(messages)) {
        return [];
    }
    return messages
        .slice()
        .sort(
            (a, b) =>
                new Date(a.timestamp).getTime() -
                new Date(b.timestamp).getTime(),
        )
        .reduce((acc: MessageGroup[], message) => {
            if (!acc.length) {
                return [
                    {
                        date: message.timestamp as string,
                        inbound: message.inbound,
                        messages: [message],
                    },
                ];
            }
            const last = acc[acc.length - 1];
            const lastMessage = last.messages[last.messages.length - 1];
            if (
                last.inbound === message.inbound &&
                lastMessage.sentByName === message.sentByName &&
                lastMessage.sender === message.sender &&
                lastMessage.user?.id === message.user?.id &&
                dayjs(last.date).isSame(message.timestamp, 'minute')
            ) {
                last.messages = last.messages.concat(message);
                return acc;
            }

            return acc.concat({
                date: message.timestamp as string,
                inbound: message.inbound,
                messages: [message],
            });
        }, []);
};

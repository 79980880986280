import { SxProps, Theme } from '@mui/material';

export const itemButton: SxProps<Theme> = {
    px: 0,
    py: 3,
    border: 'none',
    borderBottom: '1px solid',
    borderColor: 'custom.gray.light400',

    ':hover': {
        backgroundColor: 'transparent',
    },
};

export const checkbox: SxProps = {
    padding: 0,

    '&:hover': {
        backgroundColor: 'transparent',
    },
};

export const hideOnMobile: SxProps = {
    display: { xs: 'none', sm: 'block' },
};

import cc from 'classcat';
import styles from './DroplistItem.module.scss';

interface DroplistItemProps {
    className?: string;
    onClick?: () => void;
    children: React.ReactNode;
}

/** @deprecated use mui/ListItem instead */
export const DroplistItem = (props: DroplistItemProps) => (
    <div
        className={cc([styles['root'], props.className])}
        onClick={props.onClick}
    >
        <div className={styles['root__inner']}>{props.children}</div>
    </div>
);

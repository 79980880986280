import {
    useCheckHostabilityQuery,
    useInboxQueryData,
} from '../../queries/inboxes';
import { Redirect } from '../../utils/redirect';
import Loading from '../../components/Loading/Loading';
import Spacer from '../../components/Spacer';
import { useParams } from 'react-router';
import { PhoneVerificationProps } from './PhoneVerification';

export const PhoneTypeVerificationStep = () => {
    const { inboxId } = useParams<PhoneVerificationProps>();
    const inbox = useInboxQueryData(inboxId ?? '');
    const { data, isLoading: hostableLoading } = useCheckHostabilityQuery(
        inbox!.phone,
    );

    return hostableLoading ? (
        <>
            <Spacer height={20} width="auto" />
            <Loading />
        </>
    ) : data?.hostable ? (
        <Redirect to={`/inbox/${inboxId}/verify/signature`} />
    ) : (
        <Redirect to={`/inbox/${inboxId}/verify/phone-configuration`} />
    );
};

import { useSocket } from '../../websocket';
import { useEffect } from 'react';
import { Action } from '../../websocket/event-handlers/handle-conversation.updated.event';
import { UUID } from '../../types/uuid';

export function useConversationSubscription(id: UUID) {
    const socket = useSocket();

    useEffect(() => {
        const key = `c:${id}`;

        socket.stick(key, 'conversations', {
            id,
            act: Action.Subscribe,
        });
        return () => {
            socket.unstick(key, 'conversations', {
                id,
                act: Action.Unsubscribe,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
}

import { container } from './message.styles';
import { Box, Skeleton } from '@mui/material';
import { SenderType } from '../../../../api/types';
import random from 'lodash/random';
import range from 'lodash/range';

export const MessageItemSkeleton = ({ inbound }: { inbound: boolean }) => (
    <Box
        sx={{
            ...container({ inbound, senderType: SenderType.USER }),
            width: random(180, 320),
        }}
    >
        {range(random(1, 3)).map((i) => (
            <Skeleton key={i} height={14} width={`${random(40, 95)}%`} />
        ))}
    </Box>
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useReducer } from 'react';
import type { AnalyticsEventName } from '../types/AnalyticsEventNames';
import { useMeQueryData } from '../queries/user';
import { Profile } from '../api/types';
import { trackEvent } from '../services/analytics';

/** @deprecated use useTrack instead */
export enum AnalyticsActions {
    TRACK = 'track',
}

type AnalyticsState = {
    lastEvent: any;
};

export type EventAction = {
    eventName: AnalyticsEventName;
    eventPayload?: any;
};

type AnalyticsDispatch = {
    type?: AnalyticsActions;
    payload?: EventAction;
    user?: Profile;
};
/** @deprecated use useTrack instead */
const reducer = (
    state: AnalyticsState,
    action: {
        type?: AnalyticsActions;
        payload?: EventAction;
        user?: Profile;
    },
): AnalyticsState => {
    switch (action.type) {
        case AnalyticsActions.TRACK:
            if (action.payload) {
                trackEvent(action.payload);
            }
            return {
                ...state,
                lastEvent: action.payload,
            };
        default:
            return state;
    }
};

/** @deprecated use useTrack instead */
const initialState: AnalyticsState = {
    lastEvent: undefined,
};

/** @deprecated use useTrack instead */
type AnalyticsContextType = {
    state: AnalyticsState;
    dispatch: (action: AnalyticsDispatch) => void;
};
/** @deprecated use useTrack instead */
const AnalyticsContext = createContext<AnalyticsContextType>({
    state: initialState,
    dispatch: (_action: AnalyticsDispatch) => null,
});

/** @deprecated use useTrack instead */
export const AnalyticsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AnalyticsContext.Provider value={{ state, dispatch }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

/** @deprecated use useTrack instead */
export const useAnalytics = () => useContext(AnalyticsContext);

export const useTrack = () => {
    const me = useMeQueryData();

    return (
        eventName: AnalyticsEventName,
        payload: Record<string, unknown> = {},
    ) => {
        trackEvent({
            eventPayload: {
                userId: me?.id,
                email: me?.email,
                teamId: me?.activeTeam?.id,
                microsoftTenantId: me!.microsoftTenantId,
                ...payload,
            },
            eventName,
        });
    };
};

import { useGetMicrosoftTeamsUsers } from '../../../queries/integrations';
import { useInvites, useTeammates } from '../../../queries/user';
import { useMemo } from 'react';
import { Invite, Profile } from '../../../api/types';

const mapEmails = ({ email }: Invite | Profile) => email;

export function useAvailableUsersList() {
    const { data: teamsUsers = [] } = useGetMicrosoftTeamsUsers();
    const { data: teammates = [] } = useTeammates();
    const { data: invites = [] } = useInvites();

    const emailsToExclude = useMemo(
        () => [...teammates.map(mapEmails), ...invites.map(mapEmails)],
        [teammates, invites],
    );

    return useMemo(() => {
        return teamsUsers.filter(
            ({ email }) => email && !emailsToExclude.includes(email),
        );
    }, [emailsToExclude, teamsUsers]);
}

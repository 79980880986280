import IconBase, { IconBaseProps } from '../IconBase';

/** @depreacted use /src/icons/common/CCommentsIcon.tsx */
export const CommentIcon = (props: IconBaseProps) => (
    <IconBase {...props}>
        <path
            d="M19.75 20.5C19.6 20.5 19.46 20.46 19.33 20.37L17.14 18.91C16.89 18.75 16.77 18.66 16.64 18.6C16.55 18.56 16.46 18.53 16.36 18.52C16.22 18.49 16.07 18.5 15.78 18.5H10.95C9.75 18.5 9.09 18.5 8.5 18.2C7.98 17.93 7.56 17.52 7.3 17C7.27 16.95 7.25 16.9 7.23 16.85L4.09 18.42C3.86 18.54 3.58 18.52 3.36 18.39C3.14 18.25 3 18.01 3 17.75V6.95C3 5.75 3 5.09 3.3 4.5C3.57 3.98 3.98 3.57 4.5 3.3C5.09 3 5.75 3 6.95 3H14.55C15.75 3 16.41 3 17 3.3C17.52 3.57 17.94 3.98 18.2 4.5C18.5 5.09 18.5 5.75 18.5 6.95V8.12C18.68 8.16 18.84 8.22 19 8.3C19.52 8.57 19.94 8.98 20.2 9.5C20.5 10.09 20.5 10.75 20.5 11.95V19.75C20.5 20.03 20.35 20.28 20.1 20.41C19.99 20.47 19.87 20.5 19.75 20.5ZM10.95 9.5C9.98 9.5 9.45 9.5 9.18 9.64C8.94 9.76 8.76 9.95 8.63 10.19C8.49 10.46 8.49 10.99 8.49 11.96V14.56C8.49 15.53 8.49 16.06 8.63 16.33C8.75 16.57 8.94 16.75 9.18 16.88C9.45 17.02 9.98 17.02 10.95 17.02H15.78C16.17 17.02 16.39 17.02 16.63 17.06C16.84 17.1 17.05 17.16 17.25 17.25C17.47 17.35 17.65 17.47 17.98 17.68L19.01 18.37V11.97C19.01 11 19.01 10.47 18.87 10.2C18.75 9.96 18.56 9.77 18.32 9.65C18.05 9.51 17.52 9.51 16.55 9.51H10.95V9.5ZM6.95 4.5C5.98 4.5 5.45 4.5 5.18 4.64C4.94 4.76 4.76 4.95 4.63 5.19C4.49 5.46 4.49 5.99 4.49 6.96V16.55L6.99 15.3C6.99 15.08 6.99 14.84 6.99 14.56V11.96C6.99 10.76 6.99 10.1 7.29 9.51C7.56 8.99 7.97 8.57 8.49 8.31C9.08 8.01 9.74 8.01 10.94 8.01H16.54C16.7 8.01 16.85 8.01 16.99 8.01V6.96C16.99 5.99 16.99 5.46 16.85 5.19C16.73 4.95 16.54 4.76 16.3 4.64C16.03 4.5 15.5 4.5 14.53 4.5H6.95Z"
            fill="currentColor"
        />
    </IconBase>
);

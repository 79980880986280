import { pickersLayoutClasses } from '@mui/x-date-pickers';

export const DateTimePickerLayout = {
    [`& .${pickersLayoutClasses.contentWrapper}`]: {
        '& .MuiPickersCalendarHeader-root': {
            '& .MuiPickersCalendarHeader-label': {
                color: 'primary.dark',
                fontWeight: 600,
            },

            '& .MuiPickersArrowSwitcher-root': {
                minWidth: 68,
                justifyContent: 'space-between',
            },
        },

        '& .Mui-selected': {
            backgroundColor: 'info.main',
            color: 'white',
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'info.main',
            color: 'white',
        },
        '& .Mui-selected:focus': {
            backgroundColor: 'info.main',
            color: 'white',
        },

        '& .MuiPickersDay-root': {
            borderRadius: 0,
            fontWeight: 400,
            color: 'primary.dark',
        },

        '& .MuiPickersDay-root.Mui-selected': {
            fontWeight: 600,
            color: 'white',
        },

        '& .MuiDayCalendar-header > .MuiTypography-root': {
            fontWeight: 600,
            color: 'custom.gray.super',
        },
    },
};

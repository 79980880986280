import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const ActiveIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V3C11.25 2.58579 11.5858 2.25 12 2.25ZM8.6492 5.69471C8.85661 6.05326 8.73409 6.51205 8.37554 6.71946C6.20656 7.97415 4.75 10.3174 4.75 13C4.75 17.0041 7.99594 20.25 12 20.25C16.0041 20.25 19.25 17.0041 19.25 13C19.25 10.3174 17.7934 7.97415 15.6245 6.71946C15.2659 6.51205 15.1434 6.05326 15.3508 5.69471C15.5582 5.33617 16.017 5.21365 16.3755 5.42105C18.989 6.93284 20.75 9.76032 20.75 13C20.75 17.8325 16.8325 21.75 12 21.75C7.16751 21.75 3.25 17.8325 3.25 13C3.25 9.76032 5.01103 6.93284 7.62446 5.42105C7.983 5.21365 8.4418 5.33617 8.6492 5.69471Z"
                fill="currentColor"
                fillOpacity="0.8"
            />
        </svg>
    </SvgIcon>
);

import { useMemo } from 'react';

const removeSymbols = (formattedPhone: string) =>
    formattedPhone.replace(/[-\s+)(]/gi, '');

export function useFilteredPhones<T = string>({
    phones,
    searchTerm,
    phoneToString,
}: {
    phones: T[];
    searchTerm: string;
    phoneToString: (phone: T) => string;
}) {
    return useMemo(() => {
        if (!searchTerm) {
            return phones;
        }

        return phones.filter((phone) =>
            removeSymbols(phoneToString(phone)).includes(
                removeSymbols(searchTerm),
            ),
        );
    }, [searchTerm, phones, phoneToString]);
}

import { Components } from '@mui/material/styles/components';
import avatarPlaceholder from '../icons/common/avatarPlaceholder.svg';
import { Box } from '@mui/material';

export const MuiAvatar: Components['MuiAvatar'] = {
    defaultProps: {
        children: (
            <Box
                src={avatarPlaceholder}
                sx={{ width: 1 }}
                component="img"
                alt="avatar"
            />
        ),
    },
    styleOverrides: {
        root: {
            fontSize: '16px',
        },
    },
};

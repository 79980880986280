import { ModalState } from './reducer';
import ModalLayout from '../../layouts/ModalLayout';

export default function Modal({ content, modalProps = {} }: ModalState) {
    if (content) {
        // eslint-disable-next-line react/no-children-prop
        return <ModalLayout {...modalProps} children={content()} />;
    }
    return null;
}

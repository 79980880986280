import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { colors } from './palette';
import { borderRadius } from './border-radius';

export const MuiButtonGroup: Components<Theme>['MuiButtonGroup'] = {
    defaultProps: {
        disableRipple: true,
        disableElevation: true,
    },
    styleOverrides: {
        contained: ({ theme }) => ({
            borderRadius,
            backgroundColor: colors.lightGrey[100],
            '.MuiButton-containedPassive': {
                borderRadius,
                backgroundColor: 'white',
                color: theme.palette.text.primary,
                borderColor: theme.palette.primary.outlinedBorder,
                borderWidth: 1,
                borderStyle: 'solid',
                '&:not(.Mui-disabled)': {
                    boxShadow: theme.shadows[1],
                },
            },
            '.MuiButton-textPassive': {
                color: theme.palette.text.secondary,
            },
        }),
    },
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const CheckIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1101 4.97635C17.3533 5.22128 17.3519 5.617 17.107 5.86023L7.87622 15.0269C7.63249 15.2689 7.23914 15.2689 6.99542 15.0269L2.89285 10.9528C2.64793 10.7096 2.64655 10.3139 2.88977 10.0689C3.133 9.82402 3.52872 9.82264 3.77365 10.0659L7.43582 13.7026L16.2262 4.97327C16.4711 4.73004 16.8668 4.73142 17.1101 4.97635Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

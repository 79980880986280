import MessageForm, { MessageFormData } from '../MessageForm';
import { useToastContext } from '../../containers/toast/reducer';
import { MessageToSend } from '../../api/messages';
import { useAnalytics } from '../../contexts/analytics';
import { useScheduledMessageUpdateQuery } from '../../queries/scheduled-messages';
import { Conversation, Message, ScheduledMessage } from '../../api/types';
import { useMeQueryData } from '../../queries/user';
import { handleError } from './utils';
import { useMessageCreate, useUpdateMessage } from './message.query';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';
import { useInboxes } from '../../queries/inboxes';

export type ConversationMessageFormProps = {
    conversation: Conversation;
    smForEdit?: ScheduledMessage | null;
    messageForEdit?: Message | null;
    onEditCancel?: () => void;
    disabled?: boolean;
};

export function ConversationMessageForm({
    conversation,
    smForEdit,
    disabled,
    messageForEdit,
    onEditCancel,
}: ConversationMessageFormProps) {
    const { inboxId } = useParams();
    const { data: inboxes = [] } = useInboxes();
    const inbox = inboxes.find((i) => i.id === inboxId);
    const { dispatch: AnalyticsDispatch } = useAnalytics();
    const { dispatch: toastDispatch } = useToastContext();
    const me = useMeQueryData();
    const mutation = useUpdateMessage();
    const creation = useMessageCreate();
    const { mutateAsync: updateScheduledMessage } =
        useScheduledMessageUpdateQuery(conversation.id);

    const send = (message: MessageToSend) =>
        creation
            .mutateAsync({
                conversationId: conversation.id,
                body: message.body,
                attachments: message.attachments,
                messageTemplateId: message.messageTemplateId,
            })
            .catch(handleError(toastDispatch));

    const submitForm = ({
        message,
        sendAt,
        files,
        attachments,
        messageTemplateId,
    }: MessageFormData) => {
        const to = conversation?.members;
        const from = conversation?.inbox?.phone || inbox?.phone || '';

        if (
            isEmpty(conversation?.members) ||
            !to ||
            (isEmpty(message.trim()) && isEmpty(files) && isEmpty(attachments))
        ) {
            return Promise.reject();
        }

        AnalyticsDispatch({
            payload: {
                eventName: 'user_sent_message',
                eventPayload: {
                    bodyLength: message.length,
                    conversationId: conversation.id,
                    inboxId: conversation?.inbox?.id || inbox?.id,
                    userId: me?.id,
                    email: me?.email,
                },
            },
        });

        if (messageForEdit) {
            return mutation
                .mutateAsync({
                    id: messageForEdit.id,
                    message: {
                        body: message,
                        conversationId: conversation.id,
                    },
                })
                .then(() => onEditCancel?.())
                .catch(handleError(toastDispatch));
        }

        if (smForEdit) {
            return updateScheduledMessage({
                ...smForEdit,
                sendAt: sendAt || smForEdit.sendAt,
                body: message,
            })
                .then(() => onEditCancel?.())
                .catch(handleError(toastDispatch));
        }

        if (sendAt) {
            return creation
                .mutateAsync({
                    conversationId: conversation.id,
                    body: message,
                    attachments: attachments?.map(({ id }) => id),
                    timestamp: new Date(sendAt),
                })
                .catch(handleError(toastDispatch));
        }

        return send({
            from,
            to,
            messageTemplateId,
            body: message,
            inboxId: conversation.inboxId || inbox!.id,
            conversationId: conversation.id,
            fileIds: files?.map((f) => f.id),
            attachments: attachments?.map(({ id }) => id),
            user: me!,
        });
    };

    const initial = (() => {
        if (messageForEdit) {
            return {
                message: messageForEdit.body || '',
                files: messageForEdit.attachments,
                sendAt: messageForEdit.timestamp,
            } as MessageFormData;
        }

        if (smForEdit) {
            return {
                message: smForEdit.body || '',
                files: smForEdit.attachments,
                sendAt: smForEdit.sendAt,
            } as MessageFormData;
        }

        return null;
    })();

    return (
        <MessageForm
            key={conversation.id}
            signatureAvailable
            templatesAvailable={conversation?.members.length === 1}
            initial={initial}
            placeholder="Write a message..."
            onSend={submitForm}
            onCancel={onEditCancel}
            conversationId={conversation.id}
            disabled={disabled}
        />
    );
}

import { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import cc from 'classcat';
import styles from './Tooltip.module.scss';

type PopperElement = HTMLDivElement | null;

export interface TooltipProps {
    text: string;
    className?: string;
    popupClassName?: string;
    width?: string;
    light?: boolean;
    children: React.ReactNode;
}

export const Tooltip = (props: TooltipProps) => {
    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = useCallback(() => setHovered(true), []);
    const handleMouseLeave = useCallback(() => setHovered(false), []);

    // Popper
    const [referenceEl, setReferenceEl] = useState<PopperElement>(null);
    const [popperEl, setPopperEl] = useState<PopperElement>(null);
    const {
        styles: popperStyles,
        attributes,
        state,
    } = usePopper(referenceEl, popperEl, {
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 7],
                },
            },
        ],
    });

    return (
        <>
            <div
                className={cc([styles['root'], props.className])}
                ref={setReferenceEl}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {props.children}
            </div>
            {isHovered &&
                createPortal(
                    <div
                        ref={setPopperEl}
                        className={cc([
                            props.popupClassName,
                            styles['root__tooltip-body'],
                            {
                                [styles['root__tooltip-body_light']]:
                                    props.light,
                            },
                        ])}
                        style={
                            props.width
                                ? { ...popperStyles.popper, width: props.width }
                                : popperStyles.popper
                        }
                        {...attributes.popper}
                    >
                        {props.text}
                        <div
                            className={cc([
                                styles['root__arrow'],
                                {
                                    [styles['root__arrow_flipped']]:
                                        state?.placement === 'bottom',
                                    [styles['root__arrow_light']]: props.light,
                                },
                            ])}
                        />
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </>
    );
};

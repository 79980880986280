import { Chip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { UUID } from '../../types/uuid';
import { classifyConversation } from '../../api/conversation';
import { useCallback } from 'react';
import { queryClient } from '../../queries/queryClient';

type ClassifyButtonProps = {
    conversationId: UUID;
};

export const ClassifyButton = (props: ClassifyButtonProps) => {
    const { mutateAsync: classify, isPending } = useMutation({
        mutationKey: ['classify-conversation', props.conversationId],
        mutationFn: (conversationId: string) =>
            classifyConversation(conversationId),
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [
                    'conversation-classifications',
                    props.conversationId,
                ],
            });
        },
    });

    const handleClick = useCallback(async () => {
        await classify(props.conversationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.conversationId]);

    return (
        <Chip
            label="classify"
            onClick={handleClick}
            disabled={isPending}
            size="small"
        />
    );
};

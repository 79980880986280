import { Box, Skeleton } from '@mui/material';
import { DotDivider } from '../../../components/common/DotDivider';
import { author, body, container } from './CampaignMessage/message.styles';
import range from 'lodash/range';
import { PropsWithChildren } from 'react';

export const MessageSkeleton = ({ children }: PropsWithChildren) => (
    <Box sx={{ my: 6 }}>
        <Box sx={container}>
            <Box sx={{ flexGrow: 1, mt: 1.5 }}>
                <Box sx={author}>
                    <Skeleton width={40} height={12} sx={{ mr: 1 }} />
                    <Skeleton width={100} height={12} />
                    <DotDivider />
                    <Skeleton width={55} height={12} />
                </Box>
                <Box sx={{ ...body, mt: 1.5 }}>
                    {range(3).map((key) => (
                        <Skeleton key={key} width={`${Math.random() * 100}%`} />
                    ))}
                </Box>
            </Box>
            <Skeleton variant="circular" width={40} height={40} />
        </Box>
        {children}
    </Box>
);

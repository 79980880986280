import { useLocation, useNavigate } from 'react-router';
import { useMeQueryData } from './queries/user';
import { useEffect } from 'react';
import { RoleTypes } from './api/types';

export const useOnlyGuestGuard = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const me = useMeQueryData();

    useEffect(() => {
        if (me && /^(\/login|\/signup|\/forgot).*$/.test(pathname)) {
            const shouldSkipTerms =
                me?.acceptedTermsOfService ||
                me?.activeRole?.type !== RoleTypes.WorkspaceOwner;
            navigate(shouldSkipTerms ? '/' : '/setup/terms-of-service');
        }
    }, [me, navigate, pathname]);
};

import cc from 'classcat';
import styles from './Table.module.scss';

interface TableProps {
    className?: string;
    maxHeight?: string;
    children: React.ReactNode;
}

export const Table = (props: TableProps) => (
    <div
        className={cc([styles['table'], props.className])}
        style={{ maxHeight: props.maxHeight }}
    >
        {props.children}
    </div>
);

import { useInboxConversations } from '../../queries/conversations';
import { ConversationsFilter, ConversationStatus } from '../../api/types';
import { Virtuoso } from 'react-virtuoso';
import { UUID } from '../../types/uuid';
import { ConversationListItem } from './Conversation/ListItem';
import { NoConversations } from './Conversation/NoConversations';
import { FetchingSkeleton } from '../ConversationsList/FetchingSkeleton';
import { ListSkeleton } from './ListSkeleton';
import { useConversationsSearch } from './conversations.query';
import { useEffect, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';

type Props = {
    inboxId: UUID;
    term?: string;
    filter?: ConversationsFilter;
};

const getStatus = (
    type?:
        | ConversationStatus.Active
        | ConversationStatus.Archived
        | undefined
        | string,
): ConversationStatus.Active | ConversationStatus.Archived | undefined =>
    (type && ConversationStatus.Active === type) ||
    ConversationStatus.Archived === type
        ? type
        : undefined;

export const InboxConversationsList = ({ inboxId, filter, term }: Props) => {
    const debounced = useDebounce(term, 450);
    const [query, setQuery] = useState<string>('');
    const {
        data: conversations = [],
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
    } = useInboxConversations(inboxId, filter);
    const search = useConversationsSearch({
        inboxId,
        query,
        status: getStatus(filter?.type),
    });
    const data = query
        ? search.data?.pages.map(({ data }) => data).flat() || []
        : conversations;

    useEffect(() => {
        setQuery(debounced || '');
    }, [debounced]);

    if (isLoading || search.isLoading) {
        return <ListSkeleton />;
    }

    if (!data?.length) {
        return <NoConversations inboxId={inboxId} filter={filter} />;
    }

    return (
        <Virtuoso
            data={data}
            overscan={1050}
            increaseViewportBy={200}
            endReached={() => {
                if (query) {
                    if (search.hasNextPage && !search.isFetchingNextPage) {
                        search.fetchNextPage();
                    }
                } else {
                    if (hasNextPage && !isFetchingNextPage) {
                        fetchNextPage();
                    }
                }
            }}
            itemContent={(_, conversation) => (
                <ConversationListItem conversation={conversation} />
            )}
            components={{
                Footer:
                    isFetchingNextPage || search.isFetchingNextPage
                        ? () => <FetchingSkeleton />
                        : undefined,
            }}
        />
    );
};

import { Components } from '@mui/material/styles/components';
import { buttonClasses, Theme } from '@mui/material';
import { borderRadius } from './border-radius';
import { colors } from './palette';

export const MuiButton: Components<Theme>['MuiButton'] = {
    defaultProps: {
        variant: 'contained',
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(2.5, 3),
            borderRadius,
            textTransform: 'initial',

            '& .MuiButton-icon': {
                width: 20,
                height: 20,
                alignItems: 'center',
            },

            [`&.${buttonClasses.outlined}`]: {
                background: colors.primary.white,
            },

            [`&.${buttonClasses.outlined}.${buttonClasses.colorError}`]: {
                ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                    boxShadow: 'none',
                    borderColor: theme.palette.error.outlinedBorder,
                    backgroundColor: theme.palette.error.hover,
                },
                '&:not(.Mui-disabled)': {
                    boxShadow: theme.shadows[1],
                    borderColor: theme.palette.error.outlinedBorder,
                },
                '&.Mui-disabled': {
                    color: theme.palette.custom.text.disabled,
                    backgroundColor:
                        theme.palette.custom.action.disabledBackground,
                },
            },
        }),
        sizeSmall: ({ theme }) => ({
            fontSize: 14,
            lineHeight: '20px',
            padding: theme.spacing(1.5, 3),
            height: 32,
        }),
        sizeLarge: ({ theme }) => ({
            padding: theme.spacing(2.5, 3),
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
        }),

        contained: ({ theme }) => ({
            '&:not(.Mui-disabled)': {
                boxShadow: theme.shadows[2],
            },
            '&:hover:not(:focus-within)': {
                boxShadow: 'none',
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.custom.gray.disabledBackground,
                color: theme.palette.custom.gray.disabledText,
            },
        }),
        containedPrimary: ({ theme }) => ({
            boxShadow: theme.shadows[2],
            '&:hover, &:focus-within': {
                backgroundColor: colors.primary.blue.hover,
            },
            '&:hover': {
                boxShadow: 'none',
            },
        }),
        text: ({ theme }) => ({
            color: theme.palette.primary.dark,
        }),
        textInfo: ({ theme }) => ({
            color: theme.palette.info.main,
        }),
        outlinedPrimary: ({ theme }) => ({
            color: theme.palette.custom.text.primary,
            backgroundColor: theme.palette.secondary.contrastText,
            boxShadow: theme.shadows[1],
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'primary.outlined.border',
            ':hover:not(.Mui-disabled), :focus-within:not(.Mui-disabled)': {
                boxShadow: 'none',
                borderColor: theme.palette.custom.outlinedBorder.primary,
                backgroundColor: theme.palette.primary.hover,
            },
        }),

        outlined: ({ theme }) => ({
            borderColor: theme.palette.custom.gray.divider,

            '&:hover': {
                background: 'none',
            },

            [`&.${buttonClasses.colorInfo}`]: {
                borderColor: theme.palette.info.outlinedBorder,
                '&:not(.Mui-disabled)': {
                    boxShadow: theme.shadows[1],
                },
                '&:hover': {
                    backgroundColor: theme.palette.info.hover,
                    boxShadow: 'none',
                },
            },
        }),
    },
};

import { Box } from '@mui/material';
import { Unfurl as UnfurlType } from '../../api/types';
import Unfurl from './Unfurl';

import * as styles from './styles';

export interface UnfurlsProps {
    unfurls: Record<string, UnfurlType>;
    messageId: number;
    conversationId: string;
}

export const Unfurls = ({
    unfurls,
    messageId,
    conversationId,
}: UnfurlsProps) => {
    return (
        <Box sx={styles.wrapper}>
            {Object.entries(unfurls).map(([id, unfurl]) => (
                <Unfurl
                    key={`${id}_${messageId}`}
                    unfurl={unfurl}
                    unfurlId={id}
                    messageId={messageId}
                    conversationId={conversationId}
                />
            ))}
        </Box>
    );
};

export default Unfurls;

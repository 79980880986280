import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';

export interface RedirectProps {
    to: string;
    replace?: boolean;
}

export function Redirect({ to, replace = true }: RedirectProps) {
    const navigate = useNavigate();
    useLayoutEffect(() => {
        navigate(to, { replace });
    }, [navigate, replace, to]);

    return null;
}

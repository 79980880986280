import { Typography } from '@mui/material';
import { RenderPlaceholderProps } from 'slate-react/dist/components/editable';

export const Placeholder = ({
    children,
    attributes,
}: RenderPlaceholderProps) => {
    return (
        <div
            {...attributes}
            style={{
                ...attributes.style,
                opacity: 1,
            }}
        >
            <Typography variant="body3" color="custom.gray.disabledText">
                {children}
            </Typography>
        </div>
    );
};

import { CURRENT_USER_KEY, useMeQueryData } from '../../queries/user';
import { ClerkPermission, Profile } from '../../api/types';
import { FC } from 'react';
import { useQueryClient } from '@tanstack/react-query';

interface WithPermissionProps {
    requiredPermissions: ClerkPermission[];
    children: React.ReactNode;
}

type Props = WithPermissionProps;

export const WithPermission: FC<Props> = (props: Props) => {
    const me = useMeQueryData();
    const userPermissions = me?.activeRole?.permissions;

    if (
        !userPermissions ||
        !props.requiredPermissions.every((p) => userPermissions.indexOf(p) >= 0)
    ) {
        return null;
    }
    return props.children;
};

export const usePermission = (requiredPermission: ClerkPermission): boolean =>
    !!useQueryClient()
        .getQueryData<Profile>([CURRENT_USER_KEY])
        ?.activeRole?.permissions?.includes(requiredPermission);

import { forwardRef } from 'react';
import cc from 'classcat';
import styles from './Droplist.module.scss';

interface DroplistProps {
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
}

export const Droplist = forwardRef<HTMLDivElement, DroplistProps>(
    function Droplist(props, ref) {
        return (
            <div
                className={cc([styles['root'], props.className])}
                style={props.style}
                ref={ref}
            >
                {props.children}
            </div>
        );
    },
);

import { Paper, SxProps } from '@mui/material';
import { colors } from '../../theme/palette';

export const IconOuterButton = ({
    children,
    sx,
}: {
    sx?: SxProps;
    children: React.ReactNode;
}) => {
    return (
        <Paper
            sx={{
                display: 'flex',
                height: 20,
                width: 20,
                borderRadius: 1,
                border: `1px solid ${colors.primary.gray.actionSelected}`,
                p: '3px',
                svg: {
                    width: 'auto',
                    height: 'auto',
                },
                ...sx,
            }}
            elevation={1}
        >
            {children}
        </Paper>
    );
};

import { usePropertiesQuery } from '../../../../../queries/properties';
import { useMemo } from 'react';
import { DEFAULT_PROPERTIES } from './constants';

export function useProperties(search: string = '') {
    const { data: properties = [] } = usePropertiesQuery();

    const items = useMemo(
        () => properties.map(({ name }) => ({ label: name, value: name })),
        [properties],
    );

    return useMemo(() => {
        const properties = DEFAULT_PROPERTIES.concat(items).filter(
            ({ label }) => label.toLowerCase().includes(search.toLowerCase()),
        );

        const regEx = new RegExp(
            `{{{(${properties.map(({ value }) => value).join('|')})}}}`,
            'g',
        );

        return { properties, regEx };
    }, [items, search]);
}

import { Box, Typography } from '@mui/material';
import { Plan } from './Plan';
import { Period } from './Period';
import { StripeMainSubscription } from '../../../api/billing';
import dayjs from 'dayjs';

export const ActivePlan = ({
    subscription,
}: {
    subscription?: StripeMainSubscription;
}) => {
    if (!subscription) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                width: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant="body2" fontWeight={500}>
                        Active plan
                    </Typography>
                    {subscription.cancelAtPeriodEnd && (
                        <Typography
                            variant="body3"
                            color="text.secondary"
                        >{`(till ${dayjs(
                            subscription.currentPeriodEnd * 1000,
                        ).format('MMMM DD, YYYY')})`}</Typography>
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    display: { xs: 'inline-flex', md: 'flex' },
                    flexWrap: { xs: 'wrap', md: 'nowrap' },
                    gap: 6,
                    width: 1,
                }}
            >
                <Plan
                    subscription={subscription}
                    disabled={!!subscription.cancelAtPeriodEnd}
                />
                <Period
                    subscription={subscription}
                    disabled={!!subscription.cancelAtPeriodEnd}
                />
            </Box>
        </Box>
    );
};

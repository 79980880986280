import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const DinersIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
            />
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#EAEDF2"
            />
            <path
                d="M15.8902 19.6459C20.0384 19.6164 23.3773 16.2297 23.3478 12.0814C23.3184 7.93309 19.9317 4.5941 15.7835 4.62355C11.6353 4.653 8.2964 8.03974 8.32585 12.188C8.3553 16.3364 11.742 19.6753 15.8902 19.6459Z"
                fill="white"
            />
            <path
                d="M15.8472 19.7795C11.5426 19.8 8 16.3127 8 12.0677C8 7.42849 11.5426 4.2196 15.8472 4.22034H17.8644C22.1177 4.2196 26 7.42697 26 12.0677C26 16.3112 22.1177 19.7795 17.8644 19.7795H15.8472ZM15.8644 4.8638C11.9309 4.86531 8.74419 8.05293 8.74345 11.9865C8.74419 15.9196 11.9309 19.1065 15.8644 19.1073C19.7982 19.1065 22.9859 15.9196 22.9869 11.9865C22.9859 8.05293 19.7981 4.86531 15.8644 4.8638ZM14.2477 16.1981V7.77372C12.5552 8.42552 11.3546 10.0637 11.3503 11.9865C11.3546 13.9089 12.5552 15.5471 14.2477 16.1981ZM20.3794 11.9865C20.3761 10.063 19.1736 8.42246 17.4798 7.77295V16.1996C19.1736 15.5493 20.3761 13.9104 20.3794 11.9865Z"
                fill="#004A98"
            />
        </svg>
    </SvgIcon>
);

import dayjs from 'dayjs';
import { getNearestAlignedCurrentDate } from '../../../../components/Modals/TimestampChangeDialog/utils';
import { Box, LinearProgress } from '@mui/material';
import {
    loadingSpinner,
    loadingSpinnerProgress,
} from '../../../../components/ScheduledMessageRow/styles';
import { useUpdateMessage } from '../../../../components/MessageFormContainers/message.query';
import { Message } from '../../../../api/types';
import { TimestampChangeDialog } from '../../../../components/Modals/TimestampChangeDialog/TimestampChangeDialog';
import { useTrack } from '../../../../contexts/analytics';

type Props = {
    message: Message;
    open?: boolean;
    onClose: () => void;
};
export const MessageTimestampChangeDialog = ({
    message,
    open = false,
    onClose,
}: Props) => {
    const track = useTrack();
    const update = useUpdateMessage();

    return (
        <TimestampChangeDialog
            open={open}
            disabled={update.isPending}
            timestamp={dayjs(message.timestamp)}
            onChange={(date) => {
                update
                    .mutateAsync({
                        id: message.id,
                        message: {
                            timestamp: date,
                            conversationId: message.conversationId,
                        },
                    })
                    .then(() => track('message_timestamp_changed'));
            }}
            minDate={getNearestAlignedCurrentDate()?.toDate()}
            onClose={() => onClose()}
            title="Schedule a message"
        >
            {update.isPending && (
                <Box sx={loadingSpinner}>
                    <LinearProgress sx={loadingSpinnerProgress} />
                </Box>
            )}
        </TimestampChangeDialog>
    );
};

import { createContext, useContext, useReducer } from 'react';
import {
    initialState,
    reducer,
    WorkflowReducerAction,
    WorkflowReducerState,
} from './reducer';

// CONTEXT

interface WorkflowContext {
    state: WorkflowReducerState;
    dispatch: (action: WorkflowReducerAction) => void;
}

const Context = createContext<WorkflowContext>({
    state: initialState,
    dispatch: () => null,
});

// PROVIDER & HOOK

export const WorkflowProvider = ({
    children,
    initialValue = initialState,
}: {
    children: React.ReactNode;
    initialValue?: WorkflowReducerState;
}) => {
    const [state, dispatch] = useReducer(reducer, initialValue);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const useWorkflowContext = () => useContext(Context);

import { CustomEditor } from './slate';
import { Descendant, Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { createParagraphNode } from './components/Paragraph/createParagraphNode';
import get from 'lodash/get';

export const resetEditorValue = (editor: CustomEditor) => {
    Transforms.delete(editor, {
        at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
        },
    });
};

export const insertText = (
    editor: CustomEditor,
    text: string,
    replace = false,
) => {
    if (replace) {
        resetEditorValue(editor);
    }

    Transforms.insertText(editor, text);
    ReactEditor.focus(editor);
};

export const insertNodes = (
    editor: CustomEditor,
    nodes: Descendant[],
    replace = false,
) => {
    if (replace) {
        resetEditorValue(editor);
    }

    Transforms.insertNodes(editor, nodes, replace ? { at: [0, 0] } : undefined);
    Transforms.move(editor);
};

export function createTextNode(text: string): Descendant {
    return { text };
}

export const removeNode = (editor: CustomEditor, node: Descendant) => {
    Transforms.removeNodes(editor, { at: ReactEditor.findPath(editor, node) });
};

export function createInitialValue(): Descendant[] {
    return [createParagraphNode('')];
}

export function isEmpty(nodes: Descendant[]): boolean {
    return nodes.every(
        (node) =>
            get(node, 'children.length', 1) === 1 &&
            get(node, 'children.0.text', '').trim() === '',
    );
}

export function compareValues(a: Descendant[], b: Descendant[]): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function focusEditor(editor: CustomEditor) {
    ReactEditor.focus(editor);
    Transforms.select(editor, Editor.end(editor, [])); // move the cursor to the end
}

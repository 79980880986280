import LockIcon from '../../../assets/icons/icons-24/gray/lock.svg?react';
import ApiKeyIcon from '../../../assets/icons/icons-24/gray/api_key.svg?react';
import { CarrierConnectProps } from './CarrierConnect';
import { useEffect, useState } from 'react';
import { StepShell } from '../../Onboarding/StepShell';
import { useListChannelsQuery } from '../../../queries/inboxes';
import { InboxProvider } from '../../../api/types';
import { Button, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { Twilio } from '../../../icons/carriers/Twilio';
import { useNavigate } from 'react-router';

export const TwilioConnect = ({
    goBackward,
    goForward,
    setCredentials,
}: CarrierConnectProps) => {
    const navigate = useNavigate();
    const [sid, setSid] = useState('');
    const [token, setToken] = useState('');
    const [isEnabled, setEnabled] = useState(false);

    const { data, isFetching } = useListChannelsQuery(
        {
            provider: InboxProvider.TWILIO,
            twilioSid: sid,
            twilioToken: token,
        },
        isEnabled,
    );

    useEffect(() => {
        if (!isFetching) {
            setEnabled(false);
        }
    }, [isFetching]);

    useEffect(() => {
        if (data && data.length > 0) {
            setCredentials({ accountSID: sid, authToken: token });
            navigate(goForward);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <StepShell
            title="Import Twilio Phones"
            subtitle="The Account SID, which acts as a username, and the Auth Token which acts as a password."
            icon={<Twilio />}
            goBackward={goBackward}
        >
            <Stack spacing={6} width={320}>
                <TextField
                    value={sid}
                    onChange={(e) => setSid(e.target.value)}
                    fullWidth
                    placeholder="Account SID"
                    InputProps={{
                        autoFocus: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <ApiKeyIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    value={token}
                    type="password"
                    onChange={(e) => setToken(e.target.value)}
                    fullWidth
                    placeholder="Auth token"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    onClick={() => {
                        setEnabled(true);
                    }}
                    disabled={!(sid && token) || isFetching}
                    fullWidth
                >
                    {isFetching ? <ButtonDotLoader /> : 'Proceed'}
                </Button>
                <Link
                    href="https://www.twilio.com/docs/iam/api-keys"
                    variant="body4"
                    color="info.main"
                    textAlign="center"
                    target="_blank"
                >
                    How do i find my Twilio auth key -&gt;
                </Link>
            </Stack>
        </StepShell>
    );
};

import { NavigationItemProps } from '../../types/ElementsProps';
import { ListItemIcon, MenuItem, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { navItemStyle } from './styles';

const NavigationItem = ({
    icon,
    to,
    onClick,
    label,
    children,
}: NavigationItemProps) => (
    <MenuItem
        sx={navItemStyle}
        to={to}
        component={NavLink}
        onClick={(e) => {
            onClick?.(e);
        }}
    >
        <ListItemIcon>{icon}</ListItemIcon>

        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
        >
            <span>{label}</span>
            {children}
        </Stack>
    </MenuItem>
);

export default NavigationItem;

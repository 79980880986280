import { useState } from 'react';
import { MessageTemplate } from '../../../../../../api/types';
import { MessageTemplatesEditor } from './MessageTemplatesEditor';
import { MessageTemplatesList } from './MessageTemplatesList';

type Mode = 'view' | 'create';

interface MessageTemplatesProps {
    onSelect: (template: MessageTemplate) => void;
    withProperties: boolean;
}

export const MessageTemplates = ({
    onSelect,
    withProperties,
}: MessageTemplatesProps) => {
    const [mode, setMode] = useState<Mode>('view');
    const [template, setTemplate] = useState<MessageTemplate | null>(null);

    switch (mode) {
        case 'create':
            return (
                <MessageTemplatesEditor
                    withProperties={withProperties}
                    template={template}
                    onDone={() => {
                        setMode('view');
                        setTemplate(null);
                    }}
                />
            );

        case 'view':
            return (
                <MessageTemplatesList
                    withProperties={withProperties}
                    onCreateClick={() => setMode('create')}
                    onSelect={onSelect}
                    onEdit={(template) => {
                        setMode('create');
                        setTemplate(template);
                    }}
                />
            );

        default:
            return null;
    }
};

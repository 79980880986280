import { AvailableIntegration } from '../integrations-list';
import {
    MenuItem,
    RowWithMenu,
} from '../../../../components/RowWithMenu/RowWithMenu';
import { Chip, Stack, Typography, Box } from '@mui/material';
import { LockIcon } from '../../../../icons/shared/LockIcon';
import { SyncStatus } from './SyncStatus';
import { GlobeIcon } from './GlobeIcon';
import { image } from './styles';
import { CloseIcon } from '../../../../icons/shared/CloseIcon';
import { RefreshIcon } from '../../../../icons/shared/RefreshIcon';
import { SettingsCogIcon } from '../../../../icons/shared/SettingsCogIcon';
import { useTrack } from '../../../../contexts/analytics';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Integration, IntegrationTypes, Profile } from '../../../../api/types';
import {
    useDisconnectIntegration,
    useSyncIntegration,
    useUpdateIntegration,
} from '../../../../queries/integrations';

type Props = AvailableIntegration & {
    connected: Integration;
    me: Profile;
};

export function ConnectedRow({
    icon,
    name,
    hasSettings,
    connected,
    me,
}: Props) {
    const track = useTrack();
    const { mutate: updateIntegration } = useUpdateIntegration();
    const { mutate: disconnectIntegration } = useDisconnectIntegration();
    const { mutate: syncIntegration } = useSyncIntegration();

    const navigate = useNavigate();

    const handleRefresh = () => {
        syncIntegration({
            id: connected.id,
        });

        track('integration_sync_initiated', {
            integration: connected.integrationSource,
            email: me.email,
            userId: me.id,
            teamId: me.activeTeam.id,
        });
    };

    const handleSettings = () => {
        navigate(`/settings/integrations/${connected.id}`);
    };

    const handleDisconnect = () => {
        try {
            disconnectIntegration({
                id: connected.id,
            });

            track('integration_disconnected', {
                integration: connected.integrationSource,
                email: me.email,
                userId: me.id,
                teamId: me.activeTeam.id,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('DISCONNECT ERROR', e);
        }
    };

    const handleTogglePrivacy = useCallback(() => {
        try {
            updateIntegration({
                id: connected.id,
                isPublic: !connected.public,
            });

            track('integration_share_changed', {
                integration: connected.integrationSource,
                state: connected.public ? 'private' : 'public',
                email: me.email,
                userId: me.id,
                teamId: me.activeTeam.id,
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('TOGGLE PRIVACY ERROR', e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, me]);

    const menuItems = useMemo(() => {
        const menuItems: MenuItem[] = [
            {
                label: 'Refresh',
                icon: <RefreshIcon />,
                onClick: handleRefresh,
            },
        ];

        if (hasSettings) {
            menuItems.push({
                label: 'Settings',
                icon: <SettingsCogIcon />,
                onClick: handleSettings,
            });
        }

        if (connected.integrationType === IntegrationTypes.Contact) {
            menuItems.push({
                label: connected.public
                    ? 'Switch to Private'
                    : 'Switch to Team',
                icon: connected.public ? <GlobeIcon /> : <LockIcon />,
                onClick: handleTogglePrivacy,
                divider: true,
            });
        }

        menuItems.push({
            onClick: handleDisconnect,
            icon: <CloseIcon color="error" />,
            label: (
                <Typography variant="subtitle2" color="error">
                    Disconnect
                </Typography>
            ),
        });

        return menuItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSettings, handleTogglePrivacy]);

    return (
        <RowWithMenu menuItems={menuItems}>
            <Stack spacing={4} direction="row" alignItems="center" flex={1}>
                <Box component="img" src={icon} alt={name} sx={image} />
                <div>
                    <Typography variant="body3" color="primary.dark">
                        {name}
                    </Typography>

                    <SyncStatus
                        status={connected.lastSyncStatus}
                        time={connected.lastSyncedAt}
                    />
                </div>
            </Stack>
            {connected.integrationType === IntegrationTypes.Contact && (
                <Chip
                    size="small"
                    color={connected.public ? 'info' : 'default'}
                    label={connected.public ? 'Team' : 'Private'}
                    icon={connected.public ? <GlobeIcon /> : <LockIcon />}
                />
            )}
        </RowWithMenu>
    );
}

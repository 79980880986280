import cc from 'classcat';
import styles from './ButtonsGroupButton.module.scss';

export interface ButtonsGroupButtonProps {
    active?: boolean;
    disabled?: boolean;
    className?: string;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonsGroupButton = (props: ButtonsGroupButtonProps) => (
    <button
        className={cc([
            styles['root'],
            {
                [styles['root_active']]: props.active,
                [styles['root_disabled']]: props.disabled,
            },
            props.className,
        ])}
        onClick={props.onClick}
    >
        {props.children}
    </button>
);

import { SxProps, Theme } from '@mui/material';
import { shadows } from '../../theme/shadows';
import { colors } from '../../theme/palette';

export const root: SxProps<Theme> = {
    margin: 0,
    border: '1px solid',
    borderColor: 'custom.gray.borderEnabled',
    borderRadius: 1.5,
    height: 1,
    outline: 'none',
    boxShadow: shadows[3],
    paddingBottom: 11,
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'white',

    '&.assistant:not(.error):not(.disabled)': {
        borderColor: 'rgba(140, 22, 150, 0.32)',
    },

    '&.assistant:hover:not(.error):not(.disabled)': {
        boxShadow: shadows[6],
    },

    '&.assistant.canSend:focus-within:not(.disabled):not(.error)': {
        borderColor: 'secondary.main',
    },

    '&.error': {
        borderColor: 'error.main',
    },

    '&:hover:not(.disabled):not(.error):not(.assistant)': {
        borderColor: 'info.main',
    },

    '&:focus-within:not(.disabled):not(.error):not(.assistant)': {
        borderColor: colors.secondary.blue.inputHover,
        boxShadow: shadows[4],
    },

    '&.disabled': {
        backgroundColor: 'custom.gray.disabledBackground',
        borderColor: 'custom.gray.borderEnabled',
        color: 'custom.gray.disabledText',
    },
};

export const editable: SxProps = {
    width: 1,
    height: 1,
    border: 'none',
    outline: 'none',
    resize: 'none',
};

export const attachments: SxProps = {
    px: 3,
};

export const editableContainer: SxProps<Theme> = {
    padding: 3,
    maxHeight: 180,
    overflow: 'auto',

    '& a': {
        cursor: 'pointer',
        wordBreak: 'break-all',
    },
};

export const divider: SxProps = { height: 16, marginX: 2 };

export const toolbar: SxProps<Theme> = (theme) => ({
    backgroundColor: theme.palette.custom.gray.superLight,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    padding: theme.spacing(2, 3),
    position: 'absolute',
    bottom: '1px',
    right: '1px',
    width: 'calc(100% - 2px)',
    zIndex: 1,
});

export const transparentToolbar: SxProps<Theme> = (theme) => ({
    ...toolbar(theme),
    backgroundColor: 'transparent',
});

import { info, recipient } from './styles';
import { Box, Typography } from '@mui/material';
import { ContextMenu } from './ContextMenu';
import { memo } from 'react';
import { Conversation } from '../../api/types';
import { ConversationStatus } from './ConversationStatus';

type Props = {
    recipientDisplay: string;
    timestampDisplay: string;
    unread: boolean;
    isAssistantReply: boolean;
    phrase?: string;
    draftMessage: string | null;
    lastMessageBody: string | undefined;
    userTyping?: string;
    conversation: Conversation;
};

export const RowInfo = memo(function RowInfo({
    recipientDisplay,
    timestampDisplay,
    conversation,
}: Props) {
    return (
        <Box sx={info}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="body3"
                    component="span"
                    sx={recipient}
                    fontWeight={500}
                >
                    {recipientDisplay}
                </Typography>
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    whiteSpace="nowrap"
                    ml={2.5}
                >
                    {timestampDisplay}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <ConversationStatus conversation={conversation} />
                <ContextMenu conversation={conversation} />
            </Box>
        </Box>
    );
});

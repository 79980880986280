import { UUID } from '../types/uuid';
import createClient, { client } from './http';
import { Invite, Profile, RoleTypes, UserTeamSetting } from './types';

export const getMe = () =>
    client.get<Profile>('/users/profile').then(({ data }) => data);

export function getSettings(): Promise<UserTeamSetting[]> {
    return createClient()
        .get<UserTeamSetting[]>('/users/settings')
        .then((r) => r.data);
}

export function updateMe(me: Partial<Profile>) {
    return createClient()
        .post('/users/profile', me)
        .then<Profile>((r) => r.data);
}

export function updateMeSettings(setting: Partial<UserTeamSetting>) {
    return createClient()
        .post('/users/settings', setting)
        .then((r) => r.data);
}

export function inactivateUser(id: string) {
    return createClient().delete(`/users/${id}`);
}

// Invites

export type InviteTeamMemberDto = {
    emails: string[];
    role?: RoleTypes;
    inboxIds: UUID[];
};

export function inviteTeamMembers(dto: InviteTeamMemberDto) {
    return createClient().post('/invites', dto);
}

export function deleteInvite({ id }: { id: string }) {
    return createClient().delete(`/invites/${id}`);
}

export function resendInvite({ id }: { id: string }) {
    return createClient()
        .post<Invite>(`/invites/${id}/resend`)
        .then((response) => response.data);
}

export function listInvites() {
    return createClient()
        .get<Invite[]>('/invites')
        .then((res) => res.data);
}

export function resetMicrosoftGraphCredentials() {
    return createClient().get('/users/microsoft/graph/reset');
}

import { CountryCode } from 'libphonenumber-js';
import { CarrierOption, CountryOption } from './types';
import Icon from '../../icons/Icon';
import { Twilio } from '../../icons/carriers/Twilio';
import { MSTeams } from '../../icons/carriers/MSTeams';
import { Telnyx } from '../../icons/carriers/Telnyx';
import { Other } from '../../icons/carriers/Other';
import { Box, SxProps } from '@mui/material';
import { Zoom } from '../../icons/carriers/Zoom';
import Webex from '../../icons/carriers/Webex.png';
import AppleMessenger from '../../icons/carriers/AppleMessenger.png';
import { TelnyxCredentials, TwilioCredentials } from './types';
import emojis from 'emojibase-data/en/compact.json';
import { Inbox, InboxProvider } from '../../api/types';

export const getInboxData = ({
    inboxObj,
    index,
    provider,
    carrierCredentials,
    startingIndex,
}: {
    inboxObj: Partial<Inbox>;
    index: number;
    provider: InboxProvider;
    carrierCredentials?: TelnyxCredentials | TwilioCredentials;
    startingIndex: number;
}) => {
    const defaultInboxName = `Inbox #${startingIndex + index + 1}`;
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];

    const defaultInboxData = {
        provider,
        phone: inboxObj?.phone ?? '',
        name: inboxObj?.name ?? defaultInboxName,
        icon: inboxObj?.icon ?? randomEmoji.hexcode.toString(),
    };

    switch (provider) {
        case InboxProvider.TWILIO: {
            return {
                ...defaultInboxData,
                twilioSid: (carrierCredentials as TwilioCredentials).accountSID,
                twilioToken: (carrierCredentials as TwilioCredentials)
                    .authToken,
            };
        }
        case InboxProvider.TELNYX: {
            return {
                ...defaultInboxData,
                telnyxApiKey: (carrierCredentials as TelnyxCredentials).apiKey,
            };
        }
        case InboxProvider.BANDWIDTH:
        case InboxProvider.MSTEAMS:
        case InboxProvider.OTHER: {
            return defaultInboxData;
        }
        default: {
            return null;
        }
    }
};

// @deprecated - use countryFlags from utils/countries.ts instead
export const getFlagIcon = (countryCode?: CountryCode | 'other') => {
    switch (countryCode) {
        case 'CA':
            return <Icon name="CA" />;

        case 'US':
            return <Icon name="US" />;

        case 'GB':
            return <Icon name="GB" />;

        case 'AU':
            return <Icon name="AU" />;

        case 'DE':
            return <Icon name="DE" />;

        case 'BR':
            return <Icon name="BR" />;

        case 'MX':
            return <Icon name="MX" />;

        case 'other':
            return <Icon name="other-country" />;

        default:
            return null;
    }
};

export const availableCountries = ['US', 'CA'];

// @deprecated - use countriesList from utils/countries.ts instead
export const countriesOptions: CountryOption[] = [
    { id: 'CA', label: 'Canada', icon: <Icon name="CA" />, code: '+1' },
    { id: 'US', label: 'USA', icon: <Icon name="US" />, code: '+1' },
    {
        id: 'GB',
        label: 'United Kingdom',
        icon: <Icon name="GB" />,
        code: '+44',
        disabled: true,
    },
    {
        id: 'AU',
        label: 'Australia',
        icon: <Icon name="AU" />,
        code: '+61',
        disabled: true,
    },
    {
        id: 'DE',
        label: 'Germany',
        icon: <Icon name="DE" />,
        code: '+49',
        disabled: true,
    },
    {
        id: 'BR',
        label: 'Brazil',
        icon: <Icon name="BR" />,
        code: '+55',
        disabled: true,
    },
    {
        id: 'MX',
        label: 'Mexico',
        icon: <Icon name="MX" />,
        code: '+52',
        disabled: true,
    },
    {
        id: 'other',
        label: 'Other',
        icon: <Icon name="other-country" />,
        code: '',
        disabled: true,
    },
];

const carrierIcon: SxProps = {
    width: 40,
    height: 40,
};

export const carrierOptions: CarrierOption[] = [
    { id: 'twilio', label: 'Twilio', icon: <Twilio sx={carrierIcon} /> },
    {
        id: 'msteams',
        label: 'Microsoft Teams',
        icon: <MSTeams sx={carrierIcon} />,
    },
    { id: 'telnyx', label: 'Telnyx', icon: <Telnyx sx={carrierIcon} /> },
    { id: 'zoom', label: 'Zoom', icon: <Zoom sx={carrierIcon} /> },
    {
        id: 'webex',
        label: 'Webex',
        icon: <Box component="img" src={Webex} />,
    },
    { id: 'other', label: 'Other', icon: <Other sx={carrierIcon} /> },
    {
        id: 'apple',
        label: 'Apple Business Messaging',
        icon: <Box component="img" src={AppleMessenger} />,
        isComingSoon: true,
    },
];

import { Box, Chip, Skeleton, SxProps } from '@mui/material';
import { useDeleteKeyword, useKeywords } from './keywords.query';
import { CloseIcon } from '../../../../icons/common/CCloseIcon';

const five = Array.from(Array(5));

export const KeywordsList = ({ sx = {} }: { sx?: SxProps }) => {
    const { data: keywords, isPending } = useKeywords();
    const remove = useDeleteKeyword();

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', w: 1, gap: 2, ...sx }}>
            {isPending
                ? five.map((_, index) => (
                      <Skeleton
                          key={index}
                          variant="rectangular"
                          sx={{ borderRadius: 4 }}
                          width={76}
                          height={32}
                      />
                  ))
                : keywords?.map((keyword) => (
                      <Chip
                          key={keyword.id}
                          label={keyword.phrase}
                          onDelete={() => remove.mutate(keyword.id)}
                          deleteIcon={<CloseIcon />}
                      />
                  ))}
        </Box>
    );
};

import { useLocation } from 'react-router-dom';
import { SetStateAction, useEffect, useRef } from 'react';

type Props = {
    setOpen: (value: SetStateAction<boolean>) => void;
    open: boolean;
};

export const useCloseOnLocationChange = (props: Props) => {
    const location = useLocation();
    const init = useRef<boolean>(false);
    useEffect(() => {
        if (init.current) {
            props.setOpen(false);
            init.current = false;
        }
        if (props.open && !init.current) {
            init.current = true;
        }
    }, [location.key, props]);
};

import { SxProps } from '@mui/material';
import { HEADER_HEIGHT, pixelize } from './inbox.styles';

export const campaignMessageView: SxProps = {
    width: 1,
    display: 'flex',
    flexDirection: 'column',
    p: 6,
    height: `calc(100vh - ${pixelize(HEADER_HEIGHT)})`,
};

import { queryClient } from '../../queries/queryClient';
import { INBOX_LIST_KEY } from '../../queries/inboxes';
import throttle from 'lodash/throttle';

export const INBOX_UPDATED_EVENT = 'inbox:updated';
export type InboxUpdatedHandler = () => void;

export const inboxUpdatedHandler: InboxUpdatedHandler = () => {
    queryClient.refetchQueries({ queryKey: [INBOX_LIST_KEY] });
};

export const handleInboxUpdate = throttle(inboxUpdatedHandler, 200);

import { Contact } from '../../../api/types';

export const createContactFromPhoneNumber = (phoneNumber: string): Contact => {
    return {
        id: phoneNumber,
        name: phoneNumber,
        data: {},
        phone: phoneNumber,
        updated: new Date().toISOString(),
        created: new Date().toISOString(),
        public: true,
    };
};

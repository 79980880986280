import { SxProps, Theme } from '@mui/material';

export const base: SxProps<Theme> = {
    borderRadius: 2,
    p: 2,
    transition: 'background-color .2s',
    '&:hover': {
        bgcolor: 'custom.gray.active',
    },
};

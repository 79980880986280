import { Descendant, Node as SlateNode } from 'slate';
import { jsx } from 'slate-hyperscript';
import { Serializer } from '../slate';
import { createTemplateNode } from '../components/Toolbar/Template/utils';

const deserializeElement = (el: Node, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes = { ...markAttributes };

    const children = Array.from(el.childNodes)
        .map((node) => deserializeElement(node, nodeAttributes))
        .flat() as Descendant[];

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''));
    }

    switch (el.nodeName) {
        case 'BODY':
            return [jsx('element', { type: 'paragraph' }, children)];
        case 'P':
            return jsx('element', { type: 'paragraph' }, children);
        case 'BR':
            return '\n';
        case 'TPL': {
            if (children.length === 1 && 'text' in children[0]) {
                const tpl = createTemplateNode(children[0]?.text);
                return jsx('element', tpl, tpl.children);
            }
            break;
        }
        default:
            return children;
    }
};

export const TextSerializer: Serializer<string | Descendant[]> = {
    serialize: (nodes) => {
        const serialized = (Array.isArray(nodes) ? nodes : [nodes])
            .map((n) => {
                if ('type' in n && n.type === 'template') {
                    return `{{{${n.template}}}}`;
                }

                if (
                    'children' in n &&
                    n.children.some(
                        (c) => 'type' in c && c.type === 'paragraph',
                    )
                ) {
                    return TextSerializer.serialize(n.children);
                }

                return SlateNode.string(n);
            })
            .join('\n')
            .trim();

        return serialized;
    },

    deserialize: (text, propertiesRegEx) => {
        if (Array.isArray(text)) {
            return text;
        }

        const html = propertiesRegEx
            ? text.replaceAll(propertiesRegEx, '<tpl>$1</tpl>')
            : text;

        const document = new DOMParser().parseFromString(html, 'text/html');
        const deserialized = deserializeElement(document.body);

        return deserialized as Descendant[];
    },
};

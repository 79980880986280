import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const Integrations = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9999 2.25C11.5857 2.25 11.2499 2.58579 11.2499 3V3.33697C9.81655 3.67555 8.7499 4.96321 8.7499 6.5C8.7499 7.3945 9.11157 8.20417 9.69505 8.7912L6.10107 14.5815C5.15224 13.996 4.29679 13.2735 3.5622 12.4412C3.2881 12.1306 2.81415 12.1011 2.5036 12.3752C2.19304 12.6493 2.16349 13.1232 2.43759 13.4338C3.26834 14.375 4.23626 15.1928 5.31007 15.8559L2.36267 20.6045C2.14423 20.9564 2.25244 21.4188 2.60437 21.6372C2.95631 21.8557 3.41869 21.7475 3.63713 21.3955L6.63312 16.5686C8.26433 17.3266 10.0828 17.75 11.9999 17.75C13.917 17.75 15.7355 17.3266 17.3667 16.5686L20.3627 21.3955C20.5811 21.7475 21.0435 21.8557 21.3954 21.6372C21.7474 21.4188 21.8556 20.9564 21.6371 20.6045L18.6897 15.8559C19.7635 15.1928 20.7315 14.375 21.5622 13.4338C21.8363 13.1232 21.8067 12.6493 21.4962 12.3752C21.1856 12.1011 20.7117 12.1306 20.4376 12.4412C19.703 13.2735 18.8476 13.996 17.8987 14.5815L14.3047 8.7912C14.8882 8.20417 15.2499 7.3945 15.2499 6.5C15.2499 4.96321 14.1832 3.67555 12.7499 3.33697V3C12.7499 2.58579 12.4141 2.25 11.9999 2.25ZM13.0307 9.58291L16.5691 15.2836C15.1728 15.9049 13.6268 16.25 11.9999 16.25C10.373 16.25 8.82695 15.9049 7.43073 15.2836L10.9691 9.58291C11.2934 9.69131 11.6402 9.75 11.9999 9.75C12.3596 9.75 12.7064 9.69131 13.0307 9.58291ZM11.9999 4.75C12.9664 4.75 13.7499 5.5335 13.7499 6.5C13.7499 7.12688 13.4208 7.67751 12.9224 7.98754C12.655 8.15382 12.3398 8.25 11.9999 8.25C11.66 8.25 11.3448 8.15382 11.0774 7.98754C10.579 7.67751 10.2499 7.12688 10.2499 6.5C10.2499 5.5335 11.0334 4.75 11.9999 4.75Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { useState, useCallback } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import { WithPermission } from '../../../containers/WithPermission/WithPermission';
import { ClerkPermission, Feature, RoleTypes } from '../../../api/types';
import InviteMsTeamsUserModal from '../../../components/Modals/InviteMsTeamsUserModal/InviteMsTeamsUserModal';
import { useTrack } from '../../../contexts/analytics';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { AddMemberIcon } from './AddMemberIcon';
import { SearchTextField } from '../../../elements/SearchTextField/SearchTextField';
import { Invites } from './Invites/Invites';
import { UserTable } from './Users/UserTable';
import { useEnabledFeature, useMeQueryData } from '../../../queries/user';
import { useNavigate } from 'react-router';
import { InviteUserModal } from '../../../components/Modals/InviteUserModal/InviteUserModal';

export const Members = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showInviteModal, setShowInviteModal] = useState(false);
    const track = useTrack();
    const navigate = useNavigate();
    const me = useMeQueryData();

    const canInviteTeammate = useEnabledFeature(Feature.InviteTeammate);
    const addMemberHandler = useCallback(() => {
        track('clicked_send_invite');
        canInviteTeammate
            ? setShowInviteModal(true)
            : navigate('/payment/plan');
    }, [canInviteTeammate, navigate, track]);

    const modal = () =>
        [RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
            me?.activeRole?.type as RoleTypes,
        ) ? (
            <InviteUserModal
                open={showInviteModal}
                onClose={() => setShowInviteModal(false)}
            />
        ) : (
            <InviteMsTeamsUserModal
                open={showInviteModal}
                onClose={() => setShowInviteModal(false)}
            />
        );

    return (
        <SettingsLayout
            title="Members"
            category="Workspace"
            description="Manage members and users of your workspace and set their access level"
        >
            <Stack spacing={10}>
                <Stack spacing={6}>
                    <Stack
                        spacing={1}
                        justifyContent="space-between"
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <WithPermission
                            requiredPermissions={[
                                ClerkPermission.AddTeamMembers,
                            ]}
                        >
                            <Button
                                color="info"
                                size="small"
                                startIcon={<AddMemberIcon />}
                                onClick={addMemberHandler}
                                sx={{
                                    width: 152,
                                }}
                            >
                                Add member
                            </Button>
                        </WithPermission>

                        <SearchTextField
                            placeholder="Search in members"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                                setSearchTerm(
                                    event.currentTarget.value
                                        .trim()
                                        .toLocaleLowerCase(),
                                )
                            }
                        />
                    </Stack>

                    <Divider />

                    <WithPermission
                        requiredPermissions={[
                            ClerkPermission.ModifyPublicInbox,
                            ClerkPermission.ModifyPrivateInbox,
                        ]}
                    >
                        <Invites searchTerm={searchTerm} />
                    </WithPermission>
                </Stack>

                <WithPermission
                    requiredPermissions={[
                        ClerkPermission.ModifyPublicInbox,
                        ClerkPermission.ModifyPrivateInbox,
                    ]}
                >
                    <UserTable searchTerm={searchTerm} />
                </WithPermission>
                {showInviteModal && modal()}
            </Stack>
        </SettingsLayout>
    );
};

import { forwardRef } from 'react';
import cc from 'classcat';
import styles from './DropdownContainer.module.scss';

export type DropdownContainerProps = {
    className?: string;
    children: React.ReactNode;
};

export const DropdownContainer = forwardRef<
    HTMLDivElement,
    DropdownContainerProps
>(function DropdownContainer(props, ref) {
    const { children, className } = props;

    return (
        <div
            className={cc([styles['dropdown-container'], className])}
            ref={ref}
        >
            {children}
        </div>
    );
});

import { UUID } from '../../../types/uuid';
import ContactPanel from '../../../containers/ContactPanel';
import inboxStyles from '../../../components/ChatComponents/index.module.scss';
import { useConversation } from '../../../queries/conversations';
import { QueryParam } from '../query.params';
import { useGoToQueryParam } from '../query.helpers';
import { ContactPanelSkeleton } from './ContactPanelSkeleton';

type Props = {
    conversationId: UUID;
};
export const ConversationContactPanel = ({ conversationId }: Props) => {
    const { data: conversation, isPending } = useConversation(conversationId);
    const goToQueryParam = useGoToQueryParam();

    if (isPending || !conversation) {
        return <ContactPanelSkeleton />;
    }

    return (
        <ContactPanel
            contactPhone={conversation.members[0]}
            className={inboxStyles.editContact}
            onBack={() => {
                goToQueryParam({ [QueryParam.ContactInfo]: 'false' });
            }}
        />
    );
};

import { Button, Dialog, DialogActions } from '@mui/material';
import { useState } from 'react';
import { DialogTitleTwoLine } from '../elements/Dialog/DialogTitleTwoLine';
import { DialogAttentionIcon } from '../elements/Dialog/DialogAttentionIcon';

type UseConfirmDeleteParams = {
    title: string;
    subTitle: string;
};

export const useConfirmDelete = (
    params: UseConfirmDeleteParams,
): [() => Promise<boolean>, () => React.JSX.Element] => {
    const [open, setOpen] = useState<boolean>(false);
    const [rejector, setRejector] = useState(() => () => setOpen(false));
    const [resolver, setResolver] = useState(() => () => setOpen(false));

    const dialog = () => (
        <Dialog open={open} onClose={() => rejector()} maxWidth="xs">
            <DialogAttentionIcon />
            <DialogTitleTwoLine
                title={params.title}
                subtitle={params.subTitle}
            />
            <DialogActions>
                <Button
                    onClick={() => {
                        rejector();
                    }}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        resolver();
                    }}
                    sx={{ minWidth: 200 }}
                    fullWidth
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

    return [
        () => {
            setOpen(true);
            return new Promise((resolve, reject) => {
                setResolver(() => () => {
                    setOpen(false);
                    resolve(true);
                });
                setRejector(() => () => {
                    setOpen(false);
                    reject(false);
                });
            });
        },
        dialog,
    ];
};

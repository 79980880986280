import { useNavigate } from 'react-router';
import { ConversationsList } from './ConversationsList';
import Loading from '../Loading/Loading';
import { useScheduledConversationsQuery } from '../../queries/scheduled-messages';
import historyIcon from '../../assets/icons/icons-20/history.svg';

export const ScheduledConversationsList = () => {
    const {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    } = useScheduledConversationsQuery();
    const navigate = useNavigate();

    if (isPending) {
        return <Loading />;
    }

    return (
        <ConversationsList
            name="Scheduled"
            icon={<img src={historyIcon} />}
            conversations={conversations}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            getHref={(conversationId) => `/scheduled/${conversationId}`}
            onTouchEnd={(conversationId) =>
                navigate(`/scheduled/${conversationId}`)
            }
            view="Cohort"
            isLoading={isPending}
        />
    );
};

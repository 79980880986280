import { Box, Button, Step, StepLabel, Typography } from '@mui/material';
import React from 'react';
import { ProgressStepIcon } from './ProgressStepIcon';
import { CheckIcon } from '../../icons/common/CCheckIcon';
import { AdapterLink } from '../ConversationsList/AdapterLink';

type Props = {
    icon: React.ReactNode;
    title: string;
    subtitle?: React.ReactNode;
    completed: boolean;
    index: number;
    isLast?: boolean;
    content?: React.ReactNode;
    buttonText?: string;
    buttonLink?: string;
    buttonAction?: () => void;
    buttonIcon?: React.ReactNode;
};

export const OnboardingStep = ({
    icon,
    title,
    subtitle,
    completed,
    content,
    index,
    isLast,
    buttonText = 'Done',
    buttonLink,
    buttonAction,
    buttonIcon = <CheckIcon />,
}: Props) => {
    return (
        <Step
            completed={completed}
            active={!completed}
            index={index}
            last={isLast}
        >
            <StepLabel
                sx={{ alignItems: 'flex-start', pb: 4 }}
                icon={icon}
                StepIconComponent={ProgressStepIcon}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'top',
                        gap: 6,
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography pb={1} variant="body2">
                            {title}
                        </Typography>
                        {subtitle}
                        {content && (
                            <Box mt={4} mb={1}>
                                {content}
                            </Box>
                        )}
                    </Box>
                    {!completed && (
                        <Button
                            size="small"
                            color="info"
                            variant="outlined"
                            startIcon={buttonIcon}
                            onClick={buttonAction}
                            LinkComponent={buttonLink ? AdapterLink : undefined}
                            href={buttonLink}
                            sx={{ boxShadow: 1, minWidth: 106 }}
                        >
                            {buttonText}
                        </Button>
                    )}
                </Box>
            </StepLabel>
        </Step>
    );
};

import range from 'lodash/range';
import { Skeleton, Stack } from '@mui/material';
import random from 'lodash/random';

const three = range(3).map(() => random(40, 210));

export const ListSkeleton = () =>
    three.map((width, i) => (
        <Stack
            direction="row"
            key={i}
            width={1}
            gap={2}
            px={3}
            py={2}
            alignItems="center"
        >
            <Skeleton variant="circular" sx={{ width: 20, height: 20 }} />
            <Stack>
                <Skeleton width={width} height={20} />
                <Skeleton width={width} height={16} />
            </Stack>
        </Stack>
    ));

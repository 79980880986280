import { PaletteOptions } from '@mui/material/styles/createPalette';

export const colors = {
    red: {
        50: '#FCECEB',
        200: '#F5B3AD',
    },
    blue: {
        50: '#ECEFFE',
        300: '#8C9EF8',
        500: '#3F5DF4',
    },
    orange: {
        50: '#FFF8EE',
        300: '#FBBA6B',
    },
    green: {
        50: '#E6F7EA',
        300: '#67CF80',
        600: '#018C23',
    },
    purple: {
        300: '#BA73C0',
    },
    superGrey: {
        50: '#ECEFF1',
        100: '#D1D4DA',
        200: '#C2C6CE',
        500: '#667085',
    },
    lightGrey: {
        50: '#FAFAFA',
        100: '#EFF2F5',
        400: '#D5DBE5',
    },
    text: { primary: '#1f2936', secondary: '#667085' },
    action: {
        disabledBackground: '#EFF2F5',
        selected: '#E0E4EB',
    },
    primary: {
        dark: '#1f2936',
        white: '#fff',
        orange: '#F98C08',
        blue: {
            hover: '#2E365E',
        },
        gray: {
            active: '#EFF1F6',
            divider: '#E2E5EA',
            input: '#CBD2DE',
            super: '#667085',
            sideBack: '#F2F3F5',
            light: '#C2C6CE',
            disabledText: '#A3A9B6',
            disabledBackground: '#EFF2F5',
            borderEnabled: '#CBD2DE',
            outlinedBorder: 'rgba(57, 67, 118, 0.16)',
            actionSelected: '#E0E4EB',
            light400: '#D5DBE5',
            superLight: '#FAFAFA',
            tableDivider: '#EAEDF2',
        },
        button: {
            containedPrimaryHover: '#2E365E',
        },
        green: '#01AF2C',
    },
    warning: {
        alert: '#643803',
        background: '#FFF8EE',
    },
    error: {
        main: '#E54132',
    },
    info: {
        main: '#3f5df4',
    },
    secondary: {
        main: '#8C1696',
        label: '#E7E8F3',
        blue: {
            light: '#F7F8FE',
            hover: '#788DF8',
            inputHover: '#657DF6',
        },
    },
    background: {
        paper: '#FFFFFF',
    },
};

export const palette: PaletteOptions = {
    mode: 'light',
    primary: {
        main: '#394376',
        dark: colors.primary.dark,
        hover: 'rgba(57, 67, 118, 0.12)',
        focusVisible: '#3943764D',
        outlinedBorder: '#39437629',
    },
    secondary: {
        main: '#8C1696',
        dark: '#701278',
        light: '#A345AB',
        contrastText: '#fff',
        hover: '#8C16961F',
        hoverBorder: '#8c169652',
        focusVisible: '#8C16964D',
    },
    blue: {
        main: colors.info.main,
        contrastText: '#fff',
    },
    orange: {
        main: colors.primary.orange,
    },
    green: {
        main: colors.primary.green,
    },
    success: {
        main: '#01AF2C',
        contrastText: '#fff',
    },
    error: {
        main: colors.error.main,
        dark: '#B73428',
        contrastText: '#fff',
        hover: '#E541321F',
        outlinedBorder: '#E5413252',
        focusVisible: '#E541324D',
    },
    warning: {
        main: '#F98C08',
        dark: '#BF842A',
        contrastText: '#fff',
    },
    info: {
        main: colors.info.main,
        dark: '#324AC3',
        light: '#657df6',
        contrastText: '#fff',
        hover: '#3F5DF41F',
        outlinedBorder: '#3F5DF452',
    },
    toggle_gray: {
        main: colors.info.main,
        dark: '#324AC3',
        light: '#657df6',
        contrastText: '#fff',
    },
    passive: {
        main: '#fff',
        dark: colors.lightGrey[100],
        hover: colors.primary.gray.superLight,
        focusVisible: colors.primary.gray.light400,
        outlinedBorder: colors.superGrey[200],
    },
    custom: {
        text: {
            primary: colors.primary.dark,
            secondary: '#667085',
            disabled: '#A3A9B6',
        },
        action: {
            selected: '#E0E4EB',
            hover: '#EAEDF2',
            disabledBackground: '#EFF2F5',
        },
        warning: {
            alert: colors.warning.alert,
            background: colors.warning.background,
        },
        gray: {
            active: colors.primary.gray.active,
            borderEnabled: colors.primary.gray.borderEnabled,
            disabledBackground: colors.primary.gray.disabledBackground,
            disabledText: colors.primary.gray.disabledText,
            divider: colors.primary.gray.divider,
            input: colors.primary.gray.input,
            light: colors.primary.gray.light,
            light400: colors.primary.gray.light400,
            superLight: colors.primary.gray.superLight,
            sideBack: colors.primary.gray.sideBack,
            super: colors.primary.gray.super,
            outlinedBorder: colors.primary.gray.outlinedBorder,
            actionSelected: colors.primary.gray.actionSelected,
            tableDivider: colors.primary.gray.tableDivider,
        },
        primary: {
            button: {
                containedPrimaryHover:
                    colors.primary.button.containedPrimaryHover,
            },
        },
        outlinedBorder: {
            primary: 'rgba(57, 67, 118, 0.16)',
        },
        hover: {
            primary: 'rgba(57, 67, 118, 0.12)',
        },
        secondary: {
            label: colors.secondary.label,
            blue: {
                light: colors.secondary.blue.light,
                hover: colors.secondary.blue.hover,
                inputHover: colors.secondary.blue.inputHover,
            },
            orange: {
                light: '#fff9eb',
            },
        },
    },
};

import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { MoreHorizFilledIcon } from '../../icons/common/MoreHorizFilled';
import { UUID } from '../../types/uuid';
import { useMenu } from './use-menu';
import { SettingsIcon } from './CSettingsIcon';
import { MarkAsReadIcon } from './CMarkAsReadIcon';
import { CopyIcon } from '../../icons/shared/CopyIcon';
import { MuteIcon } from './CMuteIcon';
import { UnmuteIcon } from './CUnmuteIcon';
import {
    useArchiveAllForInbox,
    useInbox,
    useMarkAllAsReadForInbox,
} from '../../queries/inboxes';
import { ArchiveIcon } from './CArchiveIcon';
import { useTrack } from '../../contexts/analytics';
import { useIsMuted, useToggleInboxMute } from '../../queries/user';
import { ListItemIconLoader } from './ListItemIconLoader';
import { InboxSettings } from '../../containers/InboxSettings/InboxSettings';
import { useState } from 'react';

export const InboxContextMenu = ({ inboxId }: { inboxId: UUID }) => {
    const inbox = useInbox(inboxId);
    const track = useTrack();
    const isMuted = useIsMuted(inboxId);
    const toggleMute = useToggleInboxMute();
    const archiveAll = useArchiveAllForInbox();
    const markAsRead = useMarkAllAsReadForInbox();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { anchorEl, open, handleOpen, handleClose } = useMenu();

    return (
        <>
            <IconButton
                disabled={inbox.isPending}
                id="inbox-context-menu-button"
                aria-controls={open ? 'inbox-context-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
                aria-haspopup="true"
                color="primary"
            >
                <MoreHorizFilledIcon />
            </IconButton>
            <Menu
                id="inbox-context-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'inbox-context-menu-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        setIsDialogOpen(true);
                        track('inbox_settings_opened', {
                            inboxId,
                            origin: 'inbox_context_menu',
                        });
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={markAsRead.isPending}
                    onClick={() =>
                        markAsRead
                            .mutateAsync({ inboxId })
                            .then(() => handleClose())
                    }
                >
                    <ListItemIconLoader loading={markAsRead.isPending}>
                        <MarkAsReadIcon />
                    </ListItemIconLoader>

                    <ListItemText>Mark All Read</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={archiveAll.isPending}
                    onClick={() =>
                        archiveAll
                            .mutateAsync({ inboxId })
                            .then(() => handleClose())
                    }
                >
                    <ListItemIconLoader loading={archiveAll.isPending}>
                        <ArchiveIcon />
                    </ListItemIconLoader>
                    <ListItemText>Archive All</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={toggleMute.isPending}
                    onClick={() => {
                        toggleMute
                            .mutateAsync(inboxId)
                            .then(() =>
                                track(
                                    isMuted ? 'inbox_unmuted' : 'inbox_muted',
                                    { inboxId, origin: 'inbox_context_menu' },
                                ),
                            );
                        handleClose();
                    }}
                >
                    <ListItemIconLoader loading={toggleMute.isPending}>
                        {isMuted ? <UnmuteIcon /> : <MuteIcon />}
                    </ListItemIconLoader>

                    <ListItemText>{isMuted ? 'Unmute' : 'Mute'}</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigator.clipboard.writeText(inbox.data?.phone || '');
                        track('inbox_phone_copied', {
                            inboxId,
                            origin: 'inbox_context_menu',
                        });
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <CopyIcon />
                    </ListItemIcon>
                    <ListItemText>Copy number</ListItemText>
                </MenuItem>
            </Menu>
            <InboxSettings
                open={isDialogOpen}
                inboxId={inboxId}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};

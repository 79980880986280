import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { colors } from '../../../theme/palette';
import { section } from '../styles';
import { NavLink } from 'react-router-dom';
import { useConversationSearch } from '../../../components/Inbox/conversations.query';
import { ListSkeleton } from './ListSkeleton';
import { useTrack } from '../../../contexts/analytics';
import { ConversationAvatar } from './ConversationAvatar';
import { ConversationListItemText } from './ConversationListItemText';

type Props = { query: string };

export const ConversationsList = ({ query = '' }: Props) => {
    const track = useTrack();
    const { data: conversations = [], isLoading } = useConversationSearch(
        query.trim(),
    );

    if (!isLoading && !conversations.length) {
        return null;
    }

    return (
        <Box sx={section}>
            <Typography
                variant="body4"
                sx={{ color: colors.text.secondary, px: 3 }}
            >
                Conversations
            </Typography>
            {isLoading && <ListSkeleton />}
            {conversations?.slice(0, 3).map((conversation) => (
                <MenuItem
                    key={conversation.id}
                    component={NavLink}
                    to={`/inbox/${conversation.inboxId}/conversations/${conversation.id}`}
                    onClick={() => {
                        track('global_search_navigation', {
                            action: 'visit',
                            type: 'conversation',
                            queryLength: query.length,
                        });
                    }}
                >
                    <ListItemIcon>
                        <ConversationAvatar conversation={conversation} />
                    </ListItemIcon>
                    <ConversationListItemText conversation={conversation} />
                </MenuItem>
            ))}
        </Box>
    );
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const AssignMemberIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.16683 3.125C7.67106 3.125 6.4585 4.33756 6.4585 5.83333C6.4585 7.3291 7.67106 8.54167 9.16683 8.54167C10.6626 8.54167 11.8752 7.3291 11.8752 5.83333C11.8752 4.33756 10.6626 3.125 9.16683 3.125ZM5.2085 5.83333C5.2085 3.64721 6.9807 1.875 9.16683 1.875C11.353 1.875 13.1252 3.64721 13.1252 5.83333C13.1252 8.01946 11.353 9.79167 9.16683 9.79167C6.9807 9.79167 5.2085 8.01946 5.2085 5.83333ZM9.16683 12.2917C6.29035 12.2917 3.9585 14.6235 3.9585 17.5C3.9585 17.8452 3.67867 18.125 3.3335 18.125C2.98832 18.125 2.7085 17.8452 2.7085 17.5C2.7085 13.9332 5.59999 11.0417 9.16683 11.0417C9.80698 11.0417 10.4264 11.135 11.0118 11.3092C11.3426 11.4077 11.531 11.7557 11.4325 12.0865C11.3341 12.4174 10.9861 12.6057 10.6552 12.5073C10.1844 12.3671 9.68496 12.2917 9.16683 12.2917ZM14.1668 11.875C14.512 11.875 14.7918 12.1548 14.7918 12.5V14.375L16.6668 14.375C17.012 14.375 17.2918 14.6548 17.2918 15C17.2918 15.3452 17.012 15.625 16.6668 15.625L14.7918 15.625V17.5C14.7918 17.8452 14.512 18.125 14.1668 18.125C13.8217 18.125 13.5418 17.8452 13.5418 17.5V15.625L11.6668 15.625C11.3216 15.625 11.0418 15.3452 11.0418 15C11.0418 14.6548 11.3217 14.375 11.6668 14.375L13.5418 14.375V12.5C13.5418 12.1548 13.8217 11.875 14.1668 11.875Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);

import { SxProps, Theme } from '@mui/material';

export const layoutContainer: SxProps<Theme> = {
    p: ({ spacing }) => spacing(6, 0),
    maxWidth: 744,
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 6,
    mx: 'auto',
    width: 1,
};

export const layout: SxProps<Theme> = (theme) => ({
    display: 'grid',
    minHeight: '100vh',
    gridTemplateColumns: 'auto 1fr',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '60px 1fr',
    },
});

export const layoutHeader: SxProps<Theme> = (theme) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
        boxSizing: 'border-box',
        width: 1,
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'custom.gray.sideBack',
        borderBottom: '1px solid',
        borderColor: 'custom.gray.divider',
    },
});

export const layoutHeaderMenuButton: SxProps<Theme> = {
    width: 64,
    borderRadius: 0,
    backgroundColor: 'background.default',
};

export const container: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: [0.95, 0.95, 0.95, 1],
    mx: 'auto',
};

export const homeButton: SxProps<Theme> = (theme) => ({
    ...theme.typography.body3,
    color: 'primary.dark',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 0,
    height: '100%',
    padding: 4,

    '&:visited': {
        color: 'primary.dark',
    },

    '& .MuiButton-startIcon': {
        color: 'custom.gray.super',
        marginRight: 4,
        marginLeft: 0,
    },
});

export const header: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '60px',
};

export const sidebar: SxProps<Theme> = {
    width: '248px',
    maxHeight: '100vh',
    overflowWrap: 'anywhere',
    position: 'relative',
};

export const containerHeader: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'center',
    backgroundSize: 'cover',

    '& .MuiTypography-h1': {
        color: 'primary.dark',
    },

    '& .MuiTypography-subtitle2': {
        color: 'custom.gray.super',
    },
};

import { ContactPropertyType } from '../../api/types';
import Icon from '../../icons/Icon';
import { PropertyIcon } from '../../icons/iconsMap';

export type PropertyTypeIconMap = {
    type: ContactPropertyType;
    name: string;
    icon: React.ReactNode;
}[];

export const propertyTypeIconNameMap: Record<
    ContactPropertyType,
    PropertyIcon
> = {
    [ContactPropertyType.Text]: 'text',
    [ContactPropertyType.Number]: 'number',
    [ContactPropertyType.Select]: 'select',
    [ContactPropertyType.MultiSelect]: 'select',
    [ContactPropertyType.Date]: 'created-time',
    [ContactPropertyType.Person]: 'private',
    [ContactPropertyType.URL]: 'url',
    [ContactPropertyType.Email]: 'email-2',
    [ContactPropertyType.Phone]: 'phone',
    [ContactPropertyType.CreatedTime]: 'created-time',
    [ContactPropertyType.LastEditedTime]: 'updated',
    [ContactPropertyType.Boolean]: 'boolean',
    [ContactPropertyType.FilesAndMedia]: '',
    [ContactPropertyType.LastContactedBy]: '',
    [ContactPropertyType.LastEditedBy]: '',
};

export const typeIconMap: PropertyTypeIconMap = [
    {
        type: ContactPropertyType.Text,
        name: 'Text',
        icon: <Icon name={propertyTypeIconNameMap[ContactPropertyType.Text]} />,
    },
    {
        type: ContactPropertyType.Number,
        name: 'Number',
        icon: (
            <Icon name={propertyTypeIconNameMap[ContactPropertyType.Number]} />
        ),
    },
    {
        type: ContactPropertyType.Boolean,
        name: 'Boolean',
        icon: (
            <Icon name={propertyTypeIconNameMap[ContactPropertyType.Boolean]} />
        ),
    },
    {
        type: ContactPropertyType.Select,
        name: 'Select',
        icon: (
            <Icon name={propertyTypeIconNameMap[ContactPropertyType.Select]} />
        ),
    },
    {
        type: ContactPropertyType.MultiSelect,
        name: 'Multi-select',
        icon: (
            <Icon
                name={propertyTypeIconNameMap[ContactPropertyType.MultiSelect]}
            />
        ),
    },
    {
        type: ContactPropertyType.Date,
        name: 'Date',
        icon: <Icon name={propertyTypeIconNameMap[ContactPropertyType.Date]} />,
    },
    {
        type: ContactPropertyType.Person,
        name: 'Person',
        icon: (
            <Icon name={propertyTypeIconNameMap[ContactPropertyType.Person]} />
        ),
    },
    {
        type: ContactPropertyType.URL,
        name: 'URL',
        icon: <Icon name={propertyTypeIconNameMap[ContactPropertyType.URL]} />,
    },
    {
        type: ContactPropertyType.Email,
        name: 'Email',
        icon: (
            <Icon name={propertyTypeIconNameMap[ContactPropertyType.Email]} />
        ),
    },
    {
        type: ContactPropertyType.Phone,
        name: 'Phone',
        icon: (
            <Icon name={propertyTypeIconNameMap[ContactPropertyType.Phone]} />
        ),
    },
    {
        type: ContactPropertyType.CreatedTime,
        name: 'Created time',
        icon: (
            <Icon
                name={propertyTypeIconNameMap[ContactPropertyType.CreatedTime]}
            />
        ),
    },
    {
        type: ContactPropertyType.LastEditedTime,
        name: 'Last edited time',
        icon: (
            <Icon
                name={
                    propertyTypeIconNameMap[ContactPropertyType.LastEditedTime]
                }
            />
        ),
    },
];

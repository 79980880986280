import { Conversation, Feature } from '../../../api/types';
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Divider,
    IconButton,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    actionBar,
    answerPreview,
    popoverAdornment,
    popoverBody,
    popoverMessage,
    square20,
    staticPopoverProps,
} from '../styles';
import { PopoverHeader } from './PopoverHeader';
import { DotDivider } from '../../common/DotDivider';
import { HighlightSubstring } from '../HighlightSubquery';
import dayjs from 'dayjs';
import { useMessageStoppedBy } from '../stop-phrase/use-message-stopped-by';
import { useTrack } from '../../../contexts/analytics';
import { useEnabledFeature } from '../../../queries/user';
import { SendFilledIcon } from '../SendFilledIcon';
import { RefreshIcon } from '../RefreshIcon';
import { EastRounded } from '@mui/icons-material';
import { MessagePreview } from '../MessagePreview';
import { clearAssistant, useDeleteMessage } from '../../../queries/messages';
import {
    useMessage,
    useReplyRegeneration,
    useSendImmediately,
} from '../use-message.query';
import { saveMessageDraft } from '../../MessageForm/utils';
import { useContext } from 'react';
import { MessageUpdateContext } from '../../MessageForm/MessageUpdateContext';
import { EditIcon } from '../../../icons/common/CEditIcon';
import { useNavigate } from 'react-router';
import { useConversationName } from '../../Inbox/Conversation/use-conversation-props';

type Props = {
    conversation: Conversation;
    open: boolean;
    anchorEl: HTMLElement | null;
};
export const AssistantPopover = (props: Props) => {
    const track = useTrack();
    const navigate = useNavigate();
    const isNewInboxEnabled = useEnabledFeature(Feature.NewInbox);
    const sendNow = useSendImmediately();
    const deletion = useDeleteMessage();
    const regeneration = useReplyRegeneration();
    const message = useMessage(props.conversation.assistantMessageId);
    const { setMessage } = useContext(MessageUpdateContext);
    const phrase = useMessageStoppedBy(props.conversation.lastMessageBody);
    const timestamp = dayjs(
        props.conversation.lastMessageSent ?? props.conversation.created,
    );
    const recipientDisplay = useConversationName(
        props.conversation.members,
        'New Conversation',
    );

    const timestampDisplay =
        timestamp.diff(new Date(), 'days') < 0
            ? timestamp.format('MMM DD')
            : timestamp.format('hh:mm');

    const onClick = () => {
        track('assistant_popover_clicked');
    };

    const regenerate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        regeneration.mutate(props.conversation.id);
    };

    const onEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        if (!props.conversation.assistantMessageId || !message.data?.body) {
            return;
        }
        deletion
            .mutateAsync({
                messageId: props.conversation.assistantMessageId,
                conversationId: props.conversation.id,
            })
            .then(() => {
                track('assistant_message_edit');
                saveMessageDraft({
                    conversationId: props.conversation.id,
                    message: message.data.body,
                });
                setMessage(message.data.body);
                clearAssistant(
                    props.conversation.id,
                    props.conversation.inboxId,
                );

                navigate(
                    isNewInboxEnabled
                        ? `/inbox/${props.conversation.inboxId}/conversations/${props.conversation.id}`
                        : `/inbox/${props.conversation.inboxId}/${props.conversation.id}`,
                );
            });
    };

    const send = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        if (!props.conversation.assistantMessageId) {
            return;
        }

        sendNow
            .mutateAsync({
                messageId: props.conversation.assistantMessageId,
                conversationId: props.conversation.id,
            })
            .then(() => {
                track('assistant_message_sent_immediately', {
                    origin: 'assistant_popover',
                });
                clearAssistant(
                    props.conversation.id,
                    props.conversation.inboxId,
                );
            });
    };

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            id={`inbox-message-popover-${props.conversation.id}`}
            {...staticPopoverProps}
        >
            <PopoverHeader isStopped={!!phrase} />
            <Divider />
            <Box p={3}>
                <ButtonBase sx={popoverMessage} onClick={onClick}>
                    <Box display="flex" alignItems="center" typography="body4">
                        <Typography variant="body4" fontWeight="500">
                            {recipientDisplay}
                        </Typography>
                        <DotDivider />
                        <Typography variant="body4" color="custom.gray.super">
                            {timestampDisplay}
                        </Typography>
                    </Box>
                    <Typography variant="body3" sx={popoverBody}>
                        <HighlightSubstring
                            message={props.conversation.lastMessageBody ?? ''}
                            substring={phrase}
                        />
                    </Typography>
                    <Box
                        sx={popoverAdornment}
                        role="presentation"
                        className="cButtonBaseAdornment"
                    >
                        <EastRounded size={20} />
                    </Box>
                </ButtonBase>
            </Box>
            {!phrase && !!props.conversation.assistantMessageId && (
                <>
                    <Divider
                        textAlign="left"
                        sx={({ typography }) => ({ ...typography.body4 })}
                    >
                        Answer
                    </Divider>
                    <Box sx={answerPreview} typography="body3">
                        <MessagePreview
                            id={props.conversation.assistantMessageId}
                        />
                    </Box>

                    {message.isFetched && (
                        <>
                            <Divider />
                            <Box sx={actionBar}>
                                <Button
                                    data-testid="assistant-regenerate"
                                    onClick={regenerate}
                                    disabled={
                                        regeneration.isPending ||
                                        deletion.isPending
                                    }
                                    size="small"
                                    color="primary"
                                    startIcon={
                                        regeneration.isPending ? (
                                            <CircularProgress size={18} />
                                        ) : (
                                            <RefreshIcon />
                                        )
                                    }
                                    variant="outlined"
                                >
                                    Regenerate
                                </Button>
                                <Button
                                    onClick={onEdit}
                                    data-testid="assistant-edit"
                                    size="small"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    variant="outlined"
                                    disabled={
                                        !message.isFetched ||
                                        deletion.isPending ||
                                        regeneration.isPending
                                    }
                                >
                                    Edit
                                </Button>
                                <Divider
                                    sx={{ mx: 1 }}
                                    orientation="vertical"
                                    flexItem
                                />
                                <Tooltip title="Send" arrow>
                                    <span>
                                        <IconButton
                                            data-testid="assistant-send"
                                            type="button"
                                            onClick={send}
                                            disabled={
                                                !message.isFetched ||
                                                sendNow.isPending ||
                                                regeneration.isPending
                                            }
                                            color="white-contained"
                                            size="large"
                                        >
                                            {sendNow.isPending ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <SendFilledIcon sx={square20} />
                                            )}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        </>
                    )}
                </>
            )}
        </Popover>
    );
};

import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const VisaIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                fill="white"
            />
            <rect
                x="0.5"
                y="0.5"
                width="33"
                height="23"
                rx="3.5"
                stroke="#EAEDF2"
            />
            <g clipPath="url(#clip0_1963_77902)">
                <path
                    d="M12.8 15.4476L14.019 8.59045H15.9238L14.7428 15.4476H12.8Z"
                    fill="#3C58BF"
                />
                <path
                    d="M12.8 15.4476L14.3619 8.59045H15.9238L14.7428 15.4476H12.8Z"
                    fill="#293688"
                />
                <path
                    d="M21.6381 8.66664C21.2571 8.51426 20.6476 8.36188 19.8857 8.36188C17.9809 8.36188 16.6095 9.31426 16.6095 10.6857C16.6095 11.7143 17.5619 12.2476 18.3238 12.5904C19.0857 12.9333 19.3143 13.1619 19.3143 13.4666C19.3143 13.9238 18.7047 14.1524 18.1714 14.1524C17.4095 14.1524 16.9905 14.0381 16.3428 13.7714L16.0762 13.6571L15.8095 15.219C16.2667 15.4095 17.1047 15.6 17.9809 15.6C20 15.6 21.3333 14.6476 21.3333 13.2C21.3333 12.4 20.8381 11.7904 19.6952 11.2952C19.0095 10.9524 18.5905 10.7619 18.5905 10.419C18.5905 10.1143 18.9333 9.8095 19.6952 9.8095C20.3428 9.8095 20.8 9.92378 21.1428 10.0762L21.3333 10.1524L21.6381 8.66664Z"
                    fill="#3C58BF"
                />
                <path
                    d="M21.6381 8.66664C21.2571 8.51426 20.6476 8.36188 19.8857 8.36188C17.9809 8.36188 16.9524 9.31426 16.9524 10.6857C16.9524 11.7143 17.5619 12.2476 18.3238 12.5904C19.0857 12.9333 19.3143 13.1619 19.3143 13.4666C19.3143 13.9238 18.7047 14.1524 18.1714 14.1524C17.4095 14.1524 16.9905 14.0381 16.3428 13.7714L16.0762 13.6571L15.8095 15.219C16.2667 15.4095 17.1047 15.6 17.9809 15.6C20 15.6 21.3333 14.6476 21.3333 13.2C21.3333 12.4 20.8381 11.7904 19.6952 11.2952C19.0095 10.9524 18.5905 10.7619 18.5905 10.419C18.5905 10.1143 18.9333 9.8095 19.6952 9.8095C20.3428 9.8095 20.8 9.92378 21.1428 10.0762L21.3333 10.1524L21.6381 8.66664Z"
                    fill="#293688"
                />
                <path
                    d="M24.9143 8.59045C24.4571 8.59045 24.1143 8.62855 23.9238 9.08569L21.0667 15.4476H23.1238L23.5047 14.3047H25.9428L26.1714 15.4476H28L26.4 8.59045H24.9143ZM24.0381 13.1619C24.1524 12.819 24.8 11.1428 24.8 11.1428C24.8 11.1428 24.9524 10.7238 25.0667 10.4571L25.1809 11.1047C25.1809 11.1047 25.5619 12.819 25.6381 13.2H24.0381V13.1619Z"
                    fill="#3C58BF"
                />
                <path
                    d="M25.3714 8.59045C24.9143 8.59045 24.5714 8.62855 24.3809 9.08569L21.0667 15.4476H23.1238L23.5047 14.3047H25.9428L26.1714 15.4476H28L26.4 8.59045H25.3714ZM24.0381 13.1619C24.1905 12.7809 24.8 11.1428 24.8 11.1428C24.8 11.1428 24.9524 10.7238 25.0667 10.4571L25.1809 11.1047C25.1809 11.1047 25.5619 12.819 25.6381 13.2H24.0381V13.1619Z"
                    fill="#293688"
                />
                <path
                    d="M9.29525 13.3905L9.10478 12.4C8.76192 11.2572 7.65716 10 6.43811 9.39051L8.1524 15.4857H10.2095L13.2953 8.6286H11.2381L9.29525 13.3905Z"
                    fill="#3C58BF"
                />
                <path
                    d="M9.29525 13.3905L9.10478 12.4C8.76192 11.2572 7.65716 10 6.43811 9.39051L8.1524 15.4857H10.2095L13.2953 8.6286H11.6191L9.29525 13.3905Z"
                    fill="#293688"
                />
                <path
                    d="M4 8.59045L4.34286 8.66664C6.78095 9.23807 8.45714 10.6857 9.10476 12.4L8.41905 9.16188C8.30476 8.70474 7.9619 8.59045 7.54286 8.59045H4Z"
                    fill="#FFBC00"
                />
                <path
                    d="M4 8.59045C6.4381 9.16188 8.45714 10.6476 9.10476 12.3619L8.45714 9.65712C8.34286 9.19998 7.9619 8.93331 7.54286 8.93331L4 8.59045Z"
                    fill="#F7981D"
                />
                <path
                    d="M4 8.59045C6.4381 9.16188 8.45714 10.6476 9.10476 12.3619L8.64762 10.8762C8.53333 10.419 8.38095 9.96188 7.84762 9.77141L4 8.59045Z"
                    fill="#ED7C00"
                />
                <path
                    d="M11.2 13.1619L9.90477 11.8666L9.29524 13.3143L9.14286 12.3619C8.80001 11.219 7.69524 9.96188 6.4762 9.35236L8.19048 15.4476H10.2476L11.2 13.1619Z"
                    fill="#051244"
                />
                <path
                    d="M14.7428 15.4476L13.1047 13.7714L12.8 15.4476H14.7428Z"
                    fill="#051244"
                />
                <path
                    d="M19.1238 13.0857C19.2762 13.2381 19.3524 13.3524 19.3143 13.5047C19.3143 13.9619 18.7047 14.1905 18.1714 14.1905C17.4095 14.1905 16.9905 14.0762 16.3428 13.8095L16.0762 13.6952L15.8095 15.2571C16.2667 15.4476 17.1047 15.6381 17.9809 15.6381C19.2 15.6381 20.1905 15.2952 20.7619 14.6857L19.1238 13.0857Z"
                    fill="#051244"
                />
                <path
                    d="M21.3333 15.4476H23.1238L23.5047 14.3047H25.9428L26.1714 15.4476H28L27.3524 12.6666L25.0666 10.4571L25.1809 11.0666C25.1809 11.0666 25.5619 12.7809 25.6381 13.1619H24.0381C24.1905 12.7809 24.8 11.1428 24.8 11.1428C24.8 11.1428 24.9524 10.7238 25.0666 10.4571"
                    fill="#051244"
                />
            </g>
            <defs>
                <clipPath id="clip0_1963_77902">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(4)"
                    />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);

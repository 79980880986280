import { useUserTyping } from '../ConversationRow/use-conversation-typing';
import { Collapse, SxProps, Typography } from '@mui/material';

type TypingIndicatorProps = {
    conversationId: string;
};

const indicator: SxProps = {
    display: 'block',
    mb: 2,
    px: 7,
    color: 'custom.gray.super',
};
export const TypingIndicator = (props: TypingIndicatorProps) => {
    const userTyping = useUserTyping(props.conversationId);
    if (!userTyping) {
        return null;
    }

    if (!userTyping) {
        return null;
    }

    return (
        <Collapse in={!!userTyping}>
            <Typography variant="body4" sx={indicator}>
                {userTyping} is typing ...
            </Typography>
        </Collapse>
    );
};

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import { IntegrationIcon } from '../../../icons/shared/IntegrationIcon';
import { DialogTitleTwoLine } from '../../../elements/Dialog/DialogTitleTwoLine';
import { ArrowLeftIcon } from '../../../icons/shared/ArrowLeftIcon';
import { useTrack } from '../../../contexts/analytics';
import { FormEvent, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Integration, IntegrationSource } from '../../../api/types';
import { client } from '../../../api/http';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { INTEGRATIONS_LIST_QUERY_KEY } from '../../../queries/integrations';
import { queryClient } from '../../../queries/queryClient';

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (email: string) => void;
    onOpen: () => void;
    title: string;
    subtitle: string;
    placeholder: string;
};

const useSmarshSetup = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (rcptTo: string) =>
            client.post<Integration>(`/integrations/smarsh`, {
                rcptTo,
            }),
        onSuccess: () => {
            track('integration_completed', {
                source: IntegrationSource.Smarsh,
            });
            queryClient.invalidateQueries({
                queryKey: INTEGRATIONS_LIST_QUERY_KEY,
            });
        },
    });
};

export const EmailConnectDialog = ({
    onClose,
    open,
    onSubmit,
    title,
    subtitle,
    placeholder,
    onOpen,
}: Props) => {
    const mutation = useSmarshSetup();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError('');
        try {
            onSubmit(email);
        } catch (e) {
            setError('Use valid email address');
            return;
        }
    };

    useEffect(() => {
        if (open) {
            onOpen();
        }
    }, [open, onOpen]);

    return (
        <Dialog open={open} onClose={onClose}>
            <Box
                role="presentation"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: 8,
                }}
            >
                <IntegrationIcon sx={{ width: 40, height: 40 }} />
            </Box>
            <form onSubmit={handleFormSubmit}>
                <DialogTitleTwoLine title={title} subtitle={subtitle} />
                <DialogContent>
                    <TextField
                        value={email}
                        onChange={(e) => {
                            if (error) {
                                setError('');
                            }
                            setEmail(e.target.value);
                        }}
                        placeholder={placeholder}
                        label="Destination address"
                        name="rcptTo"
                        autoComplete="off"
                        type="email"
                        error={!!error}
                        helperText={error}
                        required
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={mutation.isPending}
                        variant="outlined"
                        startIcon={<ArrowLeftIcon />}
                        onClick={() => {
                            onClose();
                        }}
                        sx={{ minWidth: 120 }}
                    >
                        Back
                    </Button>
                    <Button
                        disabled={mutation.isPending}
                        onClick={() => {}}
                        variant="contained"
                        type="submit"
                        fullWidth
                    >
                        {mutation.isPending ? <ButtonDotLoader /> : 'Connect'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

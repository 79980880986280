import {
    useCampaignMessage,
    useCampaignMessageConversations,
} from '../../main/campaign/v3/use-campaign-messages';
import { useEffect, useMemo } from 'react';
import last from 'lodash/last';
import range from 'lodash/range';
import { Message } from '../../main/campaign/v3/CampaignMessage/Message';
import { MessageSkeleton } from '../../main/campaign/v3/MessageSkeleton';
import { Box, Divider, Skeleton } from '@mui/material';
import { pluralize } from '../../utils/pluralize';
import { Virtuoso } from 'react-virtuoso';
import { ConversationItem } from '../../main/campaign/v3/CampaignMessage/ConversationItem';

import { ConversationItemSkeleton } from '../../main/campaign/v3/CampaignMessage/ConversationItemSkeleton';
import { isAxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { UUID } from '../../types/uuid';
import { campaignMessageView } from './styles';

type Props = {
    campaignMessageId: number;
    prevUrl?: string;
};

type Params = {
    entityId: string;
    inboxId: UUID;
    entity: 'campaigns';
};

export const CampaignMessageView = ({ campaignMessageId }: Props) => {
    const params = useParams<Params>();
    const navigate = useNavigate();

    const { data: message, error } = useCampaignMessage(campaignMessageId);
    const {
        data: result,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        isLoading,
    } = useCampaignMessageConversations(campaignMessageId);

    const redirectRoute = useMemo(() => {
        let route = '/';
        if (params.inboxId) {
            route = `/inbox/${params.inboxId}`;
            if (params.entity && params.entityId) {
                route += `/${params.entity}/${params.entityId}`;
            }
        }
        return route;
    }, [params]);

    useEffect(() => {
        if (!isAxiosError(error) || error.response?.status !== 404) {
            return;
        }

        navigate(redirectRoute);
    }, [redirectRoute, error, navigate]);

    const total = useMemo(
        () => last(result?.pages)?.total || 0,
        [result?.pages],
    );
    const conversations = useMemo(
        () => result?.pages.flatMap(({ data }) => data) ?? [],
        [result?.pages],
    );

    return (
        <Box sx={campaignMessageView}>
            {message ? (
                <Message
                    sx={{ my: 4 }}
                    message={message}
                    onDelete={() => {
                        navigate(redirectRoute);
                    }}
                />
            ) : (
                <MessageSkeleton />
            )}
            <Divider textAlign="center">
                {!isLoading ? (
                    pluralize(
                        total || message?.sentCount || 0,
                        'conversation',
                        'conversations',
                        'No conversations',
                    )
                ) : (
                    <Skeleton width={120} height={12} />
                )}
            </Divider>
            {isLoading ? (
                range(message?.sentCount ?? 10).map((i) => (
                    <ConversationItemSkeleton key={i} />
                ))
            ) : (
                <Virtuoso
                    increaseViewportBy={450}
                    overscan={450}
                    data={conversations}
                    endReached={() => {
                        if (!isFetchingNextPage && hasNextPage) {
                            fetchNextPage();
                        }
                    }}
                    itemContent={(_, conversation) => (
                        <ConversationItem
                            key={conversation.id}
                            conversation={conversation}
                            sx={{ width: 1 }}
                        />
                    )}
                    components={{
                        Footer: isFetchingNextPage
                            ? () =>
                                  range(3).map((key) => (
                                      <ConversationItemSkeleton key={key} />
                                  ))
                            : undefined,
                    }}
                />
            )}
        </Box>
    );
};

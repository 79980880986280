import { useMemo, useState } from 'react';
import { MessageTemplate } from '../../../../../../api/types';
import {
    useMessageTemplateCreateQuery,
    useMessageTemplateUpdateQuery,
} from '../../../../../../queries/message-templates';
import { MessageInput, MessageInputProps } from '../../../../MessageInput';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor, Descendant } from 'slate';
import { withTemplates } from '../../Template/withTemplates';
import { withParagraphs } from '../../../Paragraph/withParagraph';
import { TextSerializer } from '../../../../serializers/text';
import { useProperties } from '../../Template/useProperties';
import {
    Stack,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type MessageTemplatesProps = {
    template: MessageTemplate | null;
    onDone: () => void;
    withProperties: boolean;
};

type Inputs = {
    name: string;
    body: Descendant[];
    isPersonal: boolean;
};

const schema = Yup.object().shape({
    name: Yup.string().required(),
    body: Yup.array<Descendant[]>(Yup.object()).required(),
    isPersonal: Yup.boolean().required(),
});

export const MessageTemplatesEditor = ({
    template,
    onDone,
    withProperties,
}: MessageTemplatesProps) => {
    const [editor] = useState(
        withTemplates(withParagraphs(withReact(withHistory(createEditor())))),
    );
    const { regEx } = useProperties();

    const defaultValues: Inputs = {
        name: template?.name || '',
        body: TextSerializer.deserialize(template?.body || '', regEx),
        isPersonal: !!template?.userId,
    };

    const {
        control,
        register,
        formState: { isValid },
        handleSubmit,
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    // Mutations
    const { mutate: create } = useMessageTemplateCreateQuery();
    const { mutate: update } = useMessageTemplateUpdateQuery();

    const toolbar = useMemo<MessageInputProps['toolbar']>(
        () => (withProperties ? ['templates', 'emojiPicker'] : ['emojiPicker']),
        [withProperties],
    );

    const onSubmit = ({ name, body, isPersonal }: Inputs) => {
        const serialized = TextSerializer.serialize(body);
        if (template) {
            update({
                id: template.id,
                name,
                body: serialized,
                isPersonal,
            });
        } else {
            create({
                name: name.trim(),
                body: serialized.trim(),
                isPersonal,
            });
        }
        onDone();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack flex={1} spacing={4} px={3} py={4}>
                <Stack
                    flex={1}
                    useFlexGap
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={4}
                >
                    <TextField
                        {...register('name')}
                        placeholder="Template name"
                        fullWidth
                        size="small"
                    />
                    <Controller
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="info"
                                        size="small"
                                        checked={value}
                                        onChange={onChange}
                                    />
                                }
                                label="Personal"
                            />
                        )}
                        name="isPersonal"
                    />
                </Stack>

                <Controller
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <MessageInput
                            transparentToolbar
                            placeholder="i.e. sent from Alice Gem at Clerk Chat"
                            editor={editor}
                            onChange={({ value }) => {
                                onChange(value);
                            }}
                            initialValue={value}
                            toolbar={toolbar}
                            height="100%"
                        />
                    )}
                    name="body"
                />

                <Stack direction="row" alignItems="center" spacing={4}>
                    <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        color="info"
                        sx={{ width: 96 }}
                        disabled={!isValid}
                    >
                        Save
                    </Button>
                    <Button
                        size="small"
                        variant="text"
                        onClick={() => onDone()}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

import { Button, Stack } from '@mui/material';
import { ArchiveIcon } from '../../../../components/Inbox/CArchiveIcon';
import { UnmuteIcon } from '../../../../components/Inbox/CUnmuteIcon';
import { MuteIcon } from '../../../../components/Inbox/CMuteIcon';
import { useArchiveAllForInbox } from '../../../../queries/inboxes';
import { useIsMuted, useToggleInboxMute } from '../../../../queries/user';
import { Inbox } from '../../../../api/types';
import { useTrack } from '../../../../contexts/analytics';

export function HeaderActions({ inboxId }: { inboxId: Inbox['id'] }) {
    const archiveAll = useArchiveAllForInbox();
    const isMuted = useIsMuted(inboxId);
    const toggleMute = useToggleInboxMute();
    const track = useTrack();

    return (
        <Stack direction="row" spacing={4} alignItems="end" useFlexGap>
            <Button
                variant="outlined"
                size="small"
                color="info"
                disabled={archiveAll.isPending}
                onClick={() => archiveAll.mutateAsync({ inboxId })}
                startIcon={<ArchiveIcon />}
                sx={{ whiteSpace: 'nowrap' }}
            >
                Archive all
            </Button>
            <Button
                disabled={toggleMute.isPending}
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                    toggleMute.mutateAsync(inboxId).then(() =>
                        track(isMuted ? 'inbox_unmuted' : 'inbox_muted', {
                            inboxId,
                            origin: 'inbox_context_menu',
                        }),
                    );
                }}
                variant="outlined"
                size="small"
                color="info"
                startIcon={isMuted ? <UnmuteIcon /> : <MuteIcon />}
            >
                {isMuted ? 'Unmute' : 'Mute'}
            </Button>
        </Stack>
    );
}

// sizes according to the design: https://www.figma.com/file/MQWa8UO5U0Z1ez31yASqt2/Styleguide-(MUI)?node-id=6587%3A47387
import { SxProps } from '@mui/material';

export type Size = 20 | 32 | 40 | 64 | 24;

export const getFont = (size: Size): SxProps => {
    if (size === 20) {
        return {
            fontWeight: 500,
            fontSize: 10,
        };
    }
    return {
        fontSize: size / 2,
    };
};

import { Box } from '@mui/material';
import React from 'react';
import { frame } from './styles';

type RectangleFrameProps = {
    children: React.ReactNode;
    variant?: 'dark' | 'light';
};

export const RectangleFrame = (props: RectangleFrameProps) => {
    return (
        <Box
            sx={{
                ...frame,
                background: (theme) =>
                    props.variant === 'dark'
                        ? theme.palette.custom.gray.sideBack
                        : 'white',
            }}
        >
            {props.children}
        </Box>
    );
};

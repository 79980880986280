import { NIL } from 'uuid';
import { Action, ActionTypes, Payload } from './actions';
import { FinishHandler } from '../types';
import { FilterValue } from '../../UserInfoProperty/types';
import { Cohort } from '../../../api/types';
import { UUID } from '../../../types/uuid';

// STATE

export interface State {
    quickSearchTerm: string;
    showFilters: boolean;
    inboxId: UUID;
    cohort: Cohort | null;
    cohortId: number | null;
    filters: FilterValue[];
    selectedContactIds: (UUID | string)[];
    mode: 'conversation' | 'campaign';
    onFinish?: FinishHandler;
    limitReached: boolean;
}

export const getInitialState = (
    inboxId: UUID = NIL,
    onFinish?: FinishHandler,
): State => ({
    quickSearchTerm: '',
    showFilters: false,
    inboxId,
    cohort: null,
    cohortId: null,
    filters: [],
    selectedContactIds: [],
    mode: 'conversation',
    limitReached: false,
    onFinish,
});

// REDUCER

type Handler = (state: State, payload?: Payload) => State;

const actionStateMap: Record<ActionTypes, Handler> = {
    [ActionTypes.SET_QUICK_SEARCH_TERM]: (state, quickSearchTerm) => ({
        ...state,
        quickSearchTerm,
    }),
    [ActionTypes.SET_LIMIT_REACHED]: (state, limitReached) => ({
        ...state,
        limitReached,
    }),
    [ActionTypes.TOGGLE_FILTERS]: (state) => ({
        ...state,
        showFilters: !state.showFilters,
    }),
    [ActionTypes.SET_COHORT]: (state, cohort) => ({ ...state, cohort }),
    [ActionTypes.SET_COHORT_ID]: (state, cohortId) => ({ ...state, cohortId }),
    [ActionTypes.SET_FILTERS]: (state, filters) => ({ ...state, filters }),
    [ActionTypes.SET_SELECTED_CONTACTS]: (state, contactIds) => ({
        ...state,
        selectedContactIds: contactIds,
    }),
    [ActionTypes.TOGGLE_MODE]: (state) => ({
        ...state,
        mode: state.mode === 'conversation' ? 'campaign' : 'conversation',
    }),
    [ActionTypes.SET_CAMPAIGN_MODE]: (state) => ({
        ...state,
        mode: 'campaign',
    }),
};

export const reducer = (state: State, { type, payload }: Action) => {
    const handler = actionStateMap[type];
    return handler ? handler(state, payload) : state;
};

import { SxProps, Theme } from '@mui/material';

export const phoneNumberType: SxProps<Theme> = (theme) => ({
    display: 'flex',
    width: 280,
    padding: theme.spacing(6, 4),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1.5,
    border: '1px solid',
    borderColor: theme.palette.custom.gray.light400,
});

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { ArchiveState } from '../../pages/NewInbox/query.helpers';
import { SpecialConversationFilter } from '../../components/Inbox/InboxFilters';
import { QueryParams } from '../../pages/NewInbox/ModernInbox';

export const useInboxSearchParams = () => {
    const [inboxSearchParams, setInboxSearchParams] = useState<QueryParams>({});
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const archiveState = searchParams.get(
            QueryParam.ArchiveState,
        ) as ArchiveState;
        const contactInfo = searchParams.get(QueryParam.ContactInfo) as
            | 'true'
            | 'false';
        const specialFilter = searchParams.get(
            QueryParam.SpecialFilter,
        ) as SpecialConversationFilter;
        const dId = searchParams.get(QueryParam.DiscussionId);
        const cohortIds = searchParams.get(QueryParam.CohortIds);

        setInboxSearchParams({
            [QueryParam.ArchiveState]: archiveState,
            [QueryParam.ContactInfo]: contactInfo,
            [QueryParam.SpecialFilter]: specialFilter,
            [QueryParam.DiscussionId]: dId ? +dId : undefined,
            [QueryParam.CohortIds]: cohortIds ?? undefined,
        });
    }, [searchParams]);
    return inboxSearchParams;
};

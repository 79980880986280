import { useSocket } from '../websocket';
import { useEffect } from 'react';
import { MessageEvent } from '../websocket/types';
import { Conversation, Message } from '../api/types';
import { useShowNotification } from '../websocket/utils/utils';

type Payload = {
    message: Message;
    conversation: Conversation;
};

export const useNotifications = () => {
    const socket = useSocket().getSocket();
    const showNotification = useShowNotification();

    useEffect(() => {
        if (socket) {
            const notifyInbound = (payload: Payload) => {
                if (payload.message.inbound) {
                    showNotification(payload.message, payload.conversation);
                }
            };
            socket.on(MessageEvent.Updated, notifyInbound);
            socket.on(MessageEvent.Created, notifyInbound);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);
};

/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import styles from './ConversationRow.module.scss';
import { memo, useCallback, useMemo, useState } from 'react';
import { useContactsQueryData } from '../../queries/contacts';
import { Contact, Conversation } from '../../api/types';
import {
    useGetConversationName,
    useMarkConversationAsRead,
} from '../../queries/conversations';
import { Box } from '@mui/material';
import { container } from './styles';
import { useMessageStoppedBy } from './stop-phrase/use-message-stopped-by';
import { AssistantPopover } from './Assistant/AssistantPopover';
import { useConversationSubscription } from './use-conversation-subscription';
import { useNow } from './use-now';
import { useUserTyping } from './use-conversation-typing';
import { MembersAvatar } from './MembersAvatar';
import { RowInfo } from './RowInfo';
import { getTextFromJSON } from '../MessageInput/serializers/json';
import { useParams } from 'react-router';
import { useInboxes } from '../../queries/inboxes';
import { getByPhoneOrEmpty } from '../../queries/contact-library.helpers';

interface ConversationRowProps {
    conversation: Conversation;
}

export const ConversationRow = memo(function ConversationRow(
    props: Readonly<ConversationRowProps>,
) {
    useConversationSubscription(props.conversation.id);
    const userTyping = useUserTyping(props.conversation.id);

    const { inboxId } = useParams();
    const { data: inboxes = [] } = useInboxes();
    const inbox = inboxes.find((i) => i.id === inboxId);

    const now = useNow({
        interval: 30_000,
        disabled: !props.conversation.assistantTimestamp,
    });
    const phrase = useMessageStoppedBy(
        inbox?.assistantEnabled && !!props.conversation.lastMessageBody
            ? props.conversation.lastMessageBody
            : '',
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const contacts = useContactsQueryData();
    const { mutate: markAsRead } = useMarkConversationAsRead();

    const { lastMessageBody, members } = props.conversation;

    const memberContacts: Partial<Contact>[] = useMemo(
        () => members.map((member) => getByPhoneOrEmpty(member, contacts)),
        [members, contacts],
    );
    const unread = props.conversation.unreadMessageCount;

    const encoded =
        window.localStorage.getItem(`${props.conversation.id}_draft`) ?? '';
    const draftMessage = useMemo(() => getTextFromJSON(encoded), [encoded]);

    const timestampDisplay = useMemo(() => {
        const timestamp = dayjs(
            props.conversation.lastMessageSent ?? props.conversation.created,
        );
        return timestamp.diff(new Date(), 'days') < 0
            ? timestamp.format('MMM DD')
            : timestamp.format('hh:mm');
    }, [props.conversation.lastMessageSent, props.conversation.created]);

    const isAssistantReply = useMemo(() => {
        const isScheduled =
            !!props.conversation.assistantMessageId &&
            dayjs(props.conversation.assistantTimestamp).diff(now, 'seconds') >=
                30;
        return (
            props.conversation.isLastMessageInbound &&
            (isScheduled || !!phrase) &&
            !userTyping
        );
    }, [
        props.conversation.isLastMessageInbound &&
            props.conversation.assistantMessageId &&
            props.conversation.assistantTimestamp &&
            now,
        phrase,
    ]);
    const getName = useGetConversationName();
    const recipientDisplay = getName(props.conversation);

    const handlePopoverOpen = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (isAssistantReply && anchorEl !== event.currentTarget) {
                setAnchorEl(event.currentTarget);
            }
        },
        [isAssistantReply],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback(() => {
        handleClose();
        if (unread) {
            markAsRead(props.conversation.id);
        }
    }, [unread, props.conversation.id]);

    return (
        <Box
            className={styles.conversation}
            sx={container}
            onClick={handleClick}
            aria-owns={
                open
                    ? `inbox-message-popover-${props.conversation.id}`
                    : undefined
            }
            aria-haspopup="true"
            role="button"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handleClose}
        >
            {unread && <span className={styles.unreadCounter} />}

            <MembersAvatar
                memberContacts={memberContacts}
                invisible={!isAssistantReply}
                phrase={phrase}
            />

            <RowInfo
                recipientDisplay={recipientDisplay}
                timestampDisplay={timestampDisplay}
                unread={unread}
                isAssistantReply={isAssistantReply}
                phrase={phrase}
                draftMessage={draftMessage}
                lastMessageBody={lastMessageBody}
                userTyping={userTyping}
                conversation={props.conversation}
            />
            {isAssistantReply && (
                <AssistantPopover
                    conversation={props.conversation}
                    open={isAssistantReply && open}
                    anchorEl={anchorEl}
                />
            )}
        </Box>
    );
});

import Icon from '../../icons/Icon';
import { useSendOTP } from '../../queries/auth';
import {
    Box,
    Button,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { ButtonDotLoader } from './ButtonDotLoader';
import { stepForm } from './styles';
import { useTeams } from 'msteams-react-base-component';
import { useRef } from 'react';

export interface EmailStepProps {
    email: string;
    setEmail: (email: string) => void;
    next: () => void;
    setMethodId: (methodId: string) => void;
}

export const EmailStep = ({
    email,
    next,
    setEmail,
    setMethodId,
}: EmailStepProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [{ inTeams }] = useTeams();

    const { mutateAsync, isPending, isError, error } = useSendOTP();

    const errorMessage =
        error?.response?.data?.statusCode === 406
            ? 'Free email addresses are not supported. Please use a valid business domain.'
            : 'Please try again';

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!email) {
            inputRef.current?.focus();
        }
        mutateAsync(email)
            .then(({ email_id }) => setMethodId(email_id))
            .then(next)
            // eslint-disable-next-line no-console
            .catch(console.log);
    };

    return (
        <Stack gap={6} component="form" onSubmit={handleSubmit} sx={stepForm}>
            <TextField
                name="token"
                error={isError}
                value={email}
                onChange={(event) => setEmail(event.target.value ?? '')}
                InputProps={{
                    'aria-label': 'Email',
                    ref: inputRef,
                    placeholder: 'Email address',
                    type: 'email',
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon name="email-2" />
                        </InputAdornment>
                    ),
                }}
                helperText={isError ? errorMessage : ''}
                fullWidth
                autoFocus
            />

            {!inTeams && (
                <>
                    <Button
                        fullWidth
                        disabled={isPending || !email}
                        type="submit"
                        color="primary"
                        size="large"
                    >
                        {isPending && <ButtonDotLoader />}
                        {!isPending && 'Proceed'}
                    </Button>

                    <Stack direction="row" alignItems="center" spacing={4}>
                        <Box
                            sx={{
                                flex: 1,
                                height: '1px',
                                background:
                                    'linear-gradient(270deg, #E2E5EA 0%, rgba(226, 229, 234, 0.00) 100%)',
                            }}
                        />
                        <Typography
                            variant="body4"
                            sx={{ textTransform: 'uppercase' }}
                            color="custom.gray.super"
                            fontWeight={600}
                        >
                            Or
                        </Typography>
                        <Box
                            sx={{
                                flex: 1,
                                height: '1px',
                                background:
                                    'linear-gradient(90deg, #E2E5EA 0%, rgba(226, 229, 234, 0.00) 100.87%)',
                            }}
                        />
                    </Stack>

                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<Icon name="microsoft-logo" />}
                        color="primary"
                        size="large"
                        href={import.meta.env.VITE_MS_OAUTH_URL}
                    >
                        Login with Microsoft
                    </Button>
                </>
            )}
        </Stack>
    );
};

export default EmailStep;
